import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { ToastContainer, toast } from "material-react-toastify";
import { Table, Input, Tag, Space, Select } from "antd";
import { getUserFullExport } from '../../app/api/index';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class UserExport extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      filterTable: null,
      data_list: [],
      fullArray: [],
      buttonLodading: false,
    };
  }

  componentDidMount() {
    this.getUserExport();
  }

  getUserExport = async () => {
    var pageCount = 0;
    var form = new FormData();
    form.append("pageNo", 0);
    var res = await getUserFullExport(form);

    console.log("erert")
    console.log(res)

    pageCount = res?.data?.pageCount;
    if (pageCount == 0) {

      this.setState({
        loading: false,
        data_list: [],
        buttonLodading: false
      })
    }
    if (pageCount == 1) {
      this.setState({

        data_list: res.data.data,
        loading: false,
        buttonLodading: false
      });
    }
    if (pageCount > 1) {
      for (let i = 0; i < pageCount; i++) {
        this.getUserExport2(i, pageCount)
        if (i == pageCount - 1) {
          this.setState({
            loading: false,

          })
        }
      }
    }
  };

  getUserExport2 = async (pageNo, pagecout) => {
    var form = new FormData();
    form.append("pageNo", pageNo);
    var res = await getUserFullExport(form);
    if (res.success) {
      this.setState({
        fullArray: [...this.state.fullArray, ...res.data.data]
      }, () => {
        if (pageNo + 1 == pagecout) {
          this.setState({
            buttonLodading: false
          })
        }
        this.setState({
          data_list: this.state.fullArray,
        });

      })
    }
  };

  search = (value) => {
    const baseData = this.state.data_list;
    const filterTable = baseData.filter(
      (o) => o.email.includes(value.toLowerCase())
    );

    this.setState({
      filterTable: filterTable
    });
  };

  render() {
    const filterTable = this.state.filterTable;
    const dataSet1 = this.state.filterTable!=null ? this.state.filterTable:this.state.data_list
    const fontValue = { sz: "12", bold: true, fill: { patternType: "solid", fgColor: { rgb: "FF0B0713" } } };

    const StatisticDataSet = [
      {
        columns: [

          { title: "User ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 210 } },//char width 
          { title: "User Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "NIC", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
          { title: "Email", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
          { title: "Mobile No", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
          { title: "Mobile No 2", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
          { title: "Organization", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
          { title: "Vendor", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
          { title: "Active Status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 
          { title: "Regoin", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width 

        ],
        data: dataSet1?.map((data) => [

          { value: data.userId ? (data.userId) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.name ? (data.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.nic ? (data.nic) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.email ? (data.email) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.mobileNumber ? (data.mobileNumber) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.mobileNumber2 ? (data.mobileNumber2) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.organizationId ? (data.organizationId) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.vendorId ? (data.vendorId) : ("Null"), style: { font: { sz: "12" } } },
          { value: data.status ? ("Yes") : ("No"), style: { font: { sz: "12" } } },
          { value: data.status ? ("Yes") : ("No"), style: { font: { sz: "12" } } },

        ]),
      }
    ];

    const columns = [
      {
        title: "User ID",
        dataIndex: "userId",
        key: "userId",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "User Name",
        dataIndex: "name",
        key: "name",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "NIC",
        dataIndex: "nic",
        key: "nic",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "Mobile No",
        dataIndex: "mobileNumber",
        key: "mobileNumber",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "Active Status",
        dataIndex: "status",
        key: "status",
        render: (item) => <span>{item == 1 ? "Yes" : "No"}</span>,
      },
    ];

    const timestamp = Date.now();

    return (
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <div class="row">
                <div class="col">
                  <h4 class="page-title">User Export</h4>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Admin</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">User Export</a></li>

                  </ol>
                </div>
                <div class="col-auto align-self-center">
                  <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                    <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                    <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-xl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">
                  Report -User Export{" "}
                  <ExcelFile
                    filename={"User List Export -" + timestamp}
                    element={
                      <button
                        disabled={this.state.buttonLodading}
                        class="btn btn-primary"
                        style={{ float: "right" }}
                      >
                        <i class="fas fa-file-export"> </i> {this.state.buttonLodading ? (<img width={20} src={'https://media.tenor.com/wpSo-8CrXqUAAAAj/loading-loading-forever.gif'}></img>) : ('')} User Full Export
                      </button>
                    }
                  >

                    <ExcelSheet dataSet={StatisticDataSet} name="User Full Export" />
                  </ExcelFile>
                </h4>
              </div>
              <div className="card-body">
                <div className='row'>
                  <div className="col-md-10">
                    <Input
                      enterButton
                      style={{ marginBottom: 15 }}
                      placeholder="Search By Email..."
                      value={this.state.currValue}

                      onChange={(e) => {
                        const currValue = e.target.value;
                        this.search(currValue);
                        this.setState({
                          currValue:currValue
                      })
                      }}
                    />
                  </div>
                  <div className="col-md-2" style={{ textAlign: 'end' }}>
                    <button type="button" class="btn btn-primary " onClick={(e) => {
                      this.setState({
                        filterTable: null
                      }, () => {
                        this.setState({
                          currValue: ''
                        })

                      })

                    }} >Clear Search</button>
                  </div>
                </div>



                <Table
                  size="small"
                  columns={columns}
                  loading={this.state.loading}
                  dataSource={filterTable == null ? [...dataSet1].sort((a, b) => b.id - a.id) : [...filterTable].sort((a, b) => b.id - a.id)}
                />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="top-right" autoClose={5000} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    depots: state.depots,
  };
}
export default connect(mapStateToProps)(UserExport);
