import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { createNewShelterType, getAllShelterType, updateShelterTypeByID } from '../api/index';
import { addVendor } from '../actions/index';
import { connect } from "react-redux";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { Modal, Drawer, Button, Popconfirm } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

class ShelterTypes extends Component {

	constructor(props) {
		super(props)
		this.validator = new SimpleReactValidator();
		this.state = {
			name: "",
			shelterTypes: [],
			editDrawerVisible: false
		}
	}

	componentDidMount() {
		this.getAllShelterTypes()
	}

	getAllShelterTypes = async () => {
		var res = await getAllShelterType();
		if (res.success) {
			this.setState({
				shelterTypes: res.data
			})
		}
	}

	showCreateShelterType = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Are You Sure You Want To Add This Shelter Type?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.createNewShelters(),
		});
	};

	showUpdateShelterType = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Are You Sure You Want To Update This Shelter Type?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.updateShelterType(),
		});
	};

	showDeleteConfirm = (record) => {
		const { confirm } = Modal;
		confirm({
			title: 'Are you sure you want to delete this shelter type?',
			onOk: async () => {
				/*var res = await deleteVendor(record.id);
		
				if (res.success) {
				  toast.success('Vendor deleted successfully');
				  this.props.removeVendor(record.id);
				}*/
			},
			onCancel() { },
		});
	};

	showUpdateConfirm = () => {
		const { confirm } = Modal;
		confirm({
		  title: 'Are you sure you want to Update This Shelter Type?',
		  onOk: () => {
			this.updateShelterType();
		  },
		  onCancel() { },
		});
	  };

	createNewShelters = async () => {
		if (this.validator.allValid()) {
			var data = {
				"name": this.state.name,
			}
			var res = await createNewShelterType(data);
			if (res.success) {
				toast.success("Successfully Created")
				this.getAllShelterTypes();
				this.setState({
					"name": ""
				})
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	updateShelterType = async () => {
		var res = await updateShelterTypeByID(this.state.editShaltorDetails, this.state.editShaltorDetails.id);
		if (res.success) {
			toast.success('Shelter Type updated successfully');
			this.closeEditDrawer();
			this.getAllShelterTypes();
		} else {
			toast.error('Something went wrong : ' + res.errorMessage);
			this.closeEditDrawer();
		}
	};

	deleteShelter = async (id) => {
		// delete shelter type logic
	}

	formItemChange = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	}

	showEditDrawer = (record) => {
		this.setState({
			editDrawerVisible: true,
			name: record.name,
			editShaltorDetails: {
				id: record.id,
				name: record.name,
			},
		});
	};

	closeEditDrawer = () => {
		this.setState({ editDrawerVisible: false,name: "", });
	};

	editFormItemChange = (event) => {
		const { name, value } = event.target;
		this.setState((prevState) => ({
			editShaltorDetails: {
				...prevState.editShaltorDetails,
				[name]: value,
			},
		}));
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	};

	render() {
		return (
			<div class="container-fluid">
				<div class="row">
					<div class="col-sm-12">
						<div class="page-title-box">
							<div class="row">
								<div class="col">
									<h4 class="page-title">Shelter Type</h4>
									<ol class="breadcrumb">
										<li class="breadcrumb-item"><a href="javascript:void(0);">Admin</a></li>
										<li class="breadcrumb-item"><a href="javascript:void(0);">Shelter Type</a></li>

									</ol>
								</div>
								<div class="col-auto align-self-center">
									<a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
										<span class="day-name" id="Day_Name">Today:</span>&nbsp;
										<span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
									</a>

								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">New Shelter Type</h4>
							</div>
							<div className="card-body">
								<form>
									<div className="mb-3">
										<label className="form-label" for="name">Name</label>
										<input autoComplete="off" type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp" onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('name', this.state.name, 'required')}</p>
									</div>
								</form>
								<button className="btn btn-primary" onClick={() => this.showCreateShelterType()}>Create New Shelter</button>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Shelter Types</h4>
							</div>
							<div className="card-body">
								<div className="table-responsive">
									<table className="table table-striped mb-0">
										<thead>
											<tr>
												<th> Name</th>
												<th className="text-end">Action</th>
											</tr>
										</thead>
										<tbody>
											{this.state.shelterTypes.map((shelterTypes, index) => {
												return <tr>
													<td>{shelterTypes.name}</td>
													<td className="text-end">
														<a href="#"><i className="las la-pen-alt text-secondary font-16"
															onClick={() => this.showEditDrawer(shelterTypes)}
														></i></a>
														{/*<a href="#"><i className="las la-trash-alt text-secondary font-16" onClick={
															() => this.showDeleteConfirm(shelterTypes)
														}></i></a>*/}
													</td>
												</tr>
											})
											}

										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

					<Drawer
						title="Edit Shelter Type"
						width={500}
						onClose={this.closeEditDrawer}
						visible={this.state.editDrawerVisible}
					>
						<form>
							<div className="mb-3">
								<label className="form-label" htmlFor="editName">
									Edit Shelter Type Name
								</label>
								<input
									autoComplete='off'
									className='form-control'
									type="text"
									name="name"
									value={this.state.name}
									onChange={this.editFormItemChange}
								/>
								<p style={{ color: '#f5325c' }}>
									{this.validator.message(
										'editName',
										this.state.name,
										'required'
									)}
								</p>
							</div>
						</form>
						<button className="btn btn-primary" onClick={() => this.showUpdateConfirm()}>Update Shelter</button>
					</Drawer>
				</div>
				<ToastContainer position="top-right" autoClose={5000} />
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		vendors: state.vendor,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		addVendor: data => dispatch(addVendor(data))
	};
}

export default connect(
	mapStateToProps, mapDispatchToProps
)(ShelterTypes);

