import React, { Component } from 'react';
import GeneralDetails from '../../components/site_components/general_details'
import AssetDetails from '../../components/site_components/asset_details'
import AccessTechnologies from '../../components/site_components/access_technologies'
import MainPowerConnection from '../../components/site_components/main_power_connection'
import SharedBackupPower from '../../components/site_components/shared_backup_power'
import PowerCapacityKw from '../../components/site_components/power_capacity_kw'
import DCLoad from '../../components/site_components/dc_load'
import BatteryBanks from '../../components/site_components/battery_banks'
import AirConditioners from '../../components/site_components/air_conditioners'
import Cabinet from '../../components/site_components/cabinet'
import GeneratorPd from '../../components/site_components/generator_pd'
import Earthing from '../../components/site_components/earthing'
import ATS from '../../components/site_components/ats'
import DCDUS from '../../components/site_components/dcdus'

import FBC from '../../components/site_components/fbc'
import SPD from '../../components/site_components/spd'
import CAE from '../../components/site_components/cae'
import TRCSL from '../../components/site_components/trcsl'
import RES from '../../components/site_components/res'
import REW from '../../components/site_components/rew'
import CabiDetails from '../../components/site_components/cabin_details'
import GeneratorHut from '../../components/site_components/generator_hut'
import SharedOutOperators from '../../components/site_components/shared-out-Operators'
import IDBR from '../../components/site_components/idbattery_racks'
import Sld from '../../components/site_components/sld'

import GeneratorDetails from '../../components/site_components/generator_details'
import DCPowerSharing from '../../components/site_components/dc_power'

import Rectifiers from '../../components/site_components/rectifiers'
import BulckTankDetails from '../../components/site_components/bulck_tank_details'
import ImageCapture from '../../components/site_components/image_capture'
import { withRouter } from 'react-router-dom';
import { getSiteDetails } from '../../api/Repos/SiteRepo';
import { getAllAtsMakes, getPowerDetailsBySite, getMasterDataSiteInfo, getPowerSummaryData, getReMasterData, updateNoOfValues, getupdateGeneralDetailsById, getAllDropdowns, getRefData } from "../../api/index";

import { Link } from "react-router-dom";
import GoogleMapReact from 'google-map-react';
import Item from 'antd/lib/list/Item';
import { message } from 'antd';



const AnyReactComponent = ({ text }) => <div>

    <i style={{ color: "#ff0000", fontSize: 30 }} class="fas fa-map-marker-alt"></i>
</div>;

class SiteSummary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            site_details: {},
            site_new_details: {},
            access_technologies_data: null,
            battery_banks_data: null,
            air_conditioners: null,
            cabinet_data: null,
            generators_data: null,
            ats_data: null,
            dcdus_data: null,
            idBatteryRacks_data: null,
            sharedOutOperatorses_data: null,
            spd_data: null,
            fcbs_data: null,
            renewableEnergySolars_data: null,
            renewableEnergyWind_data: null,
            rectifiers_data: null,
            powerSummaryDataSet: null,
            dcduMasterData: null,
            cabinetMasterData: {
                cabinetModelList: [],
                cabinetMakeList: [],
            },
            rewMasterData: {
                rewMakelList: [],

            },
            bbMasterData: {
                bbModelList: [],
                bbMakeList: [],
                cabinetList: []
            },
            atsMasterData: {
                atsModelList: [],
                atsMakeList: [],
            },
            fcdMasterData: {
                fcdModelList: [],
                fcdMakeList: [],
            },
            spdMasterData: {

                spdMakeList: [],
            },

            center: {
                lat: 3.8091,
                lng: 79.872
            },
            centerLat: null,
            centerlng: null,

            zoom: '14',
            resMasterData: {
                rectifierList: [],
            },
            mpConnectionMasterData: {
                powerConnectionType: [],
            },
            acMasterData: {
                acMakeList: []
            },
            genHutTypeMasterData: {
                typeSet: []
            },

            updateGeneralDetails: [],
            commonDropDownData: [],

            dropDownObjectSetRectifier: {
                "rectifierPlannedFromOperationalTeam": [],
                "rectifierPlannedFromPlanninglTeam": [],
                "rectifierWithCabinetPlannedFromOperationalTeam": [],
                "rectifierWithCabinetPlannedFromPlanninglTeam": [],
                "rectifierModulesPlannedFromOperationalTeam": [],
                "rectifierModulesPlannedFromPlanninglTeam": [],
                "rectifierControllerModel": [],
                "rectifierControllerSoftwareVersion": [],
                "rectifiernoOfModulesAtSite": [],
                "rectifierNoOfPoweredRecModules": [],
                "rmsintegratedStatus": [],
                "rmsinbandOutband": [],
                "rectifierNoOfBreakerSpacesAvailable": [],
                "rectifierNoOfBreakerSpacesAvailable100A": [],
                "rectifierRMSConnectionMode": [],
            },
            generatorDropDownObject: {
                "generatorControllerModel": [],
                "avrModel": [],
                "fuelTankCapacity": [],
                "availabilityOfGenCanapy": [],
                "genCanapyStatus": [],
                "soundProofStatus": [],
                "delayTimerStatus": [],
                "rmsintegratedStatus": [],
                "rmsInbandOutband": [],
                "rmsConnectionMethod": [],
                "genEngineModel": [],
            },
            dropdownObjSolar: {
                "resShelterDirectionFromTheTower": [],
                "resNonShadingSpaceDirectionFromTheTower": [],
                "resPvmake": [],
                "resInstalledLocation": [],
                "resSolarPanelInstalledOverTheOutdoorCabinet": [],
                "resInverterType": [],
                "resConverterInverterQuantity": [],
                "resRmsconnectionInband": [],
                "rmsRmsconnectionMode": [],
            },
            dropdownObjWind: {
                "rewPotential": [],
                "rewWindTurbineCount": [],
                "rewInstalledLocation": [],
                "rewRmsconnectionInband": [],
                "rewEnergyFeed": [],
                "rewRmsconnectionMode": [],
            },
            dropdownObjSoo: {
                "powerConnectionAuthority": [],
                "soohandletenant": [],
                "sharedPowerType": [],
                "sooCommercialPowerNoofPhases": [],
                "sooCommercialPowerPerPhaseCapacity": [],
                "sooGenPowerPerPhaseCapacity": [],
                "sooGenPowerNoofPhases": [],
                "sooDcPowerCapacity": [],
                "sooBillingMethod": [],
            },
            objSetDropdownCabinets: {
                "cabinetutilizedEquipmentSpace": [],
                "cabinetutilizedBatterySpace": [],
                "cnoOfCabinetsWithEquipmentInstalled": [],
                "cplannedFromPlanninglTeam": [],
                "cplannedFromOperationalTeam": [],
            },
            dropdownDetailsCabins: {
                "noofIndoorCabins": [],
                "indoorCabinType": [],
            },
            objectDropdownAc: {
                "airCoolingMethod": [],
                "acModeOfOperation": [],
            },
            dropdownObjGenHut: {
                "SpaceAvailableNewGen": [],
                "NumberofGeneratorHut": [],
                "availableGeneratorHut1Type": [],
                "availableGeneratorHut2Type": [],
            },
            objDropdowndcdu: {
                "dcduType": [],
                "dcduPowerFromLLVD": [],
                "dcduRbcapacityToPowerDCDU": [],
                "dcduNoOfBreakerSlots": [],
                "dcduFreeBreakerSlots": [],
            },
            spdDropdownObject: {
                "spdDeployedLocation": [],
                "spdProtectionViaHrcFuseIsolator": [],
            },
            dropdownObjIDBR: {
                "ibridbatteryrackModel": [],
                "iDRModelCount": [],
            },

            objBulktankDropdown: {
                "capacity": [],
            },
            dropdownObjEarthing: {
                "earERImprovementStatus": [],
            },
            dropdownATS: {
                "atsLowVoltageFunctionality": [],
            },
            dropdownObjBB: {
                "bbBatteryTemperatureSensorInstalledCorrectly": [],
            },

            allATSMakes : []

        }
    }

    componentDidMount() {
        this.getSiteData();
        this.getPowerdBData();
        this.getDataMasterDataSiteInfo();
        this.getCommonDropDownData();
        //this.getRefDatas();
    }

    getupdateGeneralDetailsById = async (id) => {
        const formData = new FormData();
        formData.append('id', id);

        var res = await getupdateGeneralDetailsById(formData);

        if (res.success) {
            this.setState({
                updateGeneralDetails: res?.data
            })
        }
    }

    // getRefDatas = async () => {
    //     var res = await getRefData();
    //     if (res.success) {
    //         console.log("-----------------------------------------------+++++++++++++++++++++++++++++jkjkjkjkjkjk");
    //         console.log(res.data);
    //     }
    // }
    getCommonDropDownData = async () => {
        var res = await getAllAtsMakes();
        if (res.success) {
            this.setState({
                allATSMakes: res.data ? res.data : []
            })
        }
        
        var res = await getAllDropdowns();
        console.log(res);
        if (res.success) {
            message.success("Common DropDowns Loaded Successfully");
            console.log("Common DropDown Data Feting Success ----------------------------------------------------------------------------------------------");
            console.log(res.data);
            console.log(res.data["Rectifiers"]["Planned from Operational team"]);
            this.setState({
                dropDownObjectSetRectifier: {
                    "rectifierPlannedFromOperationalTeam": res.data["Rectifiers"]["Planned from Operational team"]? res.data["Rectifiers"]["Planned from Operational team"] : [],
                    "rectifierPlannedFromPlanninglTeam": res.data["Rectifiers"]["Planned from Planning team"]? res.data["Rectifiers"]["Planned from Planning team"] : [],
                    "rectifierWithCabinetPlannedFromOperationalTeam": res.data["Rectifier with Cabinet units"]["Planned from Operational team"]? res.data["Rectifier with Cabinet units"]["Planned from Operational team"] : [],
                    "rectifierWithCabinetPlannedFromPlanninglTeam": res.data["Rectifier with Cabinet units"]["Planned from Planningl team"]? res.data["Rectifier with Cabinet units"]["Planned from Planningl team"] : [],
                    "rectifierModulesPlannedFromOperationalTeam": res.data["Rectifier modules"]["Planned from Operational team"]? res.data["Rectifier modules"]["Planned from Operational team"] : [],
                    "rectifierModulesPlannedFromPlanninglTeam": res.data["Rectifier modules"]["Planned from Planningl team"]? res.data["Rectifier modules"]["Planned from Planningl team"] : [],
                    "rectifierControllerModel": res.data["Rectifier 1"]["Rectifier Controller Model"]? res.data["Rectifier 1"]["Rectifier Controller Model"] : [],
                    "rectifierControllerSoftwareVersion": res.data["Rectifier 1"]["Rectifier Controller software Version"]? res.data["Rectifier 1"]["Rectifier Controller software Version"] : [],
                    "rectifiernoOfModulesAtSite": res.data["Rectifier 1"]["No. of modules at site"]? res.data["Rectifier 1"]["No. of modules at site"] : [],
                    "rectifierNoOfPoweredRecModules": res.data["Rectifier 1"]["No. of Powered Rec Modules"]? res.data["Rectifier 1"]["No. of Powered Rec Modules"] : [],
                    "rmsintegratedStatus": res.data["Rectifier 1"]["RMS integrated status"]? res.data["Rectifier 1"]["RMS integrated status"] : [],
                    "rmsinbandOutband": res.data["Rectifier 1"]["RMS inband/outband"]? res.data["Rectifier 1"]["RMS inband/outband"] : [],
                    "rectifierNoOfPhases": res.data["Rectifier 1"]["No of phases"]? res.data["Rectifier 1"]["No of phases"] : [],
                    "rectifierNoOfBreakerSpacesAvailable": res.data["Rectifier 1"]["No of breaker spaces available (size 80A or below)"]? res.data["Rectifier 1"]["No of breaker spaces available (size 80A or below)"] : [],
                    "rectifierNoOfBreakerSpacesAvailable100A": res.data["Rectifier 1"]["No of breaker spaces available (size 100A or above)"]? res.data["Rectifier 1"]["No of breaker spaces available (size 100A or above)"] : [],
                    "rectifierRMSConnectionMode": res.data["Rectifier 1"]["RMS connection method"]? res.data["Rectifier 1"]["RMS connection method"] : [],
                },
                generatorDropDownObject: {
                    "generatorControllerModel": res.data["Generator 1"]["Generator Controller Model"]? res.data["Generator 1"]["Generator Controller Model"] : [],
                    "avrModel": res.data["Generator 1"]["AVR model"]? res.data["Generator 1"]["AVR model"] : [],
                    "fuelTankCapacity": res.data["Generator 1"]["Fuel Tank Capacity (l)"]? res.data["Generator 1"]["Fuel Tank Capacity (l)"] : [],
                    "availabilityOfGenCanapy": res.data["Generator 1"]["Availability of gen Canapy"]? res.data["Generator 1"]["Availability of gen Canapy"] : [],
                    "genCanapyStatus": res.data["Generator 1"]["Gen Canapy status"]? res.data["Generator 1"]["Gen Canapy status"] : [],
                    "soundProofStatus": res.data["Generator 1"]["Sound proof Status"]? res.data["Generator 1"]["Sound proof Status"] : [],
                    "delayTimerStatus": res.data["Generator 1"]["Delay timer Status"]? res.data["Generator 1"]["Delay timer Status"] : [],
                    "rmsintegratedStatus": res.data["Generator 1"]["RMS integrated status"]? res.data["Generator 1"]["RMS integrated status"] : [],
                    "rmsInbandOutband": res.data["Generator 1"]["RMS inband/outband"]? res.data["Generator 1"]["RMS inband/outband"] : [],
                    "rmsConnectionMethod": res.data["Generator 1"]["RMS connection method"]? res.data["Generator 1"]["RMS connection method"] : [],
                    "genEngineModel": res.data["Generator 1"]["Engine Model"]? res.data["Generator 1"]["Engine Model"] : [],
                    "genCapacity": ["7.5", "8", "9.1", "10", "11.5", "12", "12.5", "13", "13.5", "15", "16", "17", "17.5", "18", "20", "22", "22.5", "22.7", "24", "25", "26", "27", "30", "35", "40", "42", "46", "55", "60", "66", "80", "100", "110", "140", "160", "167", "200", "250", "275", "300"],
                    "genMake": ["Airman", "AJ Power", "Ashok Layland", "Cummins", "Denyo", "Deutz", "Dulux", "FG Wilson", "Hired Gen", "Jubilee Power", "Kirloskar", "Kubota", "Mahendra", "Masons Power", "Olympian", "Polar Power", "Power Pro", "Power Source", "Powerlink", "Pramac", "Shindaiwa", "Soar Power", "TAIYO", "Tempast", "Volvo", "Yanmar", "Yoraka"],
                },
                dropdownObjSolar: {
                    "resShelterDirectionFromTheTower": res.data["Renewable Energy - Solar"]["Shelter Direction from the tower"],
                    "resNonShadingSpaceDirectionFromTheTower": res.data["Renewable Energy - Solar"]["Non shading space Direction from the tower"],
                    "resPvmake": res.data["Renewable Energy - Solar"]["Solar Panel Make"]? res.data["Renewable Energy - Solar"]["Solar Panel Make"] : [],
                    "resInstalledLocation": res.data["Renewable Energy - Solar"]["Installed location"]? res.data["Renewable Energy - Solar"]["Installed location"] : [],
                    "resSolarPanelInstalledOverTheOutdoorCabinet": res.data["Renewable Energy - Solar"]["Solar panel installed over the outdoor cabinet"]? res.data["Renewable Energy - Solar"]["Solar panel installed over the outdoor cabinet"] : [],
                    "resInverterType": res.data["Renewable Energy - Solar"]["Inverter / Converter  type"]? res.data["Renewable Energy - Solar"]["Inverter / Converter  type"] : [],
                    "resConverterInverterQuantity": res.data["Renewable Energy - Solar"]["Converter/ Inverter Quantity"]? res.data["Renewable Energy - Solar"]["Converter/ Inverter Quantity"] : [],
                    "resRmsconnectionInband": res.data["Renewable Energy - Solar"]["RMS connection Inband/Outband"]? res.data["Renewable Energy - Solar"]["RMS connection Inband/Outband"] : [],
                    "rmsRmsconnectionMode": res.data["Renewable Energy - Solar"]["RMS connection mode"]? res.data["Renewable Energy - Solar"]["RMS connection mode"] : [],
                },
                dropdownObjWind: {
                    "rewPotential": res.data["Renewable Energy - Wind"]["Wind potential (Strong wind/ average wind/ low wind/NA)"]? res.data["Renewable Energy - Wind"]["Wind potential (Strong wind/ average wind/ low wind/NA)"] : [],
                    "rewWindTurbineCount": res.data["Renewable Energy - Wind"]["Wind turbine count"]? res.data["Renewable Energy - Wind"]["Wind turbine count"] : [],
                    "rewInstalledLocation": res.data["Renewable Energy - Wind"]["Installed location (tower mounted/ ground)"]? res.data["Renewable Energy - Wind"]["Installed location (tower mounted/ ground)"] : [],
                    "rewRmsconnectionInband": res.data["Renewable Energy - Wind"]["RMS connection Inband/Outband"]? res.data["Renewable Energy - Wind"]["RMS connection Inband/Outband"] : [],
                    "rewEnergyFeed": res.data["Renewable Energy - Wind"]["Energy feed onto the DC/AC bus"]? res.data["Renewable Energy - Wind"]["Energy feed onto the DC/AC bus"] : [],
                    "rewRmsconnectionMode": res.data["Renewable Energy - Wind"]["RMS connection mode"]? res.data["Renewable Energy - Wind"]["RMS connection mode"] : [],
                },
                dropdownObjSoo: {
                    "powerConnectionAuthority": ["CEB", "LECO"],
                    "soohandletenant": ["ABC", "Airtel", "ARAL", "Buddhist Radio", "CEB", "Derana", "EAP", "EAP Networks", "Frankils Developments", "Hiru", "Etisalat / Hutch", "ITN", "Lak FM", "LAK-FM", "LankaBell", "LankaCom", "LECO", "MBC", "Mobitel", "MTV", "Neth FM", "Neth-FM", "People's Media", "Power House", "Rangiri", "Rupawahini", "Sirasa", "SiyathaFM", "SLA", "SLAF", "SLBC", "SLN", "SLT", "SRI-FM", "Tritel", "Try Mas Media Network", "TV Lanka", "Vendol Media", "V-FM"],
                    "sharedPowerType": ["AC Commercial Power", "DC Power - Without Gen Backup", "DC Power - FTG", "Gen Backup Power", "AC Commercial Power + Gen", "DC Power - With Gen Backup", "Full Time Gen Power"],
                    "sooCommercialPowerNoofPhases": res.data["Shared Out-Operator 1"]["Commercial Power- No of Phases"],
                    "sooCommercialPowerPerPhaseCapacity": res.data["Shared Out-Operator 1"]["Commercial Power- Per Phase Capacity (A)"]? res.data["Shared Out-Operator 1"]["Commercial Power- Per Phase Capacity (A)"] : [],
                    "sooGenPowerPerPhaseCapacity": res.data["Shared Out-Operator 1"]["Gen Power- Per Phase Capacity (A)"]? res.data["Shared Out-Operator 1"]["Gen Power- Per Phase Capacity (A)"] : [],
                    "sooGenPowerNoofPhases": res.data["Shared Out-Operator 1"]["Gen Power- No of Phases"]? res.data["Shared Out-Operator 1"]["Gen Power- No of Phases"] : [],
                    "sooDcPowerCapacity": res.data["Shared Out-Operator 1"]["DC power capacity (A)"]? res.data["Shared Out-Operator 1"]["DC power capacity (A)"] : [],
                    "sooBillingMethod": res.data["Shared Out-Operator 1"]["Billing method"]? res.data["Shared Out-Operator 1"]["Billing method"] : [],
                },
                objSetDropdownCabinets: {
                    "cabinetutilizedEquipmentSpace": res.data["Cabinets"]["Utilized Equipment space (U)"]? res.data["Cabinets"]["Utilized Equipment space (U)"] : [],
                    "cabinetutilizedBatterySpace": res.data["Cabinets"]["Utilized Battery Space (U)"]? res.data["Cabinets"]["Utilized Battery Space (U)"] : [],
                    "cnoOfCabinetsWithEquipmentInstalled": res.data["Cabinets"]["No of Cabinets with Equipment installed"]? res.data["Cabinets"]["No of Cabinets with Equipment installed"] : [],
                    "cplannedFromPlanninglTeam": ["Yes", "No"],
                    "cplannedFromOperationalTeam": ["Yes", "No"],
                },
                dropdownDetailsCabins: {
                    "noofIndoorCabins": res.data["General Details"]["No of Indoor Cabins"]? res.data["General Details"]["No of Indoor Cabins"] : [],
                    "indoorCabinType": res.data["General Details"]["Indoor Cabin Type"]? res.data["General Details"]["Indoor Cabin Type"] : [],
                },
                objectDropdownAc: {
                    "airCoolingMethod": res.data["General Details"]["Cooling Method"]? res.data["General Details"]["Cooling Method"] : [],
                    "acModeOfOperation": res.data["AC-1"]["Mode of Operation"]? res.data["AC-1"]["Mode of Operation"] : [],
                },
                dropdownObjGenHut: {
                    "SpaceAvailableNewGen": res.data["General Details"]["Generator space availability for a new generator"]? res.data["General Details"]["Generator space availability for a new generator"] : [],
                    "NumberofGeneratorHut": res.data["General Details"]["Number of Generator Hut"]? res.data["General Details"]["Number of Generator Hut"] : [],
                    "availableGeneratorHut1Type": res.data["General Details"]["Available Generator Hut 1 Type"]? res.data["General Details"]["Available Generator Hut 1 Type"] : [],
                    "availableGeneratorHut2Type": res.data["General Details"]["Available Generator Hut 2 Type"]? res.data["General Details"]["Available Generator Hut 2 Type"] : [],
                },
                objDropdowndcdu: {
                    "dcduType": res.data["DCDU"]["Type"]? res.data["DCDU"]["Type"] : [],
                    "dcduPowerFromLLVD": res.data["DCDU"]["Power from LLVD/BLVD"]? res.data["DCDU"]["Power from LLVD/BLVD"] : [],
                    "dcduRbcapacityToPowerDCDU": res.data["DCDU"]["Rectifier Breaker capacity (A) to power DCDU"]? res.data["DCDU"]["Rectifier Breaker capacity (A) to power DCDU"] : [],
                    "dcduNoOfBreakerSlots": res.data["DCDU"]["No of breaker/Fuse Slots"]? res.data["DCDU"]["No of breaker/Fuse Slots"] : [],
                    "dcduFreeBreakerSlots": res.data["DCDU"]["Free breaker/Fuse Slots "]? res.data["DCDU"]["Free breaker/Fuse Slots "] : [],
                },
                spdDropdownObject: {
                    "spdDeployedLocation": res.data["SPD 1"]["Deployed Location"]? res.data["SPD 1"]["Deployed Location"] : [],
                    "spdProtectionViaHrcFuseIsolator": res.data["SPD 1"]["SPD Protection via HRC Fuse/Isolator"]? res.data["SPD 1"]["SPD Protection via HRC Fuse/Isolator"] : [],
                },
                dropdownObjIDBR: {
                    "ibridbatteryrackModel": res.data["ID Battery Rack 1"]["ID battery rack  model"]? res.data["ID Battery Rack 1"]["ID battery rack  model"] : [],
                    "iDRModelCount": res.data["ID Battery Rack 1"]["ID battery rack Model count"]? res.data["ID Battery Rack 1"]["ID battery rack Model count"] : [],
                },
                bulckTankDropdown: {
                    "capacity": res.data["Generator 1"]["Bulk tank Capacity (l)"]? res.data["Generator 1"]["Bulk tank Capacity (l)"] : [],
                },
                dropdownObjEarthing : {
                    "earERImprovementStatus": res.data["Earthing"]["ER Improvement status (WIP/Completed)"]? res.data["Earthing"]["ER Improvement status (WIP/Completed)"] : [],
                },
                dropdownObjBB: {
                    "bbBatteryTemperatureSensorInstalledCorrectly": res.data["Battery Bank 1"]["Battery temperature sensor installed correctly"]? res.data["Battery Bank 1"]["Battery temperature sensor installed correctly"] : [],
                },
                dropdownATS: {
                    "atsLowVoltageFunctionality": res.data["ATS"]["Low voltage functionality"]? res.data["ATS"]["Low voltage functionality"] : [],
                },

            })
            console.log(this.state.rectifierPlannedFromOperationalTeam)
            console.log(this.state.dropDownObjectSetRectifier)
            console.log(this.state.generatorDropDownObject)
            console.log("Common DropDown Data Feting Success ----------------------------------------------------------------------------------------------");

        } else {
            message.error(res.message);
            this.setState({
                commonDropDownData: {}
            })
        }
    }

    getDataPowerSummaryData = async (siteId) => {
        var data = new FormData();
        data.append('siteIds', siteId);
        var res = await getPowerSummaryData(data);
        console.log('getPowerSummaryData12')
        console.log(res)

        if (res.success) {

            this.state.site_new_details["powerSummaryDetaSet"] = res.data

            this.setState({
                site_details: this.state.site_new_details
            })


        } else {
            this.setState({
                site_details: this.state.site_new_details
            })
        }

    }
    getDataMasterDataSiteInfo = async () => {
        var res = await getMasterDataSiteInfo();
        var res2 = await getReMasterData();
        console.log("176")
        console.log(res)
        console.log("178")

        console.log(res2)

        if (res.success) {
            this.setState({
                rectifiersMasterData: {
                    rectifiersMakeList: res.data?.rectifierMake,
                    rectifiersModelList: res.data?.rectifierModel,
                    cabinetList: this.state.cabinet_data,
                    rectifierModuleModelList: res2?.data?.rectifierModuleModel,
                    renewableEnergySolaList: this.state.renewableEnergySolars_data,
                    renewableEnergyWind: this.state.renewableEnergyWind_data,
                },
                cabinetMasterData: {
                    cabinetMakeList: res.data?.cabinetMake,
                    cabinetModelList: res.data?.cabinetModel,
                    batteryBanksList: this.state.battery_banks_data,
                    cabinetTypeList: res.data?.cabinetType,
                },
                rewMasterData: {
                    rewMakelList: res.data?.renewableEnergyMake,
                    rectifierList: this.state.rectifiers_data
                },
                bbMasterData: {
                    bbMakeList: res.data?.batteryBankMake,
                    bbModelList: res.data?.batteryBankModel,
                    cabinetList: this.state.cabinet_data,
                    rectifierList: this.state.rectifiers_data,
                    bbTypeList: res.data?.batteryBankType,
                },
                atsMasterData: {
                    atsMakeList: res.data?.atsMake,
                    atsModelList: res.data?.atsModel,
                },
                fcdMasterData: {
                    fcdModelList: res.data?.fcbModel,
                    fcdMakeList: res.data?.fcbMake,
                },
                spdMasterData: {
                    spdMakeList: res.data?.spdMake,
                },
                dcduMasterData: {
                    rectifierList: this.state.rectifiers_data
                },
                resMasterData: {
                    rectifierList: this.state.rectifiers_data,
                    inverterConverterModelList: res2?.data?.inverterConverterModel,
                    solarPanelModelList: res2?.data?.solarPanelModel
                },
                mpConnectionMasterData: {
                    powerConnectionType: res?.data?.powerConnectionType
                },
                genHutTypeMasterData: {
                    typeSet: res.data?.genHutType
                },
                acMasterData: {
                    acMakeList: res.data?.acMake,
                    acTypeList: res.data?.acType
                },
                genMasterData: {
                    genMake: res.data?.genMake,
                },

            })

        }

    }
    getPowerdBData = async () => {
        //update site asset count
        this.updateNoOfValues();

        var data = JSON.stringify({
            "id": this.props.match.params.id
        });
        var res = await getPowerDetailsBySite(data);
        console.log('fullData-------------------------------------------------------------------------------------------------------------------------------------------')
        console.log(res)
        if (res.success) {
            this.setState({
                allData: res.data,
                access_technologies_data: res.data?.sitePowerDb,
                battery_banks_data: res.data?.batteryBanks,
                air_conditioners: res.data?.airConditioners,
                cabinet_data: res.data?.cabinets,
                generators_data: res.data?.generators,
                ats_data: res.data?.atses,
                dcdus_data: res.data?.dcdus,
                idBatteryRacks_data: res.data?.idBatteryRacks,
                sharedOutOperatorses_data: res.data?.sharedOutOperatorses,
                renewableEnergySolars_data: res.data?.renewableEnergySolars,
                renewableEnergyWind_data: res.data?.renewableEnergyWinds,
                spd_data: res.data?.spds,
                fcbs_data: res.data?.fcbs,
                rectifiers_data: res.data?.rectifiers,
            }, () => {
                console.log("srtrrr")
                console.log(this.state.rectifiers_data)
            })
            this.getDataMasterDataSiteInfo();
            this.getSiteData();
            if (this.state.access_technologies_data != null) {
                this.getupdateGeneralDetailsById(this.state.access_technologies_data.id)
            }

        }
    }

    getSiteData = async () => {
        var res = await getSiteDetails(this.props.match.params.id);
        if (res.success) {


            if (res.data.siteId) {
                this.getDataPowerSummaryData(res.data.siteId);
            }
            console.log(res.data);
            if (res.data.siteAddressList[0] != null) {
                //console.log(2);

            } else {


            }

            if (res.data.siteAddressList[0] != null && res.data.siteAddressList[0]?.latitude != '' && res.data.siteAddressList[0]?.longitude != '' && res.data.siteAddressList[0]?.latitude != 'Null' && res.data.siteAddressList[0]?.longitude != 'Null' && res.data.siteAddressList[0]?.latitude != null && res.data.siteAddressList[0]?.longitude !== null && isNaN(res.data.siteAddressList[0]?.longitude) == false && isNaN(res.data.siteAddressList[0]?.latitude) == false) {

                this.setState({
                    site_new_details: res.data,
                    zoom: '14',

                    center: {
                        lat: parseFloat(res.data.siteAddressList[0]?.latitude !== null ? (res.data.siteAddressList[0]?.latitude) : ('')),
                        lng: parseFloat(res.data.siteAddressList[0]?.longitude !== null ? (res.data.siteAddressList[0]?.longitude) : ('')),
                    },
                    centerLat: parseFloat(res.data.siteAddressList[0]?.latitude !== null ? (res.data.siteAddressList[0]?.latitude) : ('')),
                    centerlng: parseFloat(res.data.siteAddressList[0]?.longitude !== null ? (res.data.siteAddressList[0]?.longitude) : ('')),
                }, () => {
                    console.log('this.state.center')
                    console.log(this.state.center)
                })
            } else {

                this.setState({
                    site_new_details: res.data,
                    zoom: '14',
                    centerLat: 6.8091,
                    centerlng: 80.872,
                    center: {
                        lat: 0.000,
                        lng: 0.00,
                    },

                }, () => {
                    //console.log(this.state.center)
                })
            }
            console.log('this.state.site_details')

        }
    }

    updateNoOfValues = async () => {
        var data = new FormData();
        data.append("siteId", this.props.match.params.id);
        await updateNoOfValues(data);
    }

    render() {
        const { powerSummaryDataSet } = this.state
        const { site_details } = this.state
        const { access_technologies_data } = this.state
        const { battery_banks_data } = this.state
        const { air_conditioners } = this.state
        const { cabinet_data } = this.state
        const { generators_data } = this.state
        const { ats_data } = this.state
        const { dcdus_data } = this.state
        const { idBatteryRacks_data } = this.state
        const { sharedOutOperatorses_data } = this.state
        const { renewableEnergySolars_data } = this.state
        const { renewableEnergyWind_data } = this.state
        const { spd_data } = this.state
        const { fcbs_data } = this.state
        const { rectifiers_data } = this.state
        const { allData } = this.state
        const { atsMasterData } = this.state
        const { bbMasterData } = this.state
        const { rewMasterData } = this.state
        const { fcdMasterData } = this.state
        const { spdMasterData } = this.state
        const { cabinetMasterData } = this.state
        const { rectifiersMasterData } = this.state
        const { dcduMasterData } = this.state
        const { resMasterData } = this.state
        const { mpConnectionMasterData } = this.state
        const { genHutTypeMasterData } = this.state
        const { acMasterData } = this.state
        const { genMasterData } = this.state
        const { updateGeneralDetails } = this.state
        const { dropDownObjectSetRectifier } = this.state
        const { generatorDropDownObject } = this.state
        const { dropdownObjSolar } = this.state
        const { dropdownObjWind } = this.state
        const { dropdownObjSoo } = this.state
        const { objSetDropdownCabinets } = this.state
        const { dropdownDetailsCabins } = this.state
        const { objectDropdownAc } = this.state
        const { dropdownObjGenHut } = this.state
        const { objDropdowndcdu } = this.state
        const { spdDropdownObject } = this.state
        const { dropdownObjIDBR } = this.state
        const { bulckTankDropdown } = this.state
        const { dropdownObjEarthing } = this.state
        const { dropdownObjBB } = this.state
        const { dropdownATS } = this.state
        const { allATSMakes } = this.state


        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title">Site Summary</h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="javascript:void(0);">Site</a></li>
                                        <li className="breadcrumb-item"><a href="javascript:void(0);">{site_details.siteName}</a></li>
                                        <li className="breadcrumb-item active">{site_details.siteId}</li>
                                    </ol>
                                </div>


                                <div className="col-auto align-self-center">

                                    {this.state.site_details.status !== '' ? (<span class="ant-tag ant-tag-has-color" style={{ backgroundColor: 'green', fontSize: 14 }}>{this.state.site_details.status}</span>) : ('')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body p-0" style={{ clipPath: 'ellipse(100% 75% at 50% 23%)' }}>
                                <div id="user_map" className="pro-map" style={{ height: '320px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {this.state.centerLat !== null && this.state.centerlng !== null ? (<GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyC7QjauaBLV9W__koQgy65PIuxYbcSPpfg" }}
                                        defaultCenter={{
                                            lat: this.state.centerLat,
                                            lng: this.state.centerlng
                                        }}
                                        defaultZoom={11}
                                    >

                                        <AnyReactComponent
                                            lat={this.state.center.lat}
                                            lng={this.state.center.lng}
                                            text=""
                                        />
                                    </GoogleMapReact>) : ('')}

                                </div>
                            </div>
                            <div class="card-body">
                                <div class="dastone-profile">
                                    <div class="row">
                                        <div class="col-lg-5 align-self-center mb-3 mb-lg-0">
                                            <div class="dastone-profile-main">
                                                <div class="dastone-profile-main-pic">
                                                    <img src={'https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG'} alt="" height="110" class="rounded-circle"></img>

                                                </div>
                                                <div class="dastone-profile_user-detail">
                                                    <h5 class="dastone-user-name">{site_details.siteName}</h5>
                                                    <p className="mb-0 text-secondary font-16 align-middle me-2"><span class="ant-tag ant-tag-has-color" style={{ backgroundColor: 'gray', fontSize: 14 }}>{site_details.siteId}</span></p>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4 ms-auto align-self-center">
                                            <ul class="list-unstyled personal-detail mb-0">
                                                <li className="mt-2"><i className="ti ti-map-alt text-secondary font-16 align-middle me-2"></i> <b> Region </b> : {site_details?.depot?.region?.name}</li>
                                                <li className="mt-2"><i className="ti ti-home text-secondary font-16 align-middle me-2"></i> <b> Depot </b> : {site_details?.depot?.name}</li>
                                                <li className="mt-2"><i className="ti ti-email text-secondary font-16 align-middle me-2"></i> <b> Address </b> :
                                                    <a className="font-14 text-primary"> {site_details.siteAddressList != null && site_details?.siteAddressList[0]?.address}</a>
                                                </li>
                                            </ul>

                                        </div>
                                        <div class="col-lg-3 align-self-center">
                                            <div class="row">
                                                <div class="col-auto text-end border-end">
                                                    <button type="button" class="btn btn-soft-primary btn-icon-circle btn-icon-circle-sm mb-2">
                                                        <i class="fas fa-user"></i>
                                                    </button>
                                                    <p class="mb-0 fw-semibold">Depot Office</p>
                                                    <h4 class="m-0 fw-bold"> <span class="text-muted font-12 fw-normal">{this.state.site_details?.depot?.depotOfficers[0]?.name}</span></h4>
                                                </div>
                                                <div class="col-auto">
                                                    <button type="button" class="btn btn-soft-info btn-icon-circle btn-icon-circle-sm mb-2">
                                                        <i class="fas fa-phone"></i>
                                                    </button>
                                                    <p class="mb-0 fw-semibold">Contact Number</p>
                                                    <h4 class="m-0 fw-bold"> <span class="text-muted font-12 fw-normal">{this.state.site_details?.depot?.depotOfficers[0]?.mobileNumber.slice(0, -7) + ' ' + this.state.site_details?.depot?.depotOfficers[0]?.mobileNumber.slice(2, -4) + ' ' + this.state.site_details?.depot?.depotOfficers[0]?.mobileNumber.slice(-4)}</span></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="card">

                    <div className="card-body">

                        <div class="pb-4">
                            <ul class="nav-border nav nav-pills mb-0" id="pills-tab" role="tablist" style={{ fontSize: 13 }}>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link active" data-bs-toggle="tab" href="#home" role="tab" aria-selected="false">General</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link " data-bs-toggle="tab" href="#sld" role="tab" aria-selected="false">SLD</a>
                                </li>

                                {/* <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#profile" role="tab" aria-selected="false">Assets</a>
                                        </li>  */}
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link" data-bs-toggle="tab" href="#imageCapture" role="tab" aria-selected="false">Image</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link" data-bs-toggle="tab" href="#accessTechnologies" role="tab" aria-selected="true">Access Technologies</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link" title="Main Power Connection" data-bs-toggle="tab" href="#mainPowerConnection" role="tab" aria-selected="true">Main Power</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link" data-bs-toggle="tab" href="#sharedBackupPower" role="tab" aria-selected="true" title="Shared Backup Power" >Shared Gen Backup</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link" data-bs-toggle="tab" href="#dcPowerSharing" role="tab" aria-selected="true" title="DC Power" >DC Power Share-IN</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link" data-bs-toggle="tab" href="#power_capacity_kw" role="tab" aria-selected="true">Power Capacity(KW)</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link" data-bs-toggle="tab" href="#dc_load" role="tab" aria-selected="true">DC Load</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="Cabinets" className="nav-link" data-bs-toggle="tab" href="#cabinet" role="tab" aria-selected="true">Outdoor Cabinets</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link" data-bs-toggle="tab" href="#rectifiers" role="tab" aria-selected="true">Rectifiers</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="DCDU" className="nav-link" data-bs-toggle="tab" href="#dcdus" role="tab" aria-selected="true">DCDU</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link" data-bs-toggle="tab" href="#battery_banks" role="tab" aria-selected="true">Battery Banks</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="Id Battery Racks" className="nav-link" data-bs-toggle="tab" href="#IdBatteryRacks56" role="tab" aria-selected="true">ID Battery Racks</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link" data-bs-toggle="tab" href="#generator" role="tab" aria-selected="true">Generator</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="Generator Hut" className="nav-link" data-bs-toggle="tab" href="#generatorHut" role="tab" aria-selected="true">Generator Hut</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} className="nav-link" data-bs-toggle="tab" href="#bulckTank" role="tab" aria-selected="false">Bulk Tank</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="ATS" className="nav-link" data-bs-toggle="tab" href="#ats" role="tab" aria-selected="true">ATS</a>
                                </li>

                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="Air Conditioner" className="nav-link" data-bs-toggle="tab" href="#ac" role="tab" aria-selected="true">AC</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="FCB" className="nav-link" data-bs-toggle="tab" href="#fbc" role="tab" aria-selected="true">FCB</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="Cabin Details" className="nav-link" data-bs-toggle="tab" href="#cabinDetails" role="tab" aria-selected="true">Indoor Cabin</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="SPD" className="nav-link" data-bs-toggle="tab" href="#spd" role="tab" aria-selected="true">SPD</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="Renewable Energy - Solar" className="nav-link" data-bs-toggle="tab" href="#res" role="tab" aria-selected="true">Solar</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="Renewable Energy - Wind" className="nav-link" data-bs-toggle="tab" href="#rew" role="tab" aria-selected="true">Wind</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="Shared-out Operators" className="nav-link" data-bs-toggle="tab" href="#SharedoutOperators" role="tab" aria-selected="true">Shared-out Operators</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a style={{ padding: 8 }} title="AC" className="nav-link" data-bs-toggle="tab" href="#Generators2" role="tab" aria-selected="true">Generators</a>
                                </li> */}
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="Earthing" className="nav-link" data-bs-toggle="tab" href="#earthing" role="tab" aria-selected="true">Earthing</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="CEA Submissions" className="nav-link" data-bs-toggle="tab" href="#cea" role="tab" aria-selected="true">CEA Submissions</a>
                                </li>
                                <li className="nav-item">
                                    <a style={{ padding: 8 }} title="TRCSL Complaints" className="nav-link" data-bs-toggle="tab" href="#trcsl" role="tab" aria-selected="true">TRCSL Complaints</a>
                                </li>
                                {/* <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#settings" role="tab" aria-selected="true">Services</a>
                                        </li> */}
                                {/* <li className="nav-item">
                                            <a className="nav-link" data-bs-toggle="tab" href="#req" role="tab" aria-selected="true">Access Requests</a>
                                        </li> */}
                                {/* <li className="nav-item">
                                            <a className="nav-link " data-bs-toggle="tab" href="#Payments" role="tab" aria-selected="true">Payments</a>
                                        </li> */}
                                {/* <li className="nav-item">
                                            <a className="nav-link " data-bs-toggle="tab" href="#Submitions" role="tab" aria-selected="true">CEA Submitions</a>
                                        </li> */}

                            </ul>
                        </div>

                        <div className="tab-content">
                            <div className="tab-pane p-3 active site-tab-view" id="home" role="tabpanel">
                                {Object.keys(this.state.site_details).length != 0 && <GeneralDetails details={{ site_details }} details1={{ access_technologies_data }} details3={{ renewableEnergySolars_data }} detailsRectifiersData={{ rectifiers_data }} detailsBatteryBanksData={{ battery_banks_data }} detailsGeneratorsData={{ generators_data }} detailsRenewableEnergyWindData={{ renewableEnergyWind_data }} detailssharedOutOperatorses_data={{ sharedOutOperatorses_data }} powerSummaryDataSet={powerSummaryDataSet} detailsupdateGeneralDetails={{ updateGeneralDetails }} parentMethod={this.getPowerdBData} />}
                            </div>
                            <div className="tab-pane p-3  site-tab-view" id="sld" role="tabpanel">
                                {Object.keys(this.state.site_details).length != 0 && <Sld details={{ site_details }} />}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="profile" role="tabpanel">
                                <AssetDetails />
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="imageCapture" role="tabpanel">
                                {Object.keys(this.state.site_details).length != 0 && <ImageCapture details={{ site_details }} />}

                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="bulckTank" role="tabpanel">
                                {Object.keys(this.state.site_details).length != 0 && <BulckTankDetails details={{ site_details }} dropdownObj={{ bulckTankDropdown }} />}

                            </div>
                            {/* <div className="tab-pane p-3 site-tab-view" id="generator" role="tabpanel">
                                {Object.keys(this.state.generators_data).length != 0 && <GeneratorDetails details={{ site_details }} details1={{ access_technologies_data }} details2={{ generators_data }} parentMethod={this.getPowerdBData} />}

                            </div> */}

                            {/* <div className="tab-pane p-3 site-tab-view " id="generator" role="tabpanel">
                                {this.state.generators_data !== null ? (<GeneratorDetails details={{ site_details }} details1={{ access_technologies_data }} details2={{ generators_data }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data NotFound ATS</h4></div>)}
                            </div> */}

                            <div className="tab-pane p-3 site-tab-view " id="generator" role="tabpanel">
                                {this.state.generators_data !== null ? (<GeneratorDetails details={{ site_details }} details1={{ access_technologies_data }} details2={{ generators_data }} genDropdown={{ generatorDropDownObject }} parentMethod={this.getPowerdBData} genMasterDataSet={genMasterData} detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Generator</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="accessTechnologies" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<AccessTechnologies details={{ access_technologies_data }} details2={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found  Access Technologies</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="mainPowerConnection" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (
                                <MainPowerConnection details={{ access_technologies_data }} 
                                details2={{ site_details }} 
                                parentMethod={this.getPowerdBData} 
                                mpConnectionMasterData={mpConnectionMasterData} 
                                detailsupdateGeneralDetails={{ updateGeneralDetails }}  
                                />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found  Main Power Connection</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="sharedBackupPower" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<SharedBackupPower details={{ access_technologies_data }} details2={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found  Shared Backup Power</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="dcPowerSharing" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<DCPowerSharing details={{ access_technologies_data }} details2={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found  DC Sharing</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="power_capacity_kw" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<PowerCapacityKw details={{ access_technologies_data }} details2={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found  Power Capacity</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="dc_load" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<DCLoad details={{ access_technologies_data }} details2={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found DC Load</h4></div>)}
                            </div>

                            <div className="tab-pane p-3 site-tab-view " id="rectifiers" role="tabpanel">
                                {this.state.rectifiers_data !== null ? (<Rectifiers details={{ access_technologies_data }} details2={{ rectifiers_data }} detailsSite={{ site_details }} dropDownInfoRectifier={{ dropDownObjectSetRectifier }} parentMethod={this.getPowerdBData} rectifiersMasterData={{ rectifiersMasterData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Rectifiers</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view  " id="battery_banks" role="tabpanel">
                                {this.state.battery_banks_data !== null ? (<BatteryBanks dropdown={{dropdownObjBB}} details={{ access_technologies_data }} details2={{ battery_banks_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} bbMasterData={{ bbMasterData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Battery Banks</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="ac" role="tabpanel">
                                {this.state.air_conditioners !== null ? (<AirConditioners details={{ access_technologies_data }} dropdownAC={{ objectDropdownAc }} details2={{ air_conditioners }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} detailsupdateGeneralDetails={{ updateGeneralDetails }} acMasterData={{ acMasterData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found AC</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="spd" role="tabpanel">
                                {this.state.spd_data !== null ? (<SPD details={{ access_technologies_data }} dropdown={{ spdDropdownObject }} details2={{ spd_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} spdMasterData={{ spdMasterData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found SPD</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="cabinet" role="tabpanel">
                                {this.state.cabinet_data !== null ? (<Cabinet details={{ access_technologies_data }} cabinetDropdown={{ objSetDropdownCabinets }} details2={{ cabinet_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} cabinetMasterData={{ cabinetMasterData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Cabinet</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="Generators2" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<GeneratorPd details={{ access_technologies_data }} details2={{ generators_data }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Generators</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="earthing" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<Earthing dropdoenEAR={{dropdownObjEarthing}} details={{ access_technologies_data }} details2={{ site_details }} parentMethod={this.getPowerdBData}  />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Earthing</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="ats" role="tabpanel">
                                {this.state.ats_data !== null ? (<ATS details={{ access_technologies_data }} dropdown={{dropdownATS}} allMakes={{allATSMakes}} details2={{ ats_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} atsMasterData={{ atsMasterData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found ATS</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="dcdus" role="tabpanel">
                                {this.state.dcdus_data !== null ? (<DCDUS details={{ access_technologies_data }} dcdudrop={{ objDropdowndcdu }} details2={{ dcdus_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} dcduMasterData={{ dcduMasterData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found DCDU</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="fbc" role="tabpanel">
                                {this.state.fcbs_data !== null ? (<FBC details={{ access_technologies_data }} details2={{ fcbs_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} fcdMasterData={{ fcdMasterData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data No tFound FCB</h4></div>)}
                            </div>
                            <div className="tab-pane p-3  site-tab-view" id="cea" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<CAE details={{ access_technologies_data }} details2={{ dcdus_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found CAE</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="trcsl" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<TRCSL details={{ access_technologies_data }} details2={{ dcdus_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found TRCSL</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="res" role="tabpanel">
                                {this.state.renewableEnergySolars_data !== null ? (<RES details={{ access_technologies_data }} details2={{ renewableEnergySolars_data }} detailsSite={{ site_details }} solarDropdown={{ dropdownObjSolar }} parentMethod={this.getPowerdBData} details3={{ allData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} resMasterData={{ resMasterData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data NotFound RES</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="rew" role="tabpanel">
                                {this.state.renewableEnergyWind_data !== null ? (<REW details={{ access_technologies_data }} details2={{ renewableEnergyWind_data }} detailsSite={{ site_details }} windDropdown={{ dropdownObjWind }} parentMethod={this.getPowerdBData} details3={{ allData }} rewMasterData={{ rewMasterData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found REW</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="cabinDetails" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<CabiDetails details={{ access_technologies_data }} cabindropdown={{ dropdownDetailsCabins }} details2={{ dcdus_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} details3={{ allData }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Cabin Details</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="generatorHut" role="tabpanel">
                                {this.state.access_technologies_data !== null ? (<GeneratorHut dropdownGenhut={{ dropdownObjGenHut }} details={{ access_technologies_data }} details2={{ dcdus_data }} details3={{ allData }} genHutMastetData={genHutTypeMasterData} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Generator Hut</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view" id="SharedoutOperators" role="tabpanel">
                                {this.state.sharedOutOperatorses_data !== null ? (<SharedOutOperators dropdownSoo={{ dropdownObjSoo }} details={{ access_technologies_data }} details2={{ sharedOutOperatorses_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} details3={{ allData }} detailsupdateGeneralDetails={{ updateGeneralDetails }} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found Shared-out Operators</h4></div>)}
                            </div>
                            <div className="tab-pane p-3 site-tab-view " id="IdBatteryRacks56" role="tabpanel">
                                {this.state.idBatteryRacks_data !== null ? (<IDBR details={{ access_technologies_data }} dropdown={{ dropdownObjIDBR }} details2={{ idBatteryRacks_data }} detailsSite={{ site_details }} parentMethod={this.getPowerdBData} />) : (<div style={{ height: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h4 style={{ color: "#c5c5c5" }}>Data Not Found IDBR</h4></div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SiteSummary);

