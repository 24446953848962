import React, { useState, useEffect } from 'react';
import EditHistory from './access-history';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import {
    CarOutlined,
    NodeIndexOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CheckOutlined,
    DashboardOutlined,
    SearchOutlined,
    PhoneOutlined,
    PaperClipOutlined,
    UploadOutlined,
    DownloadOutlined,
    ShoppingOutlined,
    UserOutlined,
    MailOutlined,
    BankOutlined,
    FilterOutlined,
    FormOutlined,
    UserDeleteOutlined,
    SyncOutlined,
    ClockCircleOutlined,
    KeyOutlined,
    SnippetsOutlined,
    MoreOutlined,
    ArrowLeftOutlined,
    AimOutlined
} from '@ant-design/icons';
import { Menu, Divider, Space, Avatar, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Radio, Checkbox, spin, Spin } from 'antd';
import {
    getSiteAccessRequestByIDREWAMP,
    getSiteAccessRequestStatusCountsREWAMP,
    getAllSiteAccessRequestsDataREWAMP,
    requireSiteOwnerApprovalREWAMP,
    approvalWithOwner,
    rejectWithOwner,
    approveSiteAccessRequestREWAMP,
    rejectSiteAccessRequestREWAMP,
    cancelSiteAccessRequestREWAMP,
    getAllSubRegionsDepotCreate,
    getAllDepots,
    getAllRequestCategoryREWAMP,
    getAllSubRequestTypeREWAMP,
    getFilteredSiteAccessRequestsDataByRequesterREWAMP,
    getAllSiteAccessRequestBULKFiltered,
    getAllSiteAccessRequestBULKFilteredRequester,
    getSiteAccessRequestStatusCountsREWAMPFunc,
    getAllSiteAccessRequestBULK,
    getUserHandlerWritePermissionREWAMP,
    getAllSitesListByID,
    downloadSiteAccessRequest
} from "../../../api/index";
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import FormItem from 'antd/es/form/FormItem';
const { Option } = Select;
const baseImgURL = "https://storage.googleapis.com/dns_storage_v2/dnsrevampimages/";


const AccessManage = () => {
    const { Meta } = Card;
    const history = useHistory();
    const [allAccessRequests, setAllAccessRequests] = useState([]);
    const [counts, setCounts] = useState({});
    const [requireOwnerApproval, setOwnerApproval] = useState(false);
    const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
    const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
    const [isCommunicationModalVisible, setIsCommunicationModalVisible] = useState(false);
    const [isSelectedData, setIsSelectedData] = useState([]);

    const [requestCategory, setRequestCategory] = useState([]);
    const [subRequestType, setSubRequestType] = useState([]);
    const [subRegions, setSubRegions] = useState([]);
    const [depots, setDepots] = useState([]);

    const [requestDetails, setReqDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editModelOpen, setEditModelOpen] = useState(false);
    const [equpmentJson, setEqupmentJson] = useState([]);
    const [vehicleJson, setVehicleJson] = useState([]);
    const [instrumentJson, setInstrumentJson] = useState([]);
    const [teamMemberJson, setTeamMemberJson] = useState([]);
    const [operationModal, setOperationModal] = useState(2);
    const [reqFormData, setreqFormData] = useState([]);
    const [rejectReason, setRejectReason] = useState('');

    const [communicationType, setCommunicationType] = useState(1);
    const [fileList, setFileList] = useState([]);
    const [requiredRemark, setRequiredRemark] = useState(false);
    const [rejectType, setRejectType] = useState(null);
    const [rejectModal, setRejectModal] = useState(false);
    const [approvalRequests, setApprovalRequests] = useState([]);
    const [onGoingRequests, setOnGoingRequests] = useState([]);
    const [stillDownloading, setStillDownloading] = useState(false);
    const [isTypeSelect, setIsTypeSelect] = useState([]);
    const [openFilterPane, setOpenFilterPane] = useState(false);
    const [editHistory, setEditHistory] = useState(null);
    const [editHistoryView, setEditHistoryView] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(false);
    
    
    const [approvalType, setApprovalType] = useState('SINGLE');
    const [jobType, setJobType] = useState('PENDING');
    const [showBulkInfo, setShowBulkInfo] = useState(false);
    const [insideBulk, setInsideBulk] = useState([]);

    const [dateType, setDateType] = useState(null);
    const [loadFullSiteList, setLoadFullSiteList] = useState([]);
    const [allBulkRequests, setAllBulkRequests] = useState([]);

    const [selectedBulkID, setSelectedBulkID] = useState(null);

    const [showLocationDetails, setShowLocationDetails] = useState(false);
    const [startMapCenter, setStartMapCenter] = useState({
        lat: 0,
        lng: 0
    });
    const locationViewModalClose = () => {
        setShowLocationDetails(false);
    }
    const mapOptions = {
        zoomControl: false, 
        streetViewControl: false, 
        mapTypeControl: false,
    };
    const mapStyles = {
        height: "200px",
        width: "100%",
        borderRadius: "5px"
    };

    const [editOption, setEditOption] = useState(false);
    const [editPersmission, setEditPermission] = useState(false);

    const [filterSingle, setFilterSingle] = useState({
        regionIds: [],
        depotIds: [],
        requestTypeEnums: [],
        subRequestTypeIds: [],
        requestCategoryIds: [],
        initiatorGroupId: null,
        handlerGroupId: null,
        timeFrame: null,
        filterType: jobType === "PENDING" ? "RUNNING" : "COMPLETED",
        statuses: [],
        siteIds: [],
        bulkId: null,
        creationType: 'SINGLE',
        startDate: null,
        endDate: null,
        isEditOptionEnabled: false
    });

    const [filterBulk, setFilterBulk] = useState({
        bulkStatus: jobType === "PENDING" ? "ONGOING" : "COMPLETED",
        requestTypeEnums: [],
        subRequestTypeIds: [],
        requestCategoryIds: [],
        timeFrame: null,
        startDate: null,
        endDate: null,
        bulkIds: [],
        siteIds: [],
        isEditOptionEnabled: false,
        siteAccessRequestFilter: {
            regionIds: [],
            depotIds: [],
            requestTypeEnums: [],
            subRequestTypeIds: [],
            requestCategoryIds: [],
            initiatorGroupId: null,
            handlerGroupIds: [],
            timeFrame: null,
            filterType: jobType === "PENDING" ? "RUNNING" : "COMPLETED",
            statuses: [],
            siteIds: [],
            bulkIds: null,
            creationType: 'BULK',
            startDate: null,
            endDate: null,
        }
    });

    let data = [];
    useEffect(() => {
        fetchGetAllSiteAccessRequestsDataREWAMP();
        fetchGetSiteAccessRequestStatusCountsREWAMP();
        
        fetchAllSubRegionsDepotCreate();
        fetchAllDepots();
        fetchAllRequestCategoryREWAMP();
        fetchAllSubRequestTypeREWAMP();
        fetchAllBulkRequestsList(0);
        permissionTypeFetch();
        fetchAllSiteListSet();
    }, []);

    const resetFilterData = () => {
        setFilterSingle({
            regionIds: [],
            depotIds: [],
            requestTypeEnums: [],
            subRequestTypeIds: [],
            requestCategoryIds: [],
            initiatorGroupId: null,
            handlerGroupId: null,
            timeFrame: null,
            filterType: jobType === "PENDING" ? "RUNNING" : "COMPLETED",
            statuses: [],
            siteIds: [],
            bulkId: null,
            creationType: 'SINGLE',
            startDate: null,
            endDate: null,
            isEditOptionEnabled: false
        });

        setFilterBulk({
            bulkStatus: jobType === "PENDING" ? "ONGOING" : "COMPLETED",
            requestTypeEnums: [],
            subRequestTypeIds: [],
            requestCategoryIds: [],
            timeFrame: null,
            startDate: null,
            endDate: null,
            bulkIds: [],
            siteIds: [],
            isEditOptionEnabled: false,
            siteAccessRequestFilter: {
                regionIds: [],
                depotIds: [],
                requestTypeEnums: [],
                subRequestTypeIds: [],
                requestCategoryIds: [],
                initiatorGroupId: null,
                handlerGroupIds: [],
                timeFrame: null,
                filterType: jobType === "PENDING" ? "RUNNING" : "COMPLETED",
                statuses: [],
                siteIds: [],
                bulkIds: null,
                creationType: 'BULK',
                startDate: null,
                endDate: null,
            }
        });
    }

    const formatStartDate = (dateString) => {
        if (!dateString) return null;
        let date = new Date(dateString);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}T00:00:00.000Z`;
    }
    
    const formatEndDate = (dateString) =>  {
        if (!dateString) return null;
        let date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}T00:00:00.000Z`;
    }
    
    const onFinishFilter = (value) => {
        if (approvalType === 'SINGLE') {
            const requestJson = {
                regionIds: value?.region ? value?.region : null,
                depotIds: value?.depot ? value?.depot : null,
                requestTypeEnums: value?.type ? value?.type : null,
                subRequestTypeIds: value?.subType ? value?.subType : null,
                requestCategoryIds: value?.category ? value?.category : null,
                initiatorGroupId: null,
                handlerGroupId: null,
                timeFrame: value?.date ? value?.date : null,
                filterType: jobType === "PENDING" ? "RUNNING" : "COMPLETED",
                statuses: value?.step ? value?.step : null,
                siteIds: value?.siteID ? value?.siteID : null,
                bulkId: value?.bulkIDs ? value?.bulkIDs : null,
                creationType: 'SINGLE',
                startDate: value?.customDate ? formatStartDate(value?.customDate[0]) : null,
                endDate: value?.customDate ? formatEndDate(value?.customDate[1]) : null,
                isEditOptionEnabled: editOption,
            }
            const requestJsonState = {
                regionIds: value?.region ? value?.region : null,
                depotIds: value?.depot ? value?.depot : null,
                requestTypeEnums: value?.type ? value?.type : null,
                subRequestTypeIds: value?.subType ? value?.subType : null,
                requestCategoryIds: value?.category ? value?.category : null,
                initiatorGroupId: null,
                handlerGroupId: null,
                timeFrame: value?.date ? value?.date : null,
                filterType: jobType === "PENDING" ? "RUNNING" : "COMPLETED",
                statuses: value?.step ? value?.step : null,
                siteIds: value?.siteID ? value?.siteID : null,
                bulkId: value?.bulkIDs ? value?.bulkIDs : null,
                creationType: 'SINGLE',
                startDate: value?.customDate ? value?.customDate[0] : null,
                endDate: value?.customDate ? value?.customDate[1] : null,
                isEditOptionEnabled: editOption,
            }
            setFilterSingle(requestJsonState);
            fetchGetFilteredSiteAccessRequestsDataREWAMP(requestJson);
        } else {
            let intSiteIDList = value?.siteID?.map(siteID => parseInt(siteID, 10));
            const requestJson = {
                "bulkStatus": jobType === "PENDING" ? "ONGOING" : "COMPLETED",
                // "requestTypeEnums": showBulkInfo !== true ? value?.type ? value?.type : null,
                // "subRequestTypeIds": showBulkInfo !== true ? value?.subType ? value?.subType : null,
                // "requestCategoryIds": showBulkInfo !== true ? value?.category ? value?.category : null,
                // "timeFrame": showBulkInfo !== true ? value?.date ? value?.date : null,
                // "startDate": showBulkInfo !== true ? value?.customDate ? formatStartDate(value?.customDate[0]) : null,
                // "endDate": showBulkInfo !== true ? value?.customDate ? formatEndDate(value?.customDate[1]) : null,
                "requestTypeEnums": !showBulkInfo && value?.type ? value?.type : null,
                "subRequestTypeIds": !showBulkInfo && value?.subType ? value?.subType : null,
                "requestCategoryIds": !showBulkInfo && value?.category ? value?.category : null,
                "timeFrame": !showBulkInfo && value?.date ? value?.date : null,
                "startDate": !showBulkInfo && value?.customDate && formatStartDate(value.customDate[0]) ? formatStartDate(value.customDate[0]) : null,
                "endDate": !showBulkInfo && value?.customDate && formatEndDate(value.customDate[1]) ?  formatEndDate(value.customDate[1]) : null,
                "bulkIds": showBulkInfo ? [selectedBulkID] : value?.bulkIDs ? value?.bulkIDs : null,
                "siteIds": showBulkInfo !== true ? value?.siteID ? intSiteIDList : null : null,
                "isEditOptionEnabled": editOption,
                "siteAccessRequestFilter": {
                    "regionIds": value?.region ? value?.region : null,
                    "depotIds": value?.depot ? value?.depot : null,
                    "requestTypeEnums": value?.type ? value?.type : null,
                    "subRequestTypeIds": value?.subType ? value?.subType : null,
                    "requestCategoryIds": value?.category ? value?.category : null,
                    "initiatorGroupId": null,
                    "handlerGroupIds": [],
                    "timeFrame": value?.date ? value?.date : null,
                    "filterType": null,
                    "statuses": value?.step ? value?.step : null,
                    "siteIds": showBulkInfo ? value?.siteID ? intSiteIDList : null : null,
                    "bulkIds": value?.bulkId ? [value?.bulkId] : null,
                    "creationType": "BULK",
                    "startDate": value?.customDate ? formatStartDate(value?.customDate[0]) : null,
                    "endDate": value?.customDate ? formatEndDate(value?.customDate[1]) : null,
                }
            }
            const requestJsonState = {
                "bulkStatus": jobType === "PENDING" ? "ONGOING" : "COMPLETED",
                "requestTypeEnums": value?.type ? value?.type : null,
                "subRequestTypeIds": value?.subType ? value?.subType : null,
                "requestCategoryIds": value?.category ? value?.category : null,
                "timeFrame": value?.date ? value?.date : null,
                "startDate": value?.customDate ? value?.customDate[0] : null,
                "endDate": value?.customDate ? value?.customDate[1] : null,
                "bulkIds": showBulkInfo ? [selectedBulkID] : value?.bulkIDs ? value?.bulkIDs : null,
                "siteIds": showBulkInfo !== true ? value?.siteID ? intSiteIDList : null : null,
                "isEditOptionEnabled": editOption,
                "siteAccessRequestFilter": {
                    "regionIds": value?.region ? value?.region : null,
                    "depotIds": value?.depot ? value?.depot : null,
                    "requestTypeEnums": value?.type ? value?.type : null,
                    "subRequestTypeIds": value?.subType ? value?.subType : null,
                    "requestCategoryIds": value?.category ? value?.category : null,
                    "initiatorGroupId": null,
                    "handlerGroupIds": [],
                    "timeFrame": value?.date ? value?.date : null,
                    "filterType": null,
                    "statuses": value?.step ? value?.step : null,
                    "siteIds": showBulkInfo ? value?.siteID ? intSiteIDList : null : null,
                    "bulkIds": value?.bulkId ? [value?.bulkId] : null,
                    "creationType": "BULK",
                    "startDate": value?.customDate ? value?.customDate[0] : null,
                    "endDate": value?.customDate ? value?.customDate[1] : null,
                }
            }
            setFilterBulk(requestJsonState);
            fetchGetFilteredSiteAccessRequestsDataREWAMPBulk(requestJson);
        }
    }

    const conditionsManager = (type, value, isEditOption) => {
        console.log('Execute That Function - execution');
        if (type === 'SINGLE') {
            const requestJson = {
                regionIds: value?.region ? value?.region : null,
                depotIds: value?.depot ? value?.depot : null,
                requestTypeEnums: value?.type ? value?.type : null,
                subRequestTypeIds: value?.subType ? value?.subType : null,
                requestCategoryIds: value?.category ? value?.category : null,
                initiatorGroupId: null,
                handlerGroupId: null,
                timeFrame: value?.date ? value?.date : null,
                filterType: jobType === "PENDING" ? "RUNNING" : "COMPLETED",
                statuses: value?.step ? value?.step : null,
                siteIds: value?.siteID ? value?.siteID : null,
                bulkId: value?.bulkIDs ? value?.bulkIDs : null,
                creationType: 'SINGLE',
                startDate: value?.customDate ? value?.customDate[0] : null,
                endDate: value?.customDate ? value?.customDate[1] : null,
                isEditOptionEnabled: isEditOption,
            }
            fetchGetFilteredSiteAccessRequestsDataREWAMP(requestJson);
        } else {
            const requestJson = {
                "bulkStatus": jobType === "PENDING" ? "ONGOING" : "COMPLETED",
                "requestTypeEnums": value?.type ? value?.type : null,
                "subRequestTypeIds": value?.subType ? value?.subType : null,
                "requestCategoryIds": value?.category ? value?.category : null,
                "timeFrame": value?.date ? value?.date : null,
                "startDate": value?.customDate ? value?.customDate[0] : null,
                "endDate": value?.customDate ? value?.customDate[1] : null,
                "bulkIds": value?.bulkIDs ? value?.bulkIDs : null,
                "siteIds": value?.siteID ? value?.siteID : null,
                "isEditOptionEnabled": isEditOption,
                "siteAccessRequestFilter": {
                    "regionIds": value?.region ? value?.region : null,
                    "depotIds": value?.depot ? value?.depot : null,
                    "requestTypeEnums": value?.type ? value?.type : null,
                    "subRequestTypeIds": value?.subType ? value?.subType : null,
                    "requestCategoryIds": value?.category ? value?.category : null,
                    "initiatorGroupId": null,
                    "handlerGroupIds": [],
                    "timeFrame": value?.date ? value?.date : null,
                    "filterType": null,
                    "statuses": value?.step ? value?.step : null,
                    "siteIds": value?.siteID ? value?.siteID : null,
                    "bulkIds": value?.bulkId ? [value?.bulkId] : null,
                    "creationType": "BULK",
                    "startDate": value?.customDate ? value?.customDate[0] : null,
                    "endDate": value?.customDate ? value?.customDate[1] : null,
                }
            }
            fetchGetFilteredSiteAccessRequestsDataREWAMPBulk(requestJson);
        }
    }

    const fetchGetAllSiteAccessRequestsDataREWAMP = async () => {
        const requestJson = {
            regionIds: [],
            depotIds: [],
            requestTypeEnums: [],
            subRequestTypeIds: [],
            requestCategoryIds: [],
            timeFrame: null,
            filterType: "RUNNING",
            statuses: [],
            siteId: null,
            bulkId: null,
            creationType: 'SINGLE',
            isEditOptionEnabled: editOption,
        }
        pageLoadDataFetching(requestJson);

        const requestJson_Cmp = {
            regionIds: [],
            depotIds: [],
            requestTypeEnums: [],
            subRequestTypeIds: [],
            requestCategoryIds: [],
            timeFrame: null,
            filterType: "COMPLETED",
            statuses: [],
            siteId: null,
            bulkId: null,
            creationType: 'SINGLE',
            isEditOptionEnabled: editOption,
        }
        pageLoadDataFetching(requestJson_Cmp);
    }

    const fetchAllSiteListSet = async () => {
        var responseAPI = await getAllSitesListByID();
        if (responseAPI.success) {
            setLoadFullSiteList(responseAPI?.data);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI?.message);
        }
    }

    const permissionTypeFetch = async () => {
        const permission = await getUserHandlerWritePermissionREWAMP('handler');
        if (permission.success) {
            setEditPermission(permission?.data?.results);
        } else {
            message.error("Error Fetching Permission");
        }
    }

    const openEditHistoryWindow = (requestID) => {
        setEditHistory(requestID);
        setEditHistoryView(true);
    }

    const closeEditHistory = () => {
        setEditHistory(null);
        setEditHistoryView(false);
    }

    const handleNewRequest = (record) => {
        const route = `/access/dev/request`;
        history.push(route);
    };

    const fetchAllBulkRequestsList = async (id) => {
        const response = await getAllSiteAccessRequestBULK(id);
        if (response.success) {
            setAllBulkRequests(response?.data?.results);
        } else {
            message.error("Error While Fetching Bulk Requests");
        }
    }

    insideBulk.forEach((item, index) => {
        data.push({
            key: item.id,
            requestId: item.requestId,
            instanceType: item.instanceType,
            siteId: item.siteId,
            siteName: item.siteName,
            permissionStartDateTime: item.permissionStartDateTime,
            permissionEndDate: item.permissionEndDate,
            requestStatus: item.requestStatus,
            requestApprovalStatus: item.requestApprovalStatus,
            updatedAccessReport: item.updatedAccessReport,
            id: item.id,
        });
    });
    
    const fetchAllBuikRequests = async (bulkId) => {
        console.log('Execute That Function');
        setInsideBulk([]);
        data = [];
        if (bulkId === null || bulkId === undefined || bulkId === '') {
            setApprovalRequests([]);
            message.error("Please Select Bulk Request");
            return;
        } else {
            if (jobType === "PENDING") {
                onGoingRequests?.forEach((item) => {
                    if (item.id === bulkId) {
                        console.log(item.siteAccessRequests);
                        setInsideBulk(item.siteAccessRequests);
                        return;
                    }
                });
            } else {
                approvalRequests?.forEach((item) => {
                    if (item.id === bulkId) {
                        setInsideBulk(item.siteAccessRequests);
                        return;
                    }
                });
            }
            setIsLoadingData(false);
        }
    }

    const fetchDocLinksSiteAccessRequestByID = async (id) => {
        setIsLoading(true);
        var responseAPI = await getSiteAccessRequestByIDREWAMP(id);

        if (responseAPI.success) {
            const urlOne = responseAPI?.data?.results?.initiatorFileOne ? baseImgURL + responseAPI?.data?.results?.initiatorFileOne : null;
            const urlTwo = responseAPI?.data?.results?.initiatorFileTwo ? baseImgURL + responseAPI?.data?.results?.initiatorFileTwo : null;
            const urlThree = responseAPI?.data?.results?.initiatorFileThree ? baseImgURL + responseAPI?.data?.results?.initiatorFileThree : null;
            const urlFour = responseAPI?.data?.results?.initiatorFileFour ? baseImgURL + responseAPI?.data?.results?.initiatorFileFour : null;
            const urlFive = responseAPI?.data?.results?.initiatorFileFive ? baseImgURL + responseAPI?.data?.results?.initiatorFileFive : null;
            const links = [
                urlOne,
                urlTwo,
                urlThree,
                urlFour,
                urlFive
            ];

            if (links.every(link => link === null)) {
                message.info("No Documents Found For This Request");
            } else {
                let downloadCount = 0;
                links.forEach((link, index) => {
                    if (link !== null) {
                        setStillDownloading(true);
                        setTimeout(() => {
                            downloadFiles(link);
                            downloadCount++;
                            if (downloadCount === links.filter(link => link !== null).length) {
                                closeDownloadWindow();
                            }
                        }, index * 2000);
                    }
                });
            }
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
            closeDownloadWindow();
        }
    };

    const closeDownloadWindow = () => {
        setStillDownloading(false);
    }

    const downloadFiles = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = '';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const fetchAllPageCountInFilter = async (requestJson) => {
        const response = await getFilteredSiteAccessRequestsDataByRequesterREWAMP(1, requestJson);
        if (response.success) {
            const responseDataPageCount = response?.data?.results?.totalPages;
            return responseDataPageCount;
        } else {
            return 0;
        }
    }

    const fetchGetFilteredSiteAccessRequestsDataREWAMP = async (requestJson) => {
        try {
            let pageCount = await fetchAllPageCountInFilter(requestJson);
            let dataArr = [];
            for (let i = 1; i <= pageCount; i++) {
                setIsLoadingData(true);
                const response = await getFilteredSiteAccessRequestsDataByRequesterREWAMP(i, requestJson);
                if (response.success) {
                    const responseData = response?.data?.results?.data;
                    dataArr.push(...responseData);
                    if (jobType === "PENDING") {
                        setOnGoingRequests([]);
                        setOnGoingRequests(dataArr);
                    } else {
                        setApprovalRequests([]);
                        setApprovalRequests(dataArr);
                    }
                } else {
                    message.error("Error Fetching Filtered Requests");
                    setIsLoadingData(false);
                    return false;
                }
            }
            setIsLoadingData(false);
            if (jobType === "PENDING") {
                setOnGoingRequests(dataArr);

            } else {
                setApprovalRequests(dataArr);
                if (selectedBulkID !== null) {
                    fetchAllBuikRequests(selectedBulkID);
                }
            }
            return true;
        } catch (error) {
            message.error("Error Fetching Filtered Requests");
            setIsLoadingData(false);
            return false;
        }
        setIsLoadingData(false);
    }

    const fetchAllPageCountInFilterBulk = async (requestJson) => {
        const response = await getAllSiteAccessRequestBULKFilteredRequester(30, 1, requestJson);
        if (response.success) {
            const responseDataPageCount = response?.data?.results?.totalPages;
            return responseDataPageCount;
        } else {
            return 0;
        }
    }

    const fetchGetFilteredSiteAccessRequestsDataREWAMPBulk = async (requestJson) => {
        try {
            let pageCount = await fetchAllPageCountInFilterBulk(requestJson);
            let dataArr = [];
            for (let i = 1; i <= pageCount; i++) {
                setIsLoadingData(true);
                const response = await getAllSiteAccessRequestBULKFilteredRequester(30, i, requestJson);
                if (response.success) {
                    const responseData = response?.data?.results?.data;
                    dataArr.push(...responseData);
                    if (jobType === "PENDING") {
                        setOnGoingRequests([]);
                        setOnGoingRequests(dataArr);
                    } else {
                        setApprovalRequests([]);
                        setApprovalRequests(dataArr);
                    }
                } else {
                    message.error("Error Fetching Filtered Requests");
                    setIsLoadingData(false);
                    return false;
                }
            }
            setIsLoadingData(false);
            if (jobType === "PENDING") {
                setOnGoingRequests(dataArr);
                if (selectedBulkID !== null) {
                    fetchAllBuikRequestsForFilter(selectedBulkID, dataArr);
                }
            } else {
                setApprovalRequests(dataArr);
                if (selectedBulkID !== null) {
                    fetchAllBuikRequestsForFilter(selectedBulkID, dataArr);
                }
            }
            return true;
        } catch (error) {
            message.error("Error Fetching Filtered Requests");
            setIsLoadingData(false);
            return false;
        }
        setIsLoadingData(false);
    }

    const fetchAllBuikRequestsForFilter = async (bulkId, uniqueData) => {
        console.log('Execute That Function');
        data = [];
        let insideBulkData = [];
        uniqueData?.forEach((item) => {
            if (item.id === bulkId) {
                console.log('Found Related Information');
                console.log('Execute That Function Here');
                setInsideBulk(item.siteAccessRequests);
                insideBulkData = item.siteAccessRequests;
                return;
            }
        });
        insideBulkData?.forEach((item, index) => {
            data?.push({
                key: item.id,
                requestId: item.requestId,
                instanceType: item.instanceType,
                siteId: item.siteId,
                siteName: item.siteName,
                permissionStartDateTime: item.permissionStartDateTime,
                permissionEndDate: item.permissionEndDate,
                requestStatus: item.requestStatus,
                requestApprovalStatus: item.requestApprovalStatus,
                updatedAccessReport: item.updatedAccessReport,
                id: item.id,
            });
        });
        setIsLoadingData(false);
    }

    const openFilterPaneFunc = () => {
        if ((approvalType === 'SINGLE' && filterSingle?.timeFrame === 'CUSTOM') ||
            (approvalType === 'BULK' && !showBulkInfo && filterBulk.timeFrame === 'CUSTOM') ||
            (approvalType === 'BULK' && showBulkInfo && filterBulk.siteAccessRequestFilter?.timeFrame === 'CUSTOM')) {
            setDateType('CUSTOM');
        } else {
            setDateType(null);
        }
        setOpenFilterPane(!openFilterPane);
    }

    const fetchAllSubRegionsDepotCreate = async () => {
        const response = await getAllSubRegionsDepotCreate();
        if (response.success) {
            const responseData = response?.data;
            setSubRegions(responseData);
        }
    }

    const fetchAllDepots = async () => {
        const response = await getAllDepots();
        if (response.success) {
            const responseData = response?.data;
            setDepots(responseData);
        }
    }

    const fetchAllRequestCategoryREWAMP = async () => {
        const response = await getAllRequestCategoryREWAMP();
        if (response.success) {
            const responseData = response?.data?.results;
            setRequestCategory(responseData);
        }
    }

    const fetchAllSubRequestTypeREWAMP = async () => {
        const response = await getAllSubRequestTypeREWAMP();
        if (response.success) {
            const responseData = response?.data?.results;
            setSubRequestType(responseData);
        }
    }


    const openRejectModal = (type) => {
        setRejectType(type);
        setRejectModal(true);
    }

    const closeRejectModal = () => {
        setRejectModal(false);
    }

    const closeAllModals = () => {
        setRejectModal(false);
        setIsInfoModalVisible(false);
        closeRejectModal();
        editModalClose();
        setIsCommunicationModalVisible(false);
    }

    const fetchSiteAccessRequestByID = async (id) => {
        setIsLoading(true);
        var responseAPI = await getSiteAccessRequestByIDREWAMP(id);
        if (responseAPI.success) {
            setStartMapCenter({
                lat: responseAPI?.data?.results?.latitude,
                lng: responseAPI?.data?.results?.longitude
            });
            setTeamMemberJson(responseAPI.data.results.teamMembers);
            setEqupmentJson(responseAPI.data.results.equipments);
            setVehicleJson(responseAPI.data.results.vehicles);
            setInstrumentJson(responseAPI.data.results.instruments);
            setReqDetails(responseAPI.data.results);
            setreqFormData(responseAPI?.data?.results);
            const getOperationModal = responseAPI?.data?.results?.requestType === "PROJECT" ? 1 : 0;
            setIsInfoModalVisible(true);
            setIsLoading(false);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const requireSiteOwnerApproval = async (requestID) => {
        const requestJson = {
            siteOwnerCommunicationMethod: communicationType,
            note: requiredRemark,
        };
        const request = await requireSiteOwnerApprovalREWAMP(requestID, requestJson);
        console.log(requestJson);
        if (request.success) {
            fetchGetSiteAccessRequestStatusCountsREWAMP();
            fetchGetAllSiteAccessRequestsDataREWAMP();
            sucessSiteOwnerApproval();
        } else {
            message.error("Error Approving Request");
        }
    }

    const sucessSiteOwnerApproval = () => {
        Modal.success({
            title: 'Success',
            content: <span style={{ fontWeight: "bold" }} className='textStyle-small'>Site Owner Approval Send Successfully</span>,
            onOk: () => {
                closeAllModals();
            },
        });
    }

    const sucessApproveRequestWithSiteOwner = () => {
        Modal.success({
            title: 'Success',
            content: <span style={{ fontWeight: "bold" }} className='textStyle-small'>
                Request Approved Successfully
            </span>,
            onOk: () => {
                closeAllModals();
            },
        });
    }

    const sucessRejectRequestWithSiteOwner = () => {
        Modal.success({
            title: 'Success',
            content: <span style={{ fontWeight: "bold" }} className='textStyle-small'>
                Request Rejected Successfully
            </span>,
            onOk: () => {
                closeAllModals();
            },
        });
    }

    const sucessCancleRequestWithSiteOwner = () => {
        Modal.success({
            title: 'Success',
            content: <span style={{ fontWeight: "bold" }} className='textStyle-small'>
                Request Cancelled Successfully
            </span>,
            onOk: () => {
            },
        });
    }

    const cancelReqModal = (idRequest) => {
        Modal.info({
            title: 'Are You Want To Cancel This Request',
            style: { borderRadius: '10px', width: '80%' },
            onOk: () => {
                cancleAccessRequestByGivenID(idRequest);
            },
            okCancel: () => { },
        });
    }

    const cancleAccessRequestByGivenID = async (requestID) => {
        const request = await cancelSiteAccessRequestREWAMP(requestID);
        if (request.success) {
            fetchGetSiteAccessRequestStatusCountsREWAMP();
            fetchGetAllSiteAccessRequestsDataREWAMP();
            sucessCancleRequestWithSiteOwner();

            conditionsManager("BULK", null, editOption); 
            setShowBulkInfo(false);
            setSelectedBulkID(null);
        } else {
            message.error("Error Cancelling Request");
        }
    }

    const approvalWithOwnerApproval = async (requestID) => {
        console.log(fileList);
        const requestJson = JSON.stringify({
            handlerApprovalCommunicationMethod: communicationType,
            approvalReason: requiredRemark,
        });
        const formData = new FormData();
        formData.append('requestDto', new Blob([requestJson], { type: 'application/json' }));
        formData.append('file1', fileList[0]);

        const request = await approvalWithOwner(requestID, formData);
        if (request.success) {
            fetchGetSiteAccessRequestStatusCountsREWAMP();
            fetchGetAllSiteAccessRequestsDataREWAMP();
            sucessApproveRequestWithSiteOwner();
        } else {
            message.error("Error Approving Request");
        }
    }

    const rejectWithOwnerApproval = async (requestID) => {
        const requestJson = JSON.stringify({
            handlerApprovalCommunicationMethod: communicationType,
            rejectReason: rejectReason,
        });
        const formData = new FormData();
        formData.append('requestDto', new Blob([requestJson], { type: 'application/json' }));
        formData.append('file1', fileList[0]);
        const request = await rejectWithOwner(requestID, formData);
        if (request.success) {
            fetchGetSiteAccessRequestStatusCountsREWAMP();
            fetchGetAllSiteAccessRequestsDataREWAMP();
            sucessRejectRequestWithSiteOwner();
        } else {
            message.error("Error Rejecting Request");
        }
    }

    const normalApprovalWithOutOwner = async (requestID) => {
        const requestJson = JSON.stringify({
            handlerApprovalCommunicationMethod: communicationType,
            approvalReason: requiredRemark,
        });
        const formData = new FormData();
        formData.append('requestDto', new Blob([requestJson], { type: 'application/json' }));
        formData.append('file1', fileList[0]);

        const request = await approveSiteAccessRequestREWAMP(requestID, formData);
        if (request.success) {
            fetchGetSiteAccessRequestStatusCountsREWAMP();
            fetchGetAllSiteAccessRequestsDataREWAMP();
            sucessApproveRequestWithSiteOwner();
        } else {
            message.error("Error Approving Request");
        }
    }

    const normalRejection = async (requestID) => {
        const requestJson = JSON.stringify({
            handlerApprovalCommunicationMethod: communicationType,
            rejectReason: rejectReason,
        });
        const formData = new FormData();
        formData.append('requestDto', new Blob([requestJson], { type: 'application/json' }));
        formData.append('file1', fileList[0]);

        const request = await rejectSiteAccessRequestREWAMP(requestID, formData);
        if (request.success) {
            fetchGetSiteAccessRequestStatusCountsREWAMP();
            fetchGetAllSiteAccessRequestsDataREWAMP();
            sucessRejectRequestWithSiteOwner();
        } else {
            message.error("Error Rejecting Request");
        }
    }

    const fetchGetSiteAccessRequestStatusCountsREWAMP = async () => {
        const response = await getSiteAccessRequestStatusCountsREWAMPFunc('status-counts');
        if (response.success) {
            const responseData = response?.data?.results;
            setCounts(responseData);
            return responseData;
        } else {
            message.error("Error Fetching Request Status Counts");
            return false;
        }
    }

    const fetchGetAllSiteAccessRequestsDataREWAMPPageCount = async () => {
        const response = await getAllSiteAccessRequestsDataREWAMP(1);
        if (response.success) {
            const responseDataPageCount = response?.data?.results?.totalPages;
            return responseDataPageCount;
        } else {
            message.error("Error Fetching Page Count");
            return 0;
        }
    }

    const pageLoadDataFetching = async (requestJson) => {
        try {
            let pageCount = await fetchAllPageCountInFilter(requestJson);
            let dataArr = [];
            for (let i = 1; i <= pageCount; i++) {
                setIsLoadingData(true);
                const response = await getFilteredSiteAccessRequestsDataByRequesterREWAMP(i, requestJson);
                if (response.success) {
                    const responseData = response?.data?.results?.data;
                    dataArr.push(...responseData);
                    if (requestJson.filterType === "RUNNING") {
                        setOnGoingRequests([]);
                        setOnGoingRequests(dataArr);
                    } else {
                        setApprovalRequests([]);
                        setApprovalRequests(dataArr);
                    }
                } else {
                    message.error("Error Fetching Filtered Requests");
                    setIsLoadingData(false);
                    return false;
                }
            }
            setIsLoadingData(false);
            if (requestJson.filterType === "RUNNING") {
                setOnGoingRequests(dataArr);
            } else {
                setApprovalRequests(dataArr);
            }
            return true;
        } catch (error) {
            message.error("Error Fetching Filtered Requests");
            setIsLoadingData(false);
            return false;
        }
        setIsLoadingData(false);
    }

    const downloadAccessReport = async (accessReqID) => {
        const response = await downloadSiteAccessRequest(accessReqID);
        if (response) {
            downloadFiles(response);
        } else {
            message.error("Error Downloading Report");
        }
    }


    const categoriesRequests = (dataArr) => {
        let completedJobs = [];
        let runningJobs = [];
        dataArr.forEach((item) => {
            if (item?.requestApprovalStatus === 'APPROVED' || item?.requestApprovalStatus === 'REJECTED' || item?.requestApprovalStatus === 'CANCELLED') {
                completedJobs.push(item);
            } else {
                runningJobs.push(item);
            }
        });
        setOnGoingRequests(runningJobs);
        setApprovalRequests(completedJobs);
    }

    const columns = [
        {
            title: <span className="textStyle">Request ID</span>,
            dataIndex: 'requestId',
            key: 'requestId',
            render: (text, record) => (
                <a
                    className="textStyleChild"
                    onClick={() => fetchSiteAccessRequestByID(record?.id)}
                >
                    {text}
                </a>
            ),
        },
        {
            title: <span className="textStyle">Type</span>,
            dataIndex: 'instanceType',
            key: 'instanceType',
            render: text => <a className="textStyleChild">{text ? text === 'BULK' ?
                <Tag color="orange">BULK</Tag> :
                <Tag color="green">SINGLE</Tag> :
                <Tag color="red">N/A</Tag>}</a>,
        },
        {
            title: <span className="textStyle">Site ID</span>,
            dataIndex: 'siteId',
            key: 'siteId',
            render: text => <a className="textStyleChild">{text}</a>,
        },
        {
            title: <span className="textStyle">Site Name</span>,
            dataIndex: 'siteName',
            key: 'siteName',
            render: text => <a className="textStyleChild">{text?.replace(/_/g, ' ')}</a>,
        },
        {
            title: <span className="textStyle">Permission Start Date & Time</span>,
            dataIndex: 'permissionStartDateTime',
            key: 'permissionStartDateTime',
            render: text => {
                return text ? (
                    (() => {
                        const dateObject = new Date(text);
                        const options = {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        };

                        const formattedDate = dateObject.toLocaleString(undefined, options);

                        return <a className="textStyleChild">{formattedDate}</a>;
                    })()
                ) : (
                    <span className="textStyleChild">-
                        N/A
                    </span>
                );
            }
        },
        {
            title: <span className="textStyle">Permission End Date</span>,
            dataIndex: 'permissionEndDate',
            key: 'permissionEndDate',
            render: text => {
                return text ? (
                    (() => {
                        const dateObject = new Date(text);
                        const options = {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                        };

                        const formattedDate = dateObject.toLocaleString(undefined, options);

                        return <a className="textStyleChild">{formattedDate}</a>;
                    })()
                ) : (
                    <span className="textStyleChild">-
                        N/A
                    </span>
                );
            }
        },
        {
            title: <span className="textStyle">Request Status</span>,
            dataIndex: 'requestStatus',
            key: 'requestStatus',
            render: text => {
                return text ? (
                    (() => {
                        switch (text) {
                            case 'ON_GOING':
                                return <Tag color="blue">
                                    <span className="textStyleChilds">
                                        On Going
                                    </span>
                                </Tag>;
                            case 'COMPLETED':
                                return <Tag color="green">
                                    <span className="textStyleChilds">
                                        Completed
                                    </span>
                                </Tag>;
                            case 'CANCELLED':
                                return <Tag color="pink">
                                    <span className="textStyleChilds">
                                        Cancelled
                                    </span>
                                </Tag>;
                            default:
                                return <Tag color="red">UNKNOWN</Tag>;
                        }
                    })()
                ) : (
                    <span className="textStyleChild">-
                        N/A
                    </span>
                );
            }
        },
        {
            title: <span className="textStyle">Request Approval Status</span>,
            dataIndex: 'requestApprovalStatus',
            key: 'requestApprovalStatus',
            render: text => {
                return text ? (
                    (() => {
                        switch (text) {
                            case 'PENDING_APPROVAL':
                                return <Tag color="orange">
                                    <span className="textStyleChilds">
                                        Pending Approval
                                    </span>
                                </Tag>;
                            case 'PENDING_APPROVAL_SITE_OWNER':
                                return <Tag color="purple">
                                    <span className="textStyleChilds">
                                        Pending With Site Owner
                                    </span>
                                </Tag>;
                            case 'APPROVED':
                                return <Tag color="blue">
                                    <span className="textStyleChilds">
                                        Approved
                                    </span>
                                </Tag>;
                            case 'REJECTED':
                                return <Tag color="red">
                                    <span className="textStyleChilds">
                                        Rejected
                                    </span>
                                </Tag>;
                            case 'CANCELLED':
                                return <Tag color="pink">
                                    <span className="textStyleChilds">
                                        Cancelled
                                    </span>
                                </Tag>;
                            default:
                                return <Tag color="red">UNKNOWN</Tag>;
                        }
                    })()
                ) : (
                    <span className="textStyleChild">-
                        N/A
                    </span>
                );
            }
        },
        {
            title: <span className="textStyle">Actions</span>,
            key: 'actions',
            render: (text, record) => {
                const menu = (
                    <Menu>
                        {
                            jobType === "COMPLETED" || (jobType === "PENDING" && record?.requestApprovalStatus === "PENDING_APPROVAL_SITE_OWNER") ? (
                                record?.updatedAccessReport === null ? (
                                    <Menu.Item key="1" icon={<SnippetsOutlined />} onClick={() => downloadAccessReport(record?.id)}>
                                        <span className='textStyle-small'> Download Access Letter</span>
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item key="1" icon={<SnippetsOutlined />}>
                                        <a href={`${baseImgURL}${record?.updatedAccessReport}`} target="_blank" rel="noopener noreferrer" download>
                                            <span className='textStyle-small'> Download Replaced Access Letter</span>
                                        </a>
                                    </Menu.Item>
                                )
                            ) : null
                        }
                        <Menu.Item key="2" icon={<DownloadOutlined />} onClick={() => fetchDocLinksSiteAccessRequestByID(record?.id)}>
                            <span className='textStyle-small'>Uploaded Documents</span>
                        </Menu.Item>
                        <Menu.Item key="3" icon={<ClockCircleOutlined />} onClick={() => openEditHistoryWindow(record?.id)}>
                            <span className='textStyle-small'>View Edit History</span>
                        </Menu.Item>
                        {jobType === 'PENDING' && record?.requestApprovalStatus === "PENDING_APPROVAL" && editOption && (
                            <>
                                {
                                    approvalType !== 'BULK' ? (
                                        <Menu.Item key="4" icon={<EditOutlined />} onClick={() => {
                                            const route = `/access/dev/view/${record?.id}/SINGLE`;
                                            history.push(route);
                                        }}>
                                            <span className='textStyle-small'>Edit Request</span>
                                        </Menu.Item>
                                    ) : null
                                }
                                <Menu.Item key="5" icon={<DeleteOutlined />} onClick={() => cancelReqModal(record?.id)}>
                                    <span className='textStyle-small'>Cancel Request</span>
                                </Menu.Item>
                            </>
                        )}
                    </Menu>
                );
                return (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Button shape='circle' icon={<MoreOutlined />} size='small'/>
                        </Dropdown>
                    </div>
                );
            },
        }
    ];

    const bulRequestsCols = [
        {
            title: <span className="textStyle">Bulk ID</span>,
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => (
                <a
                    className="textStyleChild"
                    onClick={() => {
                        fetchAllBuikRequests(record?.id);
                        setSelectedBulkID(record?.id);
                        setShowBulkInfo(true);
                    }}
                >
                    {text}
                </a>
            )
        },
        {
            title: <span className="textStyle">Initiator Email</span>,
            dataIndex: 'initiatorUserEmail',
            key: 'initiatorUserEmail',
            render: text => <a className="textStyleChild">{text}</a>,
        },
        {
            title: <span className="textStyle">Initiator Mobile</span>,
            dataIndex: 'initiatorUserMobile',
            key: 'initiatorUserMobile',
            render: text => <a className="textStyleChild">{text}</a>,
        },
        {
            title: <span className="textStyle">Number Of Requests</span>,
            dataIndex: 'numberOfRequests',
            key: 'numberOfRequests',
            render: text => <a className="textStyleChild">{text}</a>,
        },
        {
            title: <span className="textStyle">Pending Requests</span>,
            dataIndex: 'pendingRequests',
            key: 'pendingRequests',
            render: text => <a className="textStyleChild">{text}</a>,
        },
        {
            title: <span className="textStyle">Completed Requests</span>,
            dataIndex: 'approvedRequests',
            key: 'approvedRequests',
            render: (text, record) => (
                <a className="textStyleChild">
                    {record?.numberOfRequests - record?.pendingRequests}
                </a>
            )
        },
        {
            title: <span className="textStyle">Created At</span>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: text => <a className="textStyleChild">{text ? new Date(text).toLocaleString() : '-'}</a>,
        },
        {
            title: <span className="textStyle">Bulk Status</span>,
            dataIndex: 'bulkStatus',
            key: 'bulkStatus',
            render: text => (
                <a className="textStyleChild">
                    {text === 'ONGOING' ? <Tag color="blue">On Going</Tag> : <Tag color="green">Completed</Tag>}
                </a>
            ),
        }
    ];

    if (jobType === 'PENDING' && editOption) {
        bulRequestsCols.push({
            title: <span className="textStyle">Actions</span>,
            key: 'id',
            dataIndex: 'id',
            render: (text, record) => {
                const canBeEdited = isCanBeEdit(record?.id, record?.numberOfRequests, record?.pendingRequests);
                const menu = (
                    <Menu>
                    {canBeEdited && (
                        <Menu.Item key="4" icon={<EditOutlined />} onClick={() => {
                            const route = `/access/dev/view/${text}/BULK`;
                            history.push(route);
                        }}>
                            <span className='textStyle-small'>Edit Bulk Request</span>
                        </Menu.Item>
                    )}
                    </Menu>
                );
                return (
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Button shape='circle' icon={<MoreOutlined />} size='small' />
                        </Dropdown>
                    </div>
                );
            },
        });
    }

    const isCanBeEdit = (bulkID, numberOfrequests, pendingRequests) => {
        let isEditable = true;
        onGoingRequests?.forEach((item) => {
            if (item.id === bulkID) {
                item?.siteAccessRequests?.forEach((site) => {
                    if (site?.requestApprovalStatus === 'REJECTED' ||
                        site?.requestApprovalStatus === 'PENDING_APPROVAL_SITE_OWNER' ||
                        site?.requestApprovalStatus === 'APPROVED') {
                        isEditable = false;
                    }
                });
            }
        });
        return isEditable;
    };
    

    const closeInFoModal = () => {
        setIsInfoModalVisible(false);
    }

    const openCommunicationModal = () => {
        setIsCommunicationModalVisible(true);
    }

    const closeCommunicationModal = () => {
        setIsCommunicationModalVisible(false);
    }

    const editModalOpenModal = () => {
        setEditModelOpen(true);
    }

    const editModalClose = () => {
        setEditModelOpen(false);
    }



    // file system render functions
    const fileComponents = ['handlerFileOne', 'handlerFileTwo', 'handlerFileThree', 'handlerFileFour', 'handlerFileFive'];

    const renderFile = (file, originalFile) => (
        <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
            <Row>
                <Col span={22}>
                    <Row>
                        <Col span={20}>
                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                {file ? file : 'N/A'}
                            </span>
                        </Col>
                        <Col span={4} style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1890ff", width: "20px", height: "20px", borderRadius: "5px" }}>
                                <a href={baseImgURL + file} target="_blank" download>
                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "14px", float: "right" }}>
                                        <DownloadOutlined style={{ color: 'white' }} />
                                    </span>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );


    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Access Requester
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Site Access </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Access Requester</a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    <Button style={{ borderRadius: "5px", backgroundColor: "#00AC4F", width: "100px" }}
                                        onClick={openFilterPaneFunc}
                                    >
                                        <span className="textStyles-small" style={{ color: "white" }}>
                                            <FilterOutlined /> Filters
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Row>
                    <Col span={12}>
                        <Card
                            style={{
                                width: '99%', borderRadius: '10px', marginTop: '20px', marginBottom: '20px', cursor: "pointer"
                            }}
                            onClick={() => {
                                setJobType('PENDING');
                                setApprovalType('SINGLE');
                                conditionsManager('SINGLE');
                                setShowBulkInfo(false);
                                resetFilterData();
                            }}
                        >
                            <Row>
                                <Col span={12}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        float: "left",
                                    }}>
                                        <div style={{
                                            background: "white",
                                            borderRadius: "50%",
                                            width: "30px",
                                            height: "30px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginRight: "8px",
                                            backgroundColor: "#2d5ff4"
                                        }}>
                                            <ShoppingOutlined style={{ color: "white", fontSize: '15px' }} />
                                        </div>
                                        <span className="textStyles-small" style={{ color: "gray", textAlign: "center", fontSize: "13px" }}>
                                            Pending Approval
                                        </span>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <h3 className="textStyles-small" class="m-0" style={{ marginTop: '3px', float: "right", marginRight: "20px" }}>
                                        <b>{counts ? counts?.runningJobs : 0}</b>
                                    </h3>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card
                            style={{
                                width: '99%', borderRadius: '10px', marginTop: '20px', marginBottom: '20px', cursor: "pointer"
                            }}
                            onClick={() => {
                                setJobType('COMPLETED');
                                setApprovalType('SINGLE');
                                setShowBulkInfo(false);
                                resetFilterData();
                                conditionsManager('SINGLE');
                            }}
                        >
                            <Row>
                                <Col span={12}>
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        float: "left",
                                    }}>
                                        <div style={{
                                            background: "white",
                                            borderRadius: "50%",
                                            width: "30px",
                                            height: "30px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginRight: "8px",
                                            backgroundColor: "gray"
                                        }}>
                                            <CheckOutlined style={{ color: "white", fontSize: '15px' }} />
                                        </div>
                                        <span className="textStyles-small" style={{ color: "gray", textAlign: "center", fontSize: "13px" }}>
                                            Completed Jobs
                                        </span>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <h3 className="textStyles-small" class="m-0" style={{ marginTop: '3px', float: "right", marginRight: "20px" }}>
                                        <b>{counts ? counts?.completedJobs : 0}</b>
                                    </h3>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <div style={{
                    backgroundColor: "#FFFFFF",
                    padding: "20px 20px 20px 20px",
                    borderRadius: "10px",
                    marginTop: "5px",
                }}>
                    <Row>
                        <Col span={6}>
                            <h3><b className="headingStyle-sub">
                                {
                                    jobType === 'PENDING' ? 'Pending Approval' : 'Completed Jobs'
                                }
                            </b></h3>
                        </Col>
                        <Col span={18}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <Row justify={'end'}>
                                    {
                                        editPersmission !== false && jobType === 'PENDING' ? (
                                            <Select
                                                style={{ width: '180px', marginRight: '10px' }}
                                                placeholder="Select Mode"
                                                onChange={(value) => {
                                                    setEditOption(value);
                                                    value === true ? fetchAllBulkRequestsList(1) : fetchAllBulkRequestsList(0);
                                                    conditionsManager(approvalType, null, value); 
                                                }}
                                                className='borderedSelect'
                                                bordered={false}
                                                defaultValue={editOption}
                                            >
                                                <Option className='textStyle-small' value={false}>
                                                    View Mode
                                                </Option>
                                                <Option className='textStyle-small' value={true}>
                                                    Edit Mode
                                                </Option>
                                            </Select>
                                        ) : null
                                    }
                                    {
                                        jobType === 'COMPLETED' && showBulkInfo === false ? (
                                            <Select
                                                style={{ width: '180px', marginRight: '10px' }}
                                                placeholder="Select Approval Type"
                                                onChange={(value) => {
                                                    setApprovalType(value);
                                                    conditionsManager(value, null, editOption); 
                                                }}
                                                className='borderedSelect'
                                                bordered={false}
                                                value={approvalType}
                                            >
                                                <Option className='textStyle-small' value="SINGLE">Single Requests</Option>
                                                <Option className='textStyle-small' value="BULK">Bulk Requests</Option>
                                            </Select>
                                        ) : null
                                    }
                                    {
                                        jobType === 'PENDING' && showBulkInfo === false ? (
                                            <>
                                                <Select
                                                    style={{ width: '180px', marginRight: '10px' }}
                                                    placeholder="Select Approval Type"
                                                    onChange={(value) => {
                                                        setApprovalType(value);
                                                        conditionsManager(value, null, editOption); 
                                                    }}
                                                    className='borderedSelect'
                                                    bordered={false}
                                                    value={approvalType}
                                                >
                                                    <Option className='textStyle-small' value="SINGLE">Single Requests</Option>
                                                    <Option className='textStyle-small' value="BULK">Bulk Requests</Option>
                                                </Select>
                                            </>
                                        ) : null
                                    }
                                    <Button style={{ borderRadius: "5px", backgroundColor: "#00AC4F", width: "150px" }}
                                        onClick={handleNewRequest}
                                    >
                                        <span className="textStyles-small" style={{ color: "white" }}>
                                            Create New Request
                                        </span>
                                    </Button>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Spin spinning={isLoadingData} tip="Loading...">
                        {
                            jobType !== 'COMPLETED' ? (
                                <div>
                                    {
                                        showBulkInfo !== false ? (
                                            <>
                                                <Row style={{ display: "flex", justifyContent: "end" }}>
                                                    <Button onClick={() => { 
                                                    conditionsManager("BULK", null, editOption);
                                                    setSelectedBulkID(null);
                                                    setInsideBulk([]);
                                                    setShowBulkInfo(false)}}
                                                        style={{ marginTop: "20px", borderRadius: "5px", backgroundColor: "#00AC4F", borderColor: "#00AC4F" }}
                                                        type="primary"
                                                        icon={<ArrowLeftOutlined />}>
                                                        <span className="textStyles-small" style={{ color: "white" }}>Back </span>
                                                    </Button>
                                                </Row>
                                                <Table
                                                    className="table-striped-rows"
                                                    style={{ marginTop: "20px" }}
                                                    dataSource={data}
                                                    columns={columns}
                                                    rowKey="key"
                                                    size="small"
                                                    pagination={false}
                                                />
                                            </>
                                        ) : <Table
                                            className="table-striped-rows"
                                            style={{ marginTop: "20px" }}
                                            dataSource={onGoingRequests}
                                            columns={approvalType === 'SINGLE' ? columns : bulRequestsCols}
                                            rowKey="key"
                                            size="small"
                                        />}
                                </div>) : (<div>
                                    {
                                        showBulkInfo !== false ? (
                                            <>
                                                <Row style={{ display: "flex", justifyContent: "end" }}>
                                                    <Button onClick={() => {
                                                        setShowBulkInfo(false);
                                                        setSelectedBulkID(null);
                                                        setInsideBulk([]);
                                                        conditionsManager("BULK", null, editOption);
                                                    }}
                                                    style={{ marginTop: "20px", borderRadius: "5px", backgroundColor: "#00AC4F", borderColor: "#00AC4F" }}
                                                    type="primary"
                                                    icon={<ArrowLeftOutlined />}>
                                                        <span className="textStyles-small" style={{ color: "white" }}>Back </span>
                                                    </Button>
                                                </Row>
                                                <Table
                                                    className="table-striped-rows"
                                                    style={{ marginTop: "20px" }}
                                                    dataSource={data}
                                                    columns={columns}
                                                    rowKey="key"
                                                    size="small"
                                                    pagination={false}
                                                />
                                            </>
                                        ) : <Table
                                            className="table-striped-rows"
                                            style={{ marginTop: "20px" }}
                                            dataSource={approvalRequests}
                                            columns={approvalType === 'SINGLE' ? columns : bulRequestsCols}
                                            rowKey="key"
                                            size="small"
                                        />}
                                </div>)
                        }
                    </Spin>

                </div>
            </div>

            <Modal
                title={<span className="textStyle-small" style={{ fontSize: "15px", fontWeight: "bold" }}>
                    Site Access Request Details</span>}
                visible={isInfoModalVisible}
                onOk={closeInFoModal}
                onCancel={closeInFoModal}
                width={850}
                destroyOnClose={true}
                footer={null}
            >
                <Row>
                    <Col span={12}>
                        <div style={{ borderColor: 'gray', backgroundColor: 'white', border: '1px solid #c2c2c2', padding: '5px', width: '99%' }}>
                            <div className='textStyle-small'
                                style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                Job Details
                            </div>
                            <Descriptions
                                column={1}
                                className='table-striped-rows'
                                style={{
                                    borderColor: 'white',
                                }}
                                border={false}
                                bordered
                                size="small">
                                <Descriptions.Item label="Request ID" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.requestId}</Descriptions.Item>
                                <Descriptions.Item label="Permission Start Date & Time" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.permissionStartDateTime?.split('T')[0] + ' ' + reqFormData?.permissionStartDateTime?.split('T')[1]}</Descriptions.Item>
                                <Descriptions.Item label="Permission End Date" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.permissionEndDate}</Descriptions.Item>
                                <Descriptions.Item label="Request Status" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.requestStatus ? reqFormData?.requestStatus === 'ON_GOING' ? <Tag color="blue">On Going</Tag> : <Tag color="green">Completed</Tag> : <Tag color="red">Canceled</Tag>}</Descriptions.Item>
                                <Descriptions.Item label="Request Approval Status" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.requestApprovalStatus ? reqFormData?.requestApprovalStatus === 'PENDING_APPROVAL' ? <Tag color="orange">Pending Approval</Tag> : reqFormData?.requestApprovalStatus === 'PENDING_APPROVAL_SITE_OWNER' ? <Tag color="purple">Pending With Site Owner</Tag> : reqFormData?.requestApprovalStatus === 'APPROVED' ? <Tag color="blue">Approved</Tag> : reqFormData?.requestApprovalStatus === 'REJECTED' ? <Tag color="red">Rejected</Tag> : <Tag color="pink">Canceled</Tag> : <Tag color="red">N/A</Tag>}</Descriptions.Item>
                                <Descriptions.Item label="Request Category" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.requestCategory?.name}</Descriptions.Item>
                                <Descriptions.Item label="Request Type" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.requestType}</Descriptions.Item>
                                <Descriptions.Item label="Sub Request Type" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.subRequestType?.name}</Descriptions.Item>
                                {
                                    reqFormData?.teamLeaderName !== null || reqFormData?.teamLeaderName === "" ? (
                                        <>
                                            <Descriptions.Item label="Team Leader Name" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.teamLeaderName}</Descriptions.Item>
                                            <Descriptions.Item label="Team Leader Contact" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.teamLeaderContact}</Descriptions.Item>
                                        </>
                                    ) : null
                                }
                            </Descriptions>
                        </div>
                    </Col>

                    <Col span={12}>
                        <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', border: '1px solid #c2c2c2', padding: '5px', marginLeft: '5px', height: '100%' }}>
                            <div className='textStyle-small'
                                style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                Site Details
                            </div>

                            <Descriptions
                                column={1}
                                className='table-striped-rows'
                                style={{
                                    borderColor: 'white',
                                }}
                                border={false}
                                bordered
                                size="small">
                                <Descriptions.Item label="Site ID" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.siteId}</Descriptions.Item>
                                <Descriptions.Item label="Site Name" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.siteName?.replace(/_/g, ' ')}</Descriptions.Item>
                                <Descriptions.Item label="Site Handler" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.handlerGroupName}</Descriptions.Item>
                                <Descriptions.Item label="Region Name" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.regionName}</Descriptions.Item>
                                <Descriptions.Item label="Depot Name" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.depotName}</Descriptions.Item>
                                <Descriptions.Item label="Site Type" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.siteType}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    </Col>
                </Row>

                <Row style={{ marginTop: '10px' }}>
                    {
                        reqFormData?.siteType === 'OO' ? (
                            <Col span={reqFormData?.requestType === 'PROJECT' ? 12 : 24}>
                                <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', border: '1px solid #c2c2c2', padding: '5px', height: '100%' }}>
                                    <div className='textStyle-small'
                                        style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                        Site Type : OO Type {reqFormData?.siteOwner}
                                    </div>
                                    <Descriptions
                                        column={1}
                                        className='table-striped-rows'
                                        style={{
                                            borderColor: 'white',
                                        }}
                                        border={false}
                                        bordered
                                        size="small">
                                        <Descriptions.Item label="Visiting Purpose" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.visitingPurpose?.name}</Descriptions.Item>
                                        <Descriptions.Item label="Access Need" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.accessNeed?.name}</Descriptions.Item>
                                        <Descriptions.Item label="Reason" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.optionalRemarksOne}</Descriptions.Item>
                                        <Descriptions.Item label="Remark" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.optionalRemarksTwo}</Descriptions.Item>
                                        <Descriptions.Item label="Work Type" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.workType?.name}</Descriptions.Item>
                                        <Descriptions.Item label="Work Area" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.workArea?.name}</Descriptions.Item>
                                    </Descriptions>
                                </div>
                            </Col>
                        ) : null
                    }
                    {
                        reqFormData?.siteType === 'PROJECT' ? (
                            <Col span={reqFormData?.siteType === 'OO' ? 12 : 24}>
                                <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', border: '1px solid #c2c2c2', padding: '5px', marginLeft: '5px', height: '100%' }}>
                                    <div className='textStyle-small'
                                        style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                        Request Type : {reqFormData?.requestType}
                                    </div>
                                    <Descriptions
                                        column={1}
                                        className='table-striped-rows'
                                        style={{
                                            borderColor: 'white',
                                        }}
                                        border={false}
                                        bordered
                                        size="small">
                                        <Descriptions.Item label="Project Name" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.projectName}</Descriptions.Item>
                                        <Descriptions.Item label="Scope Of Work" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.scopeOfWork}</Descriptions.Item>
                                        <Descriptions.Item label="Down Time Required Date" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.downTimeRequiredDate}</Descriptions.Item>
                                        <Descriptions.Item label="Sub Request Type" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.subRequestType?.name}</Descriptions.Item>
                                        <Descriptions.Item label="Team Leader Name" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.teamLeaderName}</Descriptions.Item>
                                        <Descriptions.Item label="Team Leader Contace" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.teamLeaderContact}</Descriptions.Item>
                                    </Descriptions>
                                </div>
                            </Col>
                        ) : null
                    }
                </Row>

                <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '100%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px' }}>
                    <div className='textStyle-small'
                        style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                        Team Members
                    </div>

                    <Row>
                        {
                            teamMemberJson?.map((item, index) => {
                                return (
                                    <div style={{ width: "49%", backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "10px", marginTop: "10px", marginRight: '3px' }}>
                                        <Row>
                                            <Col span={20}>
                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px" }}>
                                                    {item?.name}
                                                </span>
                                                <Row>
                                                    <Col span={8}>
                                                        <span className='textStyle-small' style={{ fontSize: "11px" }}>
                                                            <UserOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                            {item?.nic}
                                                        </span>
                                                    </Col>
                                                    <Col span={8}>
                                                        <span className='textStyle-small' style={{ fontSize: "11px" }}>
                                                            <PhoneOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                            {item?.contactNumber}
                                                        </span>
                                                    </Col>
                                                    <Col span={8}>
                                                        <span className='textStyle-small' style={{ fontSize: "11px" }}>
                                                            <BankOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                            {item?.company}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={4} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                {
                                                    item?.isBlacklisted ? (
                                                        <div
                                                            style={{
                                                                cursor: "pointer", color: "gray", float: "right", width: "20px", height: "20px",
                                                                borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "gray"
                                                            }}>
                                                            <UserDeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "10px" }} />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                cursor: "pointer", color: "gray", float: "right", width: "20px", height: "20px",
                                                                borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "gray"
                                                            }}>
                                                            <UserDeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "10px" }} />
                                                        </div>
                                                    )
                                                }
                                                {
                                                    item?.isKeyHolder ? (
                                                        <>
                                                            <div
                                                                style={{
                                                                    cursor: "pointer", color: "#8C5B00", float: "right", width: "20px", height: "20px", marginLeft: "2px",
                                                                    borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "#FFA500"
                                                                }}>
                                                                <KeyOutlined style={{ cursor: "pointer", color: "white", fontSize: "10px" }} />
                                                            </div>

                                                            <div
                                                                onClick={() => {setShowLocationDetails(true);}}
                                                                style={{
                                                                    cursor: "pointer", color: "#00AC4F", float: "right", width: "20px", height: "20px", marginLeft: "2px",
                                                                    borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "#00AC4F"
                                                                }}>
                                                                <AimOutlined style={{ cursor: "pointer", color: "white", fontSize: "10px" }} />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                cursor: "pointer", color: "gray", float: "right", width: "20px", height: "20px", marginLeft: "2px",
                                                                borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "gray"
                                                            }}>
                                                            <KeyOutlined style={{ cursor: "pointer", color: "white", fontSize: "10px" }} />
                                                        </div>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                    </Row>
                </div>

                <Row>
                    <Col span={12}>
                        <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px' }}>
                            <div className='textStyle-small'
                                style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                Equipment
                            </div>

                            {
                                equpmentJson?.map((item, index) => {
                                    return (
                                        <div style={{ width: "100%", backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                            <Row>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={8}>
                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                {item?.itemName}
                                                            </span>
                                                        </Col>
                                                        <Col span={16} style={{ display: "flex", flexDirection: "row", float: "right" }}>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#E6DD08", width: "auto", height: "20px", borderRadius: "5px", padding: "3px" }}>
                                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                    <span style={{ color: "white" }}>{item?.actionType ? item?.actionType : 'N/A'}</span>
                                                                </span>
                                                            </div>
                                                            <div style={{
                                                                display: "flex", alignItems: "center", justifyContent: "center",
                                                                backgroundColor: "#AB0EB9", width: "50px", height: "20px", borderRadius: "5px", marginLeft: "5px", padding: "3px"
                                                            }}>
                                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                    <span style={{ color: "white" }}>{item?.quantity ? item?.quantity : 0}</span>
                                                                </span>
                                                            </div>
                                                        </Col>
                                                        <span className='textStyle-small' style={{ fontSize: "12px", marginTop: "7px" }}>
                                                            <b>{item?.serialNumber}</b><br></br>
                                                            <span style={{ fontSize: '10px' }}>
                                                                {
                                                                    item?.description ? item?.description : 'N/A'
                                                                }
                                                            </span>
                                                        </span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })

                            }
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', marginLeft: '5px' }}>
                            <div className='textStyle-small'
                                style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                Instrument
                            </div>

                            {
                                instrumentJson?.map((item, index) => {
                                    return (
                                        <div style={{ width: "100%", backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                            <Row>
                                                <Col span={24}>
                                                    <Row>
                                                        <Col span={8}>
                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                {item?.itemName ? item?.itemName : 'N/A'}
                                                            </span>
                                                        </Col>
                                                        <Col span={16} style={{ display: "flex", flexDirection: "row", float: "right" }}>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#E6DD08", width: "auto", height: "20px", borderRadius: "5px", padding: "3px" }}>
                                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                    <span style={{ color: "white" }}>{item?.actionType ? item?.actionType : 'N/A'}</span>
                                                                </span>
                                                            </div>
                                                            <div style={{
                                                                display: "flex", alignItems: "center", justifyContent: "center",
                                                                backgroundColor: "#AB0EB9", width: "50px", height: "20px", borderRadius: "5px", marginLeft: "5px", padding: "3px"
                                                            }}>
                                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                    <span style={{ color: "white" }}>{item?.quantity ? item?.quantity : 0}</span>
                                                                </span>
                                                            </div>
                                                        </Col>
                                                        <span className='textStyle-small' style={{ fontSize: "12px", marginTop: "7px" }}>
                                                            <b>{item?.serialNumber}</b><br></br>
                                                            <span style={{ fontSize: '10px' }}>
                                                                {
                                                                    item?.description ? item?.description : 'N/A'
                                                                }
                                                            </span>
                                                        </span>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={12}>
                        <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', height: '100%' }}>
                            <div className='textStyle-small'
                                style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                Vehicle
                            </div>

                            {
                                vehicleJson?.map((item, index) => {
                                    return (
                                        <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                            <Row>
                                                <Col span={22}>
                                                    <Row>
                                                        <Col span={8}>
                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                {item?.vehicleNumber}
                                                            </span>
                                                        </Col>
                                                        <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px", padding: "3px" }}>
                                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                    <span style={{ color: "white" }}>{item?.vehicleType}</span>
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>

                    <Col span={12}>
                        <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', marginLeft: '5px', height: '100%' }}>
                            <div className='textStyle-small'
                                style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                Documents
                            </div>

                            {
                                reqFormData?.initiatorFileOne ? (
                                    <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                        <Row>
                                            <Col span={22}>
                                                {
                                                    reqFormData?.initiatorFileOne ? (
                                                        <Row>
                                                            <Col span={20}>
                                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                    {reqFormData?.initiatorFileOneOriginal}
                                                                </span>
                                                            </Col>
                                                            <Col span={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1890ff", width: "20px", height: "20px", borderRadius: "5px" }}>
                                                                    <a href={baseImgURL + reqFormData?.initiatorFileOne} target="_blank" download>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "14px", float: "right" }}>
                                                                            <DownloadOutlined style={{ color: 'white' }} />
                                                                        </span> </a>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ) : null
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                ) : null
                            }

                            {
                                reqFormData?.initiatorFileTwo ? (
                                    <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                        <Row>
                                            <Col span={22}>
                                                {
                                                    reqFormData?.initiatorFileTwo ? (
                                                        <Row>
                                                            <Col span={20}>
                                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                    {reqFormData?.initiatorFileTwoOriginal}
                                                                </span>
                                                            </Col>
                                                            <Col span={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1890ff", width: "20px", height: "20px", borderRadius: "5px" }}>
                                                                    <a href={baseImgURL + reqFormData?.initiatorFileTwo} target="_blank" download>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "14px", float: "right" }}>
                                                                            <DownloadOutlined style={{ color: 'white' }} />
                                                                        </span> </a>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ) : null
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                ) : null
                            }

                            {
                                reqFormData?.initiatorFileThree ? (
                                    <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                        <Row>
                                            <Col span={22}>
                                                {
                                                    reqFormData?.initiatorFileThree ? (
                                                        <Row>
                                                            <Col span={20}>
                                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                    {reqFormData?.initiatorFileThreeOriginal}
                                                                </span>
                                                            </Col>
                                                            <Col span={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1890ff", width: "20px", height: "20px", borderRadius: "5px" }}>
                                                                    <a href={baseImgURL + reqFormData?.initiatorFileThree} target="_blank" download>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "14px", float: "right" }}>
                                                                            <DownloadOutlined style={{ color: 'white' }} />
                                                                        </span> </a>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ) : null
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                ) : null
                            }

                            {
                                reqFormData?.initiatorFileFour ? (
                                    <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                        <Row>
                                            <Col span={22}>
                                                {
                                                    reqFormData?.initiatorFileFour ? (
                                                        <Row>
                                                            <Col span={20}>
                                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                    {reqFormData?.initiatorFileFourOriginal}
                                                                </span>
                                                            </Col>
                                                            <Col span={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1890ff", width: "20px", height: "20px", borderRadius: "5px" }}>
                                                                    <a href={baseImgURL + reqFormData?.initiatorFileFour} target="_blank" download>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "14px", float: "right" }}>
                                                                            <DownloadOutlined style={{ color: 'white' }} />
                                                                        </span> </a>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ) : null
                                                }
                                            </Col>
                                        </Row>

                                    </div>
                                ) : null
                            }


                            {
                                reqFormData?.initiatorFileFive ? (
                                    <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                        <Row>
                                            <Col span={22}>
                                                {
                                                    reqFormData?.initiatorFileFive ? (
                                                        <Row>
                                                            <Col span={20}>
                                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                    {reqFormData?.initiatorFileFiveOriginal}
                                                                </span>
                                                            </Col>
                                                            <Col span={4} style={{ display: "flex", flexDirection: "row" }}>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1890ff", width: "20px", height: "20px", borderRadius: "5px" }}>
                                                                    <a href={baseImgURL + reqFormData?.initiatorFileFive} target="_blank" download>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "14px", float: "right" }}>
                                                                            <DownloadOutlined style={{ color: 'white' }} />
                                                                        </span> </a>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ) : null
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                ) : null
                            }
                        </div>
                    </Col>
                </Row>

                <Row style={{ marginTop: '10px' }}>
                    <Col span={12}>
                        <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', height: '100%' }}>
                            <div className='textStyle-small'
                                style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                Work Description
                            </div>
                            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                <span className='textStyle-small' style={{ fontSize: "12px", marginTop: '10px', marginLeft: '5px' }}>
                                    {reqFormData?.description}
                                </span>
                            </div>
                        </div>
                    </Col>

                    <Col span={12}>
                        <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', height: '100%', marginLeft: '5px' }}>
                            <div className='textStyle-small'
                                style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                Work Description
                            </div>
                            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', top: '5%', position: 'relative' }}>
                                    <div class="card-new" style={{ margin: '10px' }}>
                                        <div class="icon-new">
                                            <UserOutlined style={{ fontSize: '30px', color: 'blue' }} />
                                        </div>
                                        <div class="text textStyles-small" style={{ fontSize: '10px' }}>
                                            {reqFormData?.initiatorGroupName}</div>
                                    </div>
                                    <div class="card-new" style={{ margin: '10px' }}>
                                        <div class="icon-new">
                                            <PhoneOutlined style={{ fontSize: '30px', color: 'blue' }} />
                                        </div>
                                        <div class="text textStyles-small" style={{ fontSize: '10px' }}>
                                            {reqFormData?.initiatorUserMobile}</div>
                                    </div>
                                    <div class="card-new" style={{ margin: '10px' }}>
                                        <div class="icon-new">
                                            <MailOutlined style={{ fontSize: '30px', color: 'blue' }} />
                                        </div>
                                        <div class="text textStyles-small" style={{ fontSize: '10px' }}>
                                            {reqFormData?.initiatorUserEmail}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                {
                    reqFormData?.requestApprovalStatus === 'APPROVED' || reqFormData?.requestApprovalStatus === 'REJECTED' || reqFormData?.requestApprovalStatus === 'CANCELLED' || reqFormData?.requestApprovalStatus === 'PENDING_APPROVAL_SITE_OWNER' ? (

                        <Row style={{ marginTop: '10px' }}>
                            <Col span={24}>
                                <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '100%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', height: '100%' }}>
                                    <div className='textStyle-small'
                                        style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                        Request Approval Information
                                    </div>
                                    <Row>
                                        <Col span={12}>
                                            <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', height: '100%' }}>
                                                <div className='textStyle-small'
                                                    style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                                    Work Description
                                                </div>
                                                <div>
                                                    <Descriptions
                                                        column={1}
                                                        className='table-striped-rows'
                                                        style={{
                                                            borderColor: 'white',
                                                        }}
                                                        border={false}
                                                        bordered
                                                        size="small">
                                                        <Descriptions.Item label="Handler Approval Communication Method" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.handlerApprovalCommunicationMethod}</Descriptions.Item>
                                                        {
                                                            reqFormData?.requestApprovalStatus === 'APPROVED' ? (
                                                                <>
                                                                    <Descriptions.Item label="Approved At" className='textStyle-small' style={{ size: '12px' }}>
                                                                        {reqFormData?.approvedAt?.split('T')[0] + ' ' + reqFormData?.approvedAt?.split('T')[1].split('.')[0]}
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item label="Approved By Email" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.approvedByEmail}</Descriptions.Item>
                                                                    <Descriptions.Item label="Approver Remarks" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.approvalReason}</Descriptions.Item>
                                                                </>
                                                            ) : null
                                                        }
                                                        {
                                                            reqFormData?.requestApprovalStatus === 'REJECTED' ? (
                                                                <>
                                                                    <Descriptions.Item label="Rejected At" className='textStyle-small' style={{ size: '12px' }}>
                                                                        {reqFormData?.rejectedAt?.split('T')[0] + ' ' + reqFormData?.rejectedAt?.split('T')[1].split('.')[0]}
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item label="Rejected By" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.rejectedByEmail}</Descriptions.Item>
                                                                    <Descriptions.Item label="Reject Remarks" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.rejectReason}</Descriptions.Item>
                                                                </>
                                                            ) : null
                                                        }
                                                        {
                                                            reqFormData?.requestApprovalStatus === 'CANCELLED' ? (
                                                                <>
                                                                    <Descriptions.Item label="Cancelled By" className='textStyle-small' style={{ size: '12px' }}>{reqFormData?.cancelledByEmail}</Descriptions.Item>
                                                                    <Descriptions.Item label="Cancelled At" className='textStyle-small' style={{ size: '12px' }}>
                                                                        {reqFormData?.cancelledAt?.split('T')[0] + ' ' + reqFormData?.cancelledAt?.split('T')[1].split('.')[0]}
                                                                    </Descriptions.Item>
                                                                </>
                                                            ) : null
                                                        }
                                                    </Descriptions>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={12}>
                                            <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', height: '100%' }}>
                                                <div className='textStyle-small'
                                                    style={{ backgroundColor: '#b5b7c0', color: 'white', padding: '5px', fontSize: '12px', justifyContent: 'center', display: 'flex' }}>
                                                    Handler Documents
                                                </div>
                                                <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                                                    {fileComponents.map((file, index) => reqFormData[file] && renderFile(reqFormData[file], reqFormData[`${file}Original`]))}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    ) : null
                }


                {
                    jobType === 'PENDING' ? (
                        <Row>
                            <Col span={12} />
                            <Col span={12} style={{ marginTop: '10px' }}>
                                <Button
                                    type='secondary'
                                    style={{ borderRadius: "5px", width: "100px", marginTop: "10px", float: 'right', marginRight: '5px' }}
                                    onClick={closeInFoModal}
                                >
                                    <span className="textStyles-small" style={{ color: "blue" }}>
                                        Back
                                    </span>
                                </Button>
                            </Col>
                        </Row>
                    ) : null
                }
            </Modal>

            <Modal
                title={<span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "15px" }}>Request Communication Type</span>}
                visible={isCommunicationModalVisible}
                onOk={closeCommunicationModal}
                onCancel={closeCommunicationModal}
                width={500}
                footer={null}
                style={{ zIndex: 9999 }}
            >
                <>
                    <div style={{ width: '100%', backgroundColor: '#f6f4d1', padding: '10px', borderRadius: '5px', marginTop: '10px', marginBottom: '10px', border: '1px solid #f1eeb7' }}>
                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px" }}>
                            Site Owner Approval
                        </span> <br></br>
                        <Checkbox style={{ marginTop: '10px' }}
                            onChange={(e) => setOwnerApproval(e.target.checked)}
                        >
                            <span className='textStyle-small' style={{ fontSize: "12px" }}> Site Owner Approval Required </span>
                        </Checkbox>
                    </div>

                    {
                        requireOwnerApproval !== true ? (
                            <>
                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", marginTop: '50px', marginLeft: '5px' }}>
                                    Attach Document
                                </span>
                                <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '3px', border: '1px solid #c2c2c2', marginLeft: '5px' }}>
                                    <Upload
                                        className='textStyle-small' style={{ fontSize: "10px" }}>
                                        <Button icon={<UploadOutlined />}
                                            style={{ borderRadius: '5px', color: 'black', width: '100px', border: '1px solid #ffffff' }}>
                                            <span className="textStyles-small" style={{ color: "black" }}>Click to Upload</span>
                                        </Button>
                                    </Upload>
                                </div>
                            </>
                        ) : null
                    }

                    <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '10px', marginLeft: '5px' }}>
                        <Row>
                            <Col span={12}>
                                <span style={{ fontSize: "12px", marginLeft: '10px', marginTop: '20px' }} className='textStyle-small'>SMS</span><br></br>
                                <span style={{ fontSize: "12px", marginLeft: '10px' }} className='textStyle-small'>Call</span><br></br>
                                <span style={{ fontSize: "12px", marginLeft: '10px' }} className='textStyle-small'>Letter</span><br></br>
                                <span style={{ fontSize: "12px", marginLeft: '10px' }} className='textStyle-small'>Email</span><br></br>
                            </Col>

                            <Col span={12}>
                                <Radio.Group style={{ float: 'right', marginTop: '1px' }} onChange={(e) => setCommunicationType(e.target.value)} value={communicationType}>
                                    <Radio value={1} style={{ float: 'right', marginRight: '10px' }} /><br></br>
                                    <Radio value={2} style={{ float: 'right', marginRight: '10px' }} /><br></br>
                                    <Radio value={3} style={{ float: 'right', marginRight: '10px' }} /><br></br>
                                    <Radio value={4} style={{ float: 'right', marginRight: '10px' }} /><br></br>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </div>


                    <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', marginLeft: '5px' }}>
                        <Input.TextArea
                            placeholder="Enter your comments here"
                            autoSize={{ minRows: 2, maxRows: 5 }}
                            bordered={false}
                            style={{ marginTop: '10px', borderRadius: '10px' }}
                            onChange={(e) => setRequiredRemark(e.target.value)}

                        />
                    </div>

                    <Row>
                        <Col span={24} style={{ marginTop: '10px' }}>

                            {
                                requireOwnerApproval === true ? (
                                    <>
                                        <Button
                                            type='secondary'
                                            style={{ borderRadius: "5px", width: "100px", marginTop: "10px", marginRight: '5px' }}
                                            onClick={closeCommunicationModal}
                                        >
                                            <span className="textStyles-small" style={{ color: "blue" }}>
                                                Back
                                            </span>
                                        </Button>
                                        <Button
                                            type='primary'
                                            style={{ borderRadius: "5px", width: "100px", marginTop: "10px" }}
                                            onClick={() => requireSiteOwnerApproval(reqFormData?.id)}
                                        >
                                            <span className="textStyles-small" style={{ color: "white" }}>
                                                Submit
                                            </span>
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            type='secondary'
                                            style={{ borderRadius: "5px", width: "100px", marginTop: "10px", marginRight: '5px' }}
                                            onClick={closeCommunicationModal}
                                        >
                                            <span className="textStyles-small" style={{ color: "blue" }}>
                                                Back
                                            </span>
                                        </Button>
                                        <Button
                                            type='primary'
                                            style={{ borderRadius: "5px", width: "100px", marginTop: "10px", marginRight: '5px' }}
                                            onClick={() => normalApprovalWithOutOwner(reqFormData?.id)}
                                        >
                                            <span className="textStyles-small" style={{ color: "white" }}>
                                                Approve
                                            </span>
                                        </Button>
                                        <Button
                                            type='primary'
                                            style={{ borderRadius: "5px", width: "100px", marginTop: "10px" }}
                                            danger
                                            onClick={() => openRejectModal(1)}
                                        >
                                            <span className="textStyles-small" style={{ color: "white" }}>
                                                Reject
                                            </span>
                                        </Button>
                                    </>
                                )
                            }
                        </Col>
                    </Row>
                </>
            </Modal>

            <Modal
                title={<span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "15px" }}>Request Communication Type</span>}
                visible={editModelOpen}
                onOk={editModalClose}
                onCancel={editModalClose}
                width={500}
                destroyOnClose={true}
                footer={null}
                style={{ zIndex: 9999 }}
            >
                <>
                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", marginTop: '50px', marginLeft: '5px' }}>
                        Attach Document
                    </span>
                    <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '3px', border: '1px solid #c2c2c2', marginLeft: '5px' }}>
                        <Upload
                            className='textStyle-small' style={{ fontSize: "10px" }}
                            onChange={(e) => setFileList(e.fileList)}
                        >
                            <Button icon={<UploadOutlined />}
                                style={{ borderRadius: '5px', color: 'black', width: '100px', border: '1px solid #ffffff' }}>
                                <span className="textStyles-small" style={{ color: "black" }}>Click to Upload</span>
                            </Button>
                        </Upload>
                    </div>

                    <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '10px', marginLeft: '5px' }}>
                        <Row>
                            <Col span={12}>
                                <span style={{ fontSize: "12px", marginLeft: '10px', marginTop: '20px' }} className='textStyle-small'>SMS</span><br></br>
                                <span style={{ fontSize: "12px", marginLeft: '10px' }} className='textStyle-small'>Call</span><br></br>
                                <span style={{ fontSize: "12px", marginLeft: '10px' }} className='textStyle-small'>Letter</span><br></br>
                                <span style={{ fontSize: "12px", marginLeft: '10px' }} className='textStyle-small'>Email</span><br></br>
                            </Col>

                            <Col span={12}>
                                <Radio.Group style={{ float: 'right', marginTop: '1px' }} onChange={(e) => setCommunicationType(e.target.value)} value={communicationType}>
                                    <Radio value={1} style={{ float: 'right', marginRight: '10px' }} /><br></br>
                                    <Radio value={2} style={{ float: 'right', marginRight: '10px' }} /><br></br>
                                    <Radio value={3} style={{ float: 'right', marginRight: '10px' }} /><br></br>
                                    <Radio value={4} style={{ float: 'right', marginRight: '10px' }} /><br></br>
                                </Radio.Group>
                            </Col>
                        </Row>
                    </div>


                    <div style={{ borderColor: 'gray', backgroundColor: 'white', width: '99%', marginTop: '10px', border: '1px solid #c2c2c2', padding: '5px', marginLeft: '5px' }}>
                        <Input.TextArea
                            placeholder="Enter your comments here"
                            autoSize={{ minRows: 2, maxRows: 5 }}
                            bordered={false}
                            style={{ marginTop: '10px', borderRadius: '10px' }}
                            onChange={(e) => setRequiredRemark(e.target.value)}
                        />
                    </div>

                    <Row>
                        <Col span={24} style={{ marginTop: '10px' }}>
                            <>
                                <Button
                                    type='secondary'
                                    style={{ borderRadius: "5px", width: "100px", marginTop: "10px", marginRight: '5px' }}
                                    onClick={closeCommunicationModal}
                                >
                                    <span className="textStyles-small" style={{ color: "blue" }}>
                                        Back
                                    </span>
                                </Button>
                                <Button
                                    type='primary'
                                    style={{ borderRadius: "5px", width: "100px", marginTop: "10px", marginRight: '5px', float: 'right' }}
                                    onClick={() => approvalWithOwnerApproval(reqFormData?.id)}
                                >
                                    <span className="textStyles-small" style={{ color: "white" }}>
                                        Approve
                                    </span>
                                </Button>
                                <Button
                                    type='primary'
                                    style={{ borderRadius: "5px", width: "100px", marginTop: "10px", float: 'right', marginRight: '5px' }}
                                    danger
                                    onClick={() => openRejectModal(2)}
                                >
                                    <span className="textStyles-small" style={{ color: "white" }}>
                                        Reject
                                    </span>
                                </Button>
                            </>
                        </Col>
                    </Row>
                </>
            </Modal>

            <Modal
                icon={null}
                visible={rejectModal}
                onOk={closeRejectModal}
                onCancel={closeRejectModal}
                width={500}
                footer={null}
                destroyOnClose={true}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <FormOutlined style={{ fontSize: '30px', color: 'red' }} />
                    <h3 className='textStyle-small' style={{ marginTop: '10px', fontSize: '15px' }}>
                        Reject Reason
                    </h3>
                    <div style={{
                        borderColor: 'gray',
                        backgroundColor: 'white',
                        width: '99%',
                        marginTop: '10px',
                        border: '1px solid #c2c2c2',
                        padding: '5px',
                        marginLeft: '5px',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center', // Align items at center horizontally
                        justifyContent: 'center', // Align items at center vertically
                    }}>
                        <Input.TextArea
                            placeholder="Enter your comments here"
                            autoSize={{ minRows: 2, maxRows: 5 }}
                            bordered={false}
                            style={{ marginTop: '10px', borderRadius: '10px' }}
                            onChange={(e) => setRejectReason(e.target.value)}
                        />
                    </div>
                </div>
                <Row>
                    <Col span={24} style={{ marginTop: '10px' }}>
                        <>
                            <Button
                                type='secondary'
                                style={{ borderRadius: "5px", width: "100px", marginTop: "10px", marginRight: '5px', float: 'right' }}
                                onClick={closeRejectModal}
                            >
                                <span className="textStyles-small" style={{ color: "red" }}>
                                    Back
                                </span>
                            </Button>
                            {
                                rejectType === 1 ? (
                                    <Button
                                        type='primary'
                                        style={{ borderRadius: "5px", width: "100px", marginTop: "10px", marginRight: '5px', float: 'right' }}
                                        onClick={() => normalRejection(reqFormData?.id)}
                                        danger
                                    >
                                        <span className="textStyles-small" style={{ color: "white" }}>
                                            Reject
                                        </span>
                                    </Button>
                                ) : (
                                    <Button
                                        type='primary'
                                        style={{ borderRadius: "5px", width: "100px", marginTop: "10px", marginRight: '5px', float: 'right' }}
                                        onClick={() => rejectWithOwnerApproval(reqFormData?.id)}
                                        danger
                                    >
                                        <span className="textStyles-small" style={{ color: "white" }}>
                                            Reject
                                        </span>
                                    </Button>
                                )
                            }
                        </>
                    </Col>
                </Row>
            </Modal>

            <Modal
                title={<span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "15px" }}>Filter By</span>}
                visible={openFilterPane}
                onOk={()=> {
                    openFilterPaneFunc();
                    setDateType(null);
                }}
                onCancel={()=> {
                    openFilterPaneFunc();
                    setDateType(null);
                }}
                width={350}
                footer={null}
                header={null}
                style={{
                    position: 'fixed',
                    top: 20,
                    right: 20,
                    borderRadius: '10px',
                    overflow: 'auto',
                }}
                destroyOnClose={true}
            >
                <div>
                    <Form
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinishFilter}
                    >
                        <div style={{
                            overflowY: 'scroll',
                            height: '500px',
                            scrollbarWidth: 'none', /* For Firefox */
                            msOverflowStyle: 'none', /* For Internet Explorer and Edge */
                            '&::-webkit-scrollbar': { /* For Chrome, Safari and Opera */
                                display: 'none'
                            }
                        }}>
                            <div style={{ marginTop: '20px' }}>
                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", marginTop: '50px', marginLeft: '5px' }}>
                                    Site Name/ID
                                </span>
                                <Divider type="horizontal" style={{ marginTop: '2px', marginBottom: '10px', height: 'auto' }} />
                                <Form.Item
                                    name="siteID"
                                    initialValue={
                                        approvalType === 'BULK' ? filterBulk?.siteIds ? filterBulk?.siteIds : [] :
                                            approvalType === 'BULK' && showBulkInfo === true ? filterBulk?.siteAccessRequestFilter?.siteIds ? filterBulk?.siteAccessRequestFilter?.siteIds : [] :
                                                approvalType === 'SINGLE' ? filterSingle?.siteIds ? filterSingle?.siteIds : [] :
                                                    []
                                    }
                                >
                                    <Select
                                        bordered={false}
                                        className='borderedSelect'
                                        style={{ width: '100%' }}
                                        placeholder="Search By Site Name or ID"
                                        allowClear
                                        showSearch
                                        mode='multiple'
                                        filterOption={(input, option) => {
                                            const [siteName, siteId] = option.children.split(" - ");
                                            return (
                                                siteName.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                siteId.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                    >
                                        {
                                            loadFullSiteList?.map((item, index) => (
                                                // Including both siteName and siteId in the children for display and search
                                                <Select.Option key={index} value={item?.id}>
                                                    {`${item?.siteName} - ${item?.siteId}`}
                                                </Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </div>

                            {
                                approvalType !== 'BULK' || showBulkInfo ? (
                                    <>
                                        <div>
                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", marginLeft: '5px' }}>
                                                Region
                                            </span>
                                            <Divider type="horizontal" style={{ marginTop: '2px', marginBottom: '10px' }} />
                                            <FormItem
                                                name="region"
                                                initialValue={
                                                    approvalType === 'SINGLE' ? filterSingle?.regionIds ? filterSingle?.regionIds : [] :
                                                        approvalType === 'BULK' && showBulkInfo === true ? filterBulk?.siteAccessRequestFilter?.regionIds ? filterBulk?.siteAccessRequestFilter?.regionIds : [] :
                                                            []}
                                            >
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder="Select Request Type"
                                                    mode="multiple"
                                                    bordered={false}
                                                    className='borderedSelect'
                                                >
                                                    {
                                                        subRegions?.map((item, index) => {
                                                            return (
                                                                <Option value={item?.id} className='textStyle-small'>
                                                                    {item?.name}
                                                                </Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormItem>
                                        </div>

                                        <div>
                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", marginTop: '50px', marginLeft: '5px' }}>
                                                Depot
                                            </span>
                                            <Divider type="horizontal" style={{ marginTop: '2px', marginBottom: '10px' }} />
                                            <FormItem
                                                name="depot"
                                                initialValue={
                                                    approvalType === 'SINGLE' ? filterSingle?.depotIds ? filterSingle?.depotIds : [] :
                                                        approvalType === 'BULK' && showBulkInfo === true ? filterBulk?.siteAccessRequestFilter?.depotIds ? filterBulk?.siteAccessRequestFilter?.depotIds : [] :
                                                            []}
                                            >
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder="Select Depot"
                                                    mode="multiple"
                                                    bordered={false}
                                                    className='borderedSelect'
                                                >
                                                    {
                                                        depots?.map((item, index) => {
                                                            return (
                                                                <Option value={item?.id} className='textStyle-small'>
                                                                    {item?.name}
                                                                </Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormItem>
                                        </div>

                                        <div style={{ marginTop: '20px' }}>
                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", marginTop: '50px', marginLeft: '5px' }}>
                                                Step
                                            </span>
                                            <Divider type="horizontal" style={{ marginTop: '2px', marginBottom: '10px', height: 'auto' }} />
                                            <FormItem
                                                name="step"
                                                initialValue={
                                                    approvalType === 'SINGLE' ? filterSingle?.statuses ? filterSingle?.statuses : [] :
                                                        approvalType === 'BULK' && showBulkInfo === true ? filterBulk?.siteAccessRequestFilter?.statuses ? filterBulk?.siteAccessRequestFilter?.statuses : [] :
                                                            []}
                                            >
                                                <Select
                                                    style={{ width: '100%' }}
                                                    placeholder="Select Step"
                                                    mode="multiple"
                                                    bordered={false}
                                                    className='borderedSelect'
                                                >
                                                    {
                                                        jobType === 'PENDING' ? (<>
                                                            <Option value="PENDING_APPROVAL" className='textStyle-small'>Pending Approval</Option>
                                                            <Option value="PENDING_WITH_SITE_OWNER" className='textStyle-small'>Pending With Site Owner</Option>
                                                        </>
                                                        ) : (
                                                            <>
                                                                <Option value="APPROVED" className='textStyle-small'>Approved</Option>
                                                                <Option value="CANCELLED" className='textStyle-small'>Cancelled</Option>
                                                                <Option value="REJECTED" className='textStyle-small'>Rejected</Option>
                                                            </>
                                                        )
                                                    }
                                                </Select>
                                            </FormItem>
                                        </div>
                                    </>
                                ) : null
                            }

                            {
                                approvalType === 'BULK' && showBulkInfo !== true ? (
                                    <div>
                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", marginLeft: '5px' }}>
                                            Bulk ID
                                        </span>
                                        <Divider type="horizontal" style={{ marginTop: '2px', marginBottom: '10px' }} />
                                        <FormItem
                                            name="bulkIDs"
                                            initialValue={approvalType === 'BULK' && showBulkInfo !== true ? filterBulk.bulkIds : []}
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Select Bulk ID"
                                                mode="multiple"
                                                bordered={false}
                                                className='borderedSelect'
                                                filterOption={(inputValue, option) =>
                                                    option.props.children.toString().toLowerCase().includes(inputValue.toLowerCase())
                                                }
                                            >
                                                {
                                                    allBulkRequests?.map((item, index) => (
                                                        <Option key={item.id} value={item.id} className='textStyle-small'>
                                                            {item.code}
                                                        </Option>
                                                    ))
                                                }
                                            </Select>
                                        </FormItem>
                                    </div>
                                ) : null
                            }


                            <div style={{ marginTop: '20px' }}>
                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", marginTop: '50px', marginLeft: '5px' }}>
                                    Type
                                </span>
                                <Divider type="horizontal" style={{ marginTop: '2px', marginBottom: '10px', height: 'auto' }} />
                                <FormItem
                                    initialValue={approvalType === 'SINGLE' ? filterSingle?.requestTypeEnums ? filterSingle.requestTypeEnums : [] :
                                        approvalType === 'BULK' && showBulkInfo !== true ? filterBulk.requestTypeEnums ? filterBulk.requestTypeEnums : [] :
                                            approvalType === 'BULK' && showBulkInfo === true ? filterBulk.siteAccessRequestFilter?.requestTypeEnums ? filterBulk.siteAccessRequestFilter?.requestTypeEnums : [] :
                                                []
                                    }
                                    name="type">
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Select Type"
                                        mode="multiple"
                                        bordered={false}
                                        className='borderedSelect'
                                        onChange={(e) => setIsTypeSelect(e)}
                                    >
                                        <Option value="PROJECT" className='textStyle-small'>Project</Option>
                                        <Option value="OPERATION" className='textStyle-small'>Operation</Option>
                                    </Select>
                                </FormItem>

                                {
                                    isTypeSelect.length > 0 ? (
                                        <FormItem name="subType"
                                            initialValue={
                                                approvalType === 'SINGLE' ? filterSingle?.subRequestTypeIds ? filterSingle.subRequestTypeIds : [] :
                                                    approvalType === 'BULK' && showBulkInfo === false ? filterBulk.subRequestTypeIds ? filterBulk.subRequestTypeIds : [] :
                                                        approvalType === 'BULK' && showBulkInfo === true ? filterBulk.siteAccessRequestFilter?.subRequestTypeIds ? filterBulk.siteAccessRequestFilter?.subRequestTypeIds : [] :
                                                            []
                                            }
                                        >
                                            <Select
                                                style={{ width: '100%' }}
                                                placeholder="Select Sub Request Type"
                                                mode="multiple"
                                                bordered={false}
                                                className='borderedSelect'
                                                name="subType"
                                            >
                                                {
                                                    subRequestType?.map((item, index) => {
                                                        return (
                                                            <Option value={item?.id} className='textStyle-small'>
                                                                {item?.name}
                                                            </Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormItem>
                                    ) : null
                                }
                            </div>

                            <div style={{ marginTop: '20px' }}>
                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", marginTop: '50px', marginLeft: '5px' }}>
                                    Category
                                </span>
                                <Divider type="horizontal" style={{ marginTop: '2px', marginBottom: '10px', height: 'auto' }} />
                                <FormItem
                                    name="category"
                                    initialValue={approvalType === 'SINGLE' ? filterSingle?.requestCategoryIds ? filterSingle.requestCategoryIds : [] :
                                        approvalType === 'BULK' && showBulkInfo !== true ? filterBulk.requestCategoryIds ? filterBulk.requestCategoryIds : [] :
                                            approvalType === 'BULK' && showBulkInfo === true ? filterBulk.siteAccessRequestFilter?.requestCategoryIds ? filterBulk.siteAccessRequestFilter?.requestCategoryIds : [] :
                                                []}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Select Request Category"
                                        mode="multiple"
                                        bordered={false}
                                        showSearch
                                        className='borderedSelect'
                                    >
                                        {
                                            requestCategory?.map((item, index) => {
                                                return (
                                                    <Option value={item?.id} className='textStyle-small'>
                                                        {item?.name}
                                                    </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormItem>
                            </div>

                            <div style={{ marginTop: '20px' }}>
                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", marginTop: '50px', marginLeft: '5px' }}>
                                    Date
                                </span>
                                <Divider type="horizontal" style={{ marginTop: '2px', marginBottom: '10px', height: 'auto' }} />
                                <FormItem
                                    name="date"
                                    initialValue={
                                        approvalType === 'SINGLE' ? filterSingle?.timeFrame ? filterSingle.timeFrame : null :
                                            approvalType === 'BULK' && showBulkInfo !== true ? filterBulk.timeFrame ? filterBulk.timeFrame : null :
                                                approvalType === 'BULK' && showBulkInfo === true ? filterBulk.siteAccessRequestFilter?.timeFrame ? filterBulk.siteAccessRequestFilter?.timeFrame : null :
                                                    null}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        bordered={false}
                                        className='borderedSelect'
                                        placeholder="Select Step"
                                        allowClear
                                        onChange={(e) => setDateType(e)}
                                    >
                                        <Option value="TODAY" className='textStyle-small'>Today</Option>
                                        <Option value="YESTERDAY" className='textStyle-small'>Yesterday</Option>
                                        <Option value="LAST_7_DAYS" className='textStyle-small'>Last 7 Days</Option>
                                        <Option value="LAST_30_DAYS" className='textStyle-small'>Last 30 Days</Option>
                                        <Option value="CUSTOM" className='textStyle-small'>Custom</Option>
                                    </Select>
                                </FormItem>
                            </div>
                            {
                                dateType === 'CUSTOM' ? (
                                    <div style={{ marginTop: '20px' }}>
                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", marginTop: '50px', marginLeft: '5px' }}>
                                            Date Range
                                        </span>
                                        <Divider type="horizontal" style={{ marginTop: '2px', marginBottom: '10px', height: 'auto' }} />
                                        <FormItem
                                            name="customDate"
                                            rules={[{ required: true, message: 'Please select date range' }]}
                                            initialValue={
                                                approvalType === 'SINGLE' ? filterSingle.startDate && filterSingle.endDate ? [moment(filterSingle.startDate), moment(filterSingle.endDate)] : [] :
                                                    approvalType === 'BULK' && showBulkInfo !== true ? filterBulk.startDate && filterBulk.endDate ? [moment(filterBulk.startDate), moment(filterBulk.endDate)] : [] :
                                                        approvalType === 'BULK' && showBulkInfo === true ? filterBulk.siteAccessRequestFilter?.startDate && filterBulk.siteAccessRequestFilter?.endDate ? [moment(filterBulk.siteAccessRequestFilter?.startDate), moment(filterBulk.siteAccessRequestFilter?.endDate)] : [] :
                                                            []
                                            }
                                        >
                                            <DatePicker.RangePicker
                                                style={{ width: '100%', height: '33px' }}
                                                className='borderedSelect'
                                            />
                                        </FormItem>
                                    </div>
                                ) : null
                            }
                        </div>
                        <div>
                            <Button type="primary" htmlType="submit" style={{ width: '100px', marginTop: '10px', borderRadius: '5px' }}>
                                <span className='textStyle-small' style={{ color: 'white' }}>Apply</span>
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal>

            <Modal
                visible={stillDownloading}
                width={500}
                footer={null}
                destroyOnClose={true}
                closable={false}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <SyncOutlined spin style={{ fontSize: '80px', color: 'blue' }} />
                    <h3 className='textStyle-small' style={{ marginTop: '15px', fontSize: '14px' }}>
                        Your file is being prepared for download. Please wait...
                    </h3>
                </div>
            </Modal>

            <Modal
                title={<span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "15px" }}>Request Edit History</span>}
                visible={editHistoryView}
                onOk={closeEditHistory}
                onCancel={closeEditHistory}
                width={850}
                height={410}
                footer={null}
                destroyOnClose={true}
            >
                <div style={{
                    borderColor: '#FAFAFA', backgroundColor: 'white', width: '100%', height: '410px',
                    marginTop: '10px', border: '1px solid #FAFAFA',
                }}>
                    <EditHistory key={editHistory} siteAccessRequestID={editHistory} />
                </div>
            </Modal>

            <Modal
                title={<span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "15px" }}>Site Key Location</span>}
                visible={showLocationDetails}
                onCancel={locationViewModalClose}
                width={600}
                height={600}
                footer={null}
                destroyOnClose={true}>
                <div style={{ width: '100%', height: '80%', marginTop: '10px', border: '1px solid #FAFAFA', borderRadius: '5px' }}>
                    <LoadScript googleMapsApiKey="AIzaSyAYye4nok_qEPk0bHJgzEEhwzXaRxlkM0Y">
                        <GoogleMap
                            mapContainerStyle={mapStyles}
                            zoom={10}
                            center={startMapCenter}
                            options={mapOptions}
                        >
                            <Marker
                                position={startMapCenter}
                            />
                        </GoogleMap>
                    </LoadScript>
                    <br />
                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px", marginLeft: '5px' }}>
                        <AimOutlined /> Key Location : {reqFormData?.keyLocation ? reqFormData?.keyLocation : 'Not Set Yet'}
                    </span>
                </div>
            </Modal>
        </body>
    )
}
export default AccessManage;