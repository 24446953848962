import React, { Component } from "react";
import { connect } from "react-redux";
import { getSiteListByPage, filterSiteData } from "../../api/Repos/SiteRepo";
import { Link } from "react-router-dom";
// import Select from 'react-select'
import { Collapse } from "reactstrap";
import { DatePicker, Table, List, Skeleton, Select, Tag, Row, Col, Input, Button } from "antd";
import { getDonationCategories, getData } from "../../actions/index";
import {
  getAllDepotList,
  getSitesLiteForSiteManage,
  getAllSiteCategories,
} from "../../api/index";
import { checkServiceType } from "../../actions/service-type";
import { SearchOutlined, EnvironmentOutlined, PlusOutlined, FilterOutlined, CloseCircleOutlined } from '@ant-design/icons';

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const { Option } = Select;
class Site extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNo: 1,
      editVisibilty: false,
      site_list: [],
      site_name: "",
      site_id: "",
      depotList: [],
      depot_list_new: [],
      isOpen: false,
      loading: true,
      depotId: "",
      setLogin: false,
      AssetManagementPermission: false,
      filterButton: true,
      setSearchValue: "",
      filteredTable: null,
      setValues: null,
      depot_list: [],
      sitwCategory: [],
      filterRegion: null,
      filterDepot: null,
      filterCategory: null,
      fullAllSite: [],
    };
  }

  componentDidMount() {
    this.props.getData();
    this.getAllDepotList();
    console.log("checkServiceType");
    console.log(this.props);
    this.setState({
      depot_list: this.props.depots,
    });

    checkServiceType(38).then((data) =>
      this.setState(
        {
          AssetManagementPermission: data,
        },
        () => {
          console.log(this.state.AssetManagementPermission);
        }
      )
    );

    if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
      this.setState({
        setLogin: true,
      });
    }
    // if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
    // 	//window.location.reload(false);

    // } else {
    // 	this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 13)
    // }
    //	this.getAllSites()
    this.getSitesLiteForSiteManage();
    this.getAllSiteCategories();
  }
  getAllSiteCategories = async () => {
    var res = await getAllSiteCategories();
    console.log("getAllSiteCategories");
    console.log(res);
    if (res.success) {
      this.setState({
        sitwCategory: res.data,
      });
    }
  };

  localStorageSaverFunction = (data) => {
    const numberOfDaysCacheAvailable = 5;
    const currentTime = new Date().getTime();
    const cacheExpiryTime = localStorage.getItem("cacheExpiryTime");
    if (!localStorage.getItem("siteInfo") || currentTime > cacheExpiryTime) {
        localStorage.setItem("siteInfo", JSON.stringify(data));
        localStorage.setItem("cachingTime", currentTime);
        const expiryTime = currentTime + numberOfDaysCacheAvailable * 24 * 60 * 60 * 1000; 
        localStorage.setItem("cacheExpiryTime", expiryTime);
    }
};

checkLocalStorageCache = () => {
  const currentTime = new Date().getTime();
  const cacheExpiryTime = localStorage.getItem("cacheExpiryTime");
  const siteInfo = JSON.parse(localStorage.getItem("siteInfo"));
  if (siteInfo && Object.keys(siteInfo).length > 0 && currentTime <= cacheExpiryTime) {
      return true;
  }
  return false;
};

  // getSitesLiteForSiteManage = async () => {
  //   this.setState({
  //     loading: true,
  //     site_list: [],
  //   });
  //   var res = await getSitesLiteForSiteManage();
  //   console.log("getSitesLiteForSiteManage");
  //   console.log(res);
  //   if (res.success) {
  //     this.localStorageSaverFunction(res.data);
  //     if (this.state.setValues == null) {
  //       this.setState({
  //         site_list: res.data,
  //         fullAllSite: res.data,
  //         loading: false,
  //       });
  //     } else {
  //       this.setState(
  //         {
  //           site_list: res.data,
  //           fullAllSite: res.data,
  //           loading: false,
  //         },
  //         () => {
  //           const filtered = this.state.site_list.filter(
  //             (user) =>
  //               user?.site_name
  //                 ?.toLowerCase()
  //                 .includes(this.state.setValues.toLowerCase()) ||
  //               user?.site_id
  //                 ?.toLowerCase()
  //                 .includes(this.state.setValues.toLowerCase())
  //           );
  //           this.setState({
  //             filteredTable: filtered,
  //           });
  //         }
  //       );
  //     }
  //   }
  // };

  getSitesLiteForSiteManage = async () => {
    this.setState({
        loading: true,
        site_list: [],
    });

    // Check if cache is available
    if (this.checkLocalStorageCache()) {
        // Use cached data
        var res = {
            success: true,
            data: JSON.parse(localStorage.getItem("siteInfo"))
        };
    } else {
        // Fetch data and save to cache
        var res = await getSitesLiteForSiteManage();
        console.log("getSitesLiteForSiteManage");
        console.log(res);
        if (res.success) {
            this.localStorageSaverFunction(res.data);
        }
    }

    if (res.success) {
        if (this.state.setValues == null) {
            this.setState({
                site_list: res.data,
                fullAllSite: res.data,
                loading: false,
            });
        } else {
            this.setState(
                {
                    site_list: res.data,
                    fullAllSite: res.data,
                    loading: false,
                },
                () => {
                    const filtered = this.state.site_list.filter(
                        (user) =>
                            user?.site_name
                                ?.toLowerCase()
                                .includes(this.state.setValues.toLowerCase()) ||
                            user?.site_id
                                ?.toLowerCase()
                                .includes(this.state.setValues.toLowerCase())
                    );
                    this.setState({
                        filteredTable: filtered,
                    });
                }
            );
        }
    }
};


  getSetPageData = (serviceTypeIs, Pageid) => {
    //console.log("this.props");
    //console.log(serviceTypeIs.serviceType);
    if (
      serviceTypeIs.serviceType.filter(
        (checkuser) => checkuser?.id == Pageid
      )[0] == null
    ) {
      this.setState({
        editVisibilty: false,
      });
    } else {
      this.setState({
        editVisibilty: true,
      });
    }
  };
  getAllDepotList = async () => {
    var res = await getAllDepotList();
    this.setState({
      loadingDepot: true,
      depot_list_new: [],
    });
    console.log(res);
    if (res.success) {
      this.setState(
        {
          depot_list_new: res.data,
          loadingDepot: false,
        },
        () => {
          var _depotList = [];

          this.state.depot_list_new.map((depot) => {
            _depotList.push({ value: depot.id, label: depot.name });
          });

          this.setState({
            depotList: _depotList,
          });
        }
      );
    }
  };

  getAllSites = async () => {
    var res = await getSiteListByPage(this.state.pageNo);
    this.setState({
      loading: true,
      site_list: [],
      fullAllSite: [],
    });
    if (res.success) {
      this.setState({
        site_list: res.data,
        loading: false,
        fullAllSite: res.data,
      });
    }
  };

  previousBtnClick = () => {
    if (this.state.pageNo > 1) {
      this.setState({
        loading: true,
        site_list: [],
      });
      var aa = this.state.pageNo - 1;
      this.setState(
        {
          pageNo: aa,
        },
        () => {
          this.getAllSites();
        }
      );
    }
  };

  nextBtnClick = () => {
    this.setState({
      loading: true,
      site_list: [],
    });
    var aa = this.state.pageNo + 1;
    this.setState(
      {
        pageNo: aa,
      },
      () => {
        this.getAllSites();
      }
    );
  };

  searchFormItemChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    //console.log(obj)
    this.setState(obj, () => {
      if (
        this.state.site_id != "" ||
        this.state.site_name != "" ||
        this.state.depotId != ""
      ) {
        this.setState({
          filterButton: true,
        });
      } else {
        this.setState({
          filterButton: false,
        });
      }
    });
  };

  searchDepottemChange = (event) => {
    this.setState(
      {
        depotId: event.value,
      },
      () => {
        if (
          this.state.site_id != "" ||
          this.state.site_name != "" ||
          this.state.depotId != ""
        ) {
          this.setState({
            filterButton: true,
          });
        } else {
          this.setState({
            filterButton: false,
          });
        }
      }
    );
  };

  viewFilterClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  filterBtnClick = async () => {
    this.setState({
      loading: true,
    });
    console.log(this.state.filterRegion);
    console.log(this.state.filterDepot);
    console.log(this.state.filterCategory);
    console.log(this.state.fullAllSite);
    let filtered = [];
    //const filtered = this.state.site_list.filter(site=>( site.patent_region_id==this.state.filterRegion && site.depot_id==this.state.filterDepot ));
    if (this.state.filterCategory != null) {
      filtered = this.state.fullAllSite
        .filter((site) =>
          this.state.filterDepot != null
            ? site.depot_id == this.state.filterDepot
            : site && this.state.filterRegion != null
            ? site.patent_region_id == this.state.filterRegion
            : site
        )
        .filter(
          (category) => category.site_category_id == this.state.filterCategory
        );
    } else {
      filtered = this.state.fullAllSite.filter((site) =>
        this.state.filterDepot != null
          ? site.depot_id == this.state.filterDepot
          : site && this.state.filterRegion != null
          ? site.patent_region_id == this.state.filterRegion
          : site
      );
    }
    console.log(filtered);
    this.setState({
      site_list: filtered,
      loading: false,
    });
  };

  clearBtnClick = () => {
    window.location.reload();
  };
  search = (value) => {
    this.setState({
      setValues: value,
    });

    const filtered = this.state.site_list.filter(
      (user) =>
        user?.site_name?.toLowerCase().includes(value.toLowerCase()) ||
        user?.site_id?.toLowerCase().includes(value.toLowerCase())
    );

    this.setState({
      filteredTable: filtered,
    });
  };
  onChangeRegion = (value) => {
    console.log(value);

    var dd = value;
    //console.log(dd)
    var subRegion = [];
    var _depotList1 = [];
    var _allSite = [];
    var subRegionIdArray = [];

    this.props.regions
      .filter((allret) => allret?.parentRegion?.id == dd)
      .map((subR) => {
        subRegionIdArray.push(subR.id);
      });

    this.setState(
      {
        selectRegion: subRegionIdArray,
        filterDepot: null,
        filterRegion: value == "undefined" ? null : value,
      },
      () => {
        if (
          this.state.filterRegion != null ||
          this.state.filterDepot != null ||
          this.state.filterCategory != null
        ) {
          this.setState({
            filterButton: true,
          });
        } else {
          this.setState({
            filterButton: true,
          });
        }
      }
    );

    //console.log(this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)));
    subRegion = this.props.regions.filter(
      (allret) => allret?.parentRegion?.id == dd
    );
    //console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
    this.props.depots
      .filter((alldate) =>
        subRegion.map((n) => n.id).includes(alldate.region?.id)
      )
      .map((depot) => {
        _depotList1.push({ id: depot?.id, name: depot?.name });
      });
    console.log("selectDepot");
    console.log(this.state.selectDepot);

    this.setState({
      depot_list: _depotList1,
    });
  };
  onChangeCategory = (value) => {
    this.setState(
      {
        // DepotName: dataa[value - 1]?.name,
        filterCategory: value == "undefined" ? null : value,
        selectType: 2,
        selectId: value,
        selectDepot: value,
      },
      () => {
        if (
          this.state.filterRegion != null ||
          this.state.filterDepot != null ||
          this.state.filterCategory != null
        ) {
          this.setState({
            filterButton: true,
          });
        } else {
          this.setState({
            filterButton: true,
          });
        }
      }
    );
  };
  onChangeDepot = (value) => {
    //console.log("onChangeDepot");
    console.log(value);

    this.setState(
      {
        // DepotName: dataa[value - 1]?.name,
        filterDepot: value == "undefined" ? null : value,
        selectType: 2,
        selectId: value,
        selectDepot: value,
      },
      () => {
        if (
          this.state.filterRegion != null ||
          this.state.filterDepot != null ||
          this.state.filterCategory != null
        ) {
          this.setState({
            filterButton: true,
          });
        } else {
          this.setState({
            filterButton: true,
          });
        }
      }
    );
  };

  render() {
    const columns123 = [
      {
        title: "# Site Id",
        // dataIndex: "siteId",
        //	defaultSortOrder: "descend",

        render: (text, record, index) => (
          <span>
            {" "}
            <u>
              <Link to={"/site-summary/" + text.id}>{text?.site_id}</Link>
            </u>
          </span>
        ),
      },
      {
        title: "Site",

        key: "siteName",

        render: (item) => (
          <span>
            <u>
              <Link to={"/site-summary/" + item.id}>{item?.site_name}</Link>
            </u>
          </span>
        ),
      },
      {
        title: "Region",
        //   dataIndex: "accessPermissionCode",
        key: "accessPermissionCode",
        render: (item) => <span>{item.parent_region_name}</span>,
      },
      {
        title: "Depot",
        //   dataIndex: "accessPermissionCode",
        key: "accessPermissionCode",
        render: (item) => <span>{item?.depot_name}</span>,
      },

      {
        title: "Status",
        dataIndex: "status",
        key: "operationTypeName",
        render: (item) => (
          <>
            <Tag color="#03d87f" key={item}>
              <span>{item}</span>
            </Tag>
          </>
        ),
      },
      {
        title: "Site Category",
        //dataIndex: "siteCategory",
        align: "center",
        key: "siteCategory",
        render: (item) => (
          <span class="badge badge-outline-primary badge-pill">
            {item?.site_category_name}
          </span>
        ),
        //render: (item) => <span>{item}</span>,
      },
      {
        title: "Tower Height(m/ft)",
        dataIndex: "tower_height",
        align: "right",
        key: "towerHeight",
        render: (item) => <span>{item}</span>,
      },

      {
        title: "Depot Officer ",
        key: "DepotOfficer ",

        render: (item) => <span>{item?.contact_person}</span>,
      },
      {
        title: "Contact No",
        key: "contact_no",
        align: "right",
        width: "9%",

        render: (item) => (
          <span>
            {item?.contact_no?.slice(0, -7) +
              " " +
              item?.contact_no?.slice(2, -4) +
              " " +
              item?.contact_no?.slice(-4)}
          </span>
        ),
      },
      this.state.AssetManagementPermission
        ? {
            title: "Action2",
            key: "ContactNo",
            align: "center",
            hidden: true,
            render: (item) => (
              <span>
                <Link to={"/sites/edit/" + item?.id} key="list-loadmore-edit">
                  <i class="fa fa-pen"></i>
                </Link>
              </span>
            ),
          }
        : {},

      // {
      // 	title: 'Action',

      // 	key: 'x',
      // 	render: (item) => <a href='#' onClick={() => this.getSiteAccessDetails(item.id)}><i data-feather="home" class="fa fa-eye"></i></a>,
      //   },
    ];
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <div className="row">
                  <div class="col">
                    <h4 class="page-title">Sites</h4>
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Admin</a>
                      </li>
                      <li class="breadcrumb-item">
                        <a href="javascript:void(0);">Manage Sites </a>
                      </li>
                    </ol>
                  </div>

                  {/* <div style={{ display: "flex" ,backgrounColor: "red", justifyContent: "end", borderRadius: 10 , backgroundColor: "#f5f5f5", padding: 10 }}>
                    <Row gutter={16} align="middle">
                      <Col span={9}>
                        <Input
                          style={{borderRadius: 50}}
                          placeholder="Search"
                          suffix={<SearchOutlined />}
                          value={this.state.setSearchValue}
                          onChange={(e) => {
                            const currValue = e.target.value;
                            this.search(currValue);
                            this.setState({
                              setSearchValue: currValue,
                            });
                          }}
                        />
                      </Col>
                      <Col>
                        <Button type="danger" style={{borderRadius: 50}} 
                        icon={<CloseCircleOutlined />} 
                        onClick={(e) => {
                          this.setState(
                            {
                              filteredTable: null,
                            },
                            () => {
                              this.setState({
                                setSearchValue: "",
                              });
                            }
                          );
                        }}
                        />
                      </Col>
                      <Col>
                        <Button type="primary" style={{borderRadius: 50}} icon={<FilterOutlined />}>
                        </Button>
                      </Col>
                      <Col>
                        <Button type="primary" style={{borderRadius: 50}} icon={<EnvironmentOutlined />}>
                          Map
                        </Button>
                      </Col>
                      <Col>
                        <Button type="primary" style={{borderRadius: 50}} icon={<PlusOutlined />}>
                          Create Site
                        </Button>
                      </Col>
                    </Row>
                  </div> */}

                  <div
                    className="col-md-2"
                    style={{
                      display: "inline",
                      justifyContent: "end",
                      textAlign: "end",
                    }}
                  >
                    <Link
                      to="/map"
                      type="button"
                      style={{ marginLeft: 10 }}
                      class="btn btn-danger"
                    >
                      Map
                    </Link>
                    {this.state.AssetManagementPermission ? (
                      <Link
                        to="/sites/create"
                        type="button"
                        style={{ marginLeft: 10 }}
                        class="btn btn-info"
                      >
                        Create Site
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-1">
                    <button
                      type="button"
                      style={{ marginLeft: 10 }}
                      class="btn btn-danger"
                      onClick={() => this.viewFilterClick()}
                    >
                      <i class="fas fa-filter"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Collapse isOpen={this.state.isOpen}>
            <div className="row">
              <div class="card">
                <div class="card-body bootstrap-select-1">
                  <div class="row">
                    <div className="col-md-4">
                      <label class="mb-3">Region</label>
                      <Select
                        showSearch
                        onChange={this.onChangeRegion}
                        name="regionId"
                        value={this.state.filterRegion}
                        style={{ width: "100%" }}
                        placeholder="Select Regions"
                        optionFilterProp="children"
                        allowClear
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {this.props.regions
                          .filter((allret) => "0".includes(allret.isSubRegion))
                          .map((item, index) => {
                            return (
                              <Option value={item?.id}>{item?.name}</Option>
                            );
                          })}
                      </Select>
                    </div>
                    <div class="col-md-4">
                      <label class="mb-3">Depot</label>
                      {/* <Select options={this.state.depotList} allowClear name="depotId" onChange={(e) => this.searchDepottemChange(e)} /> */}
                      <Select
                        showSearch
                        onChange={this.onChangeDepot}
                        value={this.state.filterDepot}
                        name="depotsId"
                        style={{ width: "100%" }}
                        placeholder="Select Depot"
                        optionFilterProp="children"
                        allowClear
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {this.state.depot_list.map((item, index) => {
                          return <Option value={item?.id}>{item?.name}</Option>;
                        })}
                      </Select>
                    </div>
                    <div class="col-md-4">
                      <label class="mb-3">Site Category</label>
                      <Select
                        showSearch
                        onChange={this.onChangeCategory}
                        value={this.state.filterCategory}
                        name="depotsId"
                        style={{ width: "100%" }}
                        placeholder="Select Depot"
                        optionFilterProp="children"
                        allowClear
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {this.state.sitwCategory.map((item, index) => {
                          return <Option value={item?.id}>{item?.name}</Option>;
                        })}
                      </Select>
                    </div>
                    <div class="col-md-4" style={{ marginTop: 10 }}>
                      {this.state.filterButton ? (
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={() => this.filterBtnClick()}
                        >
                          {" "}
                          Apply
                        </button>
                      ) : (
                        <button
                          disabled
                          type="button"
                          class="btn btn-primary"
                          onClick={() => this.filterBtnClick()}
                        >
                          {" "}
                          Apply
                        </button>
                      )}

                      <button
                        type="button"
                        style={{ marginLeft: 5 }}
                        class="btn btn-dark"
                        onClick={() => this.clearBtnClick()}
                      >
                        Clear Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
          <div className="row">
            {/* <div className="col-lg-6">
					<ul className="list-inline">
						<li className="list-inline-item">
							<h5 className="mt-0">Here the list of all continu projects. <span className="badge badge-pink">6</span></h5>
						</li>
					</ul>
				</div>  */}

            <div className="col-lg-6 text-end">
              <div className="text-end">
                {/* <ul className="list-inline">
							<li className="list-inline-item">
								<div className="input-group">                               
									<input autoComplete="off"  type="text" id="example-input1-group2" name="example-input1-group2" className="form-control form-control-sm" placeholder="Search" />
									<button type="button" className="btn btn-soft-primary btn-sm"><i className="fas fa-search"></i></button>
								</div>
							</li>
							<li className="list-inline-item">
								<button type="button" className="btn btn-soft-primary btn-sm"><i className="fas fa-filter"></i></button>
							</li> 
						</ul> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="row">
              <div className="col-md-10">
                <Input
                  id="clearSearch"
                  enterButton
                  style={{ marginBottom: 5 }}
                  placeholder="Search by Site name or Site ID"
                  value={this.state.setSearchValue}
                  onChange={(e) => {
                    const currValue = e.target.value;
                    this.search(currValue);
                    this.setState({
                      setSearchValue: currValue,
                    });
                  }}
                />
              </div>
              <div className="col-md-2" style={{ textAlign: "end" }}>
                <button
                  type="button"
                  class="btn btn-primary "
                  onClick={(e) => {
                    this.setState(
                      {
                        filteredTable: null,
                      },
                      () => {
                        this.setState({
                          setSearchValue: "",
                        });
                      }
                    );
                  }}
                >
                  Clear Search
                </button>
              </div>
            </div>

            <Table
              size="small"
              fontSize={9}
              columns={columns123}
              bordered
              loading={this.state.loading}
              //dataSource={this.state.site_list}
              dataSource={
                this.state.filteredTable == null
                  ? [...this.state.site_list].sort((a, b) => b.id - a.id)
                  : [...this.state.filteredTable].sort((a, b) => b.id - a.id)
              }
            />
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
  Site
);

// export default connect(
// 	mapStateToProps,
// 	{}
// )(Site);
