import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { createNewVendor, getAllVendors,updateVendorByid, getAllDepots } from '../../api/index';
import { addVendor, removeVendor, updateVendor, getData, getDonationCategories } from '../../actions/index';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'material-react-toastify';
import { Table, Input, Modal, Drawer, message, Select, Space, Button } from 'antd';
import 'material-react-toastify/dist/ReactToastify.css';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import depotList from '../depot/depot-list';
const options = [];

class Vendor extends Component {
	constructor(props) {
		super(props);
		this.validator = new SimpleReactValidator();
		this.state = {
			name: '',
			code: '',
			vatNumber: '',
			contactNumber: '',
			contactPerson: '',
			contactEmail: '',
			contactAddress: '',
			depotListId: [],
			filterTable: null,
			vendors: [],
			depotList: [],
			editDrawerVisible: false,
			editVendorDetails: {
				id: null,
				name: '',
				code: '',
				vatNumber: '',
				contactNumber: '',
				contactPerson: '',
				contactEmail: '',
				contactAddress: '',
				depotListId: [],
			},
		};
	}

	componentDidMount() {
		this.props.getData();
		this.getAllVendors();
		this.getAllDepotes();
	}

	getAllVendors = async () => {
		var res = await getAllVendors();
		this.setState({
			vendors: res.data,
		});
	};

	getAllDepotes = async () => {
		var res = await getAllDepots();
		this.setState({
			depotList: res.data,
		});
	};

	sucessMessage = (messageInfo) => {
		message.success('System Message : ' + messageInfo);
	};

	errorMessage = (messageInfo) => {
		message.error('System Message : ' + messageInfo);
	};

	warningMessage = (messageInfo) => {
		message.warning('System Message : ' + messageInfo);
	};


	showCreateConfirmationModal = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Are you sure you want to register a new vendor?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.createVendor(),
		});
	};

	closePopup = () => {
		Modal.destroyAll();
	};

	showUpdateConfirmationModal = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Are you sure you want to update this vendor?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.updateVendor(),
		});
	};

	createVendor = async () => {
		// Navigate to the same page, effectively refreshing it
		//window.location.href = window.location.href;

		this.closePopup();
		if (this.validator.allValid()) {
			const isValidMobileNumber = /^[0-9]{10}$/.test(this.state.contactNumber);
			if (!isValidMobileNumber) {
				this.errorMessage('Please enter a valid 10-digit mobile number');
				return;
			}

			var data = {
				"name": this.state.name,
				"code": this.state.code,
				"vatNumber": this.state.vatNumber,
				"contactNumber": this.state.contactNumber,
				"contactPerson": this.state.contactPerson,
				"contactEmail": this.state.contactEmail,
				"contactAddress": this.state.contactAddress,
				"depot": this.state.depotListId.map((id) => {
					return {
						"id": id,
					};
				}),
			}
			var res = await createNewVendor(data);
			if (res.success) {
				this.sucessMessage('New Vendor Registered Successfully');
				this.props.addVendor(res.data.data);
				// Navigate to the same page, effectively refreshing it
				// window.location.href = window.location.href;
			}
			// Navigate to the same page, effectively refreshing it
			// window.location.href = window.location.href;
			//this.forceUpdate();
		} else {
			this.validator.showMessages();
			this.forceUpdate();
			this.errorMessage("Please fill all the required fields");
		}
	}

	clearForm = () => {
		this.setState({
			name: '',
			code: '',
			vatNumber: '',
			contactNumber: '',
			contactPerson: '',
			contactEmail: '',
			contactAddress: '',
			depotListId: [],
		});
	};

	formItemChange = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	};

	search = (value) => {
		const filterTable = this.state.vendors.filter((o) =>
			Object.keys(o).some((k) =>
				String(o[k]).toLowerCase().includes(value.toLowerCase())
			)
		);
		this.setState({ filterTable });
	};

	showDeleteConfirm = (record) => {
		const { confirm } = Modal;
		confirm({
			title: 'Are you sure you want to delete this vendor?',
			onOk: async () => {
				/*var res = await deleteVendor(record.id);
		
				if (res.success) {
				  toast.success('Vendor deleted successfully');
				  this.props.removeVendor(record.id);
				}*/
			},
			onCancel() { },
		});
	};

	showEditDrawer = (record) => {
		this.setState({
			editDrawerVisible: true,
			name: record.name,
			code: record.code,
			vatNumber: record.vatNumber,
			contactNumber: record.contactNumber,
			contactPerson: record.contactPerson,
			contactEmail: record.contactEmail,
			contactAddress: record.contactAddress,
			depotListId: record.depot.map((depot) => depot.id),
			editVendorDetails: {
				id: record.id,
				name: record.name,
				code: record.code,
				vatNumber: record.vatNumber,
				contactNumber: record.contactNumber,
				contactPerson: record.contactPerson,
				contactEmail: record.contactEmail,
				contactAddress: record.contactAddress,
				// depot : record.depot.map((depot) => {
				// 	return {
				// 		"id": depot.id,
				// 	};
				// }),
			},
		});
	};

	closeEditDrawer = () => {
		this.setState({ editDrawerVisible: false,
			name: '',
			code: '',
			vatNumber: '',
			contactNumber: '',
			contactPerson: '',
			contactEmail: '',
			contactAddress: '',
			depotListId: [],
		});
	};

	editFormItemChange = (event, type, val) => {
		console.log("Event Log Captured : ", event.target.name);
		console.log("Event Log Captured : ", event.target.value);
		console.log(event);

		if (type === 'depot') {
			this.setState((prevState) => ({
				editVendorDetails: {
					depot: val,
				},
			}));
		} else {
			const { name, value } = event.target;
			this.setState((prevState) => ({
				editVendorDetails: {
					...prevState.editVendorDetails,
					[name]: value,
				},
			}));
		}
		// const { name, value } = event.target;
		// this.setState((prevState) => ({
		// 	editVendorDetails: {
		// 		...prevState.editVendorDetails,
		// 		[name]: value,
		// 	},
		// }));

		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	};

	// editFormItemChange = (event, type, val) => {
	// 	if (type === 'depot') {
	// 		this.setState((prevState) => ({
	// 			editVendorDetails: {
	// 				...prevState.editVendorDetails,
	// 				depot: val.map(id => ({ id })),
	// 			},
	// 			depotListId: val,
	// 		}));
	// 	} else {
	// 		const { name, value } = event.target;
	// 		this.setState((prevState) => ({
	// 			editVendorDetails: {
	// 				...prevState.editVendorDetails,
	// 				[name]: value,
	// 			},
	// 		}));
	// 	}
	// };
	

	updateVendor = async () => {
		this.closePopup();
		if (this.validator.allValid()) {
			const isValidMobileNumber = /^[0-9]{10}$/.test(this.state.contactNumber);
			if (!isValidMobileNumber) {
				this.errorMessage('Please enter a valid 10-digit mobile number');
				return;
			}
			var res = await updateVendorByid(this.state.editVendorDetails, this.state.editVendorDetails.id);
			if (res.success) {
			  toast.success('Vendor updated successfully');
			  this.closeEditDrawer();
			  window.location.reload();
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	};

	render() {
		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				render: (text) => (
					<span>{text === ' ' ? <i>No name</i> : text}</span>
				),
			},
			{
				title: 'Code',
				dataIndex: 'code',
				key: 'code',
				render: (text) => <span>{text}</span>,
			},
			{
				title: "VAT Number",
				dataIndex: "vatNumber",
				key: "vatNumber",
				render: (text) => <span>{text}</span>,
			},
			{
				title: "Contact Number",
				dataIndex: "contactNumber",
				key: "contactNumber",
				render: (text) => <span>{text}</span>,
			},
			{
				title: "Contact Person",
				dataIndex: "contactPerson",
				key: "contactPerson",
				render: (text) => <span>{text}</span>,
			},
			{
				title: "Contact Email",
				dataIndex: "contactEmail",
				key: "contactEmail",
				render: (text) => <span>{text}</span>,
			},
			{
				title: "Contact Address",
				dataIndex: "contactAddress",
				key: "contactAddress",
				render: (text) => <span>{text}</span>,
			},
			{
				title: "Depot List",
				dataIndex: "depot",
				key: "depot",
				render: (text) => 
				<span>
					{text.map((depot) => (
						<span key={depot.id}>{depot.name} , </span>
					))}
				</span>,
			},
			{
				title: 'Action',
				key: 'action',
				render: (text, record, index) => (
					<span className="text-end">
						<a href="#" onClick={() => this.showEditDrawer(record)}>
							<i className="las la-pen text-secondary font-16"></i>
						</a>
						{/*<a href="#" onClick={() => this.showDeleteConfirm(record)}>
							<i className="las la-trash-alt text-secondary font-16"></i>
				</a> */}
					</span>
				),
			},
		];

		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12">
						<div className="page-title-box">
							<div className="row">
								<div className="col">
									<h4 className="page-title">Vendor</h4>
									<ol className="breadcrumb">
										<li className="breadcrumb-item">
											<a href="javascript:void(0);">Admin</a>
										</li>
										<li className="breadcrumb-item">
											<a href="javascript:void(0);">Vendor</a>
										</li>
									</ol>
								</div>
								<div className="col-auto align-self-center">
									<a href="#" className="btn btn-sm btn-outline-primary" id="Dash_Date">
										<span className="day-name" id="Day_Name">Today:</span>&nbsp;
										<span className="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-calendar align-self-center icon-xs ms-1">
											<rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
											<line x1="16" y1="2" x2="16" y2="6"></line>
											<line x1="8" y1="2" x2="8" y2="6"></line>
											<line x1="3" y1="10" x2="21" y2="10"></line>
										</svg>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-3">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">
									<b>Register New Vendor</b>
								</h4>
							</div>
							{/* <pre>
								{JSON.stringify(this.state?.depotList, null, 2)}
							</pre> */}
							<div className="card-body">
								<form>
									<div className="mb-3">
										<label className="form-label" for="name">Name</label>
										<input autoComplete="off" type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp" onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('name', this.state.name, 'required')}</p>
									</div>
									<div className="mb-3">
										<label className="form-label" for="description">Code</label>
										<input autoComplete="off" type="text" value={this.state.code} className="form-control" name="code" onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('code', this.state.code, 'required')}</p>
									</div>
									<div className="mb-3">
										<label className="form-label" for="vatNumber">VAT Number</label>
										<input autoComplete="off" type="text" value={this.state.vatNumber} className="form-control" name="vatNumber" onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('vatNumber', this.state.vatNumber, 'required')}</p>
									</div>
									<div className="mb-3">
										<label className="form-label" for="contactNumber">Contact Number</label>
										<input autoComplete="off" type="tel" pattern="[0-9]{10}" 
										value={this.state.contactNumber} 
										className="form-control" 
										name="contactNumber" 
										onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('contactNumber', this.state.contactNumber, 'required')}</p>
		                            </div>
									<div className="mb-3">
										<label className="form-label" for="contactPerson">Contact Person</label>
										<input autoComplete="off" type="text" value={this.state.contactPerson} className="form-control" name="contactPerson" onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('contactPerson', this.state.contactPerson, 'required ')}</p>
									</div>
									<div className="mb-3">
										<label className="form-label" for="contactEmail">Contact Email</label>
										<input autoComplete="off" type="text" value={this.state.contactEmail} className="form-control" name="contactEmail" onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('contactEmail', this.state.contactEmail, 'required|email')}</p>
									</div>
									<div className="mb-3">
										<label className="form-label" for="contactAddress">Contact Address</label>
										<input autoComplete="off" type="text" value={this.state.contactAddress} className="form-control" name="contactAddress" onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('contactAddress', this.state.contactAddress, 'required')}</p>
									</div>
									<div className="mb-3">
										<label className="form-label" for="depot">Depot</label>
										<Select
											mode="multiple"
											style={{
												width: '100%',
											}}
											placeholder="Please select"
											name="depotListId"
											defaultValue={[]}
											onChange={(value) => {
												console.log(`selected ${value}`);
												this.setState((prevState) => ({
													depotListId: [...prevState.depotListId, ...value],
												}));
											}}
										>
											{this.state.depotList.map((depot) => (
												<Select.Option key={depot.id} value={depot.id}>
													{depot.name}
												</Select.Option>
											))}
										</Select>

									</div>
									<p style={{ color: "#f5325c" }}>
										{this.validator.message('depot', this.state.depotListId, 'required')}
									</p>
								</form>

								<button className="btn btn-primary"
									onClick={() => this.showCreateConfirmationModal()}
								>Create New Vendor
								</button>

								<button className="btn btn-danger"
									style={{ marginLeft: 10 }}
									onClick={this.clearForm}>
									Form Clear
								</button>

							</div>
						</div>
					</div>

					<div className="col-lg-9">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">
									<b>Vendor List</b>
									<Button
										type="primary"
										style={{ float: 'right', borderRadius: 10 }}
										onClick={() => window.location.reload()}
									> Reload Vendor List </Button>
								</h4>
							</div>
							<div className="card-body">
								<div className="table-responsive">
									<Input
										enterButton
										style={{ width: '98%', margin: 10 }}
										placeholder="Search Vendors ..."
										onChange={(e) => {
											const currValue = e.target.value;
											this.search(currValue);
										}}
									/>
									<Table
										size="small"
										columns={columns}
										dataSource={
											this.state.filterTable == null
												? [...this.state.vendors].sort((a, b) => b.id - a.id)
												: [...this.state.filterTable].sort((a, b) => b.id - a.id)
										}
										rowKey="id"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Drawer
					title="Edit Vendor Details"
					width={400}
					onClose={this.closeEditDrawer}
					visible={this.state.editDrawerVisible}
				>	
						<div className="mb-3">
							<label className="form-label" htmlFor="editName">
								Edit Name
							</label>
							
							<input
						     	 autoComplete="off" type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp"
								onChange={this.editFormItemChange}
							/>
							<p style={{ color: '#f5325c' }}>
								{this.validator.message(
									'editName',
									this.state.name,
									'required'
								)}
							</p>
						</div>

						<div className="mb-3">
							<label className="form-label" htmlFor="editCode">
								Edit Code
							</label>
							<input
								autoComplete="off" type="text" value={this.state.code} className="form-control" name="code"
								onChange={this.editFormItemChange}
							/>
							<p style={{ color: '#f5325c' }}>
								{this.validator.message(
									'editCode',
									this.state.code,
									'required'
								)}
							</p>
						</div>

						<div className="mb-3">
							<label className="form-label" htmlFor="editVatNumber">
								Edit VAT Number
							</label>
							<input
								autoComplete="off" type="text" value={this.state.vatNumber} className="form-control" name="vatNumber"
								onChange={this.editFormItemChange}
							/>
							<p style={{ color: '#f5325c' }}>
								{this.validator.message(
									'editVatNumber',
									this.state.vatNumber,
									'required'
								)}
							</p>
						</div>

						<div className="mb-3">
							<label className="form-label" htmlFor="editContactNumber">
								Edit Contact Number
							</label>
							<input
								autoComplete="off" type="text" value={this.state.contactNumber} className="form-control" name="contactNumber"
								onChange={this.editFormItemChange}
							/>
							<p style={{ color: '#f5325c' }}>
								{this.validator.message(
									'editContactNumber',
									this.state.contactNumber,
									'required'
								)}
							</p>
						</div>

						<div className="mb-3">
							<label className="form-label" htmlFor="editContactPerson">
								Edit Contact Person
							</label>
							<input
								autoComplete="off" type="text" value={this.state.contactPerson} className="form-control" name="contactPerson"
								onChange={this.editFormItemChange}
							/>
							<p style={{ color: '#f5325c' }}>
								{this.validator.message(
									'editContactPerson',
									this.state.contactPerson,
									'required'
								)}
							</p>
						</div>

						<div className="mb-3">
							<label className="form-label" htmlFor="editContactEmail">
								Edit Contact Email
							</label>
							<input
								autoComplete="off" type="text" value={this.state.contactEmail} className="form-control" name="contactEmail"
								onChange={this.editFormItemChange}
							/>
							<p style={{ color: '#f5325c' }}>
								{this.validator.message(
									'editContactEmail',
									this.state.contactEmail,
									'required|email'
								)}
							</p>
						</div>

						<div className="mb-3">
							<label className="form-label" htmlFor="editContactAddress">
								Edit Contact Address
							</label>
							<input
								autoComplete="off" type="text" value={this.state.contactAddress} className="form-control" name="contactAddress"
								onChange={this.editFormItemChange}
							/>
							<p style={{ color: '#f5325c' }}>
								{this.validator.message(
									'editContactAddress',
									this.state.contactAddress,
									'required'
								)}
							</p>
						</div>
						<div className="mb-3">
							<label className="form-label" htmlFor="editDepot">Edit Depot</label>
							<Select
								mode="multiple"
								style={{
									width: '100%',
								}}
								placeholder="Please select"
								name="depotListId"
								onChange={(value) => {
									this.editFormItemChange(value, 'depot', value);
								}}
								value={this.state?.depotListId}
							>
								{this.state.depotList.map((depot) => (
									<Select.Option key={depot.id} value={depot.id}>
										{depot.name}
									</Select.Option>
								))}
							</Select>
						</div>
						<button
							className="btn btn-success"
							onClick={this.showUpdateConfirmationModal}
						>
							Update Vendor
						</button>
				</Drawer>

				<ToastContainer position="top-right" autoClose={5000} />
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		vendors: state.vendor,
	};
}

/*function mapDispatchToProps(dispatch) {
	return {
		addVendor: (data) => dispatch(addVendor(data)),
		//removeVendor: (id) => dispatch(removeVendor(id)),
		//updateVendor: (data) => dispatch(updateVendor(data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendor);
*/

export default connect(mapStateToProps, { getDonationCategories, getData })(
	Vendor
  );