import React, { Component } from 'react';
import logo from "./logo.png";
import { getUserRolesAndPermission2, getUserServiceType } from "../../api/index";
import { withRouter } from 'react-router-dom'
import { Icon } from '@iconify/react';
import { Helmet } from "react-helmet";
import './custom.css';
import {PieChartOutlined, 
  DashboardOutlined, UserOutlined, ExportOutlined, SettingOutlined, 
  FileTextOutlined, ScheduleOutlined, GlobalOutlined, PoweroffOutlined, 
  PictureOutlined, FileImageOutlined, KeyOutlined, ApartmentOutlined, ClusterOutlined,
   AppstoreOutlined, SyncOutlined, DatabaseOutlined, ExperimentOutlined, SignatureOutlined, CarOutlined, SafetyCertificateOutlined} from '@ant-design/icons';

class SideBar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      sideBarD1: false,
      sideBarPg: false,
      sideBarrefuel: false,
      sideBarHeadVehicle: false,
      sideBarHeadSiteAccess: false,
      sideBarRoutingDashboar: false,
      sidepisDashboar: false,
      sideBarWorkSpace: false,
      sideBarAppSetting: false,
      sideBarOrga: false,
      sideBarSiteData: false,
      sideBarAdmin: false,
      sideBarMasterData: false,
      sideBarUser: false,
      sideBarDataEx: false,
      sideBarDataExPg: false,
      sideBarDataExRefuel: false,
      sideBarDataExVeh: false,
      sideBarDataExSiteAc: false,
      sideBarDataExPowerDb: false,
      sideBarDataExImageCaptue: false,
      sideBarDataExSiteRoutin: false,
      sideBarDataExPISAC: false,
      sideBarReport: false,
      sideBarPgUpdate: false,
      sideBarSiteAC: false,
      sideBarPisCheclist: false,
      sideBarImageCap: false,
      sideBarVehicle: false,
      dataExSitePisSB: false,
      selectUrl: '',
      dashboardSB: false,
      workSpaceSB: false,
      appSettingSB: false,
      pGSB: false,
      refuelSB: false,
      vehicleHeadrSB: false,
      siteAccessHeadrSB: false,
      routingDashboardSB: false,
      pisDashboardSB: false,
      orgaSB: false,
      siteDataSB: false,
      adminSB: false,
      masterDataSB: false,
      userSB: false,
      dataExSB: false,
      dataExPGSB: false,
      dataExRefuleSB: false,
      dataExVheSB: false,
      dataExSiteAcSB: false,
      dataExPowerDbSB: false,
      dataExImageCapturSB: false,
      dataExSiteRoutingSB: false,
      dataExSitePIS: false,
      reportSB: false,
      powerDbSB: false,
      pgUpdateSB: false,
      siteAcSB: false,
      pisCheckListSB: false,
      imageCapSB: false,
      routineSB: false,
      pissSB: false,
      vehicleSB: false,

      rewampSB: false,
      vehicleSB: false,
      siteRWMP: false
    }
  }


  componentDidMount() {
    console.log(window.location.pathname);
    this.setState({ selectUrl: window.location.pathname }, () => {
      if (this.state.selectUrl == '/' || this.state.selectUrl == '/dashboard-pg' || this.state.selectUrl == '/dashboard-map' || this.state.selectUrl == '/dashboard-pg-cost' || this.state.selectUrl == '/dashboard-pg-cost-depot' || this.state.selectUrl == '/dashboard-pg-pending-status' || this.state.selectUrl == '/dashboard-pg-pending-status-depot' || this.state.selectUrl == '/dashboard-refuel' || this.state.selectUrl == '/routine/dashboard' || this.state.selectUrl == '/pis/dashboard/ac' || this.state.selectUrl == '/pis/dashboard/site_cleaning' || this.state.selectUrl == '/pis/dashboard/civil_routine' || this.state.selectUrl == '/vehicle/dashboard' || this.state.selectUrl == '/pis/dashboard/gen' || this.state.selectUrl == '/site-access/dashboard') {
        this.setState({
          dashboardSB: true,
          sideBarD1: true
        })

      }
      if (this.state.selectUrl == '/dashboard-pg' || this.state.selectUrl == '/dashboard-map' || this.state.selectUrl == '/dashboard-pg-cost' || this.state.selectUrl == '/dashboard-pg-cost-depot' || this.state.selectUrl == '/dashboard-pg-pending-status' || this.state.selectUrl == '/dashboard-pg-pending-status-depot') {
        this.setState({
          pGSB: true,
          sideBarPg: true
        })

      }
      if (this.state.selectUrl == '/dashboard-refuel') {
        this.setState({
          refuelSB: true,
          sideBarrefuel: true
        })

      }
      if (this.state.selectUrl == '/vehicle/dashboard') {
        this.setState({
          vehicleHeadrSB: true,
          sideBarHeadVehicle: true
        })

      }
      if (this.state.selectUrl == '/routine/dashboard') {
        this.setState({
          routingDashboardSB: true,
          sideBarRoutingDashboar: true
        })

      }
      if (this.state.selectUrl == '/pis/dashboard/gen' || this.state.selectUrl == '/pis/dashboard/ac' || this.state.selectUrl == '/pis/dashboard/site_cleaning' || this.state.selectUrl == '/pis/dashboard/civil_routine') {
        this.setState({
          pisDashboardSB: true,
          sidepisDashboar: true
        })

      }
      if (this.state.selectUrl == '/site-access/dashboard') {
        this.setState({
          siteAccessHeadrSB: true,
          sideBarHeadSiteAccess: true
        })

      }
      if (this.state.selectUrl == '/app-version') {
        this.setState({
          appSettingSB: true,
          sideBarAppSetting: true
        })

      }
      if (this.state.selectUrl == '/inbox') {
        this.setState({
          workSpaceSB: true,
          sideBarWorkSpace: true
        })

      }
       if (this.state.selectUrl == '/site') {
        this.setState({
          siteDataSB: true,
          sideBarSiteData: true
        })

      }
      if (this.state.selectUrl == '/region-list' || this.state.selectUrl == '/depot-list' || this.state.selectUrl == '/site-info') {
        this.setState({
          orgaSB: true,
          sideBarOrga: true
        })

      }
      if ( this.state.selectUrl == '/pg-view' || this.state.selectUrl == '/vendor_team' || this.state.selectUrl == '/organization' || this.state.selectUrl == '/region' || this.state.selectUrl == '/depot' || this.state.selectUrl == '/dashboard/target-values' || this.state.selectUrl == '/vendor' || this.state.selectUrl == '/site-category' || this.state.selectUrl == '/shelter-types' || this.state.selectUrl == '/users' || this.state.selectUrl == '/depot-users' || this.state.selectUrl == '/user-export' || this.state.selectUrl == '/vehicles' || this.state.selectUrl == '/vehicle/master') {
        this.setState({
          adminSB: true,
          sideBarAdmin: true
        })

      }
      if (this.state.selectUrl == '/organization' || this.state.selectUrl == '/vendor_team' || this.state.selectUrl == '/region' || this.state.selectUrl == '/depot' || this.state.selectUrl == '/vendor' || this.state.selectUrl == '/site-category' || this.state.selectUrl == '/shelter-types') {
        this.setState({
          masterDataSB: true,
          sideBarMasterData: true
        })

      }
      if (this.state.selectUrl == '/users' || this.state.selectUrl == '/depot-users' || this.state.selectUrl == '/user-export') {
        this.setState({
          userSB: true,
          sideBarUser: true
        })

      }
      if (this.state.selectUrl == '/report/full-export' || this.state.selectUrl == '/refuel-full-export' || this.state.selectUrl == '/procurement-refuel-full-export' || this.state.selectUrl == '/fuel-balance-export' || this.state.selectUrl == '/vehicle-full-export' || this.state.selectUrl == '/payment-full-export' || this.state.selectUrl == '/site-access/full-export' || this.state.selectUrl == '/site-access/full-export-team-member' || this.state.selectUrl == '/power-db/power-data-export' || this.state.selectUrl == '/image-capture/full-export' || this.state.selectUrl == '/routing/data-export' || this.state.selectUrl == '/pis/gen-service-export' || this.state.selectUrl == '/pis/ac-service-export' || this.state.selectUrl == '/pis/civil-service-export' || this.state.selectUrl == '/pis/sc-service-export') {
        this.setState({
          dataExSB: true,
          sideBarDataEx: true
        })

      }
      if (this.state.selectUrl == '/report/full-export') {
        this.setState({
          dataExPGSB: true,
          sideBarDataExPg: true
        })

      }
      if (this.state.selectUrl == '/refuel-full-export' || this.state.selectUrl == '/procurement-refuel-full-export' || this.state.selectUrl == '/fuel-balance-export') {
        this.setState({
          dataExRefuleSB: true,
          sideBarDataExRefuel: true
        })

      }
      if (this.state.selectUrl == '/vehicle-full-export' || this.state.selectUrl == '/payment-full-export') {
        this.setState({
          dataExVheSB: true,
          sideBarDataExVeh: true
        })

      }
      if (this.state.selectUrl == '/site-access/full-export' || this.state.selectUrl == '/site-access/full-export-team-member') {
        this.setState({
          dataExSiteAcSB: true,
          sideBarDataExSiteAc: true
        })

      }
      if (this.state.selectUrl == '/power-db/power-data-export') {
        this.setState({
          dataExPowerDbSB: true,
          sideBarDataExPowerDb: true
        })

      }
      if (this.state.selectUrl == '/report') {
        this.setState({
          reportSB: true,
          sideBarReport: true
        })

      }
      if (this.state.selectUrl == '/power-db/upload-powerdb'|| this.state.selectUrl == '/power-db/power-data-manage' || this.state.selectUrl == '/power-db/dashboard' || this.state.selectUrl == '/power-db/query') {
        this.setState({
          powerDbSB: true,
          sideBarPowerDb: true
        })

      }
      if (this.state.selectUrl == '/pg-view') {
        this.setState({
          pgUpdateSB: true,
          sideBarPgUpdate: true
        })

      }
      if (this.state.selectUrl == '/site-access/list' || this.state.selectUrl == '/site-access/handler' || this.state.selectUrl == '/site-access/all-requests') {
        this.setState({
          siteAcSB: true,
          sideBarSiteAC: true
        })

      }
      if (this.state.selectUrl == '/pis/cheklist-export' || this.state.selectUrl == '/pis-history-view') {
        this.setState({
          pisCheckListSB: true,
          sideBarPisCheclist: true
        })

      }
      if (this.state.selectUrl == '/image-capture/list') {
        this.setState({
          imageCapSB: true,
          sideBarImageCap: true
        })

      }
      if (this.state.selectUrl == '/pis-history-view') {
        this.setState({
          pissSB: true,

        })

      }
      if (this.state.selectUrl == '/routine-history-view') {
        this.setState({
          routineSB: true,

        })

      }
      if (this.state.selectUrl == '/vehicle/master' || this.state.selectUrl == '/vehicles') {
        this.setState({
          vehicleSB: true,
          sideBarVehicle: true
        })

      }
      if (this.state.selectUrl == '/image-capture/full-export') {
        this.setState({
          dataExImageCapturSB: true,
          sideBarDataExImageCaptue: true
        })

      }
      if (this.state.selectUrl == '/routing/data-export') {
        this.setState({
          dataExSiteRoutingSB: true,
          sideBarDataExSiteRoutin: true
        })

      }
      if (this.state.selectUrl == '/pis/gen-service-export' || this.state.selectUrl == '/pis/ac-service-export' || this.state.selectUrl == '/pis/civil-service-export' || this.state.selectUrl == '/pis/sc-service-export') {
        this.setState({
          dataExSitePisSB: true,
          sideBarDataExPISAC: true
        })

      }
      if (this.state.selectUrl == '/power-db/power-data-view') {
        this.setState({
          powerDbSB: true,
          sideBarPowerDb: true
        })

      }
      if (this.state.selectUrl == ''){
        this.setState({
          rewampSB: true,
        })
      }
    })


    if (localStorage.getItem("access_token") == null) {
      window.location.reload(false);

    } else {

      if (JSON.parse(localStorage.getItem('serviceTypeSet'))) {

        console.log(JSON.parse(localStorage.getItem('serviceTypeSet')));

        this.setState({
          serviceTypeIs: JSON.parse(localStorage.getItem('serviceTypeSet'))
        })
        console.log(JSON.parse(localStorage.getItem('serviceTypeSet')).user_id);
      } else {

        this.getDataUserRolesAndPermission2(localStorage.getItem("access_token"));
      }
    }
  }
  
  getDataUserRolesAndPermission2 = async (data) => {
    var res = await getUserRolesAndPermission2(data);
    console.log("getUserRolesAndPermission2");
    console.log(res.data);
    if (res.success) {
      this.getDataUserServiceType();


    }
  };

  getDataUserServiceType = async () => {
    var res = await getUserServiceType();
    console.log("getUserServiceType1");

    if (res.success) {
      localStorage.setItem('serviceTypeSet', JSON.stringify(res.data));
      this.setState({
        serviceTypeIs: res.data
      })
      console.log(res.data);

    }
  };
  viewFilterClick = (x) => {
    console.log(x)
    if (x == 'd1') {
      this.state.sideBarD1 ? (this.setState({
        sideBarD1: false
      })) : (this.setState({
        sideBarD1: true,

      }))
    }
    if (x == 'pg1') {
      console.log(x)
      this.state.sideBarPg ? (this.setState({
        sideBarPg: false
      })) : (this.setState({
        sideBarPg: true,

      }))
    }
    if (x == 'refu1') {
      console.log(x)
      this.state.sideBarrefuel ? (this.setState({
        sideBarrefuel: false
      })) : (this.setState({
        sideBarrefuel: true,

      }))
    }
    if (x == 'appsetting1') {
      console.log(x)
      this.state.sideBarAppSetting ? (this.setState({
        sideBarAppSetting: false
      })) : (this.setState({
        sideBarAppSetting: true,

      }))
    }
    if (x == 'works1') {
      console.log(x)
      this.state.sideBarWorkSpace ? (this.setState({
        sideBarWorkSpace: false
      })) : (this.setState({
        sideBarWorkSpace: true,

      }))
    }
    if (x == 'orga1') {
      console.log(x)
      this.state.sideBarOrga ? (this.setState({
        sideBarOrga: false
      })) : (this.setState({
        sideBarOrga: true,

      }))
    }
    if (x == 'admin1') {
      console.log(x)
      this.state.sideBarAdmin ? (this.setState({
        sideBarAdmin: false
      })) : (this.setState({
        sideBarAdmin: true,

      }))
    }

    if (x == 'master1') {
      console.log(x)
      this.state.sideBarMasterData ? (this.setState({
        sideBarMasterData: false
      })) : (this.setState({
        sideBarMasterData: true,

      }))
    }
    if (x == 'user1') {
      console.log(x)
      this.state.sideBarUser ? (this.setState({
        sideBarUser: false
      })) : (this.setState({
        sideBarUser: true,

      }))
    }
    if (x == 'dataEx1') {
      console.log(x)
      this.state.sideBarDataEx ? (this.setState({
        sideBarDataEx: false
      })) : (this.setState({
        sideBarDataEx: true,

      }))
    }
    if (x == 'dataExPG1') {
      console.log(x)
      this.state.sideBarDataExPg ? (this.setState({
        sideBarDataExPg: false
      })) : (this.setState({
        sideBarDataExPg: true,

      }))
    }
    if (x == 'dataExRe1') {
      console.log(x)
      this.state.sideBarDataExRefuel ? (this.setState({
        sideBarDataExRefuel: false
      })) : (this.setState({
        sideBarDataExRefuel: true,

      }))
    }
    if (x == 'dataExVhe1') {
      console.log(x)
      this.state.sideBarDataExVeh ? (this.setState({
        sideBarDataExVeh: false
      })) : (this.setState({
        sideBarDataExVeh: true,

      }))
    }
    if (x == 'dataExSiteAc1') {
      console.log(x)
      this.state.sideBarDataExSiteAc ? (this.setState({
        sideBarDataExSiteAc: false
      })) : (this.setState({
        sideBarDataExSiteAc: true,

      }))
    }
    if (x == 'dataExPD1') {
      console.log(x)
      this.state.sideBarDataExPowerDb ? (this.setState({
        sideBarDataExPowerDb: false
      })) : (this.setState({
        sideBarDataExPowerDb: true,

      }))
    }
    if (x == 'siteAc1') {
      console.log(x)
      this.state.sideBarSiteAC ? (this.setState({
        sideBarSiteAC: false
      })) : (this.setState({
        sideBarSiteAC: true,

      }))
    }
    if (x == 'imageCap1') {
      console.log(x)
      this.state.sideBarImageCap ? (this.setState({
        sideBarImageCap: false
      })) : (this.setState({
        sideBarImageCap: true,

      }))
    }
    if (x == 'vehicleMo1') {
      console.log(x)
      this.state.sideBarVehicle ? (this.setState({
        sideBarVehicle: false
      })) : (this.setState({
        sideBarVehicle: true,

      }))
    }
    if (x == 'imageCap2') {
      console.log(x)
      this.state.sideBarDataExImageCaptue ? (this.setState({
        sideBarDataExImageCaptue: false
      })) : (this.setState({
        sideBarDataExImageCaptue: true,

      }))
    }
    if (x == 'SiteRouting2') {
      console.log(x)
      this.state.sideBarDataExSiteRoutin ? (this.setState({
        sideBarDataExSiteRoutin: false
      })) : (this.setState({
        sideBarDataExSiteRoutin: true,

      }))
    }
    if (x == 'pis1') {
      console.log(x)
      this.state.sideBarDataExPISAC ? (this.setState({
        sideBarDataExPISAC: false
      })) : (this.setState({
        sideBarDataExPISAC: true,

      }))
    }
    if (x == 'PowerDB2') {
      console.log(x)
      this.state.sideBarPowerDb ? (this.setState({
        sideBarPowerDb: false
      })) : (this.setState({
        sideBarPowerDb: true,

      }))
    }
    if (x == 'routine') {
      console.log(x)
      this.state.sideBarRoutingDashboar ? (this.setState({
        sideBarRoutingDashboar: false
      })) : (this.setState({
        sideBarRoutingDashboar: true,

      }))
    }
    if (x == 'pisGen') {
      console.log(x)
      this.state.sidepisDashboar ? (this.setState({
        sidepisDashboar: false
      })) : (this.setState({
        sidepisDashboar: true,

      }))
    }
    if (x == 'pisChecklistMa') {
      console.log(x)
      this.state.sideBarPisCheclist ? (this.setState({
        sideBarPisCheclist: false
      })) : (this.setState({
        sideBarPisCheclist: true,

      }))
    }
    if (x == 'vehicle_set') {
      console.log(x)
      this.state.sideBarHeadVehicle ? (this.setState({
        sideBarHeadVehicle: false
      })) : (this.setState({
        sideBarHeadVehicle: true,

      }))
    }

  }
  viewFilterClick1 = (x) => {
    console.log(x)

  }
  
  render() {

    const liBold = {
      fontWeight: 'bold',
    };
    
    return (
      <div class="left-sidenav" style={{liBold}}>
        <div class="brand">
          <a href="/" class="logo">
            <span>
              <img style={{ width: "65%"  }} src={logo} alt="logo-small" class="logo"></img>
              {/* <img src='https://www.eimsky.com/img/assets/logo-dark.png' alt="logo-small" class="logo-sm"/> */}
            </span>
            <span>
              {/* <img src={logo} alt="logo-large" class="logo-lg logo-light"></img> */}
              {/* <img src={logo} alt="logo-large" class="logo-lg logo-dark"></img> */}
            </span>
          </a>
        </div>
        {this.state.serviceTypeIs != null ? (<div class="menu-content h-100" data-simplebar>
          <ul class="metismenu left-sidenav-menu" style={{ fontSize: 'small' }}>
            <li class="menu-label mt-2" style={{fontWeight: 'bold'}}>Main</li>
            {/* <li className={this.state.sideBarD1 ? ('mm-active') : ('')}
                            onClick={() => this.viewFilterClick('d1')}
                            data-toggle="collapse"
                            data-target="#dashboards1"


                        >
                            <a href="javascript: void(0);"> <svg style={{ color: this.state.dashboardSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home align-self-center menu-icon"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg><span style={{ fontSize: 'small' }}>Dashboard</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
                            <ul className={this.state.dashboardSB ? ('nav-second-level collapse show') : ('nav-second-level collapse')} aria-expanded="false" id="dashboards1">
                                <li class="nav-item"><a style={{ color: this.state.selectUrl == '/' ? ('#1761fd') : ('') }} className={this.state.selectUrl == '/' ? ('nav-link active') : ('nav-link')} href="/"><i class="ti-control-record"></i>General</a></li>
                                <li class="nav-item" 
                                 onClick={() => this.viewFilterClick1('d2S')}
                                data-toggle="collapse"
                                    data-target="#PG-data1"
                                   >
                                    <a href="javascript: void(0);"><i class="ti-control-record"></i>PG <span class="menu-arrow left-has-menu"><i class="mdi mdi-chevron-right"></i></span></a>
                                    <ul class="nav-second-level collapse" aria-expanded="true" id="PG-data1">
                                        <li><a href="apps-email-inbox.html">Inbox</a></li>
                                        <li><a href="apps-email-read.html">Read Email</a></li>
                                    </ul>
                                </li>
                              
                            </ul>
                        </li> */}
            {/* <li
                            data-toggle="collapse"
                            data-target="#dashboards2"
                        >
                            <a href="javascript: void(0);"><i data-feather="grid" class="align-self-center menu-icon"></i><span style={{ fontSize: 'small' }}>Apps</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
                            <ul class="nav-second-level collapse" aria-expanded="false" id='dashboards2'>
                                <li>
                                    <a href="javascript: void(0);"><i class="ti-control-record"></i>Email <span class="menu-arrow left-has-menu"><i class="mdi mdi-chevron-right"></i></span></a>
                                    <ul class="nav-second-level" aria-expanded="false">
                                        <li><a href="apps-email-inbox.html">Inbox</a></li>
                                        <li><a href="apps-email-read.html">Read Email</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a class="nav-link" href="apps-chat.html"><i class="ti-control-record"></i>Chat</a></li>
                                <li class="nav-item"><a class="nav-link" href="apps-contact-list.html"><i class="ti-control-record"></i>Contact List</a></li>
                                <li class="nav-item"><a class="nav-link" href="apps-calendar.html"><i class="ti-control-record"></i>Calendar</a></li>
                                <li class="nav-item"><a class="nav-link" href="apps-files.html"><i class="ti-control-record"></i>File Manager</a></li>
                                <li class="nav-item"><a class="nav-link" href="apps-invoice.html"><i class="ti-control-record"></i>Invoice</a></li>
                                <li class="nav-item"><a class="nav-link" href="apps-tasks.html"><i class="ti-control-record"></i>Tasks</a></li>
                                <li>
                                    <a href="javascript: void(0);"><i class="ti-control-record"></i>Projects <span class="menu-arrow left-has-menu"><i class="mdi mdi-chevron-right"></i></span></a>
                                    <ul class="nav-second-level" aria-expanded="false">
                                        <li><a href="apps-project-overview.html">Overview</a></li>
                                        <li><a href="apps-project-projects.html">Projects</a></li>
                                        <li><a href="apps-project-board.html">Board</a></li>
                                        <li><a href="apps-project-teams.html">Teams</a></li>
                                        <li><a href="apps-project-files.html">Files</a></li>
                                        <li><a href="apps-new-project.html">New Project</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="javascript: void(0);"><i class="ti-control-record"></i>Ecommerce <span class="menu-arrow left-has-menu"><i class="mdi mdi-chevron-right"></i></span></a>
                                    <ul class="nav-second-level" aria-expanded="false">
                                        <li><a href="apps-ecommerce-products.html">Products</a></li>
                                        <li><a href="apps-ecommerce-product-list.html">Product List</a></li>
                                        <li><a href="apps-ecommerce-product-detail.html">Product Detail</a></li>
                                        <li><a href="apps-ecommerce-cart.html">Cart</a></li>
                                        <li><a href="apps-ecommerce-checkout.html">Checkout</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li> */}
            {/* NEW MIX     */}
            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 13)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 1)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 29)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 30)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 31)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 32)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 33)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 34)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 35)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 36)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 37)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 2)[0] != null ? (<li
              data-toggle="collapse"
              data-target="#dashboards"
              class=""
              aria-expanded="true"
              onClick={() => this.viewFilterClick('d1')}
            >
              <a href="javascript: void(0);">
                {" "}
                {/* <svg style={{ color: this.state.dashboardSB ? ('#1761fd') : ('') }} 
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" 
                stroke-linecap="round" stroke-linejoin="round" class="feather feather-home align-self-center menu-icon"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z">
                  </path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> */}
                {/* <PieChartOutlined style={{ color: this.state.dashboardSB ? ('#1761fd') : (''), fontSize: '17px' }} />
                <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px', color: this.state.dashboardSB ? ('#1761fd') : ('')}}>
                  Dashboards</span> */}
                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <PieChartOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    Dashboards
                  </span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarD1 ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </div>
              </a>
            </li>) : ('')}
            <ul className={this.state.selectUrl == '/' || this.state.selectUrl == '/dashboard-pg' || this.state.selectUrl == '/dashboard-map' || this.state.selectUrl == '/dashboard-pg-cost' || this.state.selectUrl == '/dashboard-pg-cost-depot' || this.state.selectUrl == '/dashboard-pg-pending-status' || this.state.selectUrl == '/dashboard-pg-pending-status-depot' || this.state.selectUrl == '/dashboard-refuel' || this.state.selectUrl == '/vehicle/dashboard' || this.state.selectUrl == '/site-access/dashboard' || this.state.selectUrl == '/routine/dashboard' || this.state.selectUrl == '/pis/dashboard/gen' || this.state.selectUrl == '/pis/dashboard/ac' || this.state.selectUrl == '/pis/dashboard/site_cleaning' || this.state.selectUrl == '/pis/dashboard/civil_routine' ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="dashboards" style={{ paddingLeft: 25, listStyleType: 'none' }}>
              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 13)[0] != null ? (
              <li className={this.state.selectUrl == '/' ? ('active') : ('')}  >
                <a className="sub-menu-href" href="/" >
                  <i style={{ color: this.state.selectUrl == '/' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/' ? ('#1761fd') : ('') }}>General</span>
                </a>
              </li>
              ) : ('')}

              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 29)[0] != null ? (<li data-toggle="collapse"
                data-target="#PG-data"
                class="collapsed "
                onClick={() => this.viewFilterClick('pg1')}
              >
                <a href="javascript: void(0);" className="sub-menu-href">
                  {" "}
                  <i class="ti-control-record"></i>
                  <span style={{ fontSize: 'small' }}>PG</span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarPg ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </a>
              </li>) : ('')}

              <ul className={this.state.pGSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="PG-data" style={{ paddingLeft: 25 }}>

                <li className={this.state.selectUrl == '/dashboard-pg' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/dashboard-pg' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/dashboard-pg" >General</a>
                </li>
                <li className="sub-sub-menu-href">
                  <a className="sub-menu-href" href="/dashboard-pg-live" target="_blank">Live</a>
                </li>
                <li className="sub-sub-menu-href">
                  <a className="sub-menu-href" href="/dashboard-all-pg-live" target="_blank">All PG</a>
                </li>
                <li className="sub-sub-menu-href">
                  <a className="sub-menu-href" href="/dashboard-pg-status" target="_blank">PG Status Count</a>
                </li>
                <li className={this.state.selectUrl == '/dashboard-map' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/dashboard-map' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/dashboard-map" >PG  Map View</a>
                </li>
                <li className={this.state.selectUrl == '/dashboard-pg-cost' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/dashboard-pg-cost' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/dashboard-pg-cost">PG Cost of Region</a>
                </li>
                <li className={this.state.selectUrl == '/dashboard-pg-cost-depot' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/dashboard-pg-cost-depot' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/dashboard-pg-cost-depot">Depot PG  Cost</a>
                </li>
                <li className={this.state.selectUrl == '/dashboard-pg-pending-status' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/dashboard-pg-pending-status' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/dashboard-pg-pending-status">PG Pending Status </a>
                </li>
                <li className={this.state.selectUrl == '/dashboard-pg-pending-status-depot' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/dashboard-pg-pending-status-depot' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/dashboard-pg-pending-status-depot">Depot PG Pending Status </a>
                </li>

              </ul>
              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 30)[0] != null ? (<li data-toggle="collapse"
                data-target="#Refuel-data"
                onClick={() => this.viewFilterClick('refu1')}
                class="collapsed ">
                <a href="javascript: void(0);" className="sub-menu-href">
                  {" "}
                  <i class="ti-control-record"></i>
                  <span>Refuel</span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarrefuel ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </a>
              </li>) : ('')}

              <ul className={this.state.refuelSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="Refuel-data" style={{ paddingLeft: 25 }}>

                <li className={this.state.selectUrl == '/dashboard-refuel' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/dashboard-refuel' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/dashboard-refuel">General</a>
                </li>
              </ul>
              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 31)[0] != null ? (<li data-toggle="collapse"
                data-target="#vehicle-data"
                onClick={() => this.viewFilterClick('vehicle_set')}
                class="collapsed ">
                <a href="javascript: void(0);" className="sub-menu-href">
                  {" "}
                  <i class="ti-control-record"></i>
                  <span>Vehicle</span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarHeadVehicle ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </a>
              </li>) : ('')}

              <ul className={this.state.vehicleHeadrSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="vehicle-data" style={{ paddingLeft: 25 }}>
{/* 
                <li className={this.state.selectUrl == '/vehicle/dashboard' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/vehicle/dashboard' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/vehicle/dashboard">General</a>
                </li> */}
                <li className={this.state.selectUrl == '/vehicle/new/dashboard' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/vehicle/new/dashboard' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/vehicle/new/dashboard">Genaral</a>
                </li>
              </ul>
              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 32)[0] != null ? (<li data-toggle="collapse"
                data-target="#site-access-data"
                onClick={() => this.viewFilterClick('refu1')}
                class="collapsed ">
                <a href="javascript: void(0);" className="sub-menu-href">
                  {" "}
                  <i class="ti-control-record"></i>
                  <span>Site Access</span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarHeadSiteAccess ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </a>
              </li>) : ('')}

              <ul className={this.state.siteAccessHeadrSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="site-access-data" style={{ paddingLeft: 25 }}>

                <li className={this.state.selectUrl == '/access/dev/dashboard' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/access/dev/dashboard' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/access/dev/dashboard">General</a>
                </li>
              </ul>
              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 33)[0] != null ? (<li data-toggle="collapse"
                data-target="#routine-data"
                onClick={() => this.viewFilterClick('routine')}
                class="collapsed ">
                <a href="javascript: void(0);" className="sub-menu-href">
                  {" "}
                  <i class="ti-control-record"></i>
                  <span>Routine</span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarRoutingDashboar ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </a>
              </li>) : ('')}

              <ul className={this.state.routingDashboardSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="routine-data" style={{ paddingLeft: 25 }}>

                <li className={this.state.selectUrl == '/routine/dashboard' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/routine/dashboard' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/routine/dashboard">General</a>
                </li>
              </ul>

              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 34)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 35)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 36)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 37)[0] != null ? (<li data-toggle="collapse"
                data-target="#pis-dashboard-data"
                onClick={() => this.viewFilterClick('pisGen')}
                class="collapsed ">
                <a href="javascript: void(0);" className="sub-menu-href">
                  {" "}
                  <i class="ti-control-record"></i>
                  <span>PIS</span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sidepisDashboar ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </a>
              </li>) : ('')}

              <ul className={this.state.pisDashboardSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="pis-dashboard-data" style={{ paddingLeft: 25 }}>

                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 34)[0] != null ? (<li className={this.state.selectUrl == '/pis/dashboard/gen' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/pis/dashboard/gen' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/pis/dashboard/gen">Gen Service</a>
                </li>) : ('')}
                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 35)[0] != null ? (<li className={this.state.selectUrl == '/pis/dashboard/ac' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/pis/dashboard/ac' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/pis/dashboard/ac">AC Service</a>
                </li>) : ('')}
                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 36)[0] != null ? (<li className={this.state.selectUrl == '/pis/dashboard/site_cleaning' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/pis/dashboard/site_cleaning' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/pis/dashboard/site_cleaning">Site Cleaning Service</a>
                </li>) : ('')}
                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 37)[0] != null ? (<li className={this.state.selectUrl == '/pis/dashboard/civil_routine' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/pis/dashboard/civil_routine' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/pis/dashboard/civil_routine">Civil Service</a>
                </li>) : ('')}
                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 37)[0] != null ? (<li className={this.state.selectUrl == '/pis/all/mom' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/pis/all/mom' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/pis/all/mom">MOM</a>
                </li>) : ('')}
              </ul>



              {/* <li>
                  <a href="/dashboard-exceptional">Exceptional</a>
                </li> */}

            </ul>
            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 13)[0] != null ? (<li
              data-toggle="collapse"
              data-target="#workspace"
              onClick={() => this.viewFilterClick('works1')}
            >
              <a href="javascript: void(0);">
                {" "}
                {/* <svg style={{ color: this.state.workSpaceSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid align-self-center menu-icon"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg> */}
                {/* <AppstoreOutlined style={{ color: this.state.dashboardSB ? ('#1761fd') : (''), fontSize: '17px' }} />
                <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px', color: this.state.dashboardSB ? ('#1761fd') : ('')}}>Workspace</span>
                <span className="menu-arrow sid-menu-arrow">
                  <i className={this.state.sideBarWorkSpace ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                </span> */}
                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <AppstoreOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    Workspace
                  </span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarWorkSpace ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </div>
              </a>
            </li>) : ('')}

            <ul className={this.state.selectUrl == '/inbox' ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="workspace" style={{ paddingLeft: 25 }}>
              <li className={this.state.selectUrl == '/inbox' ? (' active') : ('')}>
                <a className="sub-menu-href" href="/inbox">
                  <i style={{ color: this.state.selectUrl == '/inbox' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/inbox' ? ('#1761fd') : ('') }}> Inbox</span></a>
              </li>

            </ul>

            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 13)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 22)[0] != null ? (<li
              data-toggle="collapse"
              data-target="#Organization1"

              className={this.state.selectUrl == '/region-list' ? ('collapsed active') : ('collapsed')}
            >
              <a href="/region-list">
                {" "}
                {/* <svg style={{ color: this.state.orgaSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-box align-self-center menu-icon"><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path><polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline><line x1="12" y1="22.08" x2="12" y2="12"></line></svg> */}
                {/* <span style={{ color: this.state.orgaSB ? ('#1761fd') : ('') , fontWeight: 'bold'}}>Organization</span> */}
                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <ClusterOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    Organization
                  </span>
                </div>
              </a>
            </li>) : ('')}
            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 13)[0] != null || 
            this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 22)[0] != null ? (<li
              data-toggle="collapse"
              data-target="#Organization1"
              className={this.state.selectUrl == '/site' ? ('collapsed active') : ('collapsed')}
            >
              <a href="/site">
                {" "}
                {/* <svg style={{ color: this.state.siteDataSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-radio align-self-center menu-icon"><circle cx="12" cy="12" r="2"></circle><path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"></path></svg> */}
                {/* <span style={{ color: this.state.siteDataSB ? ('#1761fd') : (''), fontWeight: 'bold'}}>Site Data</span> */}
                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <GlobalOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                  Site Data
                  </span>
                </div>
              </a>
            </li>) : ('')}
            <ul className={this.state.selectUrl == '/region-list' || this.state.selectUrl == '/depot-list' || this.state.selectUrl == '/site-info' ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="service" style={{ paddingLeft: 25, display: 'none' }}>
              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 13)[0] != null ? (<li className={this.state.selectUrl == '/region-list' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/region-list">
                  <i style={{ color: this.state.selectUrl == '/region-list' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/region-list' ? ('#1761fd') : ('') }}>Region </span></a>
              </li>) : ('')}
              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 13)[0] != null ? (<li className={this.state.selectUrl == '/depot-list' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/depot-list">
                  <i style={{ color: this.state.selectUrl == '/depot-list' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/depot-list' ? ('#1761fd') : ('') }}>Depot </span></a>
              </li>) : ('')}
              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 22)[0] != null ? (<li className={this.state.selectUrl == '/site-info' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/site-info">
                  <i style={{ color: this.state.selectUrl == '/site-info' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/site-info' ? ('#1761fd') : ('') }}>Site  </span></a>
              </li>) : ('')}
              {/* <li>
                  <a href="/site">Site List</a>
                </li> */}
              {/* <li><a href="/site-summary/1">Site Detail</a></li> */}
            </ul>

            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 13)[0] != null || 
            this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 26)[0] != null || 
            this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 27)[0] != null || 
            this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 38)[0] != null ? (<li
              data-toggle="collapse"
              data-target="#admin"
              class="collapsed active"
              onClick={() => this.viewFilterClick('admin1')}
            >
              <a href="javascript: void(0);">
                {" "}
                {/* <svg style={{ color: this.state.adminSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-database align-self-center menu-icon"><ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path></svg>

                <span style={{fontWeight: 'bold'}}>Admin</span> */}
                
                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <UserOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                  Admin
                  </span>
                  <span className="menu-arrow sid-menu-arrow">
                  <i className={this.state.sideBarAdmin ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                </span>
                </div>
              </a>
            </li>) : ('')}


            <ul className={ this.state.selectUrl == '/pg-view' || this.state.selectUrl == '/dashboard/target-values' || this.state.selectUrl == '/vendor_team' || this.state.selectUrl == '/organization' || this.state.selectUrl == '/region' || this.state.selectUrl == '/depot' || this.state.selectUrl == '/vendor' || this.state.selectUrl == '/site-category' || this.state.selectUrl == '/shelter-types' || this.state.selectUrl == '/users' || this.state.selectUrl == '/user-export' || this.state.selectUrl == '/depot-users' || this.state.selectUrl == '/vehicles' || this.state.selectUrl == '/vehicle/master'  || this.state.selectUrl == '/routine/observation' ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="admin" style={{ paddingLeft: 25 }}>
              {/* <li>
                  <a  className="sub-menu-href" href="/pg">
                    PG List
                  </a>pis1
                </li>  */}
              {/* <li>
                  <a href="/sites/create">
                    Site Create
                  </a>
                </li> */}
              {/* <li className={this.state.selectUrl == '/site' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/site">
                  <i style={{ color: this.state.selectUrl == '/site' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/site' ? ('#1761fd') : ('') }}>Manage Sites</span></a>
              </li> */}

              {/* <li
                  data-toggle="collapse"
                  data-target="#sitemenu"
                  class="collapsed "
                >
                  <a className="sub-menu-href" href="javascript: void(0);">

                    <span>Site</span>
                    <span className="menu-arrow">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>
                  </a>
                </li>
                <ul class="sub-menu collapse" id="sitemenu">
                  <li>
                    <a className="sub-menu-href" href="/site"> List</a>
                  </li>
                  {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 13)[0] != null ? (<li>
                    <a className="sub-menu-href" href="/sites/create">Create</a>
                  </li>) : ("")}

                </ul> */}
              <li data-toggle="collapse"
                data-target="#master-data"
                onClick={() => this.viewFilterClick('master1')}
                class="collapsed ">
                <a className="sub-menu-href" href="javascript: void(0);">
                  {" "}
                  <i class="ti-control-record"></i>

                  <span>Master Data</span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarMasterData ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </a>
              </li>
              <ul className={this.state.masterDataSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="master-data" style={{ paddingLeft: 25 }} >
                <li className={this.state.selectUrl == '/organization' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/organization' ? ('#1761fd') : ('') }} className="sub-menu-href " href="/organization"> Organization</a>
                </li>
                <li className={this.state.selectUrl == '/region' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/region' ? ('#1761fd') : ('') }} className="sub-menu-href " href="/region">Region</a>
                </li>
                <li className={this.state.selectUrl == '/depot' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/depot' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/depot">Depot</a>
                </li>
                <li className={this.state.selectUrl == '/vendor' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/vendor' ? ('#1761fd') : ('') }} className="sub-menu-href " href="/vendor">Vendor</a>
                </li>
                <li className={this.state.selectUrl == '/vendor_team' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/vendor_team' ? ('#1761fd') : ('') }} className="sub-menu-href " href="/vendor_team">Vendor Team</a>
                </li>
                {/* <li className="sub-sub-menu-href">
                    <a className="sub-menu-href " href="/service_types">Service Types</a>
                  </li> */}
                {/* <li className="sub-sub-menu-href">
                    <a className="sub-menu-href" href="/vendor_sla_term">Vendor SLA Term</a>
                  </li> */}
                {/* <li className="sub-sub-menu-href">
                    <a className="sub-menu-href " href="/contracts">Contract</a>
                  </li> */}
                <li className={this.state.selectUrl == '/site-category' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/site-category' ? ('#1761fd') : ('') }} className="sub-menu-href " href="/site-category">Site Category</a>
                </li>
                <li className={this.state.selectUrl == '/shelter-types' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/shelter-types' ? ('#1761fd') : ('') }} className="sub-menu-href " href="/shelter-types">Shelter Type</a>
                </li>
              </ul>


              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 26)[0] != null ? (<li
                data-toggle="collapse"
                data-target="#users12"
                onClick={() => this.viewFilterClick('user1')}
                class="collapsed "

              >
                <a className="sub-menu-href" href="javascript: void(0);">
                  <i class="ti-control-record"></i>
                  <span>Users</span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarUser ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </a>
              </li>) : ('')}
              <ul className={this.state.selectUrl == '/users' || this.state.selectUrl == '/depot-users' || this.state.selectUrl == '/user-export' ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="users12" style={{ paddingLeft: 25 }}>
                <li className={this.state.selectUrl == '/users' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/users' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/users"> List</a>
                </li>
                <li className={this.state.selectUrl == '/user-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/user-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/user-export"> User Export</a>
                </li>
                {/* <li className={this.state.selectUrl == '/depot-users' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/depot-users' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/depot-users"> Depot Users</a>
                </li> */}
              </ul>

              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 26)[0] != null ? (<li
                data-toggle="collapse"
                data-target="#routine_admin"
                onClick={() => this.viewFilterClick('routine_admin')}
                class="collapsed "

              >
                <a className="sub-menu-href" href="javascript: void(0);">
                  <i class="ti-control-record"></i>
                  <span>Routine</span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarUser ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </a>
              </li>) : ('')}
              <ul className={this.state.selectUrl == '/routine/observation'  ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="routine_admin" style={{ paddingLeft: 25 }}>
                <li className={this.state.selectUrl == '/routine/observation' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/routine/observation' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/routine/observation"> Observation </a>
                </li>
               
                {/* <li className={this.state.selectUrl == '/depot-users' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/depot-users' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/depot-users"> Depot Users</a>
                </li> */}
              </ul>

              {/* kkkk */}
              {/* {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 27)[0] != null ? (<li
                data-toggle="collapse"
                data-target="#vehicalModule"
                onClick={() => this.viewFilterClick('vehicleMo1')}
                class="collapsed "

              >
                <a className="sub-menu-href" href="javascript: void(0);">
                  <i class="ti-control-record"></i>
                  <span>Vehicle V1.0</span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarVehicle ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </a>
              </li>) : ('')}
              <ul className={this.state.selectUrl == '/vehicles' || this.state.selectUrl == '/vehicle/master' ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="vehicalModule" style={{ paddingLeft: 25 }}>
                <li className={this.state.selectUrl == '/vehicles' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/vehicles' ? ('#1761fd') : ('') }} className="sub-menu-href" href='/vehicles'> Vehicle List</a>
                </li>
                <li className={this.state.selectUrl == '/vehicle/master' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                  <a style={{ color: this.state.selectUrl == '/vehicle/master' ? ('#1761fd') : ('') }} className="sub-menu-href" href='/vehicle/master'>Vehicle Master</a>
                </li>
              </ul> */}

              <li className={this.state.selectUrl == '/pg-view' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/pg-view">
                  <i style={{ color: this.state.selectUrl == '/pg-view' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/pg-view' ? ('#1761fd') : ('') }}>Update Removed Time in PG</span></a>
              </li>

              <li className={this.state.selectUrl == '/dashboard/target-values' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/dashboard/target-values">
                  <i style={{ color: this.state.selectUrl == '/dashboard/target-values' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/dashboard/target-values' ? ('#1761fd') : ('') }}>Dashboard Target Values</span></a>
              </li>
            </ul>


            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 14)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 15)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 16)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 21)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 20)[0] != null ? (<div>
              <li
                data-toggle="collapse"
                data-target="#exportsidebar"
                class="collapsed"
                onClick={() => this.viewFilterClick('dataEx1')}
              >
                <a href="javascript: void(0);">
                  {" "}
                  {/* <svg style={{ color: this.state.dataExSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link align-self-center menu-icon"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                  <span style={{fontWeight: 'bold'}}>Data Export </span> */}
                  <div style={{
                    display: 'flex', alignItems: 'center', borderRadius: '5px',
                    width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                  }}>
                    <ExportOutlined style={{ fontSize: '17px' }} />
                    <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                      Data Export
                    </span>

                    <span className="menu-arrow sid-menu-arrow">
                      <i className={this.state.sideBarDataEx ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                    </span>
                  </div>
                </a>
              </li>
              <ul className={this.state.dataExSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="exportsidebar" style={{ paddingLeft: 25 }} >
                {/* {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 14)[0] != null ? ( <li>
                  <a className="sub-menu-href" href="/report/full-export"> PG Data Export</a>
                </li>):('')}  */}


                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 14)[0] != null ? (
                  <li data-toggle="collapse"
                    data-target="#PG-export"
                    onClick={() => this.viewFilterClick('dataExPG1')}
                    class="collapsed ">
                    <a className="sub-menu-href" href="javascript: void(0);">
                      {" "}
                      <i class="ti-control-record"></i>
                      <span>PG</span>
                      <span className="menu-arrow sid-menu-arrow">
                        <i className={this.state.sideBarDataExPg ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                      </span>
                    </a>
                  </li>) : ("")}

                <ul className={this.state.selectUrl == '/report/full-export' ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="PG-export" style={{ paddingLeft: 25 }}>

                  <li className={this.state.selectUrl == '/report/full-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.dataExPGSB ? ('#1761fd') : ('') }} className="sub-menu-href" href="/report/full-export">Data Export</a>
                  </li>

                </ul>


                
                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 15)[0] != null ? (
                  <li data-toggle="collapse"
                    data-target="#Refuel-export"
                    onClick={() => this.viewFilterClick('dataExRe1')}
                    class="collapsed ">
                    <a className="sub-menu-href" href="javascript: void(0);">
                      {" "}

                      <i class="ti-control-record"></i>
                      <span>Refuel</span>
                      <span className="menu-arrow sid-menu-arrow">
                        <i className={this.state.sideBarDataExRefuel ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                      </span>
                    </a>
                  </li>) : ("")}

                <ul className={this.state.dataExRefuleSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="Refuel-export" style={{ paddingLeft: 25 }}>

                  <li className={this.state.selectUrl == '/refuel-full-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/refuel-full-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/refuel-full-export"> Fuel Filling  </a>
                  </li>
                  <li className={this.state.selectUrl == '/procurement-refuel-full-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/procurement-refuel-full-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/procurement-refuel-full-export"> Fuel Procurement</a>
                  </li>
                  <li className={this.state.selectUrl == '/fuel-balance-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/fuel-balance-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/fuel-balance-export"> Fuel Balance</a>
                  </li>
                </ul>

                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 16)[0] != null ? (<li data-toggle="collapse"
                  data-target="#Vehicle-export"
                  onClick={() => this.viewFilterClick('dataExVhe1')}
                  class="collapsed ">
                  <a className="sub-menu-href" href="javascript: void(0);">
                    {" "}
                    <i class="ti-control-record"></i>
                    <span>Vehicle V1.0</span>
                    <span className="menu-arrow sid-menu-arrow">
                      <i className={this.state.sideBarDataExVeh ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                    </span>
                  </a>
                </li>) : ("")}

                <ul className={this.state.dataExVheSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="Vehicle-export" style={{ paddingLeft: 25 }}>

                  <li className={this.state.selectUrl == '/vehicle-full-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/vehicle-full-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/vehicle-full-export">Data Export </a>
                  </li>
                  <li className={this.state.selectUrl == '/payment-full-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/payment-full-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/payment-full-export">Payment Export</a>
                  </li>

                </ul>

                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 21)[0] != null ? (<li data-toggle="collapse"
                  data-target="#site-access-export"
                  onClick={() => this.viewFilterClick('dataExSiteAc1')}

                  class="collapsed ">
                  <a className="sub-menu-href" href="javascript: void(0);">
                    {" "}
                    <i class="ti-control-record"></i>

                    <span>Site Access</span>
                    <span className="menu-arrow sid-menu-arrow">
                      <i className={this.state.sideBarDataExSiteAc ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                    </span>
                  </a>
                </li>) : ("")}

                <ul className={this.state.dataExSiteAcSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="site-access-export" style={{ paddingLeft: 25 }}>

                  <li className={this.state.selectUrl == '/site-access/full-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/site-access/full-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/site-access/full-export">Data Export</a>
                  </li>
                  <li className={this.state.selectUrl == '/site-access/full-export-team-member' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/site-access/full-export-team-member' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/site-access/full-export-team-member">Team Export</a>
                  </li>

                </ul>

                {/* {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id ==20)[0] != null ? (<li>
                      <a className="sub-menu-href" href="/power-db/power-data-export">Power Data Export</a>
                    </li>) : ("")} */}


                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 20)[0] != null ? (
                  <li data-toggle="collapse"
                    data-target="#PowerDb-export"
                    onClick={() => this.viewFilterClick('dataExPD1')}
                    class="collapsed ">
                    <a className="sub-menu-href" href="javascript: void(0);">
                      {" "}
                      <i class="ti-control-record"></i>

                      <span>Power DB</span>
                      <span className="menu-arrow sid-menu-arrow">
                        <i className={this.state.sideBarDataExPowerDb ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                      </span>
                    </a>
                  </li>) : ("")}
                <ul className={this.state.selectUrl == '/power-db/power-data-export' ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="PowerDb-export" style={{ paddingLeft: 25 }}>
                  <li className={this.state.selectUrl == '/power-db/power-data-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/power-db/power-data-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/power-db/power-data-export">Data Export</a>
                  </li>
                </ul>

                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 6)[0] != null ? (
                  <li data-toggle="collapse"
                    data-target="#image-export"
                    onClick={() => this.viewFilterClick('imageCap2')}
                    class="collapsed ">
                    <a className="sub-menu-href" href="javascript: void(0);">
                      {" "}
                      <i class="ti-control-record"></i>

                      <span>Image Capture</span>
                      <span className="menu-arrow sid-menu-arrow">
                        <i className={this.state.sideBarDataExImageCaptue ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                      </span>
                    </a>
                  </li>) : ("")}

                <ul className={this.state.selectUrl == '/image-capture/full-export' ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="image-export" style={{ paddingLeft: 25 }}>

                  <li className={this.state.selectUrl == '/image-capture/full-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/image-capture/full-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/image-capture/full-export">Data Export</a>
                  </li>

                </ul>
                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 12)[0] != null ? (
                  <li data-toggle="collapse"
                    data-target="#site-ruting"
                    onClick={() => this.viewFilterClick('SiteRouting2')}
                    class="collapsed ">
                    <a className="sub-menu-href" href="javascript: void(0);">
                      {" "}
                      <i class="ti-control-record"></i>

                      <span>Site Routine</span>
                      <span className="menu-arrow sid-menu-arrow">
                        <i className={this.state.sideBarDataExSiteRoutin ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                      </span>
                    </a>
                  </li>) : ("")}

                <ul className={this.state.selectUrl == '/routing/data-export' ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="site-ruting" style={{ paddingLeft: 25 }}>

                  <li className={this.state.selectUrl == '/routing/data-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/routing/data-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/routing/data-export">Data Export</a>
                  </li>

                </ul>
                {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 7)[0] != null ? (
                  <li data-toggle="collapse"
                    data-target="#pis"
                    onClick={() => this.viewFilterClick('pis1')}
                    class="collapsed ">
                    <a className="sub-menu-href" href="javascript: void(0);">
                      {" "}
                      <i class="ti-control-record"></i>

                      <span>PIS</span>
                      <span className="menu-arrow sid-menu-arrow">
                        <i className={this.state.sideBarDataExPISAC ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                      </span>
                    </a>
                  </li>) : ("")}

                <ul className={this.state.dataExSitePisSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="pis" style={{ paddingLeft: 25 }}>

                  <li className={this.state.selectUrl == '/pis/gen-service-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/pis/gen-service-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/pis/gen-service-export">Gen Service Data Export</a>
                  </li>

                  <li className={this.state.selectUrl == '/pis/ac-service-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/pis/ac-service-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/pis/ac-service-export">AC Service Data Export</a>
                  </li>

                  <li className={this.state.selectUrl == '/pis/civil-service-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/pis/civil-service-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/pis/civil-service-export">Civil Routine Data Export</a>
                  </li>

                  <li className={this.state.selectUrl == '/pis/sc-service-export' ? ('sub-sub-menu-href  active') : ('sub-sub-menu-href ')}>
                    <a style={{ color: this.state.selectUrl == '/pis/sc-service-export' ? ('#1761fd') : ('') }} className="sub-menu-href" href="/pis/sc-service-export">Site Cleaning Data Export</a>
                  </li>

                </ul>
              </ul>

            </div>) : ("")}
            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 13)[0] != null ? (<li
              data-toggle="collapse"
              data-target="#appsetting"
              onClick={() => this.viewFilterClick('appsetting1')}

            >
              <a href="javascript: void(0);">
                {" "}
                {/* <svg style={{ color: this.state.appSettingSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-grid align-self-center menu-icon"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg> */}
                {/* <svg style={{ color: this.state.appSettingSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings align-self-center menu-icon"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                <span style={{fontWeight: 'bold'}}>App Settings</span> */}
                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <SettingOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    App Settings
                  </span>

                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarAppSetting ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </div>

              </a>
            </li>) : ('')}

            <ul className={this.state.selectUrl == '/app-version' ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="appsetting" style={{ paddingLeft: 25 }}>
              <li className={this.state.selectUrl == '/app-version' ? (' active') : ('')}>
                <a className="sub-menu-href" href="/app-version">
                  <i style={{ color: this.state.selectUrl == '/app-version' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/app-version' ? ('#1761fd') : ('') }}> Version Update</span></a>
              </li>

            </ul>
            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 13)[0] != null ? (<li
              data-toggle="collapse"
              data-target="#reports"

              className={this.state.selectUrl == '/report' ? ('collapsed active') : ('collapsed')}
            >
              <a href="/report">
                {" "}
                {/* <svg style={{ color: this.state.reportSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file align-self-center menu-icon"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                <span style={{ color: this.state.selectUrl == '/report' ? ('#1761fd') : (''), fontWeight: 'bold', marginLeft: '5px' }}>Reports</span> */}
                 <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <FileTextOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    Reports
                  </span>
                </div>               
              </a>
            </li>) : ('')}
            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 25)[0] != null ? (<li
            // data-toggle="collapse"
            // data-target="#pg-update"

            // className={this.state.selectUrl == '/pg-view' ? ('collapsed active') : ('collapsed')}
            >
              {/* <a href="/pg-view"> */}
              {" "}
              {/* <svg style={{ color: this.state.pgUpdateSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock align-self-center menu-icon"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg> */}
              {/* <svg style={{ color: this.state.pgUpdateSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock align-self-center menu-icon"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg> */}
              {/* <span style={{ color: this.state.selectUrl == '/pg-view' ? ('#1761fd') : ('') }}>Update Removed Time in PG</span> */}

              {/* </a> */}
            </li>) : ('')}

            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 13)[0] != null ? (<li
              data-toggle="collapse"
              data-target="#scheduler"

              className={this.state.selectUrl == '/scheduler' ? ('collapsed active') : ('collapsed')}
            >
              <a href="/scheduler">
                {" "}
                {/* <svg style={{ color: this.state.reportSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file align-self-center menu-icon"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
                <span style={{ color: this.state.selectUrl == '/scheduler' ? ('#1761fd') : (''), marginLeft: '5px', fontWeight: 'bold' }}>Scheduler</span> */}
                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <ScheduleOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    Scheduler
                  </span>
                </div>
              </a>
            </li>) : ('')}

            <hr class="hr-dashed hr-menu"></hr>
            <li class="menu-label my-2"><b>Modules</b></li>
            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 4)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 5)[0] != null || this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 19)[0] != null ? (<li
              data-toggle="collapse"
              data-target="#site_access"
              onClick={() => this.viewFilterClick('siteAc1')}

              class="collapsed"
            >
              <a href="javascript: void(0);">
                {" "}
                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <KeyOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    Site Access v1.0
                  </span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarSiteAC ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </div>
              </a>
            </li>) : ("")}
            <ul className={this.state.siteAcSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="site_access" style={{ paddingLeft: 25 }}>
              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 4)[0] != null ? (
                <li className={this.state.selectUrl == '/site-access/list' ? ('active') : ('')}>
                  <a className="sub-menu-href" href="/site-access/list">
                    <i style={{ color: this.state.selectUrl == '/site-access/list' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                    <span style={{ color: this.state.selectUrl == '/site-access/list' ? ('#1761fd') : ('') }}> Site Access</span></a>
                </li>) : ("")}

              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 5)[0] != null ? (
                <li className={this.state.selectUrl == '/site-access/handler' ? ('active') : ('')}>
                  <a className="sub-menu-href" href="/site-access/handler">
                    <i style={{ color: this.state.selectUrl == '/site-access/handler' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                    <span style={{ color: this.state.selectUrl == '/site-access/handler' ? ('#1761fd') : ('') }}>Site Access Handler</span></a>
                </li>) : ("")}

              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 19)[0] != null ? (
                <li className={this.state.selectUrl == '/site-access/all-requests' ? ('active') : ('')}>
                  <a className="sub-menu-href" href="/site-access/all-requests">
                    <i style={{ color: this.state.selectUrl == '/site-access/all-requests' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                    <span style={{ color: this.state.selectUrl == '/site-access/all-requests' ? ('#1761fd') : ('') }}>Requests Overview</span></a>
                </li>) : ("")}
            </ul>

            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 29)[0] != null ? (<li data-toggle="collapse"
              data-target="#access"
              class="collapsed ">
              <a href="javascript: void(0);" aria-expanded="true">
                {" "}
                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <SafetyCertificateOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    Site Access V2.0
                  </span>
                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarVehicle ? ('mdi mdi-chevron-down sid-arrow-down') : 
                    ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </div>
              </a>
            </li>) : ('')}

            <ul className={this.state.siteAcSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="access" style={{ paddingLeft: 25 }}>
              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 4)[0] != null ? (
                <li className={this.state.selectUrl == '/access/dev/manage' ? ('active') : ('')}>
                  <a className="sub-menu-href" href="/access/dev/manage">
                    <i style={{ color: this.state.selectUrl == '/access/dev/manage' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                    <span style={{ color: this.state.selectUrl == '/access/dev/manage' ? ('#1761fd') : ('') }}> Site Access</span></a>
                </li>) : ("")}

              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 5)[0] != null ? (
                <li className={this.state.selectUrl == '/access/dev/approval' ? ('active') : ('')}>
                  <a className="sub-menu-href" href="/access/dev/approval">
                    <i style={{ color: this.state.selectUrl == '/access/dev/approval' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                    <span style={{ color: this.state.selectUrl == '/access/dev/approval' ? ('#1761fd') : ('') }}>Site Access Handler</span></a>
                </li>) : ("")}

            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 5)[0] != null ? (
                <li className={this.state.selectUrl == '/access/dev/blacklist' ? ('active') : ('')}>
                  <a className="sub-menu-href" href="/access/dev/blacklist">
                    <i style={{ color: this.state.selectUrl == '/access/dev/blacklist' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                    <span style={{ color: this.state.selectUrl == '/access/dev/history' ? ('#1761fd') : ('') }}>Blacklist Management</span></a>
                </li>) : ("")}

              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 5)[0] != null ? (
                <li className={this.state.selectUrl == '/access/dev/master' ? ('active') : ('')}>
                  <a className="sub-menu-href" href="/access/dev/master">
                    <i style={{ color: this.state.selectUrl == '/access/dev/master' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                    <span style={{ color: this.state.selectUrl == '/access/dev/master' ? ('#1761fd') : ('') }}>Master Data</span></a>
                </li>) : ("")}

              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 5)[0] != null ? (
                <li className={this.state.selectUrl == '/access/dev/reports' ? ('active') : ('')}>
                  <a className="sub-menu-href" href="/access/dev/reports">
                    <i style={{ color: this.state.selectUrl == '/access/dev/reports' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                    <span style={{ color: this.state.selectUrl == '/access/dev/reports' ? ('#1761fd') : ('') }}>Exports and Reports</span></a>
                </li>) : ("")}

              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 5)[0] != null ? (
                <li className={this.state.selectUrl == '/access/dev/overview' ? ('active') : ('')}>
                  <a className="sub-menu-href" href="/access/dev/overview">
                    <i style={{ color: this.state.selectUrl == '/access/dev/overview' ? ('#1761fd') : ('') }} class="ti-control-record">
                    
                    </i>
                    <span style={{ color: this.state.selectUrl == '/access/dev/overview' ? ('#1761fd') : ('') }}>Request Overview Handler</span></a>
                </li>) : ("")}
                
              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 5)[0] != null ? (
                <li className={this.state.selectUrl == '/access/dev/requester/overview' ? ('active') : ('')}>
                  <a className="sub-menu-href" href="/access/dev/requester/overview">
                    <i style={{ color: this.state.selectUrl == '/access/dev/requester/overview' ? ('#1761fd') : ('') }} class="ti-control-record">
                    
                    </i>
                    <span style={{ color: this.state.selectUrl == '/access/dev/requester/overview' ? ('#1761fd') : ('') }}>Request Overview Requester</span></a>
                </li>) : ("")}

            </ul>

            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 7)[0] != null ? (<li
              data-toggle="collapse"
              data-target="#pis_history_view"
              onClick={() => this.viewFilterClick('pisChecklistMa')}

              class="collapsed"
            >
              <a href="javascript: void(0);">
                {" "}
                {/* <svg style={{ color: this.state.pisCheckListSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up-circle align-self-center menu-icon"><circle cx="12" cy="12" r="10"></circle><polyline points="16 12 12 8 8 12"></polyline><line x1="12" y1="16" x2="12" y2="8"></line></svg>
                <span>PIS</span> */}

                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <PoweroffOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    PIS
                  </span>

                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarPisCheclist ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </div>
              </a>
            </li>) : ("")}
            <ul className={this.state.pisCheckListSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="pis_history_view" style={{ paddingLeft: 25 }}>
              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 7)[0] != null ? (
                <li className={this.state.selectUrl == '/pis-history-view' ? ('active') : ('')}>
                  <a className="sub-menu-href" href="/pis-history-view">
                    <i style={{ color: this.state.selectUrl == '/pis-history-view' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                    <span style={{ color: this.state.selectUrl == '/pis-history-view' ? ('#1761fd') : ('') }}>Data View</span></a>
                </li>) : ("")}

              {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 7)[0] != null ? (
                <li className={this.state.selectUrl == '/pis/cheklist-export' ? ('active') : ('')}>
                  <a className="sub-menu-href" href="/pis/cheklist-export">
                    <i style={{ color: this.state.selectUrl == '/pis/cheklist-export' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                    <span style={{ color: this.state.selectUrl == '/pis/cheklist-export' ? ('#1761fd') : ('') }}> Cheklists Export</span></a>
                </li>) : ("")}

            </ul>




            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 12)[0] != null ? (<li class="collapsed ">
              <a href="javascript: void(0);" aria-expanded="true" href="/routine-history-view">
                {" "}
                {/* <svg style={{ color: this.state.imageCapSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hard-drive align-self-center menu-icon"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg> */}
                {/* <svg style={{ color: this.state.routineSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="feather feather-hard-drive align-self-center menu-icon"><line x1="22" y1="12" x2="2" y2="12"></line><path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path><line x1="6" y1="16" x2="6.01" y2="16"></line><line x1="10" y1="16" x2="10.01" y2="16"></line></svg> */}
                {/* <svg style={{ color: this.state.routineSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-aperture align-self-center menu-icon"><circle cx="12" cy="12" r="10"></circle><line x1="14.31" y1="8" x2="20.05" y2="17.94"></line><line x1="9.69" y1="8" x2="21.17" y2="8"></line><line x1="7.38" y1="12" x2="13.12" y2="2.06"></line><line x1="9.69" y1="16" x2="3.95" y2="6.06"></line><line x1="14.31" y1="16" x2="2.83" y2="16"></line><line x1="16.62" y1="12" x2="10.88" y2="21.94"></line></svg>
                <span style={{ color: this.state.routineSB ? ('#1761fd') : ('') }}>Site Routine</span> */}
                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <SyncOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    Site Routine
                  </span>
                </div>
              </a>
            </li>) : ('')}



            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 6)[0] != null ? (<li data-toggle="collapse"
              data-target="#imagecaptuer"
              onClick={() => this.viewFilterClick('imageCap1')}

              class="collapsed ">
              <a href="javascript: void(0);" aria-expanded="true">
                {" "}

                {/* <svg style={{ color: this.state.imageCapSB ? ('#1761fd') : ('') }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image align-self-center menu-icon"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
                <span>Image Capture</span> */}

                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <PictureOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    Image Capture
                  </span>

                  <span className="menu-arrow sid-menu-arrow">
                    <i className={this.state.sideBarImageCap ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                  </span>
                </div>

              </a>
            </li>) : ('')}

            <ul className={this.state.imageCapSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="imagecaptuer" style={{ paddingLeft: 25 }}>

              <li className={this.state.selectUrl == '/image-capture/list' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/image-capture/list">
                  <i style={{ color: this.state.selectUrl == '/image-capture/list' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/image-capture/list' ? ('#1761fd') : ('') }}>Requests</span></a>
              </li>

            </ul>

            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 28)[0] != null ? (<li data-toggle="collapse"
              data-target="#PowerDB"
              onClick={() => this.viewFilterClick('PowerDB2')}

              class="collapsed ">
              <a href="javascript: void(0);" aria-expanded="true">
                {" "}
                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <DatabaseOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    Power DB
                  </span>
                  <span className="menu-arrow sid-menu-arrow">
                  <i className={this.state.sideBarPowerDb ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                </span>
                </div>
              </a>
            </li>) : ('')}

            <ul className={this.state.powerDbSB ? ('sub-menu collapse  show') : ('sub-menu collapse ')} id="PowerDB" style={{ paddingLeft: 25 }}>

              <li className={this.state.selectUrl == '/power-db/dashboard' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/power-db/dashboard">
                  <i style={{ color: this.state.selectUrl == '/power-db/dashboard' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/power-db/dashboard' ? ('#1761fd') : ('') }}>Dashboad</span></a>
              </li>
              <li className={this.state.selectUrl == '/power-db/upload-powerdb' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/power-db/upload-powerdb">
                  <i style={{ color: this.state.selectUrl == '/power-db/upload-powerdb' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/power-db/upload-powerdb' ? ('#1761fd') : ('') }}>PowerDB Data Import</span></a>
              </li>

              {/* <li className={this.state.selectUrl == '/power-db/power-data-view' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/power-db/power-data-view">
                  <i style={{ color: this.state.selectUrl == '/power-db/power-data-view' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/power-db/power-data-view' ? ('#1761fd') : ('') }}>Data View</span></a>
              </li> */}

              <li className={this.state.selectUrl == '/power-db/power-data-manage' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/power-db/power-data-manage">
                  <i style={{ color: this.state.selectUrl == '/power-db/power-data-manage' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/power-db/power-data-manage' ? ('#1761fd') : ('') }}>Data Manage</span></a>
              </li>

              <li className={this.state.selectUrl == '/power-db/query' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/power-db/query">
                  <i style={{ color: this.state.selectUrl == '/power-db/query' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/power-db/query' ? ('#1761fd') : ('') }}>Power Data</span></a>
              </li>

            </ul>

            {this.state.serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == 39)[0] != null ? (<li data-toggle="collapse"
              data-target="#rewamp"
              onClick={() => this.viewFilterClick('PowerDB2')}

              class="collapsed ">
              <a href="javascript: void(0);" aria-expanded="true">
                {" "}
                <div style={{
                  display: 'flex', alignItems: 'center', borderRadius: '5px',
                  width: '100%', padding: '8px', borderShadow: '0px 0px 5px 0px #0000001a', height: '40px'
                }}>
                  <CarOutlined style={{ fontSize: '17px' }} />
                  <span style={{ fontSize: 'small', fontWeight: 'bold', marginLeft: '5px' }}>
                    Vehicle V2.0
                  </span>
                  <span className="menu-arrow sid-menu-arrow">
                  <i className={this.state.sideBarPowerDb ? ('mdi mdi-chevron-down sid-arrow-down') : ('mdi mdi-chevron-right sid-arrow-right')}></i>
                </span>
                </div>
              </a>
            </li>) : ('')}

            <ul className={this.state.powerDbSB ?
              ('sub-menu collapse  show') : ('sub-menu collapse ')} id="rewamp" style={{ paddingLeft: 25 }}>
              {/* <li className={this.state.selectUrl == '/vehicle/new/dashboard' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/vehicle/new/dashboard">
                  <i style={{ color: this.state.selectUrl == '/vehicle/new/dashboard' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/vehicle/new/dashboard' ? ('#1761fd') : ('') }}>Dashboard</span></a>
              </li> */}
              <li className={this.state.selectUrl == '/vehicle/sessions' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/vehicle/sessions">
                  <i style={{ color: this.state.selectUrl == '/vehicle/sessions' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/vehicle/sessions' ? ('#1761fd') : ('') }}>Sessions Management</span></a>
              </li>
              <li className={this.state.selectUrl == '/vehicle/management' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/vehicle/management">
                  <i style={{ color: this.state.selectUrl == '/vehicle/management' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/vehicle/management' ? ('#1761fd') : ('') }}>Vehicle Management</span></a>
              </li>
              {/* // /vehicle/master */}
              <li className={this.state.selectUrl == '/vehicle/new/master' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/vehicle/new/master">
                  <i style={{ color: this.state.selectUrl == '/vehicle/new/master' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/vehicle/new/master' ? ('#1761fd') : ('') }}>Vehicle Master</span></a>
              </li>
              {/* /vehicle/new/export */}
              <li className={this.state.selectUrl == '/vehicle/new/export' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/vehicle/new/export">
                  <i style={{ color: this.state.selectUrl == '/vehicle/new/export' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/vehicle/new/export' ? ('#1761fd') : ('') }}>Export and Reports</span></a>
              </li>
              {/* /vehicle/new/vendor-pool */}
              {/* <li className={this.state.selectUrl == '/vehicle/new/vendor-pool' ? ('active') : ('')}>
              <a className="sub-menu-href" href="/vehicle/new/vendor-pool">
                <i style={{ color: this.state.selectUrl == '/vehicle/new/vendor-pool' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                <span style={{ color: this.state.selectUrl == '/vehicle/new/vendor-pool' ? ('#1761fd') : ('') }}>Vendor Pool</span></a>
            </li> */}
              {/* /vehicle/new/config */}
              <li className={this.state.selectUrl == '/vehicle/new/config' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/vehicle/new/config">
                  <i style={{ color: this.state.selectUrl == '/vehicle/new/config' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/vehicle/new/config' ? ('#1761fd') : ('') }}>Master Data</span></a>
              </li>
              {/* /access/dev/request */}
              {/* <li className={this.state.selectUrl == '/access/dev/request' ? ('active') : ('')}>
                <a className="sub-menu-href" href="/access/dev/request">
                  <i style={{ color: this.state.selectUrl == '/access/dev/request' ? ('#1761fd') : ('') }} class="ti-control-record"></i>
                  <span style={{ color: this.state.selectUrl == '/access/dev/request' ? ('#1761fd') : ('') }}>Access Request</span></a>
              </li> */}
            </ul>
          </ul>
          
        </div >) : ('')
        }

        <script src="./feather.min.js"></script>

      </div >



    );
  }
}

export default SideBar;

