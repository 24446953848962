import React, { Component } from 'react';
import { Select, DatePicker, Table, Input, Progress, Button } from 'antd';
import ReactExport from "react-data-export";
import {
    getPowerData, getRectifierMake, getGeneratorModel, getBatteryBankMake, getPowerRectifierDetails, getPowerBatteryBankDetails,
    getPowerCabinetDetails, getAirconditionersData, getPowerSpdsDetails
    , getPowerSharedOperatorDetails, getPowerFcbDetails, getPowerDcduDetails, getAtsData, getGeneratorsData, getMasterDataSiteInfo, getSitesLite, powerDataFileValidationSpecial
} from "../../api/index";
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import moment from 'moment';
import { FacebookFilled } from '@ant-design/icons';
import depot from '../depot';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;
const { Option } = Select;
class PowerDataExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            filterTable: null,
            data_list: [],
            startDate: "",
            endDate: "",
            loading: false,
            depot_list: [],
            data_rectifier_list: [],
            data_PowerBatteryBankDetails_list: [],
            data_PowerCabinetDetail_list: [],
            loadingPowerBatteryBankDetails: false,
            loadingPowerCabinetDetail: false,
            loadingAirconditionersData: false,
            data_AirconditionersData_list: [],
            loadingGeneratorsData: false,
            data_GeneratorsData_list: [],
            loadingPowerDcduDetailsData: false,
            data_PowerDcduDetails_list: [],
            loadingAtsData: false,
            data_AtsData_list: [],
            loadingFcbDetails: false,
            data_FcbDetails_list: [],
            loadingSpdsDetails: false,
            data_SpdsDetails_list: [],
            loadingSharedOperatorDetails: false,
            data_SharedOperatorDetails_list: [],
            filterDepot: [],
            selectType: 0,
            selectId: 0,
            allApiLoading: false,
            allSite: [],
            allSiteForFilter: [],
            allSite_loading: true,
            allRectifierMake: [],
            allRectifierMake_loading: false,
            allBatteryBankMake: [],
            allBatteryBankMake_loading: false,
            allGeneratorModel: [],
            allGeneratorModel_loading: false,
            selectRegion: '',
            selectDepot: '',
            selectSite: '',
            selectRectifierMake: '',
            selectBattertMake: '',
            selectGeneratorMake: '',
            selectPowerConnectiontype: '',
            slectIndoorOutdoor: '',
            fullPageCount: 0,
            loopPageCount: 0,
            progressPrasantage: 0,
            recodesPerPage: 250,
            fullDataList: [],
            rectifiersList: [],
            rectifierloading: false,
            batteryBanksloading: false,
            batteryBanksList: [],
            airConditionersList: [],
            airConditionersloading: false,
            spdsList: [],
            spdsloading: false,
            cabinetsList: [],
            cabinetsloading: false,
            generatorsList: [],
            generatorsloading: false,
            atsesList: [],
            atsesloading: false,
            dcdusList: [],
            dcdusloading: false,
            fcbsList: [],
            fcbsloading: false,
            sharedOutOperatorsesList: [],
            idBatteryRacksList: [],
            idBatteryRacksloading: false,
            renewableEnergySolarsList: [],
            renewableEnergySolarsloading: false,
            RenewableEnergyWindsList: [],
            RenewableEnergyWindsloading: false,
            sharedOutOperatorsesloading: false,
            excelLoading: false,
            progressBarLoading: false,
            tabaleLoading: false,
            powerConnectionType: []



        };


    }

    fullExport = async (data) => {
        if (data == 1) {
            document.getElementById("downloadButtonId").click()
        }



    }
    getDataGeneratorsData = async (data, x) => {
        //console.log("getGeneratorsData1");

        var res = await getGeneratorsData(data);
        //console.log("getGeneratorsData2");
        //console.log(res);
        if (res.success) {
            this.setState({
                loadingGeneratorsData: false,
                data_GeneratorsData_list: res.data,
            }, () => {
                if (this.state.loading == false && this.state.loadingRectifier == false && this.state.loadingPowerBatteryBankDetails == false && this.state.loadingPowerCabinetDetail == false && this.state.loadingAirconditionersData == false && this.state.loadingSpdsDetails == false && this.state.loadingSharedOperatorDetails == false && this.state.loadingFcbDetails == false && this.state.loadingAtsData == false && this.state.loadingGeneratorsData == false && this.state.loadingPowerDcduDetailsData == false) {
                    //console.log("allOk")
                    this.fullExport(x);
                    this.setState({
                        allApiLoading: true
                    })

                }
            });
        }
    };
    getDataAtsData = async (data, x) => {
        //console.log("getAtsData1");

        var res = await getAtsData(data);
        //console.log("getAtsData2");
        //console.log(res);
        if (res.success) {
            this.setState({
                loadingAtsData: false,

                data_AtsData_list: res.data,
            }, () => {
                if (this.state.loading == false && this.state.loadingRectifier == false && this.state.loadingPowerBatteryBankDetails == false && this.state.loadingPowerCabinetDetail == false && this.state.loadingAirconditionersData == false && this.state.loadingSpdsDetails == false && this.state.loadingSharedOperatorDetails == false && this.state.loadingFcbDetails == false && this.state.loadingAtsData == false && this.state.loadingGeneratorsData == false && this.state.loadingPowerDcduDetailsData == false) {
                    //console.log("allOk")
                    this.fullExport(x);

                    this.setState({
                        allApiLoading: true
                    })

                }
            });
        }
    };
    getDataPowerDcduDetails = async (data, x) => {
        //console.log("getPowerDcduDetails1");

        var res = await getPowerDcduDetails(data);
        //console.log("getPowerDcduDetails2");
        //console.log(res);
        if (res.success) {
            this.setState({
                loadingPowerDcduDetailsData: false,
                data_PowerDcduDetails_list: res.data,

            }, () => {
                if (this.state.loading == false && this.state.loadingRectifier == false && this.state.loadingPowerBatteryBankDetails == false && this.state.loadingPowerCabinetDetail == false && this.state.loadingAirconditionersData == false && this.state.loadingSpdsDetails == false && this.state.loadingSharedOperatorDetails == false && this.state.loadingFcbDetails == false && this.state.loadingAtsData == false && this.state.loadingGeneratorsData == false && this.state.loadingPowerDcduDetailsData == false) {
                    //console.log("allOk")
                    this.fullExport(x);

                    this.setState({
                        allApiLoading: true
                    })

                }
            });
        }
    };
    getDataPowerFcbDetails = async (data, x) => {
        //console.log("getPowerFcbDetails1");

        var res = await getPowerFcbDetails(data);
        //console.log("getPowerFcbDetails2");
        //console.log(res);
        if (res.success) {
            this.setState({
                loadingFcbDetails: false,

                data_FcbDetails_list: res.data,
            }, () => {
                if (this.state.loading == false && this.state.loadingRectifier == false && this.state.loadingPowerBatteryBankDetails == false && this.state.loadingPowerCabinetDetail == false && this.state.loadingAirconditionersData == false && this.state.loadingSpdsDetails == false && this.state.loadingSharedOperatorDetails == false && this.state.loadingFcbDetails == false && this.state.loadingAtsData == false && this.state.loadingGeneratorsData == false && this.state.loadingPowerDcduDetailsData == false) {
                    //console.log("allOk")
                    this.fullExport(x);

                    this.setState({
                        allApiLoading: true
                    })

                }
            });
        }
    };
    getDataPowerSharedOperatorDetails = async (data, x) => {
        //console.log("getPowerSharedOperatorDetails1");

        var res = await getPowerSharedOperatorDetails(data);
        //console.log("getPowerSharedOperatorDetails2");
        //console.log(res);
        if (res.success) {
            this.setState({
                loadingSharedOperatorDetails: false,
                data_SharedOperatorDetails_list: res.data,
            }, () => {
                if (this.state.loading == false && this.state.loadingRectifier == false && this.state.loadingPowerBatteryBankDetails == false && this.state.loadingPowerCabinetDetail == false && this.state.loadingAirconditionersData == false && this.state.loadingSpdsDetails == false && this.state.loadingSharedOperatorDetails == false && this.state.loadingFcbDetails == false && this.state.loadingAtsData == false && this.state.loadingGeneratorsData == false && this.state.loadingPowerDcduDetailsData == false) {
                    //console.log("allOk")
                    this.fullExport(x);

                    this.setState({
                        allApiLoading: true
                    })

                }
            });
        }
    };
    getDataPowerSpdsDetails = async (data, x) => {
        //console.log("getPowerSpdsDetails1");

        var res = await getPowerSpdsDetails(data);
        //console.log("getPowerSpdsDetails2");
        //console.log(res);
        if (res.success) {
            this.setState({
                loadingSpdsDetails: false,
                data_SpdsDetails_list: res.data,
            }, () => {
                if (this.state.loading == false && this.state.loadingRectifier == false && this.state.loadingPowerBatteryBankDetails == false && this.state.loadingPowerCabinetDetail == false && this.state.loadingAirconditionersData == false && this.state.loadingSpdsDetails == false && this.state.loadingSharedOperatorDetails == false && this.state.loadingFcbDetails == false && this.state.loadingAtsData == false && this.state.loadingGeneratorsData == false && this.state.loadingPowerDcduDetailsData == false) {
                    //console.log("allOk")

                    this.fullExport(x);

                    this.setState({
                        allApiLoading: true
                    })

                }
            });
        }
    };
    getDataAirconditionersData = async (data, x) => {
        //console.log("getAirconditionersData1");

        var res = await getAirconditionersData(data);
        //console.log("getAirconditionersData2");
        //console.log(res);
        if (res.success) {
            this.setState({
                loadingAirconditionersData: false,

                data_AirconditionersData_list: res.data,
            }, () => {
                if (this.state.loading == false && this.state.loadingRectifier == false && this.state.loadingPowerBatteryBankDetails == false && this.state.loadingPowerCabinetDetail == false && this.state.loadingAirconditionersData == false && this.state.loadingSpdsDetails == false && this.state.loadingSharedOperatorDetails == false && this.state.loadingFcbDetails == false && this.state.loadingAtsData == false && this.state.loadingGeneratorsData == false && this.state.loadingPowerDcduDetailsData == false) {
                    //console.log("allOk")
                    this.fullExport(x);

                    this.setState({
                        allApiLoading: true
                    })

                }
            });
        }
    };
    getDataPowerCabinetDetails = async (data, x) => {
        //console.log("getPowerCabinetDetails1");

        var res = await getPowerCabinetDetails(data);
        //console.log("getPowerCabinetDetails2");
        //console.log(res);
        if (res.success) {
            this.setState({
                loadingPowerCabinetDetail: false,

                data_PowerCabinetDetail_list: res.data,
            }, () => {
                if (this.state.loading == false && this.state.loadingRectifier == false && this.state.loadingPowerBatteryBankDetails == false && this.state.loadingPowerCabinetDetail == false && this.state.loadingAirconditionersData == false && this.state.loadingSpdsDetails == false && this.state.loadingSharedOperatorDetails == false && this.state.loadingFcbDetails == false && this.state.loadingAtsData == false && this.state.loadingGeneratorsData == false && this.state.loadingPowerDcduDetailsData == false) {
                    //console.log("allOk")
                    this.fullExport(x);

                    this.setState({
                        allApiLoading: true
                    })

                }
            });
        }
    };
    getDataPowerBatteryBankDetails = async (data, x) => {
        //console.log("getPowerBatteryBankDetails1");

        var res = await getPowerBatteryBankDetails(data);
        //console.log("getPowerBatteryBankDetails2");
        //console.log(res);
        if (res.success) {
            this.setState({
                loadingPowerBatteryBankDetails: false,

                data_PowerBatteryBankDetails_list: res.data,
            }, () => {
                if (this.state.loading == false && this.state.loadingRectifier == false && this.state.loadingPowerBatteryBankDetails == false && this.state.loadingPowerCabinetDetail == false && this.state.loadingAirconditionersData == false && this.state.loadingSpdsDetails == false && this.state.loadingSharedOperatorDetails == false && this.state.loadingFcbDetails == false && this.state.loadingAtsData == false && this.state.loadingGeneratorsData == false && this.state.loadingPowerDcduDetailsData == false) {
                    //console.log("allOk")
                    this.fullExport(x);

                    this.setState({
                        allApiLoading: true
                    })

                }
            });
        }
    };

    getdatagetPowerRectifierDetails = async (data, x) => {
        //console.log("getPowerRectifierDetails1");

        var res = await getPowerRectifierDetails(data);
        //console.log("getPowerRectifierDetails2");
        //console.log(res);
        if (res.success) {

            this.setState({
                loadingRectifier: false,


                data_rectifier_list: res.data,
            }, () => {
                if (this.state.loading == false && this.state.loadingRectifier == false && this.state.loadingPowerBatteryBankDetails == false && this.state.loadingPowerCabinetDetail == false && this.state.loadingAirconditionersData == false && this.state.loadingSpdsDetails == false && this.state.loadingSharedOperatorDetails == false && this.state.loadingFcbDetails == false && this.state.loadingAtsData == false && this.state.loadingGeneratorsData == false && this.state.loadingPowerDcduDetailsData == false) {
                    //console.log("allOk")
                    this.fullExport(x);

                    this.setState({
                        allApiLoading: true
                    })

                }
            });
        }
    };

    getDataexpot = async (data, x) => {

        var pageCount = 0;
        console.log("ssssssssssss");
        console.log(data);
        var res = await getPowerData(data);
        console.log("getPowerData2");
        console.log(res);
        pageCount = res.data?.pageCount;
        this.state.fullPageCount = res.data?.pageCount;
        //console.log(pageCount);
        if (this.state.fullPageCount == 0) {

            this.setState({
                loading: false,
                tabaleLoading: false,
                data_list: []
            })
        }
        if (this.state.fullPageCount == 1) {
            this.setState({

                data_list: res.data.data,
                loading: false,
                tabaleLoading: false,
                fullDataList: res.data.data,

            }, () => {
                this.creatrectiferArray(this.state.fullDataList);
                this.creatBattaryBankArray(this.state.fullDataList);
                this.creatairConditionersArray(this.state.fullDataList);
                this.creatairspdsArray(this.state.fullDataList);
                this.creataircabinetsArray(this.state.fullDataList);
                this.creatairGeneratorArray(this.state.fullDataList);
                this.creatairAtsesArray(this.state.fullDataList);
                this.creatdcdusArray(this.state.fullDataList);
                this.creatfcbscArray(this.state.fullDataList);
                this.creatsharedOutOperatorsescArray(this.state.fullDataList);
                this.createIdBatteryRacks(this.state.fullDataList);
                this.createRenewableEnergySolars(this.state.fullDataList);
                this.createRenewableEnergyWinds(this.state.fullDataList);
            });

        }
        if (this.state.fullPageCount > 1) {
            this.setState({
                progressBarLoading: true,
            })

            this.getDataexpot2()


        }
    };
    getDataexpot2 = async () => {

        console.log(this.state.loopPageCount)
        var form = new FormData();
        form.append("pageNo", this.state.loopPageCount);
        form.append("recodesPerPage", this.state.recodesPerPage);
        form.append("siteIds", this.state.selectSite);
        form.append("depotIds", this.state.selectDepot);
        form.append("regionIds", this.state.selectRegion);
        form.append("powerConectionType", this.state.selectPowerConnectiontype);


        form.append("rectifireMakeIds", this.state.selectRectifierMake);
        form.append("batteryBankMakeIds", this.state.selectBattertMake);
        form.append("generatorMakeIds", this.state.selectGeneratorMake);
        form.append("indoorOutdoor", this.state.slectIndoorOutdoor);
        var res = await getPowerData(form);
        console.log(res)
        this.setState({
            data_list: [...this.state.data_list, ...res.data.data],
            tabaleLoading: false,
            progressPrasantage: (this.state.loopPageCount / this.state.fullPageCount) * 100,

        }, () => {

            if (this.state.fullPageCount - 1 == this.state.loopPageCount) {


                this.setState({
                    loading: false,
                    tabaleLoading: false,
                    progressPrasantage: 100,
                    fullDataList: this.state.data_list


                }, () => {
                    this.creatrectiferArray(this.state.fullDataList);
                    this.creatBattaryBankArray(this.state.fullDataList);
                    this.creatairConditionersArray(this.state.fullDataList);
                    this.creatairspdsArray(this.state.fullDataList);
                    this.creataircabinetsArray(this.state.fullDataList);
                    this.creatairGeneratorArray(this.state.fullDataList);
                    this.creatairAtsesArray(this.state.fullDataList);
                    this.creatdcdusArray(this.state.fullDataList);
                    this.creatfcbscArray(this.state.fullDataList);
                    this.creatsharedOutOperatorsescArray(this.state.fullDataList);
                    this.createIdBatteryRacks(this.state.fullDataList);
                    this.createRenewableEnergySolars(this.state.fullDataList);
                    this.createRenewableEnergyWinds(this.state.fullDataList);

                })


            } else {


                this.setState({
                    loopPageCount: ++this.state.loopPageCount,
                }, () => {
                    console.log('ff')

                    this.getDataexpot2();
                })
            }

        })







    }
    createRenewableEnergyWinds(data) {
        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].renewableEnergyWinds.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log('renewableEnergyWinds_1');
        console.log(alldata);
        //   //console.log(alldata);
        this.setState({
            RenewableEnergyWindsList: alldata,
            RenewableEnergyWindsloading: false,

        })

    }
    createRenewableEnergySolars(data) {
        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].renewableEnergySolars.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log('renewableEnergySolars_1');
        console.log(alldata);
        //   //console.log(alldata);
        this.setState({
            renewableEnergySolarsList: alldata,
            renewableEnergySolarsloading: false,

        })

    }
    createIdBatteryRacks(data) {
        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].idBatteryRacks.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log('idBatteryRacks');
        console.log(alldata);
        //   //console.log(alldata);
        this.setState({
            idBatteryRacksList: alldata,
            idBatteryRacksloading: false,

        })

    }
    creatsharedOutOperatorsescArray(data) {
        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].sharedOutOperatorses.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log('sharedOutOperatorses');
        console.log(alldata);
        //   //console.log(alldata);
        this.setState({
            sharedOutOperatorsesList: alldata,
            sharedOutOperatorsesloading: false,

        })

    }
    creatfcbscArray(data) {


        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].fcbs.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log('fcbs');
        console.log(alldata);
        //   //console.log(alldata);
        this.setState({
            fcbsList: alldata,
            fcbsloading: false,

        })

    }
    creatdcdusArray(data) {


        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].dcdus.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log('dcdus');
        console.log(alldata);
        //   //console.log(alldata);
        this.setState({
            dcdusList: alldata,
            dcdusloading: false,

        })

    }
    creatairAtsesArray(data) {


        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].atses.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log('atses');
        console.log(alldata);
        //   //console.log(alldata);
        this.setState({
            atsesList: alldata,
            atsesloading: false,

        })

    }
    creatairGeneratorArray(data) {


        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].generators.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log('gerators');
        console.log(alldata);
        //   //console.log(alldata);
        this.setState({
            generatorsList: alldata,
            generatorsloading: false,

        })

    }
    creataircabinetsArray(data) {


        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].cabinets.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log('cabinets');
        console.log(alldata);
        //   //console.log(alldata);
        this.setState({
            cabinetsList: alldata,
            cabinetsloading: false,

        })

    }
    creatairspdsArray(data) {


        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].spds.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log('spds');
        console.log(alldata);
        //   //console.log(alldata);
        this.setState({
            spdsList: alldata,
            spdsloading: false,

        })

    }
    creatairConditionersArray(data) {


        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].airConditioners.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log(alldata);
        //   //console.log(alldata);
        this.setState({
            airConditionersList: alldata,
            airConditionersloading: false,

        })

    }
    creatBattaryBankArray(data) {
        console.log('che1');
        console.log(data);

        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].batteryBanks.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log(alldata);

        this.setState({
            batteryBanksList: alldata,
            batteryBanksloading: false,

        })

    }
    creatrectiferArray(data) {


        const arrayResult = Object.keys(data).map(room => {
            return {
                data: data[room].rectifiers.map(e => ({
                    ...e, Id: data[room].id, siteId: data[room].siteId, siteName: data[room].siteName
                }))
            }
        });
        var alldata = arrayResult.reduce((perv, cur) => [...perv, ...cur.data], [])
        console.log(alldata);
        //   //console.log(alldata);
        this.setState({
            rectifiersList: alldata,
            rectifierloading: false,

        })

    }
    onChangepowerConnectionType = (value) => {
        console.log(value);
        if (value == undefined) {
            this.setState({
                selectPowerConnectiontype: ''
            });
        } else {
            this.setState({
                selectPowerConnectiontype: value
            });
        }




    }
    onChangeIndoorOutdoor = (value) => {
        console.log(value);
        if (value == undefined) {
            this.setState({
                slectIndoorOutdoor: ''
            });
        } else {
            this.setState({
                slectIndoorOutdoor: value
            });
        }




    }

    onChangeGenerator = (value) => {
        //console.log("onChangeDepot");
        console.log(value);


        this.setState({
            selectGeneratorMake: value
        });
    };
    onChangeBatteryMake = (value) => {
        //console.log("onChangeDepot");
        console.log(value);


        this.setState({
            selectBattertMake: value
        });
    };
    onChangeRectifier = (value) => {
        //console.log("onChangeDepot");
        console.log(value);


        this.setState({
            selectRectifierMake: value
        });
    };
    onChangeSite = (value) => {
        //console.log("onChangeDepot");
        console.log(value);


        this.setState({
            selectSite: value
        });
    };
    onChangeDepot = (value) => {
        //console.log("onChangeDepot");
        console.log(value);
        let dd = value;
        var _allSite = [];


        this.setState({
            allSite_loading: true,
        })
        this.state.allSiteForFilter.filter(alldate2 => dd.map((n) => n).includes(alldate2.depot_id)).map((sites) => {
            _allSite.push({ value: sites.id, label: sites.site_name, siteID: sites.site_id, depotId: sites.depot_id, subRegionId: sites.sub_region_id })
        })

        console.log('_allSite')
        console.log(_allSite)
        this.setState({
            allSite: _allSite,
            allSite_loading: false,
        })


        // console.log(dataa[value - 1].name);

        this.setState({

            // DepotName: dataa[value - 1]?.name,
            filterDepot: value,
            selectType: 2,
            selectId: value,
            selectDepot: value
        });
    };
    onChangeRegion = (value) => {


        var dd = value
        //console.log(dd)
        var subRegion = [];
        var _depotList1 = [];
        var _allSite = [];
        var subRegionIdArray = [];

        this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)).map((subR) => {
            subRegionIdArray.push(subR.id)
        })


        this.setState({
            selectRegion: subRegionIdArray
        }, () => {

        });

        //console.log(this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)));
        subRegion = this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id));
        //console.log(this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)));
        this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)).map((depot) => {
            _depotList1.push({ id: depot?.id, name: depot?.name })
        })
        console.log('selectDepot')
        console.log(this.state.selectDepot)

        if (this.state.selectDepot?.length > 0 || this.state.selectDepot == '') {
            this.setState({
                allSite_loading: true,
            })
            this.state.allSiteForFilter.filter(alldate2 => dd.map((n) => n).includes(alldate2.patent_region_id)).map((sites) => {
                _allSite.push({ value: sites.id, label: sites.site_name, siteID: sites.site_id, depotId: sites.depot_id, subRegionId: sites.sub_region_id })
            })

            console.log('_allSite')
            console.log(_allSite)
            this.setState({
                allSite: _allSite,
                allSite_loading: false,
            })
        }


        this.setState({
            depot_list: _depotList1,


        })


    };
    clearBtnClick = () => {
        window.location.reload()
    }
    fullExpoortDownload = () => {
        console.log("searchdd5555555555555555");
        this.setState({
            progressPrasantage: 0,
            data_list: [],
            fullPageCount: 0,
            loopPageCount: 0,
            excelLoading: false,
            fullDataList: [],

        })
        var form = new FormData();
        form.append("pageNo", 0);
        form.append("recodesPerPage", this.state.recodesPerPage);
        form.append("siteIds", '');
        form.append("depotIds", '');
        form.append("regionIds", '');
        form.append("powerConectionType", '');
        form.append("rectifireMakeIds", '');
        form.append("batteryBankMakeIds", '');
        form.append("generatorMakeIds", '');
        form.append("indoorOutdoor", '');
        this.setState({
            allApiLoading: true,
            loading: true,
            tabaleLoading: true,
            rectifierloading: true,
            loadingRectifier: true,
            batteryBanksloading: true,

            airConditionersloading: true,
            spdsloading: true,
            cabinetsloading: true,
            generatorsloading: true,
            atsesloading: true,
            dcdusloading: true,
            fcbsloading: true,
            sharedOutOperatorsesloading: true,
            idBatteryRacksloading: true,
            renewableEnergySolarsloading: true,
            RenewableEnergyWindsloading: true,
            loadingPowerBatteryBankDetails: true,
            loadingPowerCabinetDetail: true,
            loadingAirconditionersData: true,
            loadingSpdsDetails: true,
            loadingSharedOperatorDetails: true,
            loadingFcbDetails: true,
            loadingAtsData: true,
            loadingGeneratorsData: true,
            loadingPowerDcduDetailsData: true



        });
        this.getDataexpot(form, 1);
        // this.getdatagetPowerRectifierDetails(data,1);
        // this.getDataPowerBatteryBankDetails(data,1);
        // this.getDataPowerCabinetDetails(data,1);
        // this.getDataAirconditionersData(data,1);
        // this.getDataPowerSpdsDetails(data,1);
        // this.getDataPowerSharedOperatorDetails(data,1);
        // this.getDataPowerFcbDetails(data,1);
        // this.getDataAtsData(data,1);
        // this.getDataGeneratorsData(data,1);
        // this.getDataPowerDcduDetails(data,1)
    }
    componentDidMount() {
        //console.log("this.props.regions");
        console.log(this.props.regions);
        this.getMasterDataSiteInfo();
        this.getDataSitesByuser();
        this.getDataRectifierMake();
        this.getDataBatteryBankMake();
        this.getDataGeneratorModel();
        this.setState({
            depot_list: this.props.depots,
        })
        var data = {

            "id": 1,
            "type": 2
        }



    }
    getDataGeneratorModel = async () => {
        console.log("getGeneratorModel1");
        var _GeneratorModel = [];
        var res = await getGeneratorModel();
        console.log("getGeneratorModel");
        console.log(res);
        if (res.success) {
            res.data.map((sites) => {
                _GeneratorModel.push({ value: sites.id, label: sites.name })
            })
            this.setState({
                allGeneratorModel_loading: false,
                allGeneratorModel: _GeneratorModel,
            });

        }
    };
    getDataBatteryBankMake = async () => {
        console.log("getBatteryBankMake1");
        var _BatteryBankMake = [];

        var res = await getBatteryBankMake();
        console.log("getBatteryBankMake");
        console.log(res);
        if (res.success) {
            res.data.map((sites) => {
                _BatteryBankMake.push({ value: sites.id, label: sites.name })
            })
            this.setState({
                allBatteryBankMake_loading: false,
                allBatteryBankMake: _BatteryBankMake,
            });

        }
    };
    getDataRectifierMake = async () => {
        console.log("getRectifierMake1");
        var _allRectifier = [];
        var res = await getRectifierMake();
        console.log("getRectifierMake");
        console.log(res);
        if (res.success) {
            res.data.map((sites) => {
                _allRectifier.push({ value: sites.id, label: sites.name })
            })
            this.setState({
                allRectifierMake_loading: false,
                allRectifierMake: _allRectifier,
            });

        }
    };
    // getDataAllSites = async () => {
    //     var res = await getSitesLite();
    //     var _allSite = [];
    //     console.log("getAllSite");


    //     if(res.success){
    //         res.data.map((sites) => {
    //             console.log(sites.site_name);
    //             _allSite.push({ value: sites.id, label: sites.siteName, siteID: sites.siteId })
    //         })
    //         this.setState({
    //             allSite_loading: false,
    //             allSite: _allSite,
    //         });

    //     }

    // }
    getMasterDataSiteInfo = async () => {
        var res = await getMasterDataSiteInfo();
        console.log('masterData powerConnectionType')
        if (res.success) {
            this.setState({
                powerConnectionType: res.data.powerConnectionType
            })

        }
        console.log(res)
    }
    getDataSitesByuser = async () => {
        var res = await getSitesLite();
        var _allSite = [];
        console.log("getUserSites");
        console.log(res.data);
        if (res.success) {
            res.data?.map((sites) => {
                _allSite.push({ value: sites.id, label: sites.site_name, siteID: sites.site_id, depotId: sites.depot_id, subRegionId: sites.sub_region_id })
            })
            this.setState({
                allSite_loading: false,
                allSite: _allSite,
                allSiteForFilter: res.data
            });
        }
    };
    getDataSitesWithFilter = async () => {

        console.log("selectRegion")
        console.log(this.state.selectRegion)
        console.log(this.state.allSite)
        let filterSites = this.state.allSite.filter(subRegion => subRegion.subRegionId == ['12'])

        console.log(filterSites)


    }
    searchClick = () => {
        if (this.state.selectSite != '' || this.state.selectDepot != '' || this.state.selectRegion != '' || this.state.selectRectifierMake != '' || this.state.selectBattertMake != '' || this.state.selectGeneratorMake != '' || this.state.slectIndoorOutdoor != '' || this.state.selectPowerConnectiontype != '') {
            console.log("searchdd");
            this.setState({
                progressPrasantage: 0,
                data_list: [],
                fullPageCount: 0,
                loopPageCount: 0,
                fullDataList: [],
                excelLoading: false,
                // progressBarLoading:true,
            })

            var data = {
                "id": this.state.selectId,
                "type": this.state.selectType
            }
            var form = new FormData();
            form.append("pageNo", 0);
            form.append("recodesPerPage", this.state.recodesPerPage);
            form.append("siteIds", this.state.selectSite);
            form.append("depotIds", this.state.selectDepot);
            form.append("regionIds", this.state.selectRegion);
            form.append("powerConectionType", this.state.selectPowerConnectiontype);

            form.append("rectifireMakeIds", this.state.selectRectifierMake);
            form.append("batteryBankMakeIds", this.state.selectBattertMake);
            form.append("generatorMakeIds", this.state.selectGeneratorMake);
            form.append("indoorOutdoor", this.state.slectIndoorOutdoor);






            this.setState({
                allApiLoading: true,
                loading: true,
                tabaleLoading: true,
                rectifierloading: true,
                loadingRectifier: true,
                batteryBanksloading: true,

                airConditionersloading: true,
                spdsloading: true,
                cabinetsloading: true,
                generatorsloading: true,
                atsesloading: true,
                dcdusloading: true,
                fcbsloading: true,
                sharedOutOperatorsesloading: true,
                idBatteryRacksloading: true,
                renewableEnergySolarsloading: true,
                RenewableEnergyWindsloading: true,
                loadingPowerBatteryBankDetails: true,
                loadingPowerCabinetDetail: true,
                loadingAirconditionersData: true,
                loadingSpdsDetails: true,
                loadingSharedOperatorDetails: true,
                loadingFcbDetails: true,
                loadingAtsData: true,
                loadingGeneratorsData: true,
                loadingPowerDcduDetailsData: true

            });
            this.getDataexpot(form, 2);

        } else {

        }
    };
    render() {
        const downloadCompanies = e => {
            this.setState({
                excelLoading: true,
            })

            // Download file now
        }



        const fontValue = { sz: "12", bold: true, fill: { patternType: "solid", fgColor: { rgb: "FF0B0713" } } };
        const { filterTable } = this.state;
        const dataSet1 = this.state.fullDataList;
        const dataSet2 = this.state.rectifiersList;
        const dataSet3 = this.state.batteryBanksList;
        const dataSet4 = this.state.cabinetsList;
        const dataSet5 = this.state.airConditionersList;
        const dataSet6 = this.state.generatorsList;
        const dataSet7 = this.state.dcdusList;
        const dataSet8 = this.state.atsesList;
        const dataSet9 = this.state.fcbsList;
        const dataSet10 = this.state.spdsList;
        const dataSet11 = this.state.sharedOutOperatorsesList;
        const dataSet12 = this.state.idBatteryRacksList;
        const dataSet13 = this.state.renewableEnergySolarsList;
        const dataSet14 = this.state.RenewableEnergyWindsList;

        console.log('dataSet4dataSet4dataSet4')
        console.log(dataSet11)
        
        console.log("------------------------------------------------------------------------- dataSet 02 ---------------------------------------------------");
        console.log(dataSet2);
        
        
        const StatisticDataSet = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Region", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Depot", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Site Address", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Site Status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    //Sec 02
                    { title: "Longitude", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Latitude", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Type of site", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "No. of tower legs", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Tower Height (m)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    //Sec 03
                    { title: "Site Elevation (m)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Site Owner", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "PIS vendor", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Site Entity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Indoor/Outdoor", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    //Sec 04
                    //  { title: "Cooling Method", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //   { title: "Suggestions for optimizing energy consumption of Cooling system", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Outside Ambient Tempture", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    //Sec 05
                    { title: "No. of Tenants", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Tenants", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "No. of power shared tenants", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    //Sec 06
                    { title: "Site category", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Dimension(mxm)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Free land available", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    //Sec 07
                    { title: "Shelter type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Shelter size", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 08
                    { title: "Non shading space availability as 2mx1m slots on shelter ( Rooftop)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Non shading space availability as 2mx1m slots on ground.", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    //Sec 09
                    // { title: "Site classification (Dependent cell count) 2G", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Site classification (Dependent cell count) 3G", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Site classification (Dependent cell count) 4G", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Site classification (Dependent cell count) 5G", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    //Sec 10
                    { title: "Manual Distance (m)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Manual Delivery (Horizontal/Vertical)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Travel time from depot", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Working Days Accessible hours", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Weekends/Holidays Accessible hours", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Year of the JFS", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 11
                    { title: "Idle Generator hut avaialability", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Available Generator Hut 1Type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Available Generator Hut 2Type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Generator space availability for a new generator", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Site On Air Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    // Sec 12
                    { title: "PG Possibility", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Portable gen connecting setup availability (Yes/ No)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectification Rank", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Land Area in perch", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Horizontal Manual Distance (m)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Vertical Manual Disctance (No of Floors)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Boat Manual Distance by Boat (m)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Last Modification Date From Routing", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                ],
                data: dataSet1?.map((data) => [

                    // Sec 01
                    { value: data.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.regionName ? (data?.regionName) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.depotName ? (data?.depotName) : ("Null"), style: { font: { sz: "12" } } },

                    { value: data?.siteAddressList[0]?.address ? (data?.siteAddressList[0]?.address) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.status ? (data.status) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 02
                    { value: data?.siteAddressList[0]?.longitude ? (data?.siteAddressList[0]?.longitude) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.siteAddressList[0]?.latitude ? (data?.siteAddressList[0]?.latitude) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.siteType?.name ? (data?.siteType?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.towerLegsCount ? (data?.towerLegsCount) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.towerHeight ? (data?.towerHeight) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 03
                    { value: data?.siteElevation ? (data?.siteElevation) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.siteOwner?.name ? (data?.siteOwner?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.siteOwner?.name ? ('') : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.entity?.name ? (data?.entity?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.indoorOutdoor?.name ? (data?.indoorOutdoor?.name) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 04
                    //  { value: data?.coolingMethods?.id ? (data?.coolingMethods?.id) : ("Null"), style: { font: { sz: "12" } } },
                    //    { value: data?.coolingMethods?.id ? ('') : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.ambientTemperature ? (data?.ambientTemperature) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 05
                    { value: data.sitePowerDb?.noOfTenants != null ? (data.sitePowerDb?.noOfTenants) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.tenants ? (data?.tenants) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.noOfPowerSharedTenants != null ? (data.sitePowerDb?.noOfPowerSharedTenants) : ("Null"), style: { font: { sz: "12" } } },
                    ////  Sec 06
                    { value: data?.siteCategory?.name ? (data?.siteCategory?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.dimension ? (data?.dimension) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.freeLandAvailable ? (data.freeLandAvailable) : ("Null"), style: { font: { sz: "12" } } },

                    // Sec 07
                    { value: data?.shelterType?.name ? (data?.shelterType?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.shelterSize ? (data?.shelterSize) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 08
                    { value: data?.nonShadingSpaceAvailabilityOnshelter ? (data?.nonShadingSpaceAvailabilityOnshelter) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.nonShadingSpaceAvailabilityGround ? (data?.nonShadingSpaceAvailabilityGround) : ("Null"), style: { font: { sz: "12" } } },

                    // //Sec 09
                    // // { value: dataClassification_2G = !null ? (dataClassification_2G) : ("Null"), style: { font: { sz: "12" } } },
                    // // { value: dataClassification_3G = !null ? (dataClassification_3G) : ("Null"), style: { font: { sz: "12" } } },
                    // // { value: dataClassification_4G = !null ? (dataClassification_4G) : ("Null"), style: { font: { sz: "12" } } },
                    // // { value: dataClassification_5G = !null ? (dataClassification_5G) : ("Null"), style: { font: { sz: "12" } } },
                    // // //Sec 10
                    { value: data?.manualDistance ? (data.manualDistance) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.manualDelivery ? (data.manualDelivery) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.travelTimeFromDepot ? (data.travelTimeFromDepot) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.workingDaysAccessibleHours ? (data.workingDaysAccessibleHours) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.weekendsOrHolidaysAccessibleHours ? (data.weekendsOrHolidaysAccessibleHours) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.yearOfTheJfs ? (data.yearOfTheJfs) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 11
                    { value: data?.isIdleGeneratorHutAvaialable ? ('Yes') : ("No"), style: { font: { sz: "12" } } },
                    { value: data?.availableGeneratorHut1Type != null ? (data?.availableGeneratorHut1Type?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.availableGeneratorHut2Type != null ? (data?.availableGeneratorHut2Type?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.nonShadingSpaceAvailabilityGround ? (data?.nonShadingSpaceAvailabilityGround) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.siteOnAirDate ? (data?.siteOnAirDate) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 12
                    { value: data?.pgPossibility ? (data?.pgPossibility) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.sitePowerDb?.portablegenConnectingSetupAvailability ? (data?.sitePowerDb?.portablegenConnectingSetupAvailability) : ('Null'), style: { font: { sz: "12" } } },
                    { value: data?.rectificationRank ? (data?.rectificationRank) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.landAreainPerch ? (data?.landAreainPerch) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.landAreainPerch ? ('') : (''), style: { font: { sz: "12" } } },
                    { value: data?.verticalManualDisctance ? (data?.verticalManualDisctance) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.boatManualdistancebyBoat ? (data?.boatManualdistancebyBoat) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.sitePowerDb?.lastModifiedDateFromRoutine ? (data?.sitePowerDb?.lastModifiedDateFromRoutine) : ("Null"), style: { font: { sz: "12" } } },

                ]),
            }
        ];
        const StatisticAccessTechnologies = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "GSM", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "DCS", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    // Sec 03
                    { title: "3G", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "4GFDD_900", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "4GFDD_1800", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "4GFDD_2100", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "4GTDD_2300", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "5G", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 

                    // Sec 04
                    //{ title: "WIMAX", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    // { title: "WIPLL", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "CAMBIUM", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "WIBASS", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    /// { title: "CDMA", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "GPON", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Access Technology Vendor", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 

                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [
                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.sitePowerDb?.atGSM ? (data.sitePowerDb?.atGSM) : ("Not Set Yet"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.atDCS ? (data.sitePowerDb?.atDCS) : ("Not Set Yet"), style: { font: { sz: "12" } } },
                    //  Sec 03
                    { value: data.sitePowerDb?.at3G ? (data.sitePowerDb?.at3G) : ("Not Set Yet"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.at4GFDD900 ? (data.sitePowerDb?.at4GFDD900) : ("Not Set Yet"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.at4GFDD1800 ? (data.sitePowerDb?.at4GFDD1800) : ("Not Set Yet"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.at4GFDD2100 ? (data.sitePowerDb?.at4GFDD2100) : ("Not Set Yet"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.at4GTDD2300 ? (data.sitePowerDb?.at4GTDD2300) : ("Not Set Yet"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.at5G ? (data.sitePowerDb?.at5G) : ("Not Set Yet"), style: { font: { sz: "12" } } },

                    // Sec 04
                    // { value: data.sitePowerDb?.atWIMAX ? (data.sitePowerDb?.atWIMAX) : (data.sitePowerDb?.atWIMAX), style: { font: { sz: "12" } } },
                    // { value: data.sitePowerDb?.atWIPLL ? (data.sitePowerDb?.atWIPLL) : (data.sitePowerDb?.atWIPLL), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.atCAMBIUM ? (data.sitePowerDb?.atCAMBIUM) : ("Not Set Yet"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.atWIBASS ? (data.sitePowerDb?.atWIBASS) : ("Not Set Yet"), style: { font: { sz: "12" } } },
                    //  { value: data.sitePowerDb?.atCDMA ? (data.sitePowerDb?.atCDMA) : (data.sitePowerDb?.atCDMA), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.atGPON ? (data.sitePowerDb?.atGPON) : ("Not Set Yet"), style: { font: { sz: "12" } } },
                    
                    { value: data.sitePowerDb?.atAccessTechnologyVendor ? (data.sitePowerDb?.atAccessTechnologyVendor) : ("Not Set Yet"), style: { font: { sz: "12" } } },
                ]:[]),
            }
        ];

        

        const StatisticMainPowerConnection = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Power Connection type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "No. of phases", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Capacity per phase (A)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 03
                    { title: "Phase 1 Current", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Phase 2 Current", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Phase 3 Current", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    // Sec 04
                    { title: "Phase 1 Voltage", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Phase 2 Voltage", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Phase 3 Voltage", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 05
                    { title: "Separate Feeder Availablity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Any known issue preventing separate feeder", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Average availability per day(Hours)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "CEB/LECO Account Number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount






                ],
                data: dataSet1?.map((data) =>data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },

                    // Sec 02
                    { value: data.sitePowerDb?.mpcPowerConnectionType ? (data.sitePowerDb?.mpcPowerConnectionType?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.mpcNoOfPhases ? (data.sitePowerDb?.mpcNoOfPhases) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.mpcCapacityPerPhase ? (data.sitePowerDb?.mpcCapacityPerPhase) : ("Null"), style: { font: { sz: "12" } } },

                    // Sec 03
                    { value: data.sitePowerDb?.mpcPhase1Current != null ? (data.sitePowerDb?.mpcPhase1Current) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.mpcPhase2Current != null ? (data.sitePowerDb?.mpcPhase2Current) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.mpcPhase3Current != null ? (data.sitePowerDb?.mpcPhase3Current) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 04
                    { value: data.sitePowerDb?.mpcPhase1Voltage != null ? (data.sitePowerDb?.mpcPhase1Voltage) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.mpcPhase2Voltage != null ? (data.sitePowerDb?.mpcPhase2Voltage) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.mpcPhase3Voltage != null ? (data.sitePowerDb?.mpcPhase3Voltage) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 05
                    { value: data.sitePowerDb?.mpcSeparateFeederAvailablity != null ? (data.sitePowerDb?.mpcSeparateFeederAvailablity) : ('Null'), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.mpcAnyKnownIssuePreventingSeparateFeeder ? (data.sitePowerDb?.mpcAnyKnownIssuePreventingSeparateFeeder) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.mpcCebLecoAccountNumber != null ? (data.sitePowerDb?.mpcCebLecoAccountNumber) : ("Null"), style: { font: { sz: "12" } } },

                ]:[]),
            }
        ];
        const StatisticSharedBackupPower = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Shared From", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "No of Phases", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Capacity per Phase", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Backup Power Operating status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "DC Power Shared From", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "DC Power Shared Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "DC Power Average Load", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount





                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },

                    // Sec 02
                    { value: data.sitePowerDb?.sbpSharedFrom ? (data.sitePowerDb?.sbpSharedFrom) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.sbpNoOfPhases != null ? (data.sitePowerDb?.sbpNoOfPhases) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.sbpCapacityPerPhase != null ? (data.sitePowerDb?.sbpCapacityPerPhase) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.sbpBackupPowerOperatingStatus ? (data.sitePowerDb?.sbpBackupPowerOperatingStatus) : (data.sitePowerDb?.sbpBackupPowerOperatingStatus), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.dcpsSharedFrom ? (data.sitePowerDb?.dcpsSharedFrom) : ('Null'), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.dcpsSharedCapacity != null ? (data.sitePowerDb?.dcpsSharedCapacity) : ('Null'), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.dcpsAverageLoad != null ? (data.sitePowerDb?.dcpsAverageLoad) : ('Null'), style: { font: { sz: "12" } } },

                ]:[]),
            }
        ];
        const StatisticPowerCapacity = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Total Capacity Available (kW)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Used Capacity with Battery Charging (10%)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Remaining Capacity (kW)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [
                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.sitePowerDb?.pcTotalCapacityAvailable != null ? (data.sitePowerDb?.pcTotalCapacityAvailable) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.pcUsedCapacityWithBatteryCharging != null ? (data.sitePowerDb?.pcUsedCapacityWithBatteryCharging) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.pcRemainingCapacity != null ? (data.sitePowerDb?.pcRemainingCapacity) : ("Null"), style: { font: { sz: "12" } } },
                ]:[]),
            }
        ];
        const StatisticDCLoad = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 02

                    { title: "DC Load (A) ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Peak DC Load (A)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Nominal Operating Voltage (V)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                ],
                data: dataSet1?.map((data) =>data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.sitePowerDb?.dclDCLoad != null ? (data.sitePowerDb?.dclDCLoad) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.dclPeakDCLoad != null ? (data.sitePowerDb?.dclPeakDCLoad) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.dclNominalOperatingVoltage != null ? (data.sitePowerDb?.dclNominalOperatingVoltage) : ("Null"), style: { font: { sz: "12" } } },
                ]:[]),
            }
        ];
        const StatisticRectifiers = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 02
                    { title: "No of Rectifiers", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Ticket number of Pending Rectifier faulty tickets", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectifiers Planned from Operational team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectifiers Planned from Planningl team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectifier with Cabinet units Planned from Operational team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectifier with Cabinet units Planned from Planningl team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectifier modules Planned from Operational team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectifier modules Planned from Planningl team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount


                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 02
                    { value: data.sitePowerDb?.noOfRectifiers != null ? (data.sitePowerDb?.noOfRectifiers) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.rticketNumberOfPendingRectifierFaultyTickets != null ? (data.sitePowerDb?.rticketNumberOfPendingRectifierFaultyTickets) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.rplannedFromOperationalTeam!= null ? (data.sitePowerDb?.rplannedFromOperationalTeam) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.rplannedFromPlanninglTeam  ? (data.sitePowerDb?.rplannedFromPlanninglTeam) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.rwcuPlannedFromOperationalTeam ? (data.sitePowerDb?.rwcuPlannedFromOperationalTeam) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.rwcuPlannedFromPlanninglTeam ? (data.sitePowerDb?.rwcuPlannedFromPlanninglTeam) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.rmPlannedFromOperationalTeam ? (data.sitePowerDb?.rmPlannedFromOperationalTeam) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.rmPlannedFromPlanninglTeam ? (data.sitePowerDb?.rmPlannedFromPlanninglTeam) : ("Null"), style: { font: { sz: "12" } } },
                ]:[]),
            }
        ];
        const StatisticDataSetRectifier = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 90 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Rectifier Controller Serial Number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Make", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectifier model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Material Code of the Rectifier", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectifier Controller Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectifier Controller software Version", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 03
                    { title: "No of phases", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Rectifier Module model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Material Code of the rectifier module", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 04
                    { title: "Maximum Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Module Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "No. of module Slots", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Efficiency", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 05
                    { title: "No. of modules at site", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "No. of Powered Rec Modules ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "No. of Unpowered Rec Modules ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectifier modules serial numbers", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    // Sec 06
                    { title: "L1 Current", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "L2 Current", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "L3 Current ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "L1 Voltage", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "L2 Voltage", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "L3 Voltage", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount


                    // Sec 07
                    { title: "Total DC load ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Nominal Operating voltage", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Peak DC load", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectifier Positive to Earth connectivity using 16mm cable", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 08
                    { title: "Free breaker availability", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Used Breaker capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "No of breaker spaces available (size 80A or below)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "No of breaker spaces available (size 100A or above)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    { title: "Installed date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "RMS integrated status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "RMS inband/outband", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "RMS connection method", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "RMS related SIM number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount



                ],
                data: dataSet2?.map((data) => [

                    // Sec 01
                    { value: data.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.rectifierControllerSerialNumber ? (data.rectifierControllerSerialNumber) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.make?.name ? (data.make?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.model?.name ? (data.model?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.model?.materialCode ? (data.model?.materialCode) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rectifierControllerModel ? (data.rectifierControllerModel) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rectifierControllerSoftwareVersion ? (data.rectifierControllerSoftwareVersion) : ("Null"), style: { font: { sz: "12" } } },

                    // Sec 03
                    //{ value: data.model?.noOfPhases ? (data.model?.noOfPhases) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rectifierModuleModel?.name ? (data.rectifierModuleModel?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rectifierModuleModel?.materialCodeOfRectifierModul ? (data.rectifierModuleModel?.materialCodeOfRectifierModul) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 04
                    { value: data.model?.maximumCapacity ? (data.model?.maximumCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rectifierModuleModel?.moduleCapacity ? (data.rectifierModuleModel?.moduleCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.model?.noOfModuleSlot? (data.model?.noOfModuleSlot) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rectifierModuleModel?.efficiency  ? (data.rectifierModuleModel?.efficiency ) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 05
                    { value: data.noOfModulesAtSite != null ? (data.noOfModulesAtSite) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.noOfPoweredRecModules != null ? (data.noOfPoweredRecModules) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.noOfUnpoweredRecModules != null ? (data.noOfUnpoweredRecModules) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rectifierModulesSerialNumbers ? (data.rectifierModulesSerialNumbers) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 06
                    { value: data.l1Current != null ? (data.l1Current) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.l2Current != null ? (data.l2Current) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.l3Current != null ? (data.l3Current) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.l1Voltage != null ? (data.l1Voltage) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.l2Voltage != null ? (data.l2Voltage) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.l3Voltage != null ? (data.l3Voltage) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 07
                    { value: data.totalDCLoad != null ? (data.totalDCLoad) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.nominalOperatingVoltage != null ? (data.nominalOperatingVoltage) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.peakDCLoad != null ? (data.peakDCLoad) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rectifierPositiveEarthConnectivity16mmCable ? (data.rectifierPositiveEarthConnectivity16mmCable) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 08
                    { value: data.freeBreakerAvailability ? (data.freeBreakerAvailability) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.usedBreakerCapacity ? (data.usedBreakerCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.noOfBreakerSpacesAvailable80A ? (data.noOfBreakerSpacesAvailable80A) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.noOfBreakerSpacesAvailable100A ? (data.noOfBreakerSpacesAvailable100A) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.installedDate ? (data.installedDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rmsintegratedStatus ? (data.rmsintegratedStatus) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rmsinbandOutband ? (data.rmsinbandOutband) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rmsConnectionMethod ? (data.rmsConnectionMethod) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rmsrelatedSimNumber ? (data.rmsrelatedSimNumber) : ("Null"), style: { font: { sz: "12" } } },

                ]),
            }
        ];
        const StatisticDataBatteryBank = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 02
                    { title: "No of Battery Banks ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Ticket number of Pending Battery faulty tickets", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Planned Battery Capacity (Ah) - Projects", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Planned Battery Capacity (Ah) - Operation", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Previous year designed Back up time", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Designed Back up time", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Allocated Back up time", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Actual Back up time", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 


                ],
                data: dataSet1?.map((data) =>data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.sitePowerDb?.noOfBatteryBanks != null ? (data.sitePowerDb?.noOfBatteryBanks) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.bbTicketNumberOfPendingBatteryFaultyTickets != null ? (data.sitePowerDb?.bbTicketNumberOfPendingBatteryFaultyTickets) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.bbPlannedBatteryCapacityProjects != null ? (data.sitePowerDb?.bbPlannedBatteryCapacityProjects) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.plannedBatteryCapacity != null ? (data.sitePowerDb?.plannedBatteryCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.bbPreviousYearDesignedBackUpTime != null ? (data.sitePowerDb?.bbPreviousYearDesignedBackUpTime) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.bbDesignedBackupTime != null ? (data.sitePowerDb?.bbDesignedBackupTime) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.bbAllocatedBackupTime != null ? (data.sitePowerDb?.bbAllocatedBackupTime) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.bbActualBackupTime != null ? (data.sitePowerDb?.bbActualBackupTime) : ("Null"), style: { font: { sz: "12" } } },


                ]:[]),
            }
        ];
        const StatisticDataBatteryBank1 = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "BB Type ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Number of 48V Battery Blocks ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Make", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Battery Serial Number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Material Code of the Battery Bank", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Per 48V Block Capacity (Ah)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    // Sec 03
                    { title: "Total Capacity (Ah)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Experienced battery backup time in most recent incident (h)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Battery temperature sensor installed correctly", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Capacity of a string", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Number of strings", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    // Sec 04
                    { title: "Float Voltage", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Float Voltage set temperature", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Boost Voltage", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Periodic boost ch. duraion", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Periodic boost ch. period/Cycle", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 05
                    { title: "Battery current limit", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Temperature compansation for 48V Battery Bank (mV/Celceius)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Bat. Temp. Sensor port", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Relevant Rectifier", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Battery Temperature", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Installed date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                ],
                data: dataSet3?.map((data) => [

                    // Sec 01
                    { value: data.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.bbType != null ? (data.bbType?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.numberof48VBatteryBlocks ? (data.numberof48VBatteryBlocks) : ("Null"), style: { font: { sz: "12" } } },

                    { value: data.make != null ? (data.make?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.batterySerialNumber ? (data.batterySerialNumber) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.model?.name ? (data.model?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.model?.materialCodeOfTheBatteryBank ? (data.model?.materialCodeOfTheBatteryBank) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.model?.per48VBlockCapacity ? (data.model?.per48VBlockCapacity) : ("Null"), style: { font: { sz: "12" } } },


                    // Sec 03
                    //    { value: data.totalDCLoad != null ? (data.totalDCLoad) : ("Null"), style: { font: { sz: "12" } } },

                    { value: data.totalCapacity != null ? (data.totalCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.experiencedBatteryBackupTimeRecentIncident != null ? (data.experiencedBatteryBackupTimeRecentIncident) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.batteryTemperatureSensorInstalledCorrectly ? (data.batteryTemperatureSensorInstalledCorrectly) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.capacityOfString != null ? (data.capacityOfString) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.numberOfStrings != null ? (data.numberOfStrings) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 04
                    //    { value: data.totalDCLoad != null ? (data.totalDCLoad) : ("Null"), style: { font: { sz: "12" } } },

                    { value: data.floatVoltage ? (data.floatVoltage) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.floatVoltageSetTemperature ? (data.floatVoltageSetTemperature) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.boostVoltage ? (data.boostVoltage) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.periodicBoostDuraion ? (data.periodicBoostDuraion) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.periodic_boost_period ? (data.periodic_boost_period) : ("Null"), style: { font: { sz: "12" } } },

                    // Sec 05
                    { value: data.batteryCurrentLimit ? (data.batteryCurrentLimit) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.temperatureCompansation ? (data.temperatureCompansation) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.batTempSensorPort ? (data.batTempSensorPort) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.relevantRectifier != null ? (data.relevantRectifier) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.batteryTemperature ? (data.batteryTemperature) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.installedDate ? (data.installedDate) : ("Null"), style: { font: { sz: "12" } } },

                ]),
            }
        ];
        const StatisticDatadPowerCabinet = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "No. of Cabinets", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "No of Cabinets with Equipment installed", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Ticket Numbers of pending Faulty tickets", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Planned from Operational team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Planned from Planningl team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount


                ],
                data: dataSet1?.map((data) =>data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 02
                    { value: data.sitePowerDb?.noOfCabinets != null ? (data.sitePowerDb?.noOfCabinets) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.noOfCabinets != null ? ('null') : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.cticketNumbersOfPendingFaultyTickets != null ? (data.sitePowerDb?.cticketNumbersOfPendingFaultyTickets) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.cplannedFromOperationalTeam != null ? (data.sitePowerDb?.cplannedFromOperationalTeam) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.cplannedFromPlanninglTeam != null ? (data.sitePowerDb?.cplannedFromPlanninglTeam) : ("Null"), style: { font: { sz: "12" } } },


                ]:[]),
            }
        ];
        const StatisticDatadPowerCabinet1 = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Make", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Material code of the Cabinet", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Cabinet Serial Number ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    // Sec 03
                    { title: "Cooling method (Equipment compartment)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Cooling method (Battery compartment)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Cooling Capacity (Equipment compartment)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Cooling Capacity (Battery compartment) ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    // Sec 04
                    { title: "Equipment Space", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Utilized Equipment space", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Battery space", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Utilized Battery Space", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Battery Capacity ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Installed date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount





                ],
                data: dataSet4?.map((data) => [

                    // Sec 01
                    { value: data.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.type ? (data.type.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.model?.make != null ? (data.model.make?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.model?.materialCodeOfTheCabinet ? (data.model?.materialCodeOfTheCabinet) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.cabinetSerialNumber ? (data.cabinetSerialNumber) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.model?.name ? (data.model?.name) : ("Null"), style: { font: { sz: "12" } } },

                    // // Sec 03
                    { value: data?.model?.coolingMethodEquipmentCompartment ? (data.model?.coolingMethodEquipmentCompartment) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.model?.coolingMethodBatteryCompartment ? (data.model?.coolingMethodBatteryCompartment) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.model?.coolingCapacityEquipmentCompartment ? (data.model?.coolingCapacityEquipmentCompartment) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.model?.coolingCapacityBatteryCompartment ? (data.model?.coolingCapacityBatteryCompartment) : ("Null"), style: { font: { sz: "12" } } },

                    // // Sec 04
                    { value: data?.model?.equipmentSpace ? (data?.model?.equipmentSpace) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.utilizedEquipmentSpace ? (data.utilizedEquipmentSpace) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.model?.batterySpace != null ? (data?.model?.batterySpace) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.utilizedBatterySpace ? (data.utilizedBatterySpace) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.installBatteryCapacity ? (data.installBatteryCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.installedDate ? (data.installedDate) : ("Null"), style: { font: { sz: "12" } } },

                ]),
            }
        ];
        const StatisticDataCabinDetails = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "No of Indoor Cabins", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Indoor Cabin Type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Indoor Cabin Length (m)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Indoor Cabin Width (m)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount



                ],
                data: dataSet1?.map((data) => [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 02
                    { value: data.noofIndoorCabins != null ? (data.noofIndoorCabins) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.indoorCabinType != null ? (data.indoorCabinType) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.indoorCabinLength != null ? (data.indoorCabinLength) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.indoorCabinWidth != null ? (data.indoorCabinWidth) : ("Null"), style: { font: { sz: "12" } } },



                ]),
            }
        ];
        const StatisticAirConditioners = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Cooling Method", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "No of Air Conditioners", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "ticket number of pending Faulty ticket", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Planned ACs", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount





                ],
                data: dataSet1?.map((data) =>data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.sitePowerDb?.airCoolingMethod != null ? (data.sitePowerDb?.airCoolingMethod) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.noOfAirConditioners != null ? (data.sitePowerDb?.noOfAirConditioners) : ("Null"), style: { font: { sz: "12" } } },

                    { value: data.sitePowerDb?.airTicketNumberOfPendingFaultyTicket != null ? (data.sitePowerDb?.airTicketNumberOfPendingFaultyTicket) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.airPlannedACs ? (data.sitePowerDb.airPlannedACs) : ("Null"), style: { font: { sz: "12" } } },


                ]:[]),
            }
        ];
        // const StatisticAirconditionersData = [
        //     {
        //         columns: [
        //             // Sec 01
        //             { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
        //             { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
        //             // Sec 02
        //             { title: "Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
        //             { title: "Serial Number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
        //             { title: "Warranty Expire date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
        //             { title: "Installed date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
        //             { title: "Last Maintainance Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount






        //         ],
        //         data: dataSet5?.map((data) => [

        //             // Sec 01
        //             { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
        //             { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
        //             // Sec 02
        //             { value: data.type ? (data.type) : ("Null"), style: { font: { sz: "12" } } },
        //             { value: data.serialNumber ? (data.serialNumber) : ("Null"), style: { font: { sz: "12" } } },
        //             { value: data.warrantyExpireDate ? (data.warrantyExpireDate) : ("Null"), style: { font: { sz: "12" } } },
        //             { value: data.installedDate ? (data.installedDate) : ("Null"), style: { font: { sz: "12" } } },
        //             { value: data.lastMaintainanceDate ? (data.lastMaintainanceDate) : ("Null"), style: { font: { sz: "12" } } },


        //         ]),
        //     }
        // ];
        const StatisticAirconditionersData2 = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 90 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Type ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Make ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Material Code of the AC", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Power Consumption (A)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Cooling Load (A)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "AC Temperature Setting ©", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount


                    // Sec 03
                    { title: "Mode of Operation", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Indoor Unit - Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Indoor Unit - serial Number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Indoor Unit - Warranty Expire date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Indoor Unit - Installed date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Indoor Unit - Last Maintainance Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    // Sec 04
                    { title: "Outdoor Unit - Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Outdoor Unit -serial Number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Outdoor Unit -Warranty Expire date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Outdoor Unit -Installed date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Outdoor Unit -Last Maintainance Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount




                ],
                data: dataSet5?.map((data) => [

                    // Sec 01
                    { value: data.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.type != null ? (data?.type?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.make != null ? (data.make.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.make?.materialCodeOfTheAc !=null ? (data.make?.materialCodeOfTheAc) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.powerConsumption ? (data.powerConsumption) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.coolingLoad ? (data.coolingLoad) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.temperatureSetting ? (data.temperatureSetting) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.make?.capacity != null ? (data.make?.capacity) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 03
                    { value: data.modeOfOperation != null ? (data.modeOfOperation) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.indoorModel != null ? (data.indoorModel) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.serialNumber != null ? (data.serialNumber) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.warrantyExpireDate != null ? (data.warrantyExpireDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.installedDate != null ? (data.installedDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.lastMaintainanceDate != null ? (data.lastMaintainanceDate) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 04
                    { value: data.outdoorModel != null ? (data.outdoorModel) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.outdoorSerialNumber != null ? (data.outdoorSerialNumber) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.outdoorWarrantyExpireDate != null ? (data.outdoorWarrantyExpireDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.outdoorInstalledDate != null ? (data.outdoorInstalledDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.outdoorLastMaintainanceDate != null ? (data.outdoorLastMaintainanceDate) : ("Null"), style: { font: { sz: "12" } } },




                ]),
            }
        ];
        const StatisticGeneratorsDataData = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "No. of Generators", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Ticket numbers of pending tickets", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount





                ],
                data: dataSet1?.map((data) =>data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.sitePowerDb?.noOfGenerators != null ? (data.sitePowerDb?.noOfGenerators) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.genTicketNumberOfPendingFaultyTicket ? (data.sitePowerDb?.genTicketNumberOfPendingFaultyTicket) : ("Null"), style: { font: { sz: "12" } } },

                ]:[]),
            }
        ];
        const StatisticGeneratorsDataData2 = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 90 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Make", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Capacity (kVA)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 03
                    { title: "Generator SAP Serial Number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Engine Serial Number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Alternater Serial Number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Controller Serial Number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Any other serial Number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Material Code of the Generator", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 04
                    { title: "Engine Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Engine Controller model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "AVR model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 05
                    { title: "Fuel Tank Capacity (l)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Bulk tank Capacity (l)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 06
                    { title: "Average Running Hours per month", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Phase 1 Load", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Phase 2 Load", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Phase 3 Load", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 07
                    { title: "Hour Meter Reading (Hrs)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Loading with battery charging (%)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Loading without Battery Charging (%)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 08
                    { title: "Fuel Level (l)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Actual Fuel Consumption rate (litres/hour)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Charging Alternater Status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Gen Battery Status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 09
                    { title: "Gen Battery Voltage (V)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Gen Auto funtion status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Availability of gen Canapy", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 10
                    { title: "Gen Canapy status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Sound proof Status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Fuel Sensor status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Temperature Sensor status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Oil pressure Sensor status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Delay timer Status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    //Sec 11
                    { title: "Generator worm up delay time", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Generator cool down delay", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "ATS Functionality", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "ATS Triggering for any phase fault", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 12
                    { title: "Generator Earthing", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Hot Air Duct status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Radiator cleanup required", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Generator Mounting(Engine/Radiator/Control panel)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 13
                    { title: "RMS integrated status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "RMS inband/outband", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "RMS connection method", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "RMS related SIM number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "RMS related SIM serial number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    //Sec 14
                    { title: "Last Serviced Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Installed date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                ],
                data: dataSet6?.map((data) => [

                    // Sec 01
                    { value: data.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.name ? (data.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.make?.name ? (data.make?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.model?.name ? (data.model?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.category?.name ? (data.category?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.type?.name ? (data.type?.name) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 03
                    { value: data.generatorSAPSerialNumber ? (data.generatorSAPSerialNumber) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.engineSerialNumber ? (data.engineSerialNumber) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.alternaterSerialNumber ? (data.alternaterSerialNumber) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.controllerSerialNumber ? (data.controllerSerialNumber) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.anyOtherSerialNumber ? (data.anyOtherSerialNumber) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.model?.materialCodeOfTheGenerator ? (data.model?.materialCodeOfTheGenerator) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 04
                    { value: data.engineModel ? (data.engineModel) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.engineControllerModel ? (data.engineControllerModel) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.avrModel ? (data.avrModel) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 05
                    { value: data.capacity != null ? (data.capacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.capacity != null ? (data.capacity) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 06
                    { value: data.averageRunningHoursPerMonth != null ? (data.averageRunningHoursPerMonth) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.phase1Load != null ? (data.phase1Load) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.phase2Load != null ? (data.phase2Load) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.phase3Load != null ? (data.phase3Load) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 07
                    { value: data.hourMeterReading != null ? (data.hourMeterReading) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.loadingWithBatteryCharging != null ? (data.loadingWithBatteryCharging) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.loadingWithoutBatteryCharging != null ? (data.loadingWithoutBatteryCharging) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 08
                    { value: data.fuelLevel != null ? (data.fuelLevel) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.actualFuelConsumptionRate != null ? (data.actualFuelConsumptionRate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.chargingAlternaterStatus != null ? (data.chargingAlternaterStatus) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.genBatteryStatus ? (data.genBatteryStatus) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 09
                    { value: data.genBatteryVoltage != null ? (data.genBatteryVoltage) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.gen_auto_funtion_status ? (data.gen_auto_funtion_status) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.availabilityOfGenCanapy ? (data.availabilityOfGenCanapy) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 10
                    { value: data.genCanapyStatus ? (data.genCanapyStatus) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.soundProofStatus ? (data.soundProofStatus) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.fuelSensorStatus ? (data.fuelSensorStatus) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.temperatureSensorStatus ? (data.temperatureSensorStatus) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.oilPressureSensorStatus ? (data.oilPressureSensorStatus) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.delayTimerStatus ? (data.delayTimerStatus) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 11
                    { value: data.generatorWormUpDelayTime != null ? (data.generatorWormUpDelayTime) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.generatorCoolDownDelay != null ? (data.generatorCoolDownDelay) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.atsfunctionality ? (data.atsfunctionality) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.atstriggeringForAnyPhaseFault ? (data.atstriggeringForAnyPhaseFault) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 12
                    { value: data.generatorEarthing != null ? (data.generatorEarthing) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.hotAirDuctStatus != null ? (data.hotAirDuctStatus) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.radiatorCleanupRequired != null ? (data.radiatorCleanupRequired) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.generatorMounting ? (data.generatorMounting) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 13
                    { value: data.rmsintegratedStatus != null ? (data.rmsintegratedStatus) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rmsintegratedStatus != null ? ('null') : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rmsconnectionMethod ? (data.rmsconnectionMethod) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rmsrelatedSIMNumber ? (data.rmsrelatedSIMNumber) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rmsrrelatedSIMSerialNumber ? (data.rmsrrelatedSIMSerialNumber) : ("Null"), style: { font: { sz: "12" } } },

                    //Sec 14
                    { value: data.lastServicedDate ? (data.lastServicedDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.installedDate ? (data.installedDate) : ("Null"), style: { font: { sz: "12" } } },

                ]),
            }
        ];
        const StatisticDataDCDUDetails = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "No of DCDUs", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount



                ],
                data: dataSet1?.map((data) =>data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 02
                    { value: data.sitePowerDb?.noOfDCDUs != null ? (data.sitePowerDb?.noOfDCDUs) : ("Null"), style: { font: { sz: "12" } } },


                ]:[]),
            }
        ];
        const StatisticPowerDcduDetails = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Type ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Total Capacity(A) ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "No of breaker Slots", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Free breaker Slots ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Power from LLVD/BLVD", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Rectifier Breaker capacity (A) to power DCDU", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount






                ],
                data: dataSet7?.map((data) => [

                    // Sec 01
                    { value: data.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.type ? (data.type) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.totalCapacity != null ? (data.totalCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.noOfBreakerSlots != null ? (data.noOfBreakerSlots) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.freeBreakerSlots != null ? (data.freeBreakerSlots) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.powerFromLLVD ? (data.powerFromLLVD) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rbcapacityToPowerDCDU != null ? (data.rbcapacityToPowerDCDU) : ("Null"), style: { font: { sz: "12" } } },


                ]),
            }
        ];

        const StatisticAtsDataDetailsCommon = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "No. of ATSs", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount



                ],
                data: dataSet1?.map((data) =>data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 02
                    { value: data.sitePowerDb?.noOfATSs != null ? (data.sitePowerDb?.noOfATSs) : ("Null"), style: { font: { sz: "12" } } },


                ]:[]),
            }
        ];
        const StatisticAtsDataDetails = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Make ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Low voltage functionality ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "AC Temperature Setting ©", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount






                ],
                data: dataSet8?.map((data) => [

                    // Sec 01
                    { value: data.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data?.make ? (data?.make) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.model?.name ? (data?.model?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.Capacity ? (data.Capacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.lowVoltageFunctionality ? (data.lowVoltageFunctionality) : ("Null"), style: { font: { sz: "12" } } },
                    // { value: data.temperatureSetting ? (data.temperatureSetting) : ("Null"), style: { font: { sz: "12" } } },
                    // { value: data.capacity != null ? (data.capacity) : ("Null"), style: { font: { sz: "12" } } },


                ]),
            }
        ];
        const StatisticFcbDetails1 = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 02
                    { title: "No of FCB", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "ticket number of pending Faulty ticket", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount




                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 02
                    { value: data.sitePowerDb?.noOfFCB != null ? (data.sitePowerDb?.noOfFCB) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.fcmTicketNumberOfPendingFaultyTicket ? (data.sitePowerDb?.fcmTicketNumberOfPendingFaultyTicket) : ("Null"), style: { font: { sz: "12" } } },


                ]:[]),
            }
        ];
        const StatisticFcbDetails = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 90 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Make ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Serial Number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Warranty Expire date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Installed date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Last Maintainance Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount






                ],
                data: dataSet9?.map((data) => [

                    // Sec 01
                    { value: data.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data?.make?.name ? (data.make?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.model?.name ? (data.model?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.Capacity ? ('null') : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.Capacity ? (data.Capacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.warrantyExpireDate ? (data.warrantyExpireDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.installedDate ? (data.installedDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.lastMaintainanceDate ? (data.lastMaintainanceDate) : ("Null"), style: { font: { sz: "12" } } },


                ]),
            }
        ];

        const StatisticSpdsDetailsCommon = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 02
                    { title: "No of SPDs", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "ticket number of pending Faulty ticket", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount




                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 02
                    { value: data.sitePowerDb?.noOfSPDs != null ? (data.sitePowerDb?.noOfSPDs) : ("Null"), style: { font: { sz: "12" } } },



                ]:[]),
            }
        ];
        const StatisticSpdsDetails = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 90 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Deployed Location", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Make", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "SPD serial number", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Material code of the SPD", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    { title: "Warranty expiration date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "SPD Class", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 03
                    // { title: "Status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "HRC fuse/Isolator status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Installed date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount






                ],
                data: dataSet10?.map((data) => [

                    // Sec 01
                    { value: data.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.deployedLocation ? (data.deployedLocation) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.make?.name ? (data.make?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.serialNumber ? (data.serialNumber) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.make?.materialCode ? (data.make?.materialCode) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.warrantyExpireDate ? (data.warrantyExpireDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.make?.spdclass ? (data.make?.spdclass) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 03
                    // { value: data.lastMaintainanceDate ? ('null') : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.hrcFuseIsolatorStatus ? (data.hrcFuseIsolatorStatus) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.installedDate ? (data.installedDate) : ("Null"), style: { font: { sz: "12" } } },


                ]),
            }
        ];
        const StatisticEarthing = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "ER value", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Latest  ER Measured Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "SR value measure date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "SR_1m", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "SR_3m", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 03
                    { title: "Average SR value", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //  { title: "Required Land Area (Perches)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //  { title: "Possibility to extend the earthing beyound the fence (YES/NO)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "ER Improvement status (WIP/Completed)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 04
                    //  { title: "Status of TRCSL SR report & ER Design submission", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //  { title: "Received date from SAQ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "SR value request date from region", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "SR value received date from region", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //Sec 05
                    // { title: "Informed to SAQ on(if land clearance available onliy)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Received from SAQ on(if land clearance available only)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Hand over to CE signature", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Hand over to SAQ on", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sesc 02
                    { value: data.sitePowerDb?.earERvalue != null ? (data.sitePowerDb?.earERvalue) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.latestERMeasuredDate != null ? (data.sitePowerDb?.latestERMeasuredDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.earSRValueMeasureDate ? (data.sitePowerDb?.earSRValueMeasureDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.earSR1m ? (data.sitePowerDb?.earSR1m) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.earSR3m ? (data.sitePowerDb?.earSR3m) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 03
                    { value: data.sitePowerDb?.earAverageSRValue != null ? (data.sitePowerDb?.earAverageSRValue) : ("Null"), style: { font: { sz: "12" } } },
                    //  { value: data.sitePowerDb?.earRequiredLandArea != null ? (data.sitePowerDb?.earRequiredLandArea) : ("Null"), style: { font: { sz: "12" } } },
                    // { value: data.sitePowerDb?.earPossibilityToExtendEarthingBeyoundFenc ? (data.sitePowerDb?.earPossibilityToExtendEarthingBeyoundFenc) : (data.sitePowerDb?.earPossibilityToExtendEarthingBeyoundFenc), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.earERImprovementStatus ? (data.sitePowerDb?.earERImprovementStatus) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 04
                    //   { value: data.sitePowerDb?.earStatusOfTRCSLSRreport ? (data.sitePowerDb?.earStatusOfTRCSLSRreport) : ("Null"), style: { font: { sz: "12" } } },
                    //  { value: data.sitePowerDb?.earReceivedDateFromSAQ ? (data.sitePowerDb?.earReceivedDateFromSAQ) : ("Null"), style: { font: { sz: "12" } } },
                    //{ value: data.sitePowerDb?.earSRValueRequestDateFromRegion ? (data.sitePowerDb?.earSRValueRequestDateFromRegion) : ("Null"), style: { font: { sz: "12" } } },
                    //{ value: data.sitePowerDb?.earSRValueReceivedDateFromRegion ? (data.sitePowerDb?.earSRValueReceivedDateFromRegion) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 05
                    // { value: data.sitePowerDb?.earInformedToSAQOn ? (data.sitePowerDb?.earInformedToSAQOn) : ("Null"), style: { font: { sz: "12" } } },
                    // { value: data.sitePowerDb?.earReceivedFromSAQOn ? (data.sitePowerDb?.earReceivedFromSAQOn) : ("Null"), style: { font: { sz: "12" } } },
                    // { value: data.sitePowerDb?.earHandOverToCESignature ? (data.sitePowerDb?.earHandOverToCESignature) : ("Null"), style: { font: { sz: "12" } } },
                    // { value: data.sitePowerDb?.earHandOverToSAQOn ? (data.sitePowerDb?.earHandOverToSAQOn) : ("Null"), style: { font: { sz: "12" } } },



                ]:[]),
            }
        ];
        const StatisticCEASubmissions = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Report Request Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Report Submit Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount


                ],
                data: dataSet1?.map((data) =>data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sesc 02
                    { value: data.sitePowerDb?.caeReportRequestDate ? (data.sitePowerDb?.caeReportRequestDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.caeReportSubmitDate ? (data.sitePowerDb?.caeReportSubmitDate) : ("Null"), style: { font: { sz: "12" } } },




                ]:[]),
            }
        ];
        const StatisticTRCSLComplaints = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "TRCSL 5 Year Report Submitted Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    // { title: "Received Complaints(Yes/No)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Any Pending Complaints", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount


                ],
                data: dataSet1?.map((data) =>  data.sitePowerDb!=null?[

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.trcsl5YearReportSubmittedDate ? (data.trcsl5YearReportSubmittedDate) : ("Null"), style: { font: { sz: "12" } } },
                    // Sesc 02
                    // { value: data.sitePowerDb?.trReceivedComplaints ? (data.sitePowerDb?.trReceivedComplaints) : (data.sitePowerDb?.trReceivedComplaints), style: { font: { sz: "12" } } },
                    // { value: data.sitePowerDb?.caeReportSubmitDate ? ('null') : ("Null"), style: { font: { sz: "12" } } },


                ]:[]),
            }
        ];
        const StatisticRenewableEnergySolar = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Installed Solar Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Solar Panel Make", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Solar Panel Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Comissioned date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 03
                    { title: "Panel Count on roof top", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Panel Count on ground", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Installed location (Rooftop mounted/ tower mounted/ ground)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Solar panel installed over the outdoor cabinet", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Per Panel Capacity (W)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Inverter / Converter  type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Inverter / Converter  Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //04
                    { title: "Per Converter/ Inverter Capacity (kW)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Converter/ Inverter Quantity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Solar expected Output (kWh/Solar kW/day)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "RMS connection Inband/Outband", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "RMS connection mode", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Last Maintenance date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    //  { title: "Average energy generation from solar (kWh / day)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount


                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sesc 02
                    //  { value: data.sitePowerDb?.installedSolarCapacity ? (data.sitePowerDb?.installedSolarCapacity) : (data.sitePowerDb?.installedSolarCapacity), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.installedSolarCapacity != null ? (data.sitePowerDb?.installedSolarCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.solarPanelMake != null ? (data.sitePowerDb?.solarPanelMake) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.solarPanelModel != null ? (data.sitePowerDb?.solarPanelModel) : ("Null"), style: { font: { sz: "12" } } },

                    // { value: data.sitePowerDb?.resPVMake ? (data.sitePowerDb?.resPVMake) : ("Null"), style: { font: { sz: "12" } } },
                    // { value: data.sitePowerDb?.resEquipmentModel ? (data.sitePowerDb?.resEquipmentModel) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.resComissionedDate ? (data.sitePowerDb?.resComissionedDate) : ("Null"), style: { font: { sz: "12" } } },
                    //Sec 03
                    { value: data.sitePowerDb?.resPanelCountOnRooptop != null ? (data.sitePowerDb?.resPanelCountOnRooptop) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.resPanelCountOnGround != null ? (data.sitePowerDb?.resPanelCountOnGround) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.resInstalledLocation ? (data.sitePowerDb?.resInstalledLocation) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.solarPanelInstalledOvertheOutdoorCabinet ? (data.sitePowerDb?.solarPanelInstalledOvertheOutdoorCabinet) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.resPerPanelCapacity ? (data.sitePowerDb?.resPerPanelCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.resInverterType ? (data.sitePowerDb?.resInverterType) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.inverterConverterModel ? (data.sitePowerDb?.inverterConverterModel) : ("Null"), style: { font: { sz: "12" } } },
                    //04
                    { value: data.sitePowerDb?.resInverterCapacity ? (data.sitePowerDb?.resInverterCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.resInverterQuantity ? (data.sitePowerDb?.resInverterQuantity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.resSolarExpectedOutput ? (data.sitePowerDb?.resSolarExpectedOutput) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.resRMSConnectionInband ? (data.sitePowerDb?.resRMSConnectionInband) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.resRMSConnectionMode ? (data.sitePowerDb?.resRMSConnectionMode) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.resLastMaintenanceDate ? (data.sitePowerDb?.resLastMaintenanceDate) : ("Null"), style: { font: { sz: "12" } } },
                    //   { value: data.sitePowerDb?.resRMSConnectionMode ? (data.sitePowerDb?.resRMSConnectionMode) : ("Null"), style: { font: { sz: "12" } } },



                ]:[]),
            }
        ];
        const StatisticSharedOperatorDetailsCommon = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "No of PIV Copies", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Power Connection Authority ", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "CEB/LECO Depot", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Power Connected Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Total Capex Amount (LKR)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

                    // { title: "Received Complaints(Yes/No)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Any Pending Complaints", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount


                ],
                data: dataSet1?.map((data) =>  data.sitePowerDb!=null?[

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sesc 02
                    { value: data.sitePowerDb?.noOfPIVCopies ? (data.sitePowerDb?.noOfPIVCopies) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.powerConnectionAuthority ? (data.sitePowerDb?.powerConnectionAuthority) : ("Null"), style: { font: { sz: "12" } } },

                    { value: data.sitePowerDb?.sooCEBLECODepot ? (data.sitePowerDb?.sooCEBLECODepot) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.powerConnectedDate ? (data.sitePowerDb?.powerConnectedDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.sooCapexAmount3PPowerConnection ? (data.sitePowerDb?.sooCapexAmount3PPowerConnection) : ("Null"), style: { font: { sz: "12" } } },

                    // { value: data.sitePowerDb?.trReceivedComplaints ? (data.sitePowerDb?.trReceivedComplaints) : (data.sitePowerDb?.trReceivedComplaints), style: { font: { sz: "12" } } },
                    // { value: data.sitePowerDb?.caeReportSubmitDate ? ('null') : ("Null"), style: { font: { sz: "12" } } },


                ]:[]),
            }
        ];
        const StatisticSharedOperatorDetails = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 90 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Tenant", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Shared power type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    // Sec 03
                    { title: "Commercial Power- No of Phases", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Commercial Power Shared per phase capacity (A)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Gen Power- No of Phases", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Gen Power Shared per phase capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "DC power capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    // Sec 04
                    { title: "Sharing status(sharing/Disconnected)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Type (AC metering/AC fixed/DC fixed)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Billing method", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Billing start", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Current Billing amount", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    //Sec 05
                    { title: "Disconnected date(if billing end)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Request Reference numbers_Requested Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 






                ],
                data: dataSet11?.map((data) => [

                    // Sec 01
                    { value: data?.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data.tenant ? (data.tenant) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sharedPowerType ? (data.sharedPowerType) : ("Null"), style: { font: { sz: "12" } } },

                    // Sec 03
                    { value: data.commercialPowerNoofPhases ? (data.commercialPowerNoofPhases) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.commercialPowerPerPhaseCapacity != null ? (data.commercialPowerPerPhaseCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.genPowerNoofPhases ? (data.genPowerNoofPhases) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.genPowerPerPhaseCapacity != null ? (data.genPowerPerPhaseCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.dcPowerCapacity != null ? (data.dcPowerCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 04
                    { value: data.sharingStatus ? (data.sharingStatus) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.type ? (data.type) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.billingMethod ? (data.billingMethod) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.billingStartDate ? (data.billingStartDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.billingAmount != null ? (data.billingAmount) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 05
                    { value: data.disconnectedDate ? (data.disconnectedDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.requestReferenceNumbersRequestedDate ? (data.requestReferenceNumbersRequestedDate) : ("Null"), style: { font: { sz: "12" } } },

                ]),
            }
        ];

        const StatisticIdBatteryRacksDetails = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Battery Rack Model Count", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount



                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sesc 02
                    { value: data.sitePowerDb?.batteryRackModelCount ? (data.sitePowerDb?.batteryRackModelCount) : (data.sitePowerDb?.batteryRackModelCount), style: { font: { sz: "12" } } },
                    // { value: data.sitePowerDb?.batteryRackModelCount != null ? (data.sitePowerDb?.batteryRackModelCount) : ("Null"), style: { font: { sz: "12" } } },




                ]:[]),
            }
        ];
        const StatisticIdBatteryRacks = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 90 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "ID Battery Rack Model Count", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "ID Battery Rack Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    // Sec 03





                ],
                data: dataSet12?.map((data) => [

                    // Sec 01
                    { value: data?.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data?.idbatteryRackModelCount ? (data.idbatteryRackModelCount) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.idbatteryrackModel ? (data.idbatteryrackModel) : ("Null"), style: { font: { sz: "12" } } },



                ]),
            }
        ];
        const renewableEnergySolarsDetailsCommon = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "No of Different Solar Panel Models", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Solar expected Output (kWh/Solar kW/day)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount



                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sesc 02
                    { value: data.sitePowerDb?.resNoOfDifferentSolarPanelModels ? (data.sitePowerDb?.resNoOfDifferentSolarPanelModels) : (data.sitePowerDb?.resNoOfDifferentSolarPanelModels), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.resExpectedEnergyGenerationFromWind ? (data.sitePowerDb?.resExpectedEnergyGenerationFromWind) : (data.sitePowerDb?.resExpectedEnergyGenerationFromWind), style: { font: { sz: "12" } } },
                    // { value: data.sitePowerDb?.batteryRackModelCount != null ? (data.sitePowerDb?.batteryRackModelCount) : ("Null"), style: { font: { sz: "12" } } },




                ]:[]),
            }
        ];
        const renewableEnergySolarsDetails = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 90 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "No of Different Solar Panel Models", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Equipment Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    // Sec 03
                    { title: "Commissioned Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Panel Count On Roof Top", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Panel Count On Ground", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Installed Location", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Solar Panel Installed Over The Outdoor Cabinet", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    // Sec 04
                    { title: "Per Panel Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Inverter Type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Inverter Converter Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Per Converter Inverter Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Converter Inverter Quantity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    //Sec 05
                    { title: "Last Maintenance Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Non Shading Space Availability On Shelter", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Non Shading Space Availability On Ground", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Shelter Direction From The Tower", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Non Shading Space Direction From The Tower", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Average Energy Generation From Solar", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "PV Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "PV Make", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "RMS connection Inband", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "RMS connection Mode", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                ],
                data: dataSet13?.map((data) => [

                    // Sec 01
                    { value: data?.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data?.noofDifferentSolarPanelModels ? (data.noofDifferentSolarPanelModels) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.equipmentModel ? (data.equipmentModel) : ("Null"), style: { font: { sz: "12" } } },

                    // Sec 03
                    { value: data.commissionedDate ? (data.commissionedDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.panelCountOnRoofTop != null ? (data.panelCountOnRoofTop) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.panelCountOnGround ? (data.panelCountOnGround) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.installedLocation != null ? (data.installedLocation) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.solarPanelInstalledOverTheOutdoorCabinet != null ? (data.solarPanelInstalledOverTheOutdoorCabinet) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 04
                    { value: data?.solarPanelModel?.perPanelCapacity ? (data?.solarPanelModel?.perPanelCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.inverterType ? (data.inverterType) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.inverterConverterModel ? (data.inverterConverterModel?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.inverterConverterModel ? (data.inverterConverterModel?.perConverterInverterCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.converterInverterQuantity != null ? (data.converterInverterQuantity) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 05
                    { value: data.lastMaintenanceDate ? (data.lastMaintenanceDate) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.nonShadingSpaceAvailabilityOnShelter ? (data.nonShadingSpaceAvailabilityOnShelter) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.nonShadingSpaceAvailabilityOnGround ? (data.nonShadingSpaceAvailabilityOnGround) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.shelterDirectionFromTheTower ? (data.shelterDirectionFromTheTower) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.nonShadingSpaceDirectionFromTheTower ? (data.nonShadingSpaceDirectionFromTheTower) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.averageEnergyGenerationFromSolar ? (data.averageEnergyGenerationFromSolar) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.pvcapacity ? (data.pvcapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.pvmake ? (data.pvmake) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rmsconnectionInband ? (data.rmsconnectionInband) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rmsconnectionMode ? (data.rmsconnectionMode) : ("Null"), style: { font: { sz: "12" } } },

                ]),
            }
        ];


        const StatisticrenewableEnergyWinds = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Expected energy generation  from wind (kWh / day)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Average energy generation from wind (kWh / day)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount


                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sesc 02
                    //  { value: data.sitePowerDb?.installedSolarCapacity ? (data.sitePowerDb?.installedSolarCapacity) : (data.sitePowerDb?.installedSolarCapacity), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.rewExpectedEnergyGeneration != null ? (data.sitePowerDb?.rewExpectedEnergyGeneration) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.rewAverageEnergyGeneration != null ? (data.sitePowerDb?.rewAverageEnergyGeneration) : ("Null"), style: { font: { sz: "12" } } },




                ]:[]),
            }
        ];

        const renewableEnergyWindsDetails = [
            {
                columns: [
                    // Sec 01
                    { title: "ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 90 } },//char width 
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Make", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Wind Potential", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    // Sec 03
                    { title: "Per Wind Turbine Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Wind Turbine Count", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Installed Location", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Installed Height", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Commissioned Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    // Sec 04
                    { title: "Energy Feed", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "RMS Connection Inband", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "RMS Connection Mode", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 





                ],
                data: dataSet14?.map((data) => [

                    // Sec 01
                    { value: data?.id ? (data.id) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 02
                    { value: data?.make?.name ? (data?.make?.name) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data?.windPotential ? (data?.windPotential) : ("Null"), style: { font: { sz: "12" } } },

                    // Sec 03
                    { value: data?.perWindTurbineCapacity ? (data.perWindTurbineCapacity) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.windTurbineCount ? (data.windTurbineCount) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.installedLocation != null ? (data.installedLocation) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.installedHeight ? (data.installedHeight) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.commissionedDate != null ? (data.commissionedDate) : ("Null"), style: { font: { sz: "12" } } },
                    // Sec 04
                    { value: data.energyFeed != null ? (data.energyFeed) : ("Null"), style: { font: { sz: "12" } } },

                    { value: data.rmsconnectionInband ? (data.rmsconnectionInband) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.rmsconnectionMode ? (data.rmsconnectionMode) : ("Null"), style: { font: { sz: "12" } } },


                ]),
            }
        ];
        
        const renewableEnergyProjectUpgrades = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "No. of  WIP projects", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Average energy generation from wind (kWh / day)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount


                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sesc 02
                    //  { value: data.sitePowerDb?.installedSolarCapacity ? (data.sitePowerDb?.installedSolarCapacity) : (data.sitePowerDb?.installedSolarCapacity), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.puNoOfwipProjects != null ? (data.sitePowerDb?.puNoOfwipProjects) : ("Null"), style: { font: { sz: "12" } } },
                //    { value: data.sitePowerDb?.rewAverageEnergyGeneration != null ? (data.sitePowerDb?.rewAverageEnergyGeneration) : ("Null"), style: { font: { sz: "12" } } },




                ]:[]),
            }
        ];
        const renewableEnergySiteDependencies = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Power Dependencies", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "SAQ Dependencies", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Civil Dependencies", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Average energy generation from wind (kWh / day)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount


                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sesc 02
                    //  { value: data.sitePowerDb?.installedSolarCapacity ? (data.sitePowerDb?.installedSolarCapacity) : (data.sitePowerDb?.installedSolarCapacity), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.sdPowerDependencies != null ? (data.sitePowerDb?.sdPowerDependencies) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.sdSAQDependencies != null ? (data.sitePowerDb?.sdSAQDependencies) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.sdCivilDependencies != null ? (data.sitePowerDb?.sdCivilDependencies ) : ("Null"), style: { font: { sz: "12" } } },
                //    { value: data.sitePowerDb?.rewAverageEnergyGeneration != null ? (data.sitePowerDb?.rewAverageEnergyGeneration) : ("Null"), style: { font: { sz: "12" } } },




                ]:[]),
            }
        ];
        const renewableAverageEnergy = [
            {
                columns: [
                    // Sec 01
                    { title: "Site Id", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
                    { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // Sec 02
                    { title: "Last Month Commercial Power  Consumption  (kWh)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Last Month Solar Generation  (kWh)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Last Month Generator Running Hours (Hr)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Last Month PG Running Hours (Hr)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Last Month Commercial Power Cost (LKR)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Last Month Fuel Filling  Liters", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Last Month Fuel Cost (LKR)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    { title: "Last Month PG Cost (LKR)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
                    // { title: "Average energy generation from wind (kWh / day)", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount


                ],
                data: dataSet1?.map((data) => data.sitePowerDb!=null? [

                    // Sec 01
                    { value: data.siteId ? (data.siteId) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.siteName ? (data.siteName) : ("Null"), style: { font: { sz: "12" } } },
                    // Sesc 02
                    //  { value: data.sitePowerDb?.installedSolarCapacity ? (data.sitePowerDb?.installedSolarCapacity) : (data.sitePowerDb?.installedSolarCapacity), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.lastMonthCommercialPowerConsumption != null ? (data.sitePowerDb?.lastMonthCommercialPowerConsumption) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.lastMonthSolarGeneration != null ? (data.sitePowerDb?.lastMonthSolarGeneration) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.lastMonthGeneratorRunningHours != null ? (data.sitePowerDb?.lastMonthGeneratorRunningHours ) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.lastMonthPGRunningHours != null ? (data.sitePowerDb?.lastMonthPGRunningHours ) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.lastMonthCommercialPowerCost != null ? (data.sitePowerDb?.lastMonthCommercialPowerCost ) : ("Null"), style: { font: { sz: "12" } } },
                   
                    { value: data.sitePowerDb?.lastMonthFuelFillingLiters != null ? (data.sitePowerDb?.lastMonthFuelFillingLiters ) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.lastMonthFuelCost != null ? (data.sitePowerDb?.lastMonthFuelCost ) : ("Null"), style: { font: { sz: "12" } } },
                    { value: data.sitePowerDb?.lastMonthPGCost != null ? (data.sitePowerDb?.lastMonthPGCost ) : ("Null"), style: { font: { sz: "12" } } },
                //    { value: data.sitePowerDb?.rewAverageEnergyGeneration != null ? (data.sitePowerDb?.rewAverageEnergyGeneration) : ("Null"), style: { font: { sz: "12" } } },




                ]:[]),
            }
        ];




        const columns = [

            {
                title: "Site ID",

                dataIndex: "siteId",
                key: "site",
                fixed: 'left',
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Site Name",

                dataIndex: "siteName",
                key: "siteName",
                fixed: 'left',
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Region Name",
                dataIndex: "regionName",
                key: "regionName",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Depot Name",
                dataIndex: "depotName",
                key: "depotName",
                render: (item) => <span>{item}</span>,
            },
            {
                title: "Site Address",
                // dataIndex: "depotName",
                key: "siteAddressList",
                render: (item) => <span>{item?.siteAddressList[0]?.address}</span>,
            },
            {
                title: "Longitude",
                // dataIndex: "depotName",
                key: "Longitude",
                render: (item) => <span>{item?.siteAddressList[0]?.longitude}</span>,
            },
            {
                title: "Latitude",
                // dataIndex: "depotName",
                key: "Latitude",
                render: (item) => <span>{item?.siteAddressList[0]?.latitude}</span>,
            },
            {
                title: "Site Type",
                // dataIndex: "depotName",
                key: "siteType",
                render: (item) => <span>{item?.siteType?.name}</span>,
            },
            {
                title: "Site Status",
                // dataIndex: "depotName",
                key: "status",
                render: (item) => <span>{item?.status}</span>,
            },
            {
                title: "Site Elevation",
                // dataIndex: "siteElevation",
                key: "siteElevation",
                render: (item) => <span>{item.siteElevation}</span>,
            },
            {
                title: "Site Key Required",
                // dataIndex: "siteKeyRequired",
                key: "siteKeyRequired",
                render: (item) => <span>{item.siteKeyRequired ? ('true') : ('false')}</span>,
            },
            {
                title: "Site On Air Date",
                // dataIndex: "siteOnAirDate",
                key: "siteOnAirDate",
                render: (item) => <span>{item.siteOnAirDate}</span>,
            },
            // {
            //     title: "Site PowerDb",
            //     dataIndex: "sitePowerDb",
            //     key: "sitePowerDb",
            //     render: (item) => <span>{item}</span>,
            // },
            {
                title: "Site Topology Terrain Mapping",
                // dataIndex: "siteTopologyTerrainMapping",
                key: "siteTopologyTerrainMapping",
                render: (item) => <span>{item.siteTopologyTerrainMapping}</span>,
            },
            {
                title: "Shelter Size",
                // dataIndex: "shelterSize",
                key: "shelterSize",
                render: (item) => <span>{item.shelterSize}</span>,
            },
            {
                title: "Tower Height",
                // dataIndex: "towerHeight",
                key: "towerHeight",
                render: (item) => <span>{item.towerHeight}</span>,
            },
            {
                title: "Tower Legs Count",
                // dataIndex: "towerLegsCount",
                key: "towerLegsCount",
                render: (item) => <span>{item.towerLegsCount}</span>,
            },
            {
                title: "Tower Owner Category",
                // dataIndex: "towerOwnerCategory",
                key: "towerOwnerCategory",
                render: (item) => <span>{item.towerLegsCount}</span>,
            },
            {
                title: "Vehicle Type",
                // dataIndex: "vehicleType",
                key: "vehicleType",
                render: (item) => <span>{item.vehicleType}</span>,
            },


        ];

        const timestamp = Date.now();
        return (
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Power DB Full Export</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export </a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Power DB Full Export</a></li>

                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='row' style={{ padding: 8 }}>
                        <div className="col-md-3 col-lg-3 col-xl-3">


                            <Select
                                mode="multiple"
                                showSearch
                                onChange={this.onChangeRegion}
                                name="regionId"
                                value={this.state.filterRegion}

                                style={{ width: "100%" }}
                                placeholder="Select Regions"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >

                                {this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((item, index) => {
                                    return <Option value={item?.id}>{item?.name}</Option>;
                                })}
                            </Select>
                        </div>
                        <div className="col-md-3 col-lg-3 col-xl-3">
                            <Select
                                mode="multiple"
                                showSearch
                                onChange={this.onChangeDepot}
                                value={this.state.filterDepot}
                                name="depotsId"
                                style={{ width: "100%" }}
                                placeholder="Select Depot"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >

                                {this.state.depot_list.map((item, index) => {
                                    return <Option value={item?.id}>{item?.name}</Option>;
                                })}
                            </Select>
                        </div>
                        <div class="col-md-3">

                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="Site Name or Id"
                                style={{ width: "100%" }}
                                // options={this.state.allSite} 
                                loading={this.state.allSite_loading}
                                name="siteId"
                                onChange={(e) => this.onChangeSite(e)}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {this.state.allSite.map((item, index) => {
                                    return <Option value={item.value}>{item.label + ' (' + item.siteID + ')'}</Option>;
                                })}
                            </Select>
                        </div>
                        {/* <div class="col-md-3">
                            <Input placeholder="Power Connection type" onChange={this.handlePowerConnectiontype} />

                        </div> */}
                        <div class="col-md-3">

                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="Rectifier Make"
                                style={{ width: "100%" }}
                                // options={this.state.allSite} 
                                //loading={this.state.allSite_loading}
                                name="Rectifierid"
                                onChange={(e) => this.onChangeRectifier(e)}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {this.state.allRectifierMake.map((item, index) => {
                                    return <Option value={item.value}>{item.label}</Option>;
                                })}
                            </Select>
                        </div>
                    </div>
                    <div className='row' style={{ padding: 8 }}>

                        <div class="col-md-3">

                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="Battery Make/Brand"
                                style={{ width: "100%" }}
                                // options={this.state.allSite} 
                                //loading={this.state.allSite_loading}
                                name="BatteryID"
                                onChange={(e) => this.onChangeBatteryMake(e)}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {this.state.allBatteryBankMake.map((item, index) => {
                                    return <Option value={item.value}>{item.label}</Option>;
                                })}
                            </Select>
                        </div>
                        <div class="col-md-3">

                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="Generator Make"
                                style={{ width: "100%" }}
                                // options={this.state.allSite} 
                                //loading={this.state.allSite_loading}
                                name="generatorId"
                                onChange={(e) => this.onChangeGenerator(e)}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {this.state.allGeneratorModel.map((item, index) => {
                                    return <Option value={item.value}>{item.label}</Option>;
                                })}
                            </Select>
                        </div>   {/* <div class="col-md-3">
                            <Input placeholder="Power Connection type" onChange={this.handlePowerConnectiontype} />

                        </div> */}
                        <div class="col-md-3">
                            {/* <Input placeholder="Indoor/Outdoor" onChange={this.handleIndoorOutdoor} /> */}

                            <Select

                                showSearch
                                placeholder="Indoor/Outdoor"
                                style={{ width: "100%" }}
                                // options={this.state.allSite} 
                                //loading={this.state.allSite_loading}
                                name="generatorId"
                                onChange={(e) => this.onChangeIndoorOutdoor(e)}
                                optionFilterProp="children"
                                allowClear

                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                <Option value={1}>{'Indoor'}</Option>
                                <Option value={2}>{'Outdoor'}</Option>

                            </Select>

                        </div>
                        <div class="col-md-3">
                            {/* <Input placeholder="Indoor/Outdoor" onChange={this.handleIndoorOutdoor} /> */}

                            <Select

                                showSearch
                                placeholder="Power Connection type"
                                style={{ width: "100%" }}
                                // options={this.state.allSite} 
                                //loading={this.state.allSite_loading}
                                name="generatorId"
                                onChange={(e) => this.onChangepowerConnectionType(e)}
                                optionFilterProp="children"
                                allowClear

                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {this.state.powerConnectionType.map((item, index) => {
                                    return <Option value={item.id}>{item.name}</Option>;
                                })}

                            </Select>

                        </div>
                    </div>

                    <div className="row" style={{ display: "flex", justifyContent: 'end' }}>
                        <div className='col-md-2' style={{ textAlign: 'end', padding: 0 }}>
                            <button
                                onClick={this.searchClick}
                                class="btn btn-primary"
                                style={{ marginRight: -16 }}
                            >
                                <i class="fas fa-search"> </i> Search
                            </button>
                        </div>
                        <div className='col-md-1' style={{ textAlign: 'end' }}>
                            <button type="button" style={{ marginLeft: 5 }} class="btn btn-dark" onClick={() => this.clearBtnClick()}>Clear</button>
                        </div>


                    </div>
                    {this.state.progressBarLoading ? (<div className='row' style={{ paddingLeft: 16, paddingRight: 8 }}>

                        <Progress percent={parseFloat(this.state.progressPrasantage).toFixed(2)} />
                    </div>) : ('')}

                    <br /><br />
                    <div className="col-lg-12 col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row ">
                                    <div className="col">
                                        <h4 className="card-title">
                                            Report -Power DB Full export{" "}

                                        </h4>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">

                                {/* <Table
                                size="small"
                                columns={columns}
                                // rowKey="id"
                                loading={this.state.loading}
                                dataSource={this.state.data_list}
                            /> */}
                                <Table
                                    size="small"
                                    columns={columns}
                                    // loading={this.state.loading && this.state.rectifierloading && this.state.batteryBanksloading && this.state.airConditionersloading && this.state.spdsloading && this.state.cabinetsloading && this.state.generatorsloading && this.state.atsesloading && this.state.dcdusloading && this.state.fcbsloading && this.state.sharedOutOperatorsesloading}
                                    loading={this.state.tabaleLoading}
                                    dataSource={this.state.data_list}
                                    scroll={{
                                        x: 1300,
                                    }}
                                />

                                {/* <pre>
                                    {JSON.stringify(this.state.data_list, 0, 1)}
                                </pre> */}

                                <br></br>
                                <div className='row'>
                                    <div className='col-md-10'>

                                        <ExcelFile
                                            filename={"Power DB Full export -" + timestamp}

                                            element={
                                                <button
                                                    disabled={this.state.loading}
                                                    class="btn btn-primary"
                                                    style={{ float: "right" }}
                                                    onClick={downloadCompanies}
                                                >
                                                    <i class="fas fa-file-export"> </i> {this.state.loading ? (<img width={20} src={'https://media.tenor.com/wpSo-8CrXqUAAAAj/loading-loading-forever.gif'}></img>) : ('')} Download

                                                </button>
                                            }

                                        >

                                            <ExcelSheet dataSet={StatisticDataSet} name="General" />
                                            <ExcelSheet dataSet={StatisticMainPowerConnection} name="Main Power Connection" />
                                            <ExcelSheet dataSet={StatisticAccessTechnologies} name="Access Technologies" />

                                            <ExcelSheet dataSet={StatisticPowerCapacity} name="Power Capacity (kW)" />
                                            <ExcelSheet dataSet={StatisticDCLoad} name="DC Load" />
                                            
                                            <ExcelSheet dataSet={StatisticRectifiers} name="Rectifiers" /> 
                                            <ExcelSheet dataSet={StatisticDataSetRectifier} name="Rectifier_1" /> 

                                            <ExcelSheet dataSet={StatisticDataBatteryBank} name="Battery Banks" />
                                            <ExcelSheet dataSet={StatisticDataBatteryBank1} name="Battery Banks_1" />
                                            <ExcelSheet dataSet={StatisticAirConditioners} name="Air Conditioners" />
                                            <ExcelSheet dataSet={StatisticAirconditionersData2} name="AC-1" />
                                            <ExcelSheet dataSet={StatisticSpdsDetailsCommon} name="SPD" />
                                            <ExcelSheet dataSet={StatisticSpdsDetails} name="SPD 1" />
                                            <ExcelSheet dataSet={StatisticDatadPowerCabinet} name="Cabinet" />
                                            <ExcelSheet dataSet={StatisticDatadPowerCabinet1} name="Cabinet_1" />
                                            <ExcelSheet dataSet={StatisticDataCabinDetails} name="Cabin Details" />
                                            <ExcelSheet dataSet={StatisticGeneratorsDataData} name="Generators" />
                                            
                                            
                                            <ExcelSheet dataSet={StatisticGeneratorsDataData2} name="Generator_1" />
                                            <ExcelSheet dataSet={StatisticAtsDataDetailsCommon} name="ATS" />
                                            <ExcelSheet dataSet={StatisticAtsDataDetails} name="ATS_1" />
                                            <ExcelSheet dataSet={StatisticDataDCDUDetails} name="DCDU" />

                                            <ExcelSheet dataSet={StatisticPowerDcduDetails} name="DCDU 1" />
                                            <ExcelSheet dataSet={StatisticFcbDetails1} name="FBC" />
                                            <ExcelSheet dataSet={StatisticFcbDetails} name="FBC_1" />
                                            <ExcelSheet dataSet={StatisticEarthing} name="Earthing" />
                                            <ExcelSheet dataSet={StatisticCEASubmissions} name="CEA Submissions" />
                                            <ExcelSheet dataSet={StatisticTRCSLComplaints} name="TRCSL Complaints" />
                                            <ExcelSheet dataSet={StatisticRenewableEnergySolar} name="Renewable Energy-Solar" />
                                            <ExcelSheet dataSet={StatisticSharedOperatorDetailsCommon} name="Shared Out -Operators" />
                                            <ExcelSheet dataSet={StatisticSharedOperatorDetails} name="Shared Out -Operators_1" />
                                            <ExcelSheet dataSet={StatisticIdBatteryRacksDetails} name="ID Battery Racks" />
                                            <ExcelSheet dataSet={StatisticIdBatteryRacks} name="ID Battery Racks 1" />
                                            <ExcelSheet dataSet={renewableEnergySolarsDetailsCommon} name="Renewable Energy Solars" />
                                            <ExcelSheet dataSet={renewableEnergySolarsDetails} name="Renewable Energy Solars_1" />
                                            <ExcelSheet dataSet={StatisticrenewableEnergyWinds} name="Renewable Energy Winds" />
                                            <ExcelSheet dataSet={renewableEnergyWindsDetails} name="Renewable Energy Winds_1" />
                                            <ExcelSheet dataSet={renewableEnergyProjectUpgrades} name="Project Upgrades" />
                                            <ExcelSheet dataSet={renewableEnergySiteDependencies} name="Site Dependencies" />
                                            <ExcelSheet dataSet={renewableAverageEnergy} name="Average Energy costs (LKR)" />
                                       
                                            
                                            
                                            {/* <ExcelSheet dataSet={StatisticAccessTechnologies} name="Access Technologies" /> */}
                                            {/* <ExcelSheet dataSet={StatisticMainPowerConnection} name="Main Power Connection" />
                                            <ExcelSheet dataSet={StatisticSharedBackupPower} name="Shared Backup Power" />
                                            <ExcelSheet dataSet={StatisticPowerCapacity} name="Power Capacity (kW)" />
                                            <ExcelSheet dataSet={StatisticDCLoad} name="DC Load" />
                                            <ExcelSheet dataSet={StatisticRectifiers} name="Rectifiers" />
                                            <ExcelSheet dataSet={StatisticDataSetRectifier} name="Rectifier_1" />
                                            <ExcelSheet dataSet={StatisticDataBatteryBank} name="Battery Banks" />
                                            <ExcelSheet dataSet={StatisticDataBatteryBank1} name="Battery Banks_1" />
                                            <ExcelSheet dataSet={StatisticAirConditioners} name="Air Conditioners" />
                                            <ExcelSheet dataSet={StatisticAirconditionersData2} name="AC-1" />
                                            <ExcelSheet dataSet={StatisticSpdsDetailsCommon} name="SPD" />
                                            <ExcelSheet dataSet={StatisticSpdsDetails} name="SPD 1" />
                                            <ExcelSheet dataSet={StatisticDatadPowerCabinet} name="Cabinet" />
                                            <ExcelSheet dataSet={StatisticDatadPowerCabinet1} name="Cabinet_1" />
                                            <ExcelSheet dataSet={StatisticDataCabinDetails} name="Cabin Details" />
                                            <ExcelSheet dataSet={StatisticGeneratorsDataData} name="Generators" />

                                            <ExcelSheet dataSet={StatisticGeneratorsDataData2} name="Generator_1" />
                                            <ExcelSheet dataSet={StatisticAtsDataDetailsCommon} name="ATS" />
                                            <ExcelSheet dataSet={StatisticAtsDataDetails} name="ATS_1" />
                                            <ExcelSheet dataSet={StatisticDataDCDUDetails} name="DCDU" />

                                            <ExcelSheet dataSet={StatisticPowerDcduDetails} name="DCDU 1" />
                                            <ExcelSheet dataSet={StatisticFcbDetails1} name="FBC" />
                                            <ExcelSheet dataSet={StatisticFcbDetails} name="FBC_1" />
                                            <ExcelSheet dataSet={StatisticEarthing} name="Earthing" />
                                            <ExcelSheet dataSet={StatisticCEASubmissions} name="CEA Submissions" />
                                            <ExcelSheet dataSet={StatisticTRCSLComplaints} name="TRCSL Complaints" />
                                            <ExcelSheet dataSet={StatisticRenewableEnergySolar} name="Renewable Energy-Solar" />
                                            <ExcelSheet dataSet={StatisticSharedOperatorDetailsCommon} name="Shared Out -Operators" />
                                            <ExcelSheet dataSet={StatisticSharedOperatorDetails} name="Shared Out -Operators_1" />
                                            <ExcelSheet dataSet={StatisticIdBatteryRacksDetails} name="ID Battery Racks" />
                                            <ExcelSheet dataSet={StatisticIdBatteryRacks} name="ID Battery Racks 1" />
                                            <ExcelSheet dataSet={renewableEnergySolarsDetailsCommon} name="Renewable Energy Solars" />
                                            <ExcelSheet dataSet={renewableEnergySolarsDetails} name="Renewable Energy Solars_1" />
                                            <ExcelSheet dataSet={StatisticrenewableEnergyWinds} name="Renewable Energy Winds" />
                                            <ExcelSheet dataSet={renewableEnergyWindsDetails} name="Renewable Energy Winds_1" />
                                            <ExcelSheet dataSet={renewableEnergyProjectUpgrades} name="Project Upgrades" />
                                            <ExcelSheet dataSet={renewableEnergySiteDependencies} name="Site Dependencies" />
                                            <ExcelSheet dataSet={renewableAverageEnergy} name="Average Energy costs (LKR)" /> */}

                                            {/* <ExcelSheet dataSet={StatisticAccessTechnologies} name="Access Technologies" />
                                        <ExcelSheet dataSet={StatisticMainPowerConnection} name="Main Power Connection" />
                                        <ExcelSheet dataSet={StatisticSharedBackupPower} name="Shared Backup Power" />
                                        <ExcelSheet dataSet={StatisticPowerCapacity} name="Power Capacity (kW)" />
                                        <ExcelSheet dataSet={StatisticDCLoad} name="DC Load" />
                                        <ExcelSheet dataSet={StatisticRectifiers} name="Rectifiers" />
                                        <ExcelSheet dataSet={StatisticDataSetRectifier} name="Rectifier_1" />
                                        <ExcelSheet dataSet={StatisticDataBatteryBank} name="Battery Banks" />
                                        <ExcelSheet dataSet={StatisticDataBatteryBank1} name="Battery Banks_1" />
                                        <ExcelSheet dataSet={StatisticDatadPowerCabinet} name="Cabinet" />
                                        <ExcelSheet dataSet={StatisticDatadPowerCabinet1} name="Cabinet_1" />
                                        <ExcelSheet dataSet={StatisticAirConditioners} name="Air Conditioners" />
                                        <ExcelSheet dataSet={StatisticAirconditionersData} name="AC1 -Indoor Unit" />
                                        
                                        <ExcelSheet dataSet={StatisticGeneratorsDataData} name="Generators" />
                                        <ExcelSheet dataSet={StatisticGeneratorsDataData2} name="Generator_1" />
                                        <ExcelSheet dataSet={StatisticPowerDcduDetails} name="DCDU 1" />
                                        <ExcelSheet dataSet={StatisticAtsDataDetails} name="ATS" />
                                        <ExcelSheet dataSet={StatisticFcbDetails1} name="FBC" />
                                        <ExcelSheet dataSet={StatisticFcbDetails} name="FBC_1" />
                                      
                                        <ExcelSheet dataSet={StatisticEarthing} name="Earthing" />
                                        <ExcelSheet dataSet={StatisticCEASubmissions} name="CEA Submissions" />
                                        <ExcelSheet dataSet={StatisticTRCSLComplaints} name="TRCSL Complaints" />
                                        <ExcelSheet dataSet={StatisticRenewableEnergySolar} name="Renewable Energy-Solar" />
                                        <ExcelSheet dataSet={StatisticSharedOperatorDetails} name="Shared Out -Operators" /> */}
                                        </ExcelFile>
                                    </div>
                                    <div className='col-md-2'>
                                        <button type="button" style={{ marginLeft: 5 }} class="btn btn-dark" onClick={() => this.fullExpoortDownload()}>Power DB Full export</button>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    //console.log("state", state);
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
        pins: state.pins
    };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(PowerDataExport);