import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { ToastContainer, toast } from "material-react-toastify";
import { createNewUser, getUserPageData, editUser, getMasterData, getAllUsersList, userActive } from "../api/index";
import { Table, Input, Popconfirm, Tag, Space, Button, Row, Col, Drawer, Select, Spin, Switch, Checkbox, Collapse } from "antd";
import {
  EditOutlined, EyeOutlined
} from '@ant-design/icons';

const { Option } = Select;


class Users extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      validators: {
        mobile: {  // name the rule
          message: 'Invalid mobile number',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^(\+\d{1,3}[- ]?)?\d{10}$/) && params.indexOf(val) === -1
          },
          required: true  // optional
        },
        alpha_num: {  // name the rule
          message: 'Invalid nic',
          rule: (val, params, validator) => {

            if (val.length == 10 || val.length == 12) {
              return true
            } else {
              return false
            }


          },
          required: true  // optional
        }
      }
    });

    this.state = {
      filterTable: null,
      baseData: "",
      onClose: false,
      open: false,
      organizationList: [],
      vendorList: [],
      userRoleList: [],
      user_id: "",
      name: "",
      email: "",
      nic: "",
      mobileNumber: "",
      mobileNumber2: "",
      organization: {},
      organizationId: '',
      vendor: {},
      vendorId: '',
      roles: [],
      role_name_list: [],
      isLoading: false,
      regionList: [],
      selectedRegionList: [],
      depotList: [],
      selectedDepotList: [],
      depotOfficerSelectedDepotList: [],
      showDosDepotList: false,
      selectedUserId: 0,
      depotLevelUser: false,
      regionLevelUser: false,
      isEditDrawer: false,
      buttonLodading: false,
      fullArray: [],
      data_list: [],
      loading: false,
      openPop1: false,
      selectIdForActive: null,
      selectForActiveOrNoActive: null,
      isDepotOfficer: false,
      mobileNumber2Error: false,
      viewArray: null,
      openView: false
    };
  }

  componentDidMount() {
    this.getBaseData();
    this.getMasterData();
    this.getDataAllUsersList();
  }
  getDataAllUsersList = async () => {
    this.setState({ baseData: [], fullArray: [], buttonLodading: true, loading: true, })
    var form = new FormData();
    form.append("pageNo", 0);
    var res = await getAllUsersList(form);
    console.log('getAllUsersList');
    console.log(res);
    var pageCount = 0;
    if (res.success) {
      pageCount = res?.data?.pageCount;
      if (pageCount == 0) {

        this.setState({
          loading: false,
          buttonLodading: false,
          baseData: [],
        });
      }
      if (pageCount == 1) {
        this.setState({
          loading: false,
          buttonLodading: false,
          baseData: res.data.data,
        });
      }

      if (pageCount > 1) {
        for (let i = 0; i < pageCount; i++) {

          console.log(i)
          this.getDataAllUsersList2(i, pageCount)
          if (i == pageCount - 1) {
            this.setState({
              loading: false,

            })
          }
        }
      }


    }
  }
  getDataAllUsersList2 = async (data, pagecout) => {



    var form = new FormData();
    form.append("pageNo", data);

    var res = await getAllUsersList(form);
    if (res.success) {
      this.setState({
        fullArray: [...this.state.fullArray, ...res.data.data]
      }, () => {
        if (data + 1 == pagecout) {
          console.log('data+1')
          console.log(data + 1)
          this.setState({
            buttonLodading: false
          })
        }
        this.setState({

          baseData: this.state.fullArray,
        });
      })

    }

  }
  getMasterData = async () => {
    var res = await getMasterData();

    console.log(res);
    if (res.success) {
      this.setState({
        vendorList: res.data.vendor
      })

    }


  }

  getBaseData = async () => {
    this.showLoading();
    var res = await getUserPageData();

    if (res.success) {
      // const roleList = [...res.data.roleList];
      this.setState({
        // baseData: res.data.userList,
        organizationList: res.data.organizationList,
        regionList: res.data.regionList,
        depotList: res.data.depotList,
        userRoleList: res.data.roleList,
      });
    }
    this.hideLoading();
  }




  showLoading() {
    this.setState({
      isLoading: true
    })
  }


  hideLoading() {
    this.setState({
      isLoading: false
    })
  }


  formItemChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
    //console.log(obj);
  };


  search = (value) => {
    const { baseData } = this.state;
    //console.log("PASS", { value });

    const filterTable = baseData.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(value.toLowerCase())
      )
    );

    this.setState({ filterTable });
  };
  filterRegions = (value, region) => {
    var rr = region.filter((d) => d.name.includes(value));
    //console.log(rr);
    return rr.length > 0;
  };
  filterRegions22 = (value, region) => {
    var rr = region.includes(value);
    //console.log(rr);
    return rr.length > 0;
  };

  openRegisterDrawer = () => {
    this.setState({
      open: true,
      isEditDrawer: false,
      depotLevelUser: false,
      regionLevelUser: false,
    })
  }
  onDrawerViewClose = () => {
    this.setState({
      openView: false,
      viewArray: null
    })
  }

  onDrawerClose = () => {
    this.setState({
      open: false
    })
    this.clearFormState();
  }
  searchVendorListChange = (id) => {
    this.setState({
      vendorError: false,
      vendorId: id,
      vendor: { "id": id }
    })
  }

  searchOrganizationListChange = (id) => {
    this.setState({
      organizationError: false,
      organizationId: id,
      organization: { "id": id }
    })
  }

  searchRoleListChange = (roleList) => {
    console.log(roleList);
    this.setState({
      showDosDepotList: false,
      isDepotOfficer: false
    })
    var _roles = []
    roleList.map((item) => {
      var userRole = this.state.userRoleList.filter((role) => role.id === item);
      _roles.push({ "id": item, "depoLevel": item != 1 ? userRole[0].depoLevel : false, "regionLevel": userRole[0].regionLevel })
      if (item == 1) {
        this.setState({
          showDosDepotList: true,
          isDepotOfficer: true
        })
      }
    })


    console.log(this.state.selectedDepotList);
    this.setState({
      roleError: false,
      roles: _roles,
      depotLevelUser: _roles.filter((role) => role.depoLevel === true && role.id !== 1).length > 0 ? true : false,
      regionLevelUser: _roles.filter((role) => role.regionLevel === true).length > 0 ? true : false,
    })
  }

  depotListChange = (depotList) => {
    var _depots = []
    depotList.map((item) => {
      _depots.push({ "id": item, "primary": false })

    })
    this.setState({
      selectedDepotList: _depots
    })


  }


  depotOfficerListChange = (depotList) => {
    console.log(depotList)
    var _depots = []
    depotList.map((item) => {
      _depots.push({ "id": item, "primary": true })

    })
    console.log(_depots);
    this.setState({
      depotOfficerSelectedDepotList: _depots
    })


  }


  regionListChange = (regionList) => {
    var _regions = []
    regionList.map((item) => {
      _regions.push({ "id": item })

    })
    this.setState({
      selectedRegionList: _regions
    })


  }

  formInputChangemobileNumber2 = (e) => {
    var key = e.target.id;
    this.setState({
      [key]: e.target.value
    }, () => {


      if (this.state.mobileNumber2 != '' || this.state.mobileNumber2 != null) {
        var phoneno = /^\d{10}$/;
        if (this.state.mobileNumber2.match(phoneno) == null) {
          this.setState({
            mobileNumber2Error: true
          })
        } else {
          this.setState({
            mobileNumber2Error: false
          })
        }
      } else {

        this.setState({
          mobileNumber2Error: false
        })
      }

      if (e.target.value == '') {
        console.log("dyu")

        this.setState({
          mobileNumber2Error: false
        })

      }

    });
  }
  formInputChange = (e) => {
    var key = e.target.id;
    this.setState({
      [key]: e.target.value
    });
  }

  emailChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }
  onDrawerClose2 = (e) => {
    console.log('sss')
    this.setState({ openPop1: false, switchDisable: false, filterTable: null, baseData: "" }, () => {
      this.getBaseData();
      this.getMasterData();
      this.getDataAllUsersList();
    })
  }
  submitFormTogal = async (e) => {
    this.setState({ openPop1: true, selectIdForActive: e, switchDisable: true }, () => {

    })

  }
  submitFormStatus = async (e) => {

    this.setState({ openPop1: false, switchDisable: false, selectForActiveOrNoActive: e }, () => {
      this.AllDataSubmitUpdateActivet()
    })


  }

  AllDataSubmitUpdateActivet = async () => {
    var form = new FormData();
    form.append("id", this.state.selectIdForActive);
    form.append("status", this.state.selectForActiveOrNoActive);

    var res = await userActive(form);

    console.log(res)
    if (res.success) {
      this.setState({ openPop1: false, switchDisable: false, filterTable: null, baseData: "" }, () => {
        this.getBaseData();
        this.getMasterData();
        this.getDataAllUsersList();
      })
    }



  }

  submitForm = async (e) => {

    if (this.validator.allValid()) {
      console.log(this.state.mobileNumber2)

      if (this.state.mobileNumber2 == '') {

        if (Object.keys(this.state.organization).length === 0 || this.state.roles.length === 0) {
          if (Object.keys(this.state.organization).length === 0) {
            this.setState({
              organizationError: true
            })
          }
          if (this.state.roles.length === 0) {
            this.setState({
              roleError: true
            })
          }
          if (this.state.vendor.length === 0) {
            this.setState({
              vendorError: true
            })
          }

          return;
        }
      } else {
        var phoneno = /^\d{10}$/;

        console.log("hojjj")


        if (Object.keys(this.state.organization)?.length === 0 || this.state.roles?.length === 0 ) {
          if (Object.keys(this.state.organization)?.length === 0) {
            this.setState({
              organizationError: true
            })
          }
          if (this.state.roles.length === 0) {
            this.setState({
              roleError: true
            })
          }
          if (this.state.vendor.length === 0) {
            this.setState({
              vendorError: true
            })
          }
        

          return;
        }



      }



      this.setState({
        isLoading: true,
        open: false
      })
      var user = {
        "user_id": this.state.userId,
        "name": this.state.name,
        "email": this.state.email,
        "nic": this.state.nic,
        "mobileNumber": this.state.mobileNumber,
        "mobileNumber2": this.state.mobileNumber2,
        "status": 1,
        "organization": this.state.organization,
        "vendor": this.state.vendor,
        "roles": this.state.roles,

      };

      if (!this.state.isDepotOfficer) {
        this.setState({
          depotOfficerSelectedDepotList: []
        })
      }
      const mergedDepotListArray = this.state.depotOfficerSelectedDepotList.concat(this.state.selectedDepotList);

      const uniqueDepotList = mergedDepotListArray.reduce((acc, curr) => {
        const existingItem = acc.find(item => item.id === curr.id);
        if (!existingItem) {
          acc.push(curr);
        }
        return acc;
      }, []);

      var data = {
        "user": user,
        "deports": uniqueDepotList,
        "regions": this.state.selectedRegionList
      }

      console.log(data);

      var res = false;
      if (!this.state.isEditDrawer) {
        res = await createNewUser(data);
      }
      else {
        res = await editUser(this.state.selectedUserId, data);
      }

      this.setState({
        isLoading: false
      })
      if (res.success) {


        if (this.state.isEditDrawer) {
          toast.success("Successfully Updated")
        } else {
          toast.success("Successfully Created")

        }
        this.getDataAllUsersList();
         window.location.reload();
        // this.getBaseData();
        // this.clearFormState();
      }
      else {
        toast.error("Error. Something went wrong")
      }


    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }


  clearFormState() {
    this.setState({
      "userId": "",
      "name": "",
      "email": "",
      "nic": "",
      "mobileNumber": "",
      "mobileNumber2": "",
      "organization": {},
      "organizationId": "",
      "vendor": {},
      "vendorId": '',
      "roles": []
    })
  }
  viewUser = (data) => {
    console.log(data)
    this.setState({
      viewArray: data
    }, () => {
      this.setState({
        openView: true
      })
    })

  }

  editUser = (data) => {


    const uniqueIds = new Set();
    const resultArray = data.deport.reduce((acc, item) => {
      if (!uniqueIds.has(item.id)) {
        uniqueIds.add(item.id);
        acc.push(item);
      }
      return acc;
    }, []);



    var depotList = resultArray.filter((depot) => depot.primary === false)
    var primaryDepotList = resultArray.filter((depot) => depot.primary === true)

    this.setState({
      selectedUserId: data.id,
      name: data.name,
      email: data.email,
      nic: data.nic,
      mobileNumber: data.mobileNumber.length === 9 ? "0" + data.mobileNumber : data.mobileNumber,
      mobileNumber2: data.mobileNumber2,
      userId: data.user_id,
      roles: data.roles.map((role) => {
        return { "id": role.id };
      }),
      organization: data.organization,
      organizationId: data?.organization?.id,
      vendor: data.vendor,
      vendorId: data?.vendor?.id,
      selectedRegionList: data.region.map((region) => {
        return { "id": region.id, };
      }),
      selectedDepotList: depotList != null ? depotList.map((depot) => {
        return { "id": depot.id, "primary": false };


      }) : [],
      depotOfficerSelectedDepotList: primaryDepotList != null ? primaryDepotList.map((depot) => {
        return { "id": depot.id, "primary": true };


      }) : [],
      depotLevelUser: data.roles.filter((role) => role.depoLevel === true && role.id !== 1).length > 0 ? true : false,
      regionLevelUser: data.roles.filter((role) => role.regionLevel === true).length > 0 ? true : false,
      isDepotOfficer: data.roles.filter((role) => role.id === 1).length > 0 ? true : false,
      showDosDepotList: data.roles.filter((role) => role.id === 1).length > 0 ? true : false,
      open: true,
      isEditDrawer: true
    })


  }

  onIsDepotOfficerChange = (e) => {
    this.setState({
      showDosDepotList: e.target.checked
    })

    let _roles = [...this.state.roles]
    if (e.target.checked) {
      _roles.push({ "id": 1, "depoLevel": true, "regionLevel": false })
    }
    else {
      const itemIndex = _roles.findIndex(item => item.id === 1);

      if (itemIndex !== -1) {
        // Create a new array without the item at the specified index
        const updatedItems = [..._roles.slice(0, itemIndex), ..._roles.slice(itemIndex + 1)];
        _roles = updatedItems;
      }
    }


    this.setState({
      roles: _roles,
      isDepotOfficer: e.target.checked
    })
  };

  render() {
    const { Panel } = Collapse;
    const { filterTable, baseData } = this.state;

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        // defaultSortOrder: "ascend",
        sorter: (a, b) => a.id - b.id,
        sortDirections: ["descend"],
        render: (text, record, index) => <span>{text}</span>,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (text) => <span>{text}</span>,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        filters: [
          {
            text: "@dns.dialog.lk",
            value: "@dns.dialog.lk",
          },
          {
            text: "@eimsky.com",
            value: "@eimsky.com",
          },
        ],
        // specify the condition of filtering result
        // here is that finding the name started with `value`
        onFilter: (value, record) => record.email.includes(value),
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: "Mobile",
        dataIndex: "mobileNumber",
        key: "mobileNumber",
      },
      {
        title: "Roles",
        key: "roles",
        dataIndex: "roles",
        filters: this.props.roles.map((item) => ({
          text: item.name.toUpperCase(),
          value: item.name,
        })),

        onFilter: (value, record) => this.filterRegions(value, record.roles),
        render: (tags) => (
          <>
            {tags.map((tag) => {
              let color = "geekblue";
              return (
                <Tag color={color} key={tag} style={{ fontSize: 10 }}>
                  {tag.name.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: "Region",
        key: "region",
        dataIndex: "region",
        filters: this.props.regions.map((item) => ({
          text: item.name.toUpperCase(),
          value: item.name,
        })),

        onFilter: (value, record) => this.filterRegions(value, record.region),

        render: (tags) => (
          <>
            {tags.map((tag) => {
              let color = "green";

              return (
                <Tag color={color} key={tag} style={{ fontSize: 10 }}>
                  {tag.name.toUpperCase()}
                </Tag>
              );
            })}
          </>
        ),
      },
      {
        title: "Organization",
        key: "organization",
        dataIndex: "organization",
        filters: this.props.organization.map((item) => ({
          text: item.name.toUpperCase(),
          value: item.name,
        })),

        onFilter: (value, record) =>
          record.organization.name.indexOf(value) === 0,

        render: (organization) => (
          <>
            <Tag style={{ fontSize: 10 }} color="volcano" key={organization}>
              {organization.name.toUpperCase()}
            </Tag>
          </>
        ),
      },
      //   {
      //     title: "Active12",
      //     dataIndex: "status",
      //     key: "status",
      //     filters: [
      //       {
      //         text: "Active",
      //         value:1,
      //       },
      //       {
      //         text: "Inactive",
      //         value:0,
      //       },
      //     ],

      //     onFilter: (value, record) => record.status === value,



      // },

      {
        title: "Active",

        key: "status",
        filters: [
          {
            text: "Active",
            value: 1,
          },
          {
            text: "Inactive",
            value: 0,
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.status === value,
        render: (item) => <>{item.status ? (
          <>
            <Popconfirm
              title="Are You Sure?"
              description="Open Popconfirm with async logic"
              disabled={this.state.openPop1}
              onConfirm={() => this.submitFormStatus(0)}
              okButtonProps={{
                loading: this.state.confirmLoading,
              }}
              onCancel={this.onDrawerClose2}
            > <Switch disabled={this.state.switchDisable} onChange={() => this.submitFormTogal(item.id)} defaultChecked checkedChildren="Yes" unCheckedChildren="No" />   </Popconfirm> </>) : (<Popconfirm
              title="Are You Sure?"
              description="Open Popconfirm with async logic"
              disabled={this.state.openPop1}
              onConfirm={() => this.submitFormStatus(1)}
              okButtonProps={{
                loading: this.state.confirmLoading,
              }}
              onCancel={this.onDrawerClose2}
            > <Switch disabled={this.state.switchDisable} onChange={() => this.submitFormTogal(item.id)} checkedChildren="Yes" unCheckedChildren="No" /></Popconfirm>)}

        </>,

      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            <EditOutlined onClick={() => this.editUser(record)} />
            <EyeOutlined onClick={() => this.viewUser(record)} />
          </Space>
        ),
      },
    ];
    return (
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <div class="row">
                <div class="col">
                  <h4 class="page-title">Users</h4>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Admin</a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Users</a></li>

                  </ol>
                </div>
                <div class="col-auto align-self-center">
                  <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                    <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                    <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <Row>
                  <Col span={21}><h4 className="card-title">Users</h4></Col>
                  <Col style={{ textAlign: 'end' }} span={3}>

                    <button class="btn btn-primary" onClick={() => this.openRegisterDrawer()}>Create User</button>

                  </Col>
                </Row>


              </div>
              <Spin spinning={this.state.isLoading} delay={200}>
                <div className="card-body">
                  <div className='row'>
                    <div className="col-md-10">
                      <Input
                        enterButton
                        style={{ marginBottom: 5 }}
                        placeholder="Search ..."
                        value={this.state.currValue}
                        onChange={(e) => {
                          const currValue = e.target.value;
                          this.search(currValue);
                          this.setState({
                            currValue:currValue
                        })
                        }}
                      />
                    </div>
                    <div className="col-md-2" style={{ textAlign: 'end' }}>
                      <button type="button" class="btn btn-primary " onClick={(e) => {
                        this.setState({
                          filterTable: null
                        }, () => {
                          this.setState({
                            currValue: ''
                          })

                        })

                      }} >Clear Search</button>

                    </div>
                  </div>



                  <Table
                    size="small"
                    columns={columns}
                    loading={this.state.loading}
                    dataSource={filterTable == null ? [...baseData].sort((a, b) => b.id - a.id) : [...filterTable].sort((a, b) => b.id - a.id)}

                  />
                </div></Spin>
            </div>
          </div>
          <Drawer maskClosable={false} title={this.state.isEditDrawer ? "Edit User" : "Create User"} width={"600px"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open}>
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <form  >
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" for="exampleInputEmail1">Name</label>
                            <input class="form-control" style={{ border: "0.5px solid #d9d9d9" }} id="name" value={this.state.name} onChange={(e) => this.formInputChange(e, "name")} />
                            <p style={{ color: "red", fontSize: '10px' }}>{this.validator.message('name', this.state.name, 'required|alpha_space')} </p>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" for="exampleInputEmail1">Email address</label>
                            <input type="email" style={{ border: "0.5px solid #d9d9d9" }} class="form-control" id="email" value={this.state.email} onChange={(e) => this.formInputChange(e, "email")} aria-describedby="emailHelp" />
                            <p style={{ color: "red", fontSize: '10px' }}>{this.validator.message('email1', this.state.email, 'required|email')} </p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" for="exampleInputEmail1">NIC</label>
                            <input type="text" style={{ border: "0.5px solid #d9d9d9" }} class="form-control" id="nic" aria-describedby="emailHelp" value={this.state.nic} onChange={(e) => this.formInputChange(e, "nic")} />
                            <p style={{ color: "red", fontSize: '10px' }}>{this.validator.message('nic', this.state.nic, 'required|alpha_num')} </p>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" for="exampleInputEmail1">Mobile No 1</label>
                            <input type="email" style={{ border: "0.5px solid #d9d9d9" }} class="form-control" id="mobileNumber" aria-describedby="emailHelp" value={this.state.mobileNumber} onChange={(e) => this.formInputChange(e, "mobileNumber")} />
                            <p style={{ color: "red", fontSize: '10px' }}>{this.validator.message('mobileNumber', this.state.mobileNumber, 'required|mobile:0700000000')} </p>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" for="exampleInputEmail1">Mobile No 2</label>
                            <input type="text" style={{ border: "0.5px solid #d9d9d9" }} class="form-control" id="mobileNumber2" aria-describedby="emailHelp" value={this.state.mobileNumber2} onChange={(e) => this.formInputChangemobileNumber2(e, "mobileNumber2")} />
                            {this.state.mobileNumber2Error && <p style={{ color: "red", fontSize: '10px' }}>Invalid mobile number.</p>}

                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" for="exampleInputEmail1">User Id</label>
                            <input type="email" style={{ border: "0.5px solid #d9d9d9" }} class="form-control" id="userId" aria-describedby="emailHelp" value={this.state.userId} onChange={(e) => this.formInputChange(e, "userId")} />
                            <p style={{ color: "red", fontSize: '10px' }}>{this.validator.message('userId', this.state.userId, 'required|userId')} </p>

                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" for="exampleInputEmail1">Organization</label>
                            <Select
                              // mode="multiple"
                              showSearch
                              name="regionId"
                              onChange={(e) => this.searchOrganizationListChange(e)}
                              placeholder="Organization"
                              style={{ width: "100%" }}
                              value={this.state.organization?.id}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.state.organizationList.map((item, index) => {
                                return <Option key={index} value={item.id}>{item.name}</Option>;
                              })}
                            </Select>
                            <p style={{ color: "red", fontSize: '10px' }}>{this.validator.message('organization', this.state.organizationId, 'required|organization')} </p>

                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" for="exampleInputEmail1">Vendor</label>
                            <Select
                              // mode="multiple"
                              showSearch
                              name="vendorId"
                              onChange={(e) => this.searchVendorListChange(e)}
                              placeholder="vendor"
                              style={{ width: "100%" }}
                              value={this.state.vendor?.id}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.state.vendorList.map((item, index) => {
                                return <Option key={index} value={item.id}>{item.name}</Option>;
                              })}
                            </Select>

                            <p style={{ color: "red", fontSize: '10px' }}>{this.validator.message('Vendor', this.state.vendorId, 'required|Vendor')} </p>


                          </div>
                        </div>

                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" for="exampleInputEmail1">User Role</label>
                            <Select
                              mode="multiple"
                              showSearch
                              name="roleId"
                              value={this.state.roles.map((item) => item.id)}
                              onChange={(e) => this.searchRoleListChange(e)}
                              placeholder="User Role"
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.state.userRoleList.map((item, index) => {
                                return <Option key={index} value={item.id} >{item.name}</Option>;
                              })}
                            </Select>
                            {this.state.roleError && <p style={{ color: "red", fontSize: '10px' }}>The role field is required.</p>}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        {this.state.regionLevelUser && <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" for="exampleInputEmail1">Region</label>
                            <Select
                              mode="multiple"
                              showSearch
                              name="regionId"
                              onChange={(e) => this.regionListChange(e)}
                              placeholder="Region"
                              style={{ width: "100%" }}
                              value={this.state.selectedRegionList.map((item) => item.id)}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.state.regionList.map((item, index) => {
                                return <Option key={index} value={item.id}>{item.name}</Option>;
                              })}
                            </Select>
                            {/* {this.state.organizationError && <p style={{ color: "red", fontSize: '10px' }}>The organization field is required.</p>} */}
                          </div>
                        </div>}
                        {this.state.depotLevelUser && <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" for="exampleInputEmail1">Other Depot</label>
                            <Select
                              mode="multiple"
                              showSearch
                              name="roleId"
                              value={this.state.selectedDepotList.map((item) => item.id)}
                              onChange={(e) => this.depotListChange(e)}
                              placeholder="Depot"
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.state.depotList.map((item, index) => {
                                return <Option key={index} value={item.id} >{item.name}</Option>;
                              })}
                            </Select>
                            {this.state.roleError && <p style={{ color: "red", fontSize: '10px' }}>The role field is required.</p>}
                          </div>
                        </div>}
                      </div>
                      <div class="row">
                        <div class="col-lg-6">
                          <Checkbox style={{ marginBottom: "20px" }} onChange={(e) => this.onIsDepotOfficerChange(e)} checked={this.state.isDepotOfficer}>IsDepotOfficer</Checkbox>
                        </div>
                      </div>
                      <div class="row">

                        {this.state.showDosDepotList && <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label" for="exampleInputEmail1">Depot</label>
                            <Select
                              mode="multiple"
                              showSearch
                              onChange={(e) => this.depotOfficerListChange(e)}
                              placeholder="Depot"
                              value={this.state.depotOfficerSelectedDepotList.map((item) => item.id)}
                              style={{ width: "100%" }}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.state.depotList.map((item, index) => {
                                return <Option key={index} value={item.id} >{item.name}</Option>;
                              })}
                            </Select>
                            {this.state.roleError && <p style={{ color: "red", fontSize: '10px' }}>The role field is required.</p>}
                          </div>
                        </div>}
                      </div>

                    </form>
                    <button class="btn btn-primary" onClick={() => this.submitForm()}>Submit</button>
                  </div>
                </div>
              </div>
            </div>

          </Drawer>
          <Drawer maskClosable={false} title={"User Details"} width={"600px"} placement="right" onClose={() => this.onDrawerViewClose()} visible={this.state.openView}>
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <ul class="list-group">

                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i> Name

                        </div>
                        {this.state.viewArray?.name}
                        {/* <span class="badge badge-outline-success">12</span> */}




                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i> user_id


                        </div>

                        <span class="badge badge-outline-success">{this.state.viewArray?.user_id}</span>




                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i>NIC


                        </div>

                        <span class="badge badge-outline-secondary">{this.state.viewArray?.nic}</span>




                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i> Email


                        </div>

                        <span class="badge badge-outline-primary">{this.state.viewArray?.email}</span>

                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i> MobileNumber 1


                        </div>

                        <span class="badge badge-outline-warning">{this.state.viewArray?.mobileNumber?.slice(0, -7) + ' ' + this.state.viewArray?.mobileNumber?.slice(2, -4) + ' ' + this.state.viewArray?.mobileNumber?.slice(-4)}</span>

                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i> MobileNumber 2


                        </div>

                        <span class="badge badge-outline-primary">{this.state.viewArray?.mobileNumber2?.slice(0, -7) + ' ' + this.state.viewArray?.mobileNumber2?.slice(2, -4) + ' ' + this.state.viewArray?.mobileNumber2?.slice(-4)}</span>

                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i> Organization


                        </div>

                        <span class="badge badge-outline-danger">{this.state.viewArray?.organization.name}</span>

                      </li>

                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i> Vendor


                        </div>

                        <span class="badge badge-outline-secondary">{this.state.viewArray?.vendor.name}</span>

                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i> Roles


                        </div>
                        <div className="col-md-8" style={{textAlign:'end'}}>
                        {this.state.viewArray?.roles.map((x) => {
                          return <span class="badge badge-outline-dark">{x.name}</span>
                        })}
                        </div>



                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i> Region


                        </div>
                       
                          <div className="col-md-8" style={{textAlign:'end'}}>
                            {this.state.viewArray?.region.map(x => <> <span class="badge badge-outline-warning">{x.name}</span>  </>)}
                          </div>

                        




                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        <div>
                          <i class="la la-hand-o-right text-primary me-2"></i> Deport


                        </div>
                       
                          <div className="col-md-8" style={{textAlign:'end'}}>
                            {this.state.viewArray?.deport.map(x => <> <span class="badge badge-outline-info">{x.name}</span>  </>)}
                          </div>

                        




                      </li>
                    </ul>

                  </div>
                </div>
              </div></div>

          </Drawer>
        </div>
        <ToastContainer position="top-right" autoClose={5000} />

      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    organization: state.organization,
    regions: state.regions,
    roles: state.roles,
  };
}
export default connect(mapStateToProps)(Users);
