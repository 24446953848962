import React, { useState, useEffect } from "react";
import {
    getAllActiveSessions,
    allJobsData,
    registerNewSessionRewamp,
    startNewJourneyRewamp,
    endJourneyRewamp,
    getSessionDataBySessionID,
    getOngoingJourneyByDriverID,
    getCurrentTaskData,
    endSessionRewampAPI,
    getDriversWithVehicleMastersAPI,
    getAllSessionsForAdminPendingAndApproved,
    getAllApprovedSessionsForAdminAPI,
    getAllRegion,
    getAllJourneyBySessionID
} from "../../api/index";
import { Divider, Avatar, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Col, Collapse, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Spin } from 'antd';
import {
    CarOutlined,
    NodeIndexOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CheckOutlined,
    DashboardOutlined,
    SearchOutlined,
    PhoneOutlined,
    FieldTimeOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './style.css';
import dayjs from 'dayjs';
import moment from 'moment';
const { Meta } = Card;
const { Option } = Select;
const { Step } = Steps;
const { RangePicker } = DatePicker;

const SessionsList = () => {
    const [sessions, setSessions] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [dropdownValue, setDropdownValue] = useState("PENDING_APPROVAL");
    const [indexValue, setIndexValue] = useState();
    const [selectedVehicleType, setSelectedVehicleType] = useState({
        totalVehicleJobs: 0,
        completedJobs: 0,
        runningJobs: 0,
        totalVehicles: 0,
        poolVehicles: 0,
        fixedVehicles: 0,
        drivers: 0
    });
    const [currentStep, setCurrentStep] = useState(0);
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = useState(false);
    const history = useHistory();

    const [checkInTime, setCheckInTime] = useState(null);
    const [driverID, setDriverID] = useState(null);
    const [sessionID, setSessionID] = useState(null);
    const [regionList, setRegionList] = useState([]);

    const [checkOutTime, setCheckOutTime] = useState(null);
    const [journeyStartDateTime, setJourneyStartDateTime] = useState(null);
    const [journeyEndDateTime, setJourneyEndDateTime] = useState(null);
    const [SessionData, setSessionData] = useState([]);
    const [allDrivers, setAllDrivers] = useState([]);
    const [startMeaterReading, setStartMeaterReading] = useState(null);
    const [endMeaterReading, setEndMeaterReading] = useState(null);
    const [totalJourneyDuration, setTotalJourneyDuration] = useState(null);
    const [taskID, setTaskID] = useState(null);
    const [journeyListInSession, setJourneyListInSession] = useState([]);

    const [isCheckIn, setIsCheckIn] = useState(false);

    const [dateTimeStart, setDateTimeStart] = useState(null);
    const [dateTimeEnd, setDateTimeEnd] = useState(null);
    const [regionID, setRegionID] = useState(null);

    const [approvedSessions, setApprovedSessions] = useState([]);
    const [pendingSessions, setPendingSessions] = useState([]);    
    const [distanceUnit, setDistanceUnit] = useState("KM");
    const [isLoading, setIsLoading] = useState(false);

    // 07 days before today
    const date = new Date(new Date().setDate(new Date().getDate() - 7));
    const today = new Date();
    const dateStrStart = date.toISOString().split('T')[0] + 'T00:00:00';
    const dateStrEnd = today.toISOString().split('T')[0] + 'T23:59:59';
    const dateTimeArr = [];
    dateTimeArr.push(dateStrStart);
    dateTimeArr.push(dateStrEnd);
    const [startDateEndDate, setStartDateEndDate] = useState(dateTimeArr);

    useEffect(() => {
        fetchSessions(0);
        allJobData();
        fetchAllgetAllDrivers();
        fetchAllRegions();
    }, []);

    const fetchAllRegions = async () => {
        const response = await getAllRegion();
        if (response.success) {
            console.log(response.data);
            setRegionList(response.data.results);
        } else {
            message.error("Failed to fetch regions");
        }
    };

    const fetchGetAllSessionsForAdminPendingAndApproved = async (regionID) => {
        const formdata = new FormData();
        formdata.append("startDate", startDateEndDate[0] ? startDateEndDate[0] : dateStrStart);
        formdata.append("endDate", startDateEndDate[1] ? startDateEndDate[1] : dateStrEnd);
        setSearchData([]);

        let pageCount = await fetchGetAllSessionsForAdminPendingAndApprovedPageCount(formdata);
        let dataArr = [];

        for (let i = 1; i <= pageCount; i++) {
            setIsLoading(true);
            const response = await getAllSessionsForAdminPendingAndApproved(i, formdata);
            if (response.success) {
                const responseData = response.data.results.data;
                if (regionID !== 0) {
                    const filteredData = responseData.filter((session) => {
                        return session?.vehicleDetails?.regionId === regionID;
                    });
                    dataArr.push(...filteredData.reverse());
                } else {
                    dataArr.push(...responseData.reverse());
                }
                setSearchData(dataArr);
                setPendingSessions(dataArr);
            } else {
                message.error("Error fetching sessions");
            }
        }
        setIsLoading(false);
        return true;
    }
    

    const fetchGetAllSessionsForAdminPendingAndApprovedPageCount = async (formdata) => {
        const response = await getAllSessionsForAdminPendingAndApproved(1, formdata);
        if (response.success) {
            const responseDataPageCount = response?.data?.results?.totalPages;
            console.log(responseDataPageCount);
            return responseDataPageCount;
        } else {
            message.error("Error Fetching Page Count");
            return 0;
        }
    }

    const fetchGetAllApprovedSessionsForAdminAPI = async (regionID) => {
        const formdata = new FormData();
        formdata.append("startDate", startDateEndDate[0] ? startDateEndDate[0] : dateStrStart);
        formdata.append("endDate", startDateEndDate[1] ? startDateEndDate[1] : dateStrEnd);
        setSearchData([]);
        let pageCount = await fetchGetAllApprovedSessionsForAdminAPIPageCount(formdata);
        let dataArr = [];
        for (let i = 1; i <= pageCount; i++) {
            setIsLoading(true);
            const response = await getAllApprovedSessionsForAdminAPI(i, formdata);
            if (response.success) {
                const responseData = response.data.results.data;
                if (regionID !== 0) {
                    const filteredData = responseData.filter((session) => {
                        return session?.vehicleDetails?.regionId === regionID;
                    });
                    dataArr.push(...filteredData.reverse());
                } else {
                    dataArr.push(...responseData.reverse());
                }
                setSearchData(dataArr);
                setApprovedSessions(dataArr);
            } else {
                message.error("Error fetching sessions");
                return false;
            }
        }
        setIsLoading(false);
        return true;
    }

    const fetchGetAllApprovedSessionsForAdminAPIPageCount = async (formdata) => {
        const response = await getAllApprovedSessionsForAdminAPI(1, formdata);
        if (response.success) {
            const responseDataPageCount = response?.data?.results?.totalPages;
            return responseDataPageCount;
        } else {
            message.error("Error Fetching Page Count");
            return 0;
        }
    }

    const fetchSessions = async (regionID) => {
        setIsLoading(true);
        const response = await getAllActiveSessions();
        if (response.success) {
            setSessions(response.data.results);
            setPendingSessions(response.data.results);
            setSearchData([]);
            if (regionID !== 0) {
                const filteredData = response.data.results.filter((session) => {
                    return session?.vehicleDetails?.regionId === regionID;
                });
                setSearchData(filteredData.reverse());
            } else {
                setSearchData(response.data.results.reverse());
            }
            setIsLoading(false);
            return true;
        } else {
            message.error("Error fetching sessions");
            setIsLoading(false);
            return false;
        }
    }

    const setStartAndEndTimes = (date, dateString) => {
        const startTimeDate = dateString[0]+"T00:00:00";
        const endTimeDate = dateString[1]+"T23:59:59";
        const dateArray = [];
        dateArray.push(startTimeDate);
        dateArray.push(endTimeDate);
        console.log('Start Time : ', startTimeDate);
        console.log('End Time : ', endTimeDate);
        setStartDateEndDate(dateArray);
    }

    const handleButtonClick = (record) => {
        const route = `/vehicle/session/${record}`;
        history.push(route);
    };

    const resetValues = () => {
        setJourneyStartDateTime(null);
        setJourneyEndDateTime(null);
        setStartMeaterReading(null);
        setEndMeaterReading(null);
        setTotalJourneyDuration(null);
        setTaskID(null);
    }

    const newJourney = () => {
        resetValues();
        setCurrentStep(1);
        form.resetFields(['meaterReading', 'startDateAndTime', 'endMeaterReading', 'endDateAndTime']);
    }

    const timeSet = (date, dateString) => {
        setCheckInTime(dateString);
    }

    const journeyStartTime = (date, dateString) => {
        setJourneyStartDateTime(dateString);
    }

    const setDateAndTimeRange = (date, dateString) => {
        const startDate = dateString[0];
        const endDate = dateString[1];
        setDateTimeStart(startDate);
        setDateTimeEnd(endDate);
    }

    const journeyEndTime = (date, dateString) => {
        console.log('Journey End Date Time : ', dateString);
        setJourneyEndDateTime(dateString);
        const duration = moment.duration(moment(dateString).diff(moment(journeyStartDateTime)));
        const hours = duration.asMinutes();
        console.log('Duration : ', hours);
        setTotalJourneyDuration(hours);
    }

    const openModel = () => {
        setOpenModal(true);
    }


    const approveCloseModel = () => {
        Modal.confirm({
            title: "Warning!",
            content: `Are you Sure You Want To Close This Model?`,
            onOk: () => {
                closeModel();
            },
            onCancel: () => { },
        });
    }

    const closeModel = () => {
        setOpenModal(false);
    }

    const endSessionBySessionID = async () => {
        if (checkOutTime === null || checkOutTime === "" || checkInTime === null || checkInTime === "") {
            message.error("Please Fill All Fields, Related To Session End");
        } else {

            if (new Date(checkOutTime) <= new Date(checkInTime)) {
                message.error("Check out time must be after check in time");
            } else {
                const duration = moment.duration(moment(checkOutTime).diff(moment(checkInTime)));
                const hours = duration.asMinutes();
                const endSessionRequest = JSON.stringify({
                    id: sessionID,
                    checkoutDateTime: convertToISOFormat(checkOutTime),
                    hoursWorked: hours,
                    normalOt: 0.0,
                    doubleOt: 0.0,
                    remark: "End of session"
                });
                const formData = new FormData();
                formData.append('endSessionRequest', new Blob([endSessionRequest], { type: 'application/json' }));
                const response = await endSessionRewampAPI(sessionID, formData);
                if (response.success) {
                    message.success("Session Ended Successfully");
                    setOpenModal(false);
                } else {
                    message.error("Error Ending Session");
                }
            }
        }
    }

    const ApproveEdndSession = () => {
        Modal.confirm({
            title: "Confirm End Session",
            content: `Are you Sure You Want To End This Session?`,
            onOk: () => {
                endSessionBySessionID();
            },
            onCancel: () => { },
        });
    }

    const fetchgetOngoingJourneyByDriverID = async (id) => {
        const response = await getOngoingJourneyByDriverID(id);
        if (response.success) {
            fetchCurrentTaskData(response.data.results.camundaInstanceId);
        } else {
            console.log('Error In Fetching API Request : ', response.message);
            message.error('Error In Fetching API Request : ' + response.message);
        }
    };

    const fetchCurrentTaskData = async (id) => {
        var data = {
            "processInstanceId": id
        }
        var responseAPI = await getCurrentTaskData(data);
        if (responseAPI.success) {
            const taskID = responseAPI.data.results;
            setTaskID(taskID);
            setCurrentStep(currentStep + 1);
        } else {
            console.log('Error In Fetching API Request : ', responseAPI.message);
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    };

    const ApproveEndJourney = () => {
        Modal.confirm({
            title: "Confirm End Journey",
            content: `Are you Sure You Want To End This Journey?`,
            onOk: () => {
                endJourney();
            },
            onCancel: () => { },
        });
    }

    const milesToKilometers = (miles) => {
        return miles * 1.6;
    }

    const endJourney = async () => {
        if (endMeaterReading === null || endMeaterReading === "" || journeyEndDateTime === null || journeyEndDateTime === "") {
            message.error("Please Fill All Fields, Related To Journey End");
        } else {
            if (new Date(journeyEndDateTime) <= new Date(journeyStartDateTime)) {
                message.error("Journey end time must be after journey start time");
            } else {
                if (endMeaterReading - startMeaterReading > 1500) {
                    message.error("Distance travelled cannot be more than 1500 km");
                } else {
                    if (endMeaterReading - startMeaterReading < 0) {
                        message.error("End meter reading cannot be less than start meter reading");
                    } else {

                        let startMeaterReadingInKM = startMeaterReading;
                        let endMeaterReadingInKM = endMeaterReading;
                        let totalDistanceInKM = endMeaterReadingInKM - startMeaterReadingInKM;

                        if (distanceUnit !== "KM") {
                            totalDistanceInKM = milesToKilometers(totalDistanceInKM);
                        }

                        const formData = new FormData();
                        const journeyDetails = JSON.stringify({
                            startMeterReading: startMeaterReadingInKM,
                            endMeterReading: endMeaterReadingInKM,
                            distanceUnit: SessionData?.vehicleDistanceUnit,
                            startDateTime: convertToISOFormat(journeyStartDateTime),
                            endDateTime: convertToISOFormat(journeyEndDateTime),
                            totalDistance: totalDistanceInKM,
                            journeyDuration: totalJourneyDuration,
                            sessionId: sessionID,
                            isDefaultTravelingOfficer: true,
                            endLocationLatitude: 0.0,
                            endLocationLongitude: 0.0,
                            comment: "Session Created By Admin"
                        });

                        // setJourneyListInSession([...journeyListInSession, journeyDetails]);

                        formData.append('journeyRequest', new Blob([journeyDetails], { type: 'application/json' }));
                        const response = await endJourneyRewamp(taskID, formData);
                        if (response.success) {
                            message.success("Journey Ended Successfully");
                            setCurrentStep(currentStep + 1);
                        } else {
                            message.error("Error Ending Journey");
                        }
                    }
                }
            }
        }
    }

    const fetchSessionData = async (id) => {
        var responseAPI = await getSessionDataBySessionID(id);
        if (responseAPI.success) {
            setSessionData(responseAPI.data.results);
            setDistanceUnit(responseAPI?.data?.results?.vehicleDetails?.vehicleDistanceUnit);
            setCurrentStep(currentStep + 1);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    };

    const fetchAllgetAllDrivers = async () => {
        const response = await getDriversWithVehicleMastersAPI();
        if (response.success) {
            setAllDrivers(response.data.results);
        } else {
            message.error("Error Fetching Drivers List");
        }
    }

    const currentTimeFetch = () => {
        const currentDate = new Date();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
        const currentTime = `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
        return currentTime;
    }

    const convertToISOFormat = (dateTimeString) => {
        const [date, time] = dateTimeString.split(" ");
        const [year, month, day] = date.split("-");
        const [hour, minute, second] = time.split(":");

        return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
    }

    const handleNewSession = () => {
        Modal.confirm({
            title: "Confirm New Session",
            content: `Are you Sure You Want To Start New Session?`,
            onOk: () => {
                registerNewSessionRewampRegister();
            },
            onCancel: () => { },
        });
    };

    const registerNewSessionRewampRegister = async () => {
        if (checkInTime === null || checkInTime === "" || driverID === null || driverID === "") {
            message.error("Please Fill All Fields, Related To Session Start");
        } else {
            const response = await registerNewSessionRewamp({
                checkinDateTime: convertToISOFormat(checkInTime),
                driverId: driverID,
                isAdminCreated: true,
                adminString: localStorage.getItem("logged_user_email")
            });

            if (response.success) {
                setSessionID(response.data.results);
                message.success("Session Started Successfully");
                setIsCheckIn(true);
                setSessionID(response.data.results);
                fetchSessionData(response.data.results);
            } else {
                const errorMessage = response.data.errors[0].errorMessage;
                message.error(errorMessage);
            }
        }
    }

    const ApproveStartNewJourney = () => {
        Modal.confirm({
            title: "Confirm Start Journey",
            content: `Are you Sure You Want To Start New Journey?`,
            onOk: () => {
                startJourney();
            },
            onCancel: () => { },
        });
    }

    const startJourney = async () => {
        if (journeyStartDateTime === null || journeyStartDateTime === "" || startMeaterReading === null || startMeaterReading === "") {
            message.error("Please Fill All Fields, Related To Journey Start");
        } else {
            const sessionStartDateTime = convertToISOFormat(checkInTime);
            const journeiesStartDateTime = convertToISOFormat(journeyStartDateTime);
            if (new Date(journeiesStartDateTime) <= new Date(sessionStartDateTime)) {
                message.error("Journey start time must be after session start time");
            } else {
                let startMeaterReadingInKM = startMeaterReading;

                // if (SessionData?.distanceUnit !== "KM") {
                //     startMeaterReadingInKM = milesToKilometers(startMeaterReading);
                // }
                const formData = new FormData();

                const journeyDetails = JSON.stringify({
                    driverId: driverID,
                    startMeterReading: startMeaterReadingInKM,
                    distanceUnit: SessionData?.vehicleDistanceUnit,
                    startDateTime: convertToISOFormat(journeyStartDateTime),
                    sessionId: sessionID,
                    isDefaultTravelingOfficer: true,
                    startLocationLatitude: 0.0,
                    startLocationLongitude: 0.0
                });
                formData.append('journeyRequest', new Blob([journeyDetails], { type: 'application/json' }));

                const response = await startNewJourneyRewamp(formData);
                if (response.success) {
                    message.success("Journey Started Successfully");
                    fetchgetOngoingJourneyByDriverID(driverID);
                } else {
                    message.error("Error Starting Journey");
                }
            }
        }
    }

    const initialValue = moment(dayjs().format('YYYY-MM-DD HH:mm:ss'));

    const quickCheckOut = () => {
        Modal.confirm({
            title: "Confirm End Session",
            content: `Are you Sure You Want To End This Session?`,
            onOk: () => {
                setCurrentStep(3);
            },
            onCancel: () => { },
        });
    }

    const steps = [
        {
            title: <div><b><span className="textStyles-smallx">
                Check In
            </span></b>
                <br></br>
                <span style={{ fontSize: '11px' }} className='textStyleChild'>
                    {checkInTime ? checkInTime : "Select Check In Time"}
                </span>,</div>,
            content: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '150px',
                        height: '150px',
                        background: '#082B95',
                        borderRadius: '10px',
                        cursor: 'pointer'
                    }}
                        onClick={handleNewSession}
                    >
                        <div style={{ display: 'block' }}>
                            <FieldTimeOutlined
                                style={{
                                    color: 'white',
                                    fontSize: '70px'
                                }}
                            />
                        </div>
                        <div style={{ display: 'block' }}>
                            <p className="textStyles-smallx" style={{ color: 'white', fontSize: '15px' }}>
                                <b>Check In</b>
                            </p>
                        </div>
                    </div>
                    <div style={{
                        display: 'block',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <h2 className="headingStyle">
                            {checkInTime ? dayjs(checkInTime).format('HH:mm:ss') : currentTimeFetch()}
                        </h2>
                        <h6 className="textStyle-small">
                            {checkInTime ? dayjs(checkInTime).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')}
                        </h6>
                        <Row style={{
                            marginTop: '20px'
                        }}>
                            <Col span={12}>
                                <Form.Item
                                    name="checkInTime"
                                    label={<span className="descriptionStyle">Check In Time</span>}
                                    // initialValue={moment(dayjs(checkInTime).format('YYYY-MM-DD HH:mm:ss')) ? moment(dayjs(checkInTime).format('YYYY-MM-DD HH:mm:ss')) : dayjs()}
                                    defaultValue={moment()}
                                >
                                    <DatePicker
                                        showTime
                                        format="YYYY-MM-DD HH:mm:ss"
                                        // defaultValue={dayjs()}
                                        onChange={timeSet}
                                        defaultValue={moment()}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12} >
                                <Form.Item
                                    name="driverID"
                                    label={<span className="descriptionStyle">Select Driver</span>}
                                >
                                    <Select
                                        showSearch  // Enable search
                                        style={{ width: 200, marginRight: 10 }}
                                        placeholder="Select a driver"
                                        className="textStyle-small"
                                        size="medium"
                                        onChange={(value) => setDriverID(value)}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        } // Filter options based on input
                                    >
                                        {allDrivers.map((driver) => (
                                            <Option value={driver.id} key={driver.id}>
                                                {driver?.vehicleMaster?.vehicleMasterCode}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                    </div>
                </div>
            ),
        },
        {
            title: <div><b><span className="textStyles-smallx">
                Journey Started
            </span></b>
                <br></br>
                <span style={{ fontSize: '11px' }} className='textStyleChild'>
                    {journeyStartDateTime ? journeyStartDateTime : "Select Journey Start Time"}
                </span>,</div>,
            content: (
                <>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                layout="vertical"
                                name="meaterReading"
                                label={<span>Start Meter Reading ({distanceUnit})</span>}
                                style={{ width: '300px' }}
                                size="small"
                                rules={[{ required: true, message: 'Please input your Meater Reading!' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value >= 0) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Please input a non-negative value for start meter reading!'));
                                    },
                                }),
                                ]}
                                onChange={(e) => setStartMeaterReading(e.target.value)}
                                initialValue={startMeaterReading ? startMeaterReading : 0}
                            >
                                <Input
                                    placeholder="Meater Reading"
                                    className="textStyle-small"
                                    type="number"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="startDateAndTime"
                                label="Start Date & Time"
                                size="small"
                                rules={[
                                    { required: true, message: 'Please input your Start Date & Time!' }]}
                                style={{ width: '300px' }}
                                // initialValue={moment(dayjs(journeyStartDateTime).format('YYYY-MM-DD HH:mm:ss'))}
                                defaultValue={moment()}
                            >
                                <DatePicker showTime
                                    style={{ width: '350px' }}
                                    onChange={journeyStartTime}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    defaultValue={moment()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                layout="vertical"
                                name="vehicleMasterCode"
                                label="Vehicle Master Code"
                                style={{ width: '300px' }}
                                size="small"
                                initialValue={SessionData?.vehicleDetails?.vehicleMasterCode}
                            >
                                <Input
                                    placeholder="Vehicle Number"
                                    className="textStyle-small"
                                    disabled={true}
                                    style={{ color: 'black' }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="vehicleRegisterNumber"
                                label="Vehicle Register Number"
                                size="small"
                                initialValue={SessionData?.vehicleDetails?.vehicleRegistrationNumber}
                            >
                                <Input
                                    placeholder="Vehicle Code"
                                    className="textStyle-small"
                                    disabled={true}
                                    style={{ color: 'black' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                layout="vertical"
                                name="vehicleType"
                                label="Vehicle Type"
                                style={{ width: '300px' }}
                                size="small"
                                initialValue={SessionData?.vehicleDetails?.vehicleType ? SessionData?.vehicleDetails?.vehicleType : "N/A"}
                            >
                                <Input
                                    placeholder="Vehicle Type"
                                    className="textStyle-small"
                                    disabled={true}
                                    style={{ color: 'black' }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="vehicleOwner"
                                label="Vehicle Owner"
                                size="small"
                                initialValue={SessionData?.vehicleDetails?.vehicleOwner ? SessionData?.vehicleDetails?.vehicleOwner : "N/A"}
                            >
                                <Input
                                    placeholder="Vehicle Owner"
                                    className="textStyle-small"
                                    disabled={true}
                                    style={{ color: 'black' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Button
                                type="primary"
                                style={{ width: '150px', height: '30px', borderRadius: '5px' }}
                                className="textStyle-small"
                                onClick={ApproveStartNewJourney}
                            >
                                Journey Start
                            </Button>

                            <Button
                                // type=""
                                style={{ width: '150px', height: '30px', borderRadius: '5px', marginLeft: '10px' }}
                                className="textStyle-small"
                                onClick={
                                    quickCheckOut
                                }
                            >
                                End Session
                            </Button>
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            title: <div><b><span className="textStyles-smallx">
                Journey Ended
            </span></b>
                <br></br>
                <span style={{ fontSize: '11px' }} className='textStyleChild'>
                    {journeyEndDateTime ? journeyEndDateTime : "Select Journey End Time"}
                </span>,</div>,
            content: (
                <>
                    {/* end meater reading , ending date & time */}
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                layout="vertical"
                                name="meaterReading"
                                label={<span>Start Meter Reading({distanceUnit})</span>}
                                style={{ width: '300px' }}
                                size="small"
                                rules={[{ required: true, message: 'Please input your Meater Reading!' }]}
                                onChange={(e) => setStartMeaterReading(e.target.value)}
                                initialValue={startMeaterReading ? startMeaterReading : 0}
                            >
                                <Input
                                    placeholder="Meter Reading"
                                    className="textStyle-small"
                                    type="number"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="startDateAndTime"
                                label="Start Date & Time"
                                size="small"
                                rules={[
                                    { required: true, message: 'Please input your Start Date & Time!' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value >= 0) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Please input a non-negative value for start meter reading!'));
                                        },
                                    }),
                                ]}
                                style={{ width: '300px' }}
                                initialValue={moment(dayjs(journeyStartDateTime).format('YYYY-MM-DD HH:mm:ss'))}
                            >
                                <DatePicker showTime
                                    style={{ width: '350px' }}
                                    onChange={journeyStartTime}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                layout="vertical"
                                name="endMeaterReading"
                                label={<span>End Meter Reading({distanceUnit})</span>}
                                style={{ width: '300px' }}
                                size="small"
                                rules={[{ required: true, message: 'Please input your End Meater Reading!' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value >= 0) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Please Input Valid End Meater Reading'));
                                    },
                                }),
                                ]}
                                onChange={(e) => setEndMeaterReading(e.target.value)}
                            >
                                <Input
                                    placeholder="End Meater Reading"
                                    className="textStyle-small"
                                    type="number"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                name="endDateAndTime"
                                label="End Date & Time"
                                size="small"
                                rules={[{ required: true, message: 'Please input your End Date & Time!' }]}
                                defaultValue={moment()}
                            >
                                <DatePicker showTime
                                    style={{ width: '350px' }}
                                    onChange={journeyEndTime}
                                    format="YYYY-MM-DD HH:mm:ss"
                                    defaultValue={moment()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* total distance traveled and journey duration */}

                    <Row>
                        <Col span={24}>
                            <Descriptions
                                bordered
                                size="small"
                                column={1}
                                style={{ width: '100%' }}
                            >
                                <Descriptions.Item label="Total Distance" className="textStyle-small">
                                    <b>{
                                        distanceUnit !== "KM" ?
                                            milesToKilometers(endMeaterReading ? endMeaterReading - startMeaterReading : 0).toFixed(2) + " km" :
                                            endMeaterReading ? endMeaterReading - startMeaterReading : 0 + " km"
                                    }</b>
                                </Descriptions.Item>

                                <Descriptions.Item label="Journey Duration" className="textStyle-small">
                                    <b>{totalJourneyDuration ? totalJourneyDuration.toFixed(2) : 0}{"  minutes"}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24} style={{ marginTop: '10px' }}>
                            <Button
                                type="primary"
                                style={{ width: '150px', height: '30px', borderRadius: '5px' }}
                                className="textStyle-small"
                                onClick={ApproveEndJourney}
                            >
                                Journey End
                            </Button>
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            title: <div><b><span className="textStyles-smallx">
                Check Out
            </span></b>
                <br></br>
                <span style={{ fontSize: '11px' }} className='textStyleChild'>
                    {checkOutTime ? checkOutTime : "Select Check Out Time"}
                </span>,</div>,
            content: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '150px',
                        height: '150px',
                        background: '#082B95',
                        borderRadius: '10px',
                        cursor: 'pointer'
                    }}
                        onClick={ApproveEdndSession}
                    >
                        <div style={{ display: 'block' }}>
                            <FieldTimeOutlined
                                style={{
                                    color: 'white',
                                    fontSize: '70px'
                                }}
                            />
                        </div>
                        <div style={{ display: 'block' }}>
                            <p className="textStyles-smallx" style={{ color: 'white', fontSize: '15px' }}>
                                <b>Check Out</b>
                            </p>
                        </div>
                    </div>
                    <div style={{
                        display: 'block',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <h2 className="headingStyle">
                            {checkInTime ? dayjs(checkInTime).format('HH:mm:ss') : currentTimeFetch()}
                        </h2>
                        <h6 className="textStyle-small">
                            {checkInTime ? dayjs(checkInTime).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD')}
                        </h6>
                        <Form.Item
                            name="checkOutTime"
                            rules={[{ required: true, message: 'Please input your Check In Time!' }]}
                            defaultValue={moment()}
                        >
                            <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                defaultValue={moment()}
                                onChange={(date, dateString) => setCheckOutTime(dateString)}
                            />
                        </Form.Item>

                        <Button
                            style={{ width: '150px', height: '30px', borderRadius: '5px' }}
                            className="textStyle-small"
                            onClick={newJourney}
                        >
                            New Journey
                        </Button>

                    </div>
                </div>
            ),
        }
    ];

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    const onFinish = (values) => {
        console.log('Received values:', values);
    };

    const allJobData = async () => {
        var responseAPI = await allJobsData();
        if (responseAPI.success) {
            setSelectedVehicleType({
                totalVehicleJobs: responseAPI.data.results.totalJourneys,
                completedJobs: responseAPI.data.results.completedJourneys,
                runningJobs: responseAPI.data.results.ongoingJourneys,
                totalVehicles: responseAPI.data.results.totalVehicles,
                poolVehicles: responseAPI.data.results.poolVehicles,
                fixedVehicles: responseAPI.data.results.fixedVehicles,
                drivers: responseAPI.data.results.drivers
            });
        } else {
            console.log('Error In Fetching API Request : ', responseAPI.message);
        }
    };

    const convertMinutesToHoursAndMinutes = (minutes) => {
        if (isNaN(minutes)) {
            return '0';
        }

        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        return `${hours} hours ${remainingMinutes} minutes`;
    }

    const columns = [
        {
            title: <span className="textStyle">Session ID</span>,
            dataIndex: 'sessionCode',
            key: 'sessionCode',
            render: text => <a className="textStyleChild">{text}</a>,
        },
        {
            title: <span className="textStyle">Check-In Date Time</span>,
            dataIndex: 'checkinDateTime',
            key: 'checkinDateTime',
            render: text => {
                return text ? (
                    (() => {
                        const dateObject = new Date(text);
                        const options = {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        };

                        const formattedDate = dateObject.toLocaleString(undefined, options);

                        return <a className="textStyleChild">{formattedDate}</a>;
                    })()
                ) : (
                    <span className="textStyleChild">-
                        N/A
                    </span>
                );
            }
        },
        {
            title: <span className="textStyle">Check-Out Date Time</span>,
            dataIndex: 'checkoutDateTime',
            key: 'checkoutDateTime',
            render: (text) => {
                return text ? (
                    (() => {
                        const dateObject = new Date(text);
                        const options = {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                        };

                        const formattedDate = dateObject.toLocaleString(undefined, options);

                        return <a className="textStyleChild">{formattedDate}</a>;
                    })()
                ) : (
                    <span className="textStyleChild">
                        N/A
                    </span>
                );
            }
        },
        {
            title: <span className="textStyle">Vehicle Master Code</span>,
            dataIndex: 'vehicleMasterCode',
            key: 'vehicleMasterCode',
            render: (text, record) => {
                return <a className="textStyleChild">{record?.vehicleDetails?.vehicleMasterCode ? record?.vehicleDetails?.vehicleMasterCode : "N/A"}</a>;
            }
        },
        {
            title: <span className="textStyle">Creation Type</span>,
            dataIndex: 'sessionCreationType',
            key: 'sessionCreationType',
            render: text => <a>
                {text === 'MANUAL' ? <Tag color="green" style={{ width: '100px', textAlign: 'center' }}><b>MANUAL</b></Tag> :
                    <Tag color="blue" style={{ width: '100px', textAlign: 'center' }}><b>AUTOMATIC</b></Tag>}
            </a>,
        },
        {
            title: <span className="textStyle">Session Status</span>,
            dataIndex: 'sessionStatus',
            key: 'sessionStatus',
            render: text => <div>
                {text === "IN_PROGRESS" ? <Tag color="orange" style={{ width: '100px', textAlign: 'center' }}><b>IN PROGRESS</b></Tag> :
                    text === "COMPLETED" ? <Tag color="blue" style={{ width: '100px', textAlign: 'center' }}><b>COMPLETED</b></Tag>
                        : <Tag color="purple" style={{ width: '100px', textAlign: 'center' }}><b>CLOSED</b></Tag>}

            </div>
        },
        {
            title: <span className="textStyle">Session Approval Status</span>,
            dataIndex: 'sessionApprovalStatus',
            key: 'sessionApprovalStatus',
            render: text => <div>
                {text === "APPROVED" ? <Tag color="green" style={{ width: '100px', textAlign: 'center' }}><b>APPROVED</b></Tag> :
                    text === "PENDING_APPROVAL" ? <Tag color="orange" style={{ width: '100px', textAlign: 'center' }}><b>PENDING</b></Tag>
                        : <Tag color="red" style={{ width: '100px', textAlign: 'center' }}><b>REJECTED</b></Tag>}
            </div>
        },
        {
            title: <span className="textStyle">Actions</span>,
            key: 'id',
            dataIndex: 'id',
            render: (text, record) => (
                <Button
                    type="circle"
                    style={{ backgroundColor: "#ebe6f2", marginLeft: 10, width: "25px", height: "25px" }}
                    icon={<EyeOutlined style={{ color: "#2f066b", fontWeight: "bold", fontSize: "10px" }} />}
                    size="small"
                    onClick={() => handleButtonClick(text)}
                />
            ),
        }
    ];

    const searchDetailsByID = (value) => {
        if (value === "" || value === null) {
            const getRegionID = regionID ? regionID : 0;
            console.log(getRegionID);
    
            if (dropdownValue === 'PENDING_APPROVAL') {
                fetchSessions(getRegionID);
            } else {
                if (startDateEndDate[0] === null || startDateEndDate[1] === null 
                    || startDateEndDate[0] === "" || startDateEndDate[1] === "") {
                    message.error("Please Select Start Date And End Date");
                } else {
                    if (startDateEndDate[0] > startDateEndDate[1]) {
                        message.error("Start Date Must Be Less Than End Date");
                    } else {
                        if (dropdownValue === 'APPROVED') {
                            fetchGetAllApprovedSessionsForAdminAPI(getRegionID);
                        } else if (dropdownValue === 'ALL') {
                            fetchGetAllSessionsForAdminPendingAndApproved(getRegionID);
                        }
                    }
                }
            }
        } else {
            const filterData = searchData.filter((session) => {
                return session?.vehicleDetails?.vehicleMasterCode?.toLowerCase().includes(value.toLowerCase());
            });
            setSearchData(filterData.reverse());
        }
    };

    const setDropdownValueToState = (value) => {
        setDropdownValue(value);
    };

    const handleFiltersProcess = async () => {
        setSearchData(null);
        const getRegionID = regionID ? regionID : 0;
        if (dropdownValue === 'PENDING_APPROVAL') {
            const response = await fetchSessions(getRegionID);
        } else {
            if (startDateEndDate[0] === null || startDateEndDate[1] === null 
                || startDateEndDate[0] === "" || startDateEndDate[1] === "") {
                message.error("Please Select Start Date And End Date");
            } else {
                if (startDateEndDate[0] > startDateEndDate[1]) {
                    message.error("Start Date Must Be Less Than End Date");
                } else {
                    if (dropdownValue === 'APPROVED') {
                        const response = await fetchGetAllApprovedSessionsForAdminAPI(getRegionID);
                    } else if (dropdownValue === 'ALL') {
                        const response = await fetchGetAllSessionsForAdminPendingAndApproved(getRegionID);
                    }
                }
            }
        }
    }

    const handleDropdownChange = (value) => {
        setDropdownValueToState(value);
    };

    const resetFilterData = () => {
        fetchSessions(1);
        setDropdownValue('PENDING_APPROVAL');
        setDateTimeStart(null);
        setDateTimeEnd(null);
    }


    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Session List
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Vehicle </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Session List </a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-outline-primary"
                                        id="Dash_Date"
                                    >
                                        <span className="day-name" id="Day_Name">
                                            Today:
                                        </span>
                                        &nbsp;
                                        <span className="" id="Select_date">
                                            {new Date().toUTCString().slice(5, 16)}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="feather feather-calendar align-self-center icon-xs ms-1"
                                        >
                                            <rect
                                                x="3"
                                                y="4"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className='row' style={{ backgroundColor: '#f8f9fa', borderRadius: '10px', width: '100%' }}>
                    <Card
                        style={{
                            width: 255,
                            height: 97,
                            marginTop: 16,
                            borderRadius: '10px', marginLeft: '10px'
                        }}
                    // loading={loading}
                    >
                        <Meta
                            avatar={<div style={{ background: 'linear-gradient(45deg, #F1C8FF, #FDF4FE)', borderRadius: '50%', width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px' }}>
                                <CarOutlined style={{ fontSize: '35px', color: '#C624FFCF' }} />
                            </div>}
                            title={<div style={{ textAlign: 'left' }}>
                                <span style={{ fontSize: '13px', fontWeight: 'bold', color: 'gray' }}>
                                    No. Of Drivers
                                </span>
                                <h3 style={{ margin: '0' }}><b>
                                    {selectedVehicleType.drivers ? selectedVehicleType.drivers : 0}
                                </b></h3>
                            </div>}
                        />
                    </Card>

                    <Card
                        style={{
                            width: 255,
                            height: 97,
                            marginTop: 16,
                            borderRadius: '10px', marginLeft: '10px'
                        }}
                    // loading={loading}
                    >
                        <Meta
                            avatar={<div style={{ background: 'linear-gradient(45deg, #F8F499, #FFFFF7)', borderRadius: '50%', width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px' }}>
                                <CarOutlined style={{ fontSize: '35px', color: '#CBC300' }} />
                            </div>}
                            title={<div style={{ textAlign: 'left' }}>
                                <span style={{ fontSize: '13px', fontWeight: 'bold', color: 'gray' }}>
                                    Total Vehicles
                                </span>
                                <h3 style={{ margin: '0' }}><b>
                                    {selectedVehicleType.totalVehicles ? selectedVehicleType.totalVehicles : 0}
                                </b></h3>
                            </div>}
                        />
                    </Card>

                    <Card
                        style={{
                            width: 255,
                            height: 97,
                            marginTop: 16,
                            borderRadius: '10px', marginLeft: '10px'
                        }}
                    // loading={loading}
                    >
                        <Meta
                            avatar={<div style={{ background: 'linear-gradient(45deg, #FF92A5, #FFFDFE)', borderRadius: '50%', width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px' }}>
                                <CarOutlined style={{ fontSize: '35px', color: '#EB0029' }} />
                            </div>}
                            title={<div style={{ textAlign: 'left' }}>
                                <span style={{ fontSize: '13px', fontWeight: 'bold', color: 'gray' }}>
                                    Pool Vehicles
                                </span>
                                <h3 style={{ margin: '0' }}><b>
                                    {selectedVehicleType.poolVehicles ? selectedVehicleType.poolVehicles : 0}
                                </b></h3>
                            </div>}
                        />
                    </Card>

                    <Card
                        style={{
                            width: 255,
                            height: 97,
                            marginTop: 16,
                            borderRadius: '10px', marginLeft: '10px'
                        }}
                    // loading={loading}
                    >
                        <Meta
                            avatar={<div style={{ background: 'linear-gradient(45deg, #FFFDFE, #E8E6E6)', borderRadius: '50%', width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px' }}>
                                <CarOutlined style={{ fontSize: '35px', color: '#9D9B9B' }} />
                            </div>}
                            title={<div style={{ textAlign: 'left' }}>
                                <span style={{ fontSize: '13px', fontWeight: 'bold', color: 'gray' }}>
                                    Fixed Vehicles
                                </span>
                                <h3 style={{ margin: '0' }}><b>
                                    {selectedVehicleType.fixedVehicles ? selectedVehicleType.fixedVehicles : 0}
                                </b></h3>
                            </div>}
                        />
                    </Card>

                    <Card
                        style={{
                            width: 255,
                            height: 97,
                            marginTop: 16,
                            borderRadius: '10px',
                            marginLeft: '10px'
                        }}
                    // loading={loading}
                    >
                        <Meta
                            avatar={<div style={{ background: 'linear-gradient(45deg, #A3B9FA, #EFFFF6)', borderRadius: '50%', width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px' }}>
                                <CarOutlined style={{ fontSize: '35px', color: 'blue' }} />
                            </div>}
                            title={<div style={{ textAlign: 'left' }}>
                                <span style={{ fontSize: '13px', fontWeight: 'bold', color: 'gray' }}>
                                    Total Vehicle Jobs
                                </span>
                                <h3 style={{ margin: '0' }}><b>
                                    {selectedVehicleType.totalVehicleJobs ? selectedVehicleType.totalVehicleJobs : 0}
                                </b></h3>
                            </div>}
                        />
                    </Card>

                    <Card
                        style={{
                            width: 255,
                            height: 97,
                            marginTop: 16,
                            borderRadius: '10px', marginLeft: '10px'
                        }}
                    // loading={loading}
                    >
                        <Meta
                            avatar={<div style={{ background: 'linear-gradient(45deg, #D3FFE7 ,#EFFFF6)', borderRadius: '50%', width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px' }}>
                                <NodeIndexOutlined style={{ fontSize: '35px', color: '#00AC4F' }} />
                            </div>}
                            title={<div style={{ textAlign: 'left' }}>
                                <span style={{ fontSize: '13px', fontWeight: 'bold', color: 'gray' }}>
                                    Completed Jobs
                                </span>
                                <h3 style={{ margin: '0' }}><b>
                                    {selectedVehicleType.completedJobs ? selectedVehicleType.completedJobs : 0}
                                </b></h3>
                            </div>}
                        />
                    </Card>

                    <Card
                        style={{
                            width: 255,
                            height: 97,
                            marginTop: 16,
                            borderRadius: '10px', marginLeft: '10px'
                        }}
                    // loading={loading}
                    >
                        <Meta
                            avatar={<div style={{ background: 'linear-gradient(45deg, #F9BE7D ,#FCFFFD)', borderRadius: '50%', width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px' }}>
                                <NodeIndexOutlined style={{ fontSize: '35px', color: '#F68407' }} />
                            </div>}
                            title={<div style={{ textAlign: 'left' }}>
                                <span style={{ fontSize: '13px', fontWeight: 'bold', color: 'gray' }}>
                                    Running Jobs
                                </span>
                                <h3 style={{ margin: '0' }}><b>
                                    {selectedVehicleType.runningJobs ? selectedVehicleType.runningJobs : 0}
                                </b></h3>
                            </div>}
                        />
                    </Card>
                </div> */}

                <div style={{ padding: '20px', backgroundColor: '#ffffff', marginTop: '20px', borderRadius: '10px' }}>
                    <div style={{ padding: 10, borderRadius: 10 }}>
                        <Row>
                            <Col span={12}>
                                <h3><b className="headingStyle">
                                    Sessions
                                </b></h3>
                            </Col>
                            <Col span={12}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    {/* Empty div to push the button to the right */}
                                    <div></div>
                                    <Button style={{ borderRadius: "5px", backgroundColor: "#00AC4F", width: "150px" }}
                                        onClick={() => openModel()}>
                                        <span className="textStyles-small" style={{ color: "white" }}>
                                            Create New Session
                                        </span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {
                        isLoading !== true ? (
                            <Row>
                                <Col span={24}>
                                    <div style={{
                                        backgroundColor: "#fafafa",
                                        padding: "10px 10px 10px 10px",
                                        borderRadius: "10px",
                                        marginTop: "5px",
                                    }}>
                                        <Input
                                            placeholder="Search By Master Code"
                                            prefix={<SearchOutlined />}
                                            className="textStyle-small customInput"
                                            style={{ width: '220px', marginRight: 10 }}
                                            onChange={(e) => searchDetailsByID(e.target.value)}
                                        />
                                        {
                                            dropdownValue !== 'PENDING_APPROVAL' ?
                                                <>
                                                    <RangePicker
                                                        className='customDropdown'
                                                        // onChange={(date, dateString) => setStartAndEndDate(dateString)}
                                                        onChange={setStartAndEndTimes}
                                                        defaultValue={[moment().subtract(7, 'days'),
                                                        moment().add(0, 'days')]}
                                                    />
                                                </>
                                                : <></>
                                        }
                                        <Select
                                            placeholder="Select Region"
                                            className='borderedSelect'
                                            style={{ width: '220px', marginLeft: "10px", height: "30px" }}
                                            bordered={false}
                                            onChange={(value) => setRegionID(value)}
                                            defaultValue={0}
                                        >
                                            <Option value={0} className="textStyle-small">All</Option>
                                            {regionList === null ? [] : regionList.map((region) => (
                                                <Option key={region.id} value={region.id} className="textStyle-small">{region.name}</Option>
                                            ))}
                                        </Select>
                                        <Select
                                            placeholder="Select Region"
                                            className='borderedSelect'
                                            style={{ width: '220px', marginLeft: "10px", height: "30px" }}
                                            bordered={false}
                                            defaultValue="PENDING"
                                            onChange={handleDropdownChange}
                                        >
                                            <Option value="PENDING_APPROVAL" className="textStyle-small">Pending</Option>
                                            <Option value="APPROVED" className="textStyle-small">Approved</Option>
                                            <Option value="ALL" className="textStyle-small">All</Option>
                                        </Select>
                                        <Button
                                            onClick={handleFiltersProcess}
                                            type="primary"
                                            shape="circle"
                                            icon={<SearchOutlined />}
                                            size="small"
                                            style={{ marginRight: '10px', marginLeft: "10px" }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        ) : null
                    }

                    <Spin spinning={isLoading} tip="Data Fetching..." size="large">
                        <Table
                            className="table-striped-rows"
                            columns={columns}
                            dataSource={searchData?.reverse()}
                            // loading={sessions.length === 0}
                            size='small'
                            style={{ marginTop: '20px' }}
                        />
                    </Spin>
                </div>

                <Modal
                    title={
                        <span className="textStyles-smallx">
                            Create New Session
                    </span>}
                    visible={openModal}
                    onOk={approveCloseModel}
                    onCancel={approveCloseModel}
                    footer={<></>}
                    width={800}
                    height={500}
                >
                    <div >
                        <Steps current={currentStep}
                            size="small"
                        >
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>

                        <div style={{ marginTop: '50px' }}>
                            <Form form={form}
                                onFinish={onFinish}
                                initialValues={{}}
                                layout="vertical"
                                style={{ padding: '20px' }}
                            >
                                {steps[currentStep].content}

                                {/* // <div style={{ marginTop: '20px', float: 'right' }}>
                                //     {currentStep > 0 && (
                                //         <Button style={{ marginRight: '8px', width: '100px', borderRadius: '5px' }}
                                //             onClick={handlePrev}
                                //             className="textStyles-smallx"
                                //         >
                                //             Previous
                                //         </Button>
                                //     )}
                                //             currentStep === 0 ? (
                                //                 <></>
                                //             ) : (
                                //                 <Button type="primary" onClick={handleNext} style={{ width: '100px', borderRadius: '5px' }}
                                //                     className="textStyles-smallx"
                                //                 >
                                //                     Next
                                //                 </Button>
                                //             )
                                    
                                //     {currentStep === steps.length - 1 && (
                                //         <Button type="primary" htmlType="submit"
                                //             className="textStyles-smallx"
                                //             style={{ width: '100px', borderRadius: '5px' }}
                                //         >
                                //             Submit
                                //         </Button>
                                //     )}
                                // </div> */} 
                            </Form>
                        </div>
                    </div>
                </Modal>

            </div>
        </body>

    );
}

export default SessionsList;