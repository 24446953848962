import React, { Component } from 'react'
import { connect } from "react-redux";
import { getDonationCategories, getData } from "../../actions/index";
import {
    VehicleDashboardCounts,
    VehicleDashboardCounts2,
    getMonthlyRegionViceMillage,
    getVehicleDashboardCharts,
    getDashboardChartsDataRewamp,

    getGeneMonthlyRegionViceMillageRewamp,
    getDashboardCountsRewamp,
    getDashboardCounts2Rewamp,

    getChartsJournyTrendRewamp,
    getChartsRunningKms2Rewamp,
    getChartsRunningKmsRewamp,
    getChartsJourneyStatusRewamp,
    getMonthlyRegionViceMillageRewamp,

} from "../../api/index";
import StackedBarLine from '../../components/charts/stacked-bar-line';
import StackedBarChat from '../../components/charts/stacked-bar';
import LineCharts from '../../components/charts/line-charts';
import ColumnChartsDataLablesChart from '../../components/charts/column-charts-data-lables'
import SemiCircleGaugeChat from '../../components/charts/semi-circle-gauge'
import './style.css';
import {
    PlusCircleFilled,
    PlusSquareFilled,
    ClockCircleFilled,
    CarFilled,
    UserOutlined,
    CarOutlined,
    PlusCircleOutlined,
    NodeIndexOutlined,
    FieldTimeOutlined
} from '@ant-design/icons';
import './style.css';

import { Select, DatePicker, Space, Badge, message, Row, Col } from "antd";
import moment from 'moment';
const { RangePicker } = DatePicker;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SubRegion_list: [],
            depot_list: [],
            filter_date: moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01')._i,
            filter_date_to: moment().endOf('month').format("YYYY-MM-DD"),
            filter_depot: [],
            filter_region: [],
            filter_sub_region: [],
            journey_end_count: 0,
            journey_admin_verify_count: 0,
            journey_start_count: 0,
            journey_officer_verify_count: 0,
            journey_vehicle_release_count: 0,
            total_count: 0,
            journey_initiate_count: 0,
            journey_canceled_count: 0,
            getAllData: [],
            vehicleSeriesCount: [20, 28, 30, 12],
            vehicleSerieslabels: [],
            runningKMChart2: false,
            runningKMChart2SetY1: null,
            runningKMChart2SetX1: null,
            runningKMChart2: false,
            runningKMChart2SetY1: null,
            runningKMChart2SetX1: null,
            requesteTrendChart: false,
            requesteTrendChartSetY1: null,
            requesteTrendChartSetX1: null,
            journeyStatus: false,
            journeyStatusSetY1: null,
            journeyStatusSetX1: null,
            runningKMChart1: false,
            runningKMChart1SetY1: null,
            runningKMChart1SetX1: null,

            vehicleRegionViceMillageERChart: false,
            vehicleRegionViceMillageERChartValues: null,
            vehicleRegionViceMillageERChartTarget: null,
            vehicleRegionViceMillageERChartRealValue: null,

            vehicleRegionViceMillageWRChart: false,
            vehicleRegionViceMillageWRChartValues: null,
            vehicleRegionViceMillageWRChartTarget: null,
            vehicleRegionViceMillageWRChartRealValue: null,

            vehicleRegionViceMillageCRChart: false,
            vehicleRegionViceMillageCRChartValues: null,
            vehicleRegionViceMillageCRChartTarget: null,
            vehicleRegionViceMillageCRChartRealValue: null,

            vehicleRegionViceMillageSRChart: false,
            vehicleRegionViceMillageSRChartValues: null,
            vehicleRegionViceMillageSRChartTarget: null,
            vehicleRegionViceMillageSRChartRealValue: null,
            vehicleRegionViceMillageNRChart: false,
            vehicleRegionViceMillageNRChartValues: null,
            vehicleRegionViceMillageNRChartTarget: null,
            vehicleRegionViceMillageNRChartRealValue: null,

            runningKMChartWithcumulativeData: null,
            journeyStatusChartData: null,
            requestTrendChartData: null,

            chartSelectedRegionSet: '',
            chartTwoSelectedRegionSet: ''
        }
    }
    componentDidMount() {
        this.props.getData();
        var data1 = new FormData();
        data1.append('scheduleDateFrom', this.state.filter_date);
        data1.append('scheduleDateTo', this.state.filter_date_to);

        this.getDashboardCounts2RewampFetch(data1);
        this.getMonthlyRegionViceMillageRewampFetch(data1);

        this.JourneyTrendDetails();
        this.getChartsRunningKms2RewampFetch();
        this.getChartsRunningKmsRewampFetch();

        this.getChartsJourneyStatusRewampFetch();

        this.setState({
            depot_list: this.props.depots,
            SubRegion_list: this.props.regions.filter((item) => {
                return item.isSubRegion == 1;
            }),
        });
    }

    JourneyTrendDetails = (regionID) => {
        const regionIDGet = regionID === null || regionID === undefined ? '' : regionID;
        this.setState.chartSelectedRegionSet = regionIDGet;
        var data = new FormData();
        data.append('scheduleDateFrom', this.state.filter_date);
        data.append('scheduleDateTo', this.state.filter_date_to);
        data.append('regions', regionIDGet);
        this.fetchJourneyTrendDetails(data);
    }

    getLastDayOfMonth(inputDate) {
        const [year, month] = inputDate.split('-');
        const nextMonth = new Date(year, month);
        nextMonth.setDate(0);
        const lastDay = nextMonth.getDate();
        return `${year}-${month}-${lastDay.toString().padStart(2, '0')}`;
    }

    fetchJourneyTrendDetails = async (formData) => {
        this.setState({
            requesteTrendChartSetX1: null,
            requesteTrendChartSetY1: null,
            requesteTrendChart: false
        });
        var res = await getChartsJournyTrendRewamp(formData);
        if (res.success) {
            const data = res.data.results.requestTrend;
            if (data != null) {
                // const today = new Date();
                // const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                // const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];

                let set_year = this.state.filter_date;
                let set_month = this.state.filter_date_to;
                let set_data = set_year + '-' + set_month
                const inputDate = set_data;
                const lastDayOfMonth = this.getLastDayOfMonth(inputDate);
                const today = new Date(lastDayOfMonth);
                const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];

                const filteredData = data
                    .filter(entry => entry.date >= formattedFirstDay && entry.date <= today.toISOString().split('T')[0])
                    .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

                const countSet = filteredData.map(entry => entry.count);
                const dateSet = filteredData.map(entry => entry.date);

                this.setState({
                    requesteTrendChartSetY1: [{
                        name: "Request",
                        data: countSet
                    }],
                    requesteTrendChartSetX1: dateSet,
                }, () => {
                    this.setState({
                        requesteTrendChart: true
                    })
                })
            }
        } else {
            message.error('Data Fetching Error, In Journey Trend Details !');
        }
    }

    getChartsRunningKms2RewampFetch = async (regionID) => {
        const regionIDGet = regionID === null || regionID === undefined ? '' : regionID;
        this.setState.chartTwoSelectedRegionSet = regionIDGet;
        var data = new FormData();
        data.append('scheduleDateFrom', this.state.filter_date);
        data.append('scheduleDateTo', this.state.filter_date_to);
        data.append('regions', regionIDGet);
        this.fetchgetChartsRunningKms2Rewamp(data);
    }

    fetchgetChartsRunningKms2Rewamp = async (formData) => {
        this.setState({
            runningKMChart2SetX1: null,
            runningKMChart2SetY1: null,
            runningKMChart2: false
        });
        try {
            const res = await getChartsRunningKms2Rewamp(formData);
            if (res.success) {
                const data = res.data.results.runningKMs2;
                if (data != null) {
                    const countSet = data.map(a => a.count).reverse();
                    const monthSet = data.map(a => a.month.substring(0, 3)).reverse();
                    this.setState({
                        runningKMChart2SetY1: [{
                            name: 'Inflation',
                            data: countSet
                        }],
                        runningKMChart2SetX1: monthSet
                    }, () => {
                        this.setState({
                            runningKMChart2: true
                        })
                    })
                }
            }
        } catch (error) {
            console.error('Error fetching running kms 2:', error);
            message.error('An error occurred while fetching running kms 2.');
        }
    }

    fetchgetChartsRunningKmsRewamp = async (formData) => {
        this.setState({
            runningKMChart1SetX1: null,
            runningKMChart1SetY1: null,
            runningKMChart1: false
        });
        try {
            const res = await getChartsRunningKmsRewamp(formData);
            if (res.success) {
                const data = res.data.results.runningKMs;
                if (data != null) {
                    const countSet = data.map(a => a.count);
                    const dateSet = data.map(a => a.date.slice(8));
                    const cumulative_sumSet = data.map(a => a.cumulative_sum);
                    const WRSet = data.map(a => a.WR);
                    const CRSet = data.map(a => a.CR);
                    const ERSet = data.map(a => a.ER);
                    const NRSet = data.map(a => a.NR);
                    const SRSet = data.map(a => a.SR);

                    this.setState({
                        runningKMChart1SetY1: [
                            {
                                label: 'Total Value',
                                yAxisID: 'y2',
                                type: 'line',
                                data: cumulative_sumSet,
                                borderWidth: 2,
                                backgroundColor: 'rgb(53, 162, 235)',
                                borderColor: 'rgb(53, 162, 200)',
                            },
                            {
                                label: 'CR',
                                yAxisID: 'y',
                                data: CRSet,
                                backgroundColor: 'rgb(255, 99, 132)',
                            },
                            {
                                label: 'ER',
                                yAxisID: 'y',
                                data: ERSet,
                                backgroundColor: 'rgb(255,165,0)',
                            },
                            {
                                label: 'NR',
                                yAxisID: 'y',
                                data: NRSet,
                                backgroundColor: 'rgb(0,250,154)',
                            },
                            {
                                label: 'SR',
                                yAxisID: 'y',
                                data: SRSet,
                                backgroundColor: 'rgb(0,191,255)',
                            },
                            {
                                label: 'WR',
                                yAxisID: 'y',
                                data: WRSet,
                                backgroundColor: 'rgb(139,0,139)',
                            },

                        ],
                        runningKMChart1SetX1: dateSet,
                    }, () => {
                        this.setState({
                            runningKMChart1: true
                        })
                    })
                }
            }
        } catch (error) {
            message.error('An error occurred while fetching running kms.');
        }
    }

    getChartsRunningKmsRewampFetch = async () => {
        var data = new FormData();
        data.append('scheduleDateFrom', this.state.filter_date);
        data.append('scheduleDateTo', this.state.filter_date_to);
        this.fetchgetChartsRunningKmsRewamp(data);
    }

    fetchgetChartsJourneyStatusRewamp = async (formData) => {
        this.setState({
            journeyStatusSetX1: null,
            journeyStatusSetY1: null,
            journeyStatus: false
        });
        try {
            const res = await getChartsJourneyStatusRewamp(formData);
            if (res.success) {
                const data = res.data.results.journeyStatus;
                if (data != null) {
                    data.splice(5, 3);
                    const regionSet = data.map(a => a.region != null ? a.region : 'null');
                    const canceledSet = data.map(a => a.canceled);
                    const compleateSet = data.map(a => a.compleate);
                    const pendingSet = data.map(a => a.pending);
                    this.setState({
                        journeyStatusSetY1: [{
                            name: 'Cancelled',
                            type: 'column',
                            data: canceledSet
                        }, {
                            name: 'Completed',
                            type: 'column',
                            data: compleateSet
                        }, {
                            name: 'On Going',
                            type: 'column',
                            data: pendingSet
                        },],
                        journeyStatusSetX1: regionSet,
                    }, () => {
                        this.setState({
                            journeyStatus: true
                        })
                    })
                }
            }
        } catch (error) {
            console.error('Error fetching journey status:', error);
            message.error('An error occurred while fetching journey status.');
        }
    }

    getChartsJourneyStatusRewampFetch = async () => {
        var data = new FormData();
        data.append('scheduleDateFrom', this.state.filter_date);
        data.append('scheduleDateTo', this.state.filter_date_to);
        this.fetchgetChartsJourneyStatusRewamp(data);
    }

    getMonthlyRegionViceMillageRewampFetch = async (data) => {
        this.setState({
            vehicleRegionViceMillageERChartValues: null,
            vehicleRegionViceMillageERChartTarget: null,
            vehicleRegionViceMillageERChartRealValue: null,
            vehicleRegionViceMillageERChart: false,
            vehicleRegionViceMillageWRChartValues: null,
            vehicleRegionViceMillageWRChartTarget: null,
            vehicleRegionViceMillageWRChartRealValue: null,
            vehicleRegionViceMillageWRChart: false,
            vehicleRegionViceMillageCRChartValues: null,
            vehicleRegionViceMillageCRChartTarget: null,
            vehicleRegionViceMillageCRChartRealValue: null,
            vehicleRegionViceMillageCRChart: false,
            vehicleRegionViceMillageSRChartValues: null,
            vehicleRegionViceMillageSRChartTarget: null,
            vehicleRegionViceMillageSRChartRealValue: null,
            vehicleRegionViceMillageSRChart: false,
            vehicleRegionViceMillageNRChartValues: null,
            vehicleRegionViceMillageNRChartTarget: null,
            vehicleRegionViceMillageNRChartRealValue: null,
            vehicleRegionViceMillageNRChart: false,
        });
        var res = await getMonthlyRegionViceMillageRewamp(data);
        if (res.success) {
            if (res?.data?.results != null) {
                res?.data?.results.map((x, y) => {
                    if (x.region == 'Eastern (ER)') {
                        console.log("sssss" + x)
                        console.log(x)
                        let setvaluesscr = (x?.target / x?.value)
                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageERChartValues: setvaluesscr,
                            vehicleRegionViceMillageERChartTarget: x?.value,
                            vehicleRegionViceMillageERChartRealValue: x?.target,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageERChart: true
                            })
                        })
                    }
                    if (x.region == 'Western (WR)') {
                        let setvaluesscr = (x?.target / x?.value)
                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageWRChartValues: setvaluesscr,
                            vehicleRegionViceMillageWRChartTarget: x?.value,
                            vehicleRegionViceMillageWRChartRealValue: x?.target,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageWRChart: true
                            })
                        })
                    }
                    if (x.region == 'Central (CR)') {
                        let setvaluesscr = (x?.target / x?.value)

                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageCRChartValues: setvaluesscr,
                            vehicleRegionViceMillageCRChartTarget: x?.value,
                            vehicleRegionViceMillageCRChartRealValue: x?.target,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageCRChart: true
                            })
                        })
                    }
                    if (x.region == 'Southern (SR)') {
                        let setvaluesscr = (x?.target / x?.value)
                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageSRChartValues: setvaluesscr,
                            vehicleRegionViceMillageSRChartTarget: x?.value,
                            vehicleRegionViceMillageSRChartRealValue: x?.target,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageSRChart: true
                            })
                        })
                    }
                    if (x.region == 'Northern (NR)') {
                        let setvaluesscr = (x?.target / x?.value)

                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageNRChartValues: setvaluesscr,
                            vehicleRegionViceMillageNRChartTarget: x?.value,
                            vehicleRegionViceMillageNRChartRealValue: x?.target,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageNRChart: true
                            })
                        })
                    }
                })
            }
        } else {
            message.error('Data Fetching Error !');
        }
    }

    getDashboardChartsDataRewampFetch = async () => {
        var res = await getDashboardChartsDataRewamp();
        console.log('getDashboardChartsDataRewamp')
        console.log(res)
        if (res.success) {
            this.setState({
                runningKMChartWithcumulativeData: res?.data?.results?.runningKMs,
                journeyStatusChartData: res?.data?.results?.journeyStatus,
                requestTrendChartData: res?.data?.results?.requestTrend,
                runningKM2ChartData: res?.data?.results?.runningKMs2,
            }, () => {
                this.runningKMChartWithcumulativeDataSet(this.state.runningKMChartWithcumulativeData)
                this.journeyStatusDataSet(this.state.journeyStatusChartData)
                this.requestTrendDataSet(this.state.requestTrendChartData)
                this.runningKM2ChartDataSet(this.state.runningKM2ChartData)
            })
        } else {
            console.log(res);
        }
    }

    runningKM2ChartDataSet(data) {
        console.log(data)
        data.reverse();
        if (data != null) {
            let countSet = data.map(a => a.count);
            let monthSet = data.map(a => a.month.substring(0, 3));
            this.setState({
                runningKMChart2SetY1: [{
                    name: 'Inflation',
                    data: countSet
                }],
                runningKMChart2SetX1: monthSet
            }, () => {
                this.setState({
                    runningKMChart2: true
                })
            })
        }
    }
    requestTrendDataSet(data) {
        console.log("Request Trend Data Set : " + data);
        if (data != null) {

            // let fff = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
            // let dateSet = fff.map(a => a.date);
            // let countSet = fff.map(a => a.count);

            const today = new Date();
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];

            const filteredData = data
                .filter(entry => entry.date >= formattedFirstDay && entry.date <= today.toISOString().split('T')[0])
                .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));

            const countSet = filteredData.map(entry => entry.count);
            const dateSet = filteredData.map(entry => entry.date);

            this.setState({
                requesteTrendChartSetY1: [{
                    name: "Request",
                    data: countSet
                }],
                requesteTrendChartSetX1: dateSet,
            }, () => {
                this.setState({
                    requesteTrendChart: true
                })
            })
        }
    }

    journeyStatusDataSet(data) {
        if (data != null) {
            data.splice(5, 3);
            let fff = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
            let regionSet = data.map(a => a.region != null ? a.region : 'null');
            let canceledSet = fff.map(a => a.canceled);
            let compleateSet = fff.map(a => a.compleate);
            let pendingSet = fff.map(a => a.pending);
            this.setState({
                journeyStatusSetY1: [{
                    name: 'Cancelled',
                    type: 'column',
                    data: canceledSet
                }, {
                    name: 'Completed',
                    type: 'column',
                    data: compleateSet
                }, {
                    name: 'On Going',
                    type: 'column',
                    data: pendingSet
                },],
                journeyStatusSetX1: regionSet,
            }, () => {
                this.setState({
                    journeyStatus: true
                })
            })
        }
    }

    runningKMChartWithcumulativeDataSet(data) {
        console.log('runningKMChartWithcumulativeDataSet')
        console.log(data)
        if (data != null) {
            let fff = data.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
            let dateSet = fff.map(a => a.date.slice(8));
            let cumulative_sumSet = fff.map(a => a.cumulative_sum);
            let WRSet = fff.map(a => a.WR);
            let CRSet = fff.map(a => a.CR);
            let ERSet = fff.map(a => a.ER);
            let NRSet = fff.map(a => a.NR);
            let SRSet = fff.map(a => a.SR);

            console.log(NRSet)
            this.setState({
                runningKMChart1SetY1: [
                    {
                        label: 'Cumulative Cost',
                        yAxisID: 'y2',
                        type: 'line',
                        data: cumulative_sumSet,
                        borderWidth: 2,
                        backgroundColor: 'rgb(53, 162, 235)',
                        borderColor: 'rgb(53, 162, 200)',
                    },
                    {
                        label: 'CR',
                        yAxisID: 'y',
                        data: CRSet,
                        backgroundColor: 'rgb(255, 99, 132)',
                    },
                    {
                        label: 'ER',
                        yAxisID: 'y',
                        data: ERSet,
                        backgroundColor: 'rgb(255,165,0)',
                    },
                    {
                        label: 'NR',
                        yAxisID: 'y',
                        data: NRSet,
                        backgroundColor: 'rgb(0,250,154)',
                    },
                    {
                        label: 'SR',
                        yAxisID: 'y',
                        data: SRSet,
                        backgroundColor: 'rgb(0,191,255)',
                    },
                    {
                        label: 'WR',
                        yAxisID: 'y',
                        data: WRSet,
                        backgroundColor: 'rgb(139,0,139)',
                    },

                ],
                runningKMChart1SetX1: dateSet,
            }, () => {
                this.setState({
                    runningKMChart1: true
                })
            })
        }
    }

    getDataMonthlyRegionViceMillage = async (data) => {
        var res = await getMonthlyRegionViceMillage(data);
        console.log('getMonthlyRegionViceMillage');
        console.log(res);
        if (res.success) {
            if (res?.data != null) {
                res?.data.map((x, y) => {
                    if (x.region == 'Eastern (ER)') {
                        let setvaluesscr = (x?.value / x?.target)
                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageERChartValues: setvaluesscr,
                            vehicleRegionViceMillageERChartTarget: x?.target,
                            vehicleRegionViceMillageERChartRealValue: x?.value,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageERChart: true
                            })
                        })
                    }
                    if (x.region == 'Western (WR)') {
                        let setvaluesscr = (x?.value / x?.target)
                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageWRChartValues: setvaluesscr,
                            vehicleRegionViceMillageWRChartTarget: x?.target,
                            vehicleRegionViceMillageWRChartRealValue: x?.value,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageWRChart: true
                            })
                        })
                    }
                    if (x.region == 'Central (CR)') {
                        let setvaluesscr = (x?.value / x?.target)

                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageCRChartValues: setvaluesscr,
                            vehicleRegionViceMillageCRChartTarget: x?.target,
                            vehicleRegionViceMillageCRChartRealValue: x?.value,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageCRChart: true
                            })
                        })
                    }
                    if (x.region == 'Southern (SR)') {
                        let setvaluesscr = (x?.value / x?.target)
                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageSRChartValues: setvaluesscr,
                            vehicleRegionViceMillageSRChartTarget: x?.target,
                            vehicleRegionViceMillageSRChartRealValue: x?.value,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageSRChart: true
                            })
                        })
                    }
                    if (x.region == 'Northern (NR)') {
                        let setvaluesscr = (x?.value / x?.target)

                        console.log('setvaluesscr' + setvaluesscr)
                        this.setState({
                            vehicleRegionViceMillageNRChartValues: setvaluesscr,
                            vehicleRegionViceMillageNRChartTarget: x?.target,
                            vehicleRegionViceMillageNRChartRealValue: x?.value,
                        }, () => {
                            this.setState({
                                vehicleRegionViceMillageNRChart: true
                            })
                        })
                    }
                })
            }
        }
    }

    getVehicleDashboardCounts = async (data) => {
        var data = data;
        var res = await VehicleDashboardCounts(data);
        console.log(res);

        if (res.success) {
            this.setState({
                journey_admin_verify_count: res.data.journey_admin_verify,
                journey_start_count: res.data.journey_start,
                journey_end_count: res.data.journey_end,
                journey_canceled_count: res.data.journey_canceled,
                journey_initiate_count: res.data.journey_initiate,
                journey_officer_verify_count: res.data.journey_officer_verify,
                journey_vehicle_release_count: res.data.journey_vehicle_release,
                total_count: res.data.total,
            })
        }
    };

    getVehicleDashboardCounts2 = async (data) => {
        var data = data;
        var res = await VehicleDashboardCounts2(data);
        if (res.success) {
            this.setState({
                getAllData: res.data
            })
        }
    };

    getDashboardCounts2RewampFetch = async (data) => {
        var res = await getDashboardCounts2Rewamp(data);
        console.log('getDashboardCountsRewamp')
        console.log(res)
        if (res.success) {
            this.setState({
                getAllData: res.data
            })
        } else {
            message.error('Data Fetching Error !');
        }
    }

    onChangeRegion = (value) => {
        this.setState({
            depot_list: [],
            filter_depot: [],
            filter_sub_region: [],

        });
        console.log(value);
        this.setState({
            filter_region: value,
            selectedDepot: "all_depots",
            selected_region_id: value,
            SubRegion_list: this.props.regions.filter(alldate => value.map((n) => n).includes(alldate.parentRegion?.id)),
            depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.parentRegion?.id))
        }, () => {
            if (this.state.filter_sub_region == "") {
                var getall = this.props.regions.filter(alldate => value.includes(alldate.parentRegion?.id))
                var output = [];
                for (var i = 0; i < getall.length; ++i)
                    output.push(getall[i]['id']);
                var data1 = new FormData();

                data1.append('scheduleDateFrom', this.state.filter_date);
                data1.append('scheduleDateTo', this.state.filter_date_to);
                data1.append('regions', this.state.filter_region);
                data1.append('subregions', this.state.filter_sub_region);
                this.getDashboardCounts2RewampFetch(data1);
            }
        });
        var data = {
            id: value,
            type: "region",
        };
    };
    dataonChange = (date, dateString) => {
        const [year, month] = dateString.split('-');
        const startDate = moment([year, month - 1]).format('YYYY-MM-DD');
        const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');

        this.setState({
            filter_date: startDate,
            filter_date_to: endDate,
        });
        var data1 = new FormData();
        data1.append('scheduleDateFrom', startDate);
        data1.append('scheduleDateTo', endDate);
        data1.append('regions', this.state.filter_region);

        // call maindashboard values main tiles data
        this.getDashboardCounts2RewampFetch(data1);
        // call monthly region vice millage data - 05 data set
        this.getMonthlyRegionViceMillageRewampFetch(data1);
        // call journies status rewamp
        this.fetchgetChartsJourneyStatusRewamp(data1);
        // call running kms 2 rewamp
        this.fetchgetChartsRunningKmsRewamp(data1);

        // other data
        // this.JourneyTrendDetails(this.state.chartSelectedRegionSet);
        // this.getChartsRunningKms2RewampFetch(this.state.chartTwoSelectedRegionSet);
        this.fetchDatePlusDataCount(startDate, endDate);
        this.fetchDatePlusDataCountTwo(startDate, endDate);
    };

    fetchDatePlusDataCount = (startDate, endDate) => {
        var data = new FormData();
        data.append('scheduleDateFrom', startDate);
        data.append('scheduleDateTo', endDate);
        data.append('regions', this.state.chartSelectedRegionSet);
        this.fetchJourneyTrendDetails(data);
    }

    fetchDatePlusDataCountTwo = (startDate, endDate) => {
        var data = new FormData();
        data.append('scheduleDateFrom', startDate);
        data.append('scheduleDateTo', endDate);
        data.append('regions', this.state.chartTwoSelectedRegionSet);
        this.fetchgetChartsRunningKms2Rewamp(data);
    }

    onsubChange = (value) => {
        this.setState({
            depot_list: [],
            filter_depot: [],
        });
        this.setState({
            selectedDepot: "all_depots",
            selected_region_id: value,
            filter_sub_region: value,
            depot_list: this.props.depots.filter(alldate => value.map((n) => n).includes(alldate.region?.id))
        }, () => {
            var data1 = new FormData();
            data1.append('scheduleDateFrom', this.state.filter_date);
            data1.append('scheduleDateTo', this.state.filter_date_to);
            data1.append('regions', this.state.filter_region);
            data1.append('subregions', this.state.filter_sub_region);
            this.getDashboardCounts2RewampFetch(data1);
        });
        var data = {
            id: value,
            type: "region",
        };
    };

    stripYearFromDates(dates) {
        return dates.map(date => date.slice(8));
    }

    renderChart = () => (
        <div className="card-body">
            {this.state.requesteTrendChart ? (
                <LineCharts
                    redraw={true}
                    key={this.state.requesteTrendChartSetX1} // Ensure a unique key
                    y1={this.state.requesteTrendChartSetY1}
                    x1={this.stripYearFromDates(this.state.requesteTrendChartSetX1)}
                    yLabale={"Total"}
                />
            ) : ('')}
        </div>
    );

    handleButtonClick = () => {
        // Set state to trigger rendering of the chart
        this.setState({ requesteTrendChart: true });
    };

    render() {
        const { Option } = Select;

        function onBlur() {
            //console.log("blur");
        }

        function onFocus() {
            //console.log("focus");
        }

        function onSearch(val) {
            //console.log("search:", val);
        }
        const { vehicleSeriesCount } = this.state
        const { vehicleSerieslabels } = this.state

        return (
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="page-title-box">
                            <div class="row">
                                <div class="col">
                                    <h4 class="page-title">Dashboard</h4>
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Dashboard</a></li>
                                        <li class="breadcrumb-item"><a href="javascript:void(0);">Vehicle</a></li>
                                    </ol>
                                </div>
                                <div class="col-auto align-self-center">
                                    <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div className="row" style={{
                            background: 'white', padding: '10px', borderRadius: '10px'
                        }}>
                            <div className="col-md-2 col-lg-3">
                                <DatePicker
                                    picker="month"
                                    className='customDropdown textStyles-small'
                                    style={{ width: "100%", borderRadius: "5px" }}
                                    onChange={this.dataonChange}
                                    allowClear={false}
                                    defaultValue={moment(moment().format("YYYY-MM-DD").slice(0, -2) + '01')}
                                    size='medium'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>

                <div style={{ background: 'white', borderRadius: '10px', padding: '20px' }}>
                    <Row>
                        <Col span={8}>
                            <h5 style={{
                                fontWeight: "bold", fontFamily: 'Roboto'
                            }}>Dashboard Statistics </h5>
                        </Col>
                        <Col span={16}>
                            <div style={{ float: 'right' }}>

                                <Select
                                    mode="multiple"
                                    showSearch
                                    className="borderedSelect"
                                    bordered={false}
                                    style={{ width: "250px", borderRadius: "5px", marginLeft: '5px' }}
                                    placeholder="Select a region"
                                    optionFilterProp="children"
                                    onChange={this.onChangeRegion}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    onSearch={onSearch}
                                    filterOption={(input, option) =>
                                        option.props.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((item, index) => {
                                        return <Option value={item.id}>{item.name}</Option>;
                                    })}
                                </Select>
                            </div>

                        </Col>
                    </Row>


                    <div class="row justify-content-center" style={{ marginTop: '20px' }}>
                        <div class="col-md-2 col-lg-3" style={{ width: "25%" }}>
                            <div class="card report-card bg-soft-success" style={{ borderRadius: '20px' }}>
                                <div class="card-body" >
                                    <div class="row d-flex justify-content-center">
                                        <div class="col">
                                            <p class="mb-0 fw-semibold textStyles-smallx"><b>Total No.<br />Journeys</b></p>
                                            <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.results?.totalJourneys ? (this.state.getAllData?.results?.totalJourneys).toLocaleString() : (0)}</h2>
                                        </div>
                                        <div class="col-auto align-self-center">
                                            <div style={{
                                                background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                alignItems: "center", justifyContent: "center", margin: "auto"
                                            }} float="left" className="mt-1">
                                                <NodeIndexOutlined style={{ color: "#99db7f", fontSize: '20px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-3" style={{ width: "25%" }}>
                            <div class="card report-card bg-soft-pink" style={{ borderRadius: '20px' }}>
                                <div class="card-body" >
                                    <div class="row d-flex justify-content-center">
                                        <div class="col">
                                            <p class="mb-0 fw-semibold textStyles-smallx"><b>Ongoing <br />Journeys</b></p>
                                            <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.results?.ongoingJourneys ? (this.state.getAllData?.results?.ongoingJourneys).toLocaleString() : (0)}</h2>
                                        </div>
                                        <div class="col-auto align-self-center">
                                            <div style={{
                                                background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                alignItems: "center", justifyContent: "center", margin: "auto"
                                            }} float="left" className="mt-1">
                                                <FieldTimeOutlined style={{ color: "#fd3ca3", fontSize: '20px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-3" style={{ width: "25%" }}>
                            <div class="card report-card bg-soft-warning" style={{ borderRadius: '20px' }}>
                                <div class="card-body" >
                                    <div class="row d-flex justify-content-center">
                                        <div class="col">
                                            <p class="mb-0 fw-semibold textStyles-smallx"><b>Number Of<br /> Vehicles</b></p>
                                            <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.results?.vehicles ? (this.state.getAllData?.results?.vehicles).toLocaleString() : (0)}</h2>
                                        </div>
                                        <div class="col-auto align-self-center">
                                            <div style={{
                                                background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                alignItems: "center", justifyContent: "center", margin: "auto"
                                            }} float="left" className="mt-1">
                                                <CarOutlined style={{ color: "#ffda7e", fontSize: '20px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-3" style={{ width: "25%" }}>
                            <div class="card report-card bg-soft-info" style={{ borderRadius: '20px' }}>
                                <div class="card-body" >
                                    <div class="row d-flex justify-content-center">
                                        <div class="col">
                                            <p class="mb-0 fw-semibold textStyles-smallx" style={{
                                            }}><b>Number Of<br />Drivers</b></p>
                                            <h2 class="m-0" style={{ marginTop: '3px' }}>{this.state.getAllData?.results?.drivers ? (this.state.getAllData?.results?.drivers).toLocaleString() : (0)}</h2>
                                        </div>
                                        <div class="col-auto align-self-center">
                                            <div style={{
                                                background: "white", borderRadius: "50%", width: "40px", height: "40px", display: "grid",
                                                alignItems: "center", justifyContent: "center", margin: "auto"
                                            }} float="left" className="mt-1">
                                                <UserOutlined style={{ color: "#2d81fe", fontSize: '20px' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>



                <div class="row">
                    <div className="col-md-12 col-lg-12">

                    </div>
                </div>


                <br></br>
                <div className='col-md-12'>

                    <div className="card">
                        <div className="card-header">
                            <div className='row'>
                                <div className="col-md-3">
                                    <h4 className="card-title textStyle-charts-header" >Vehicle Running KM's Monthly</h4>
                                </div>
                                <div className="container" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div className="col">
                                        <span>
                                            <Badge color="#2D5FF4" text="Actual" style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }} /></span>
                                        <span style={{ marginLeft: "10px", fontFamily: 'Roboto', fontSize: '9px', fontWeight: 'bold' }}><Badge color="#42B02A" text="Target" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className='row'>
                                <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageWRChartTarget ? (
                                        <SemiCircleGaugeChat
                                            setcolor="#2D5FF4"
                                            setvalues={((this.state.vehicleRegionViceMillageWRChartTarget / this.state.vehicleRegionViceMillageWRChartRealValue) * 100).toFixed(2)}
                                            setTitel="Western"
                                            targetValue={this.state.vehicleRegionViceMillageWRChartRealValue}
                                            realValue={this.state.vehicleRegionViceMillageWRChartTarget}
                                        />
                                    ) : (
                                        console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                    )}
                                </div>

                                <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageSRChartTarget ? (
                                        <SemiCircleGaugeChat
                                            setcolor="#2D5FF4"
                                            setvalues={((this.state.vehicleRegionViceMillageSRChartTarget / this.state.vehicleRegionViceMillageSRChartRealValue) * 100).toFixed(2)}
                                            setTitel="Southern"
                                            targetValue={this.state.vehicleRegionViceMillageSRChartRealValue}
                                            realValue={this.state.vehicleRegionViceMillageSRChartTarget}
                                        />
                                    ) : (
                                        console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                    )}
                                </div>

                                <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageCRChartTarget ? (
                                        <SemiCircleGaugeChat
                                            setcolor="#2D5FF4"
                                            setvalues={((this.state.vehicleRegionViceMillageCRChartTarget / this.state.vehicleRegionViceMillageCRChartRealValue) * 100).toFixed(2)}
                                            setTitel="Central"
                                            targetValue={this.state.vehicleRegionViceMillageCRChartRealValue}
                                            realValue={this.state.vehicleRegionViceMillageCRChartTarget}
                                        />
                                    ) : (
                                        console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                    )}
                                </div>

                                <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageNRChartTarget ? (
                                        <SemiCircleGaugeChat
                                            setcolor="#2D5FF4"
                                            setvalues={((this.state.vehicleRegionViceMillageNRChartTarget / this.state.vehicleRegionViceMillageNRChartRealValue) * 100).toFixed(2)}
                                            setTitel="Northern"
                                            targetValue={this.state.vehicleRegionViceMillageNRChartRealValue}
                                            realValue={this.state.vehicleRegionViceMillageNRChartTarget}
                                        />
                                    ) : (
                                        console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                    )}
                                </div>

                                <div class="col-md-6 col-lg-3" style={{ textAlign: "center", width: "20%" }} >
                                    {this.state.vehicleRegionViceMillageERChartRealValue ? (
                                        <SemiCircleGaugeChat
                                            setcolor="#2D5FF4"
                                            setvalues={((this.state.vehicleRegionViceMillageERChartTarget / this.state.vehicleRegionViceMillageERChartRealValue) * 100).toFixed(2)}
                                            setTitel="Eastern"
                                            targetValue={this.state.vehicleRegionViceMillageERChartRealValue}
                                            realValue={this.state.vehicleRegionViceMillageERChartTarget}
                                        />
                                    ) : (
                                        console.log(((this.state.vehicleRegionViceMillageCRChartRealValue / this.state.vehicleRegionViceMillageCRChartTarget) * 100).toFixed(2))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br></br>

                <div className="row">
                    <div className="col-md-12 col-lg-6">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h4 className="card-title textStyle-charts-header">Running KM's</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {this.state.runningKMChart1 ? (
                                    <StackedBarLine
                                        y1={this.state.runningKMChart1SetY1}
                                        x1={this.state.runningKMChart1SetX1}
                                        y1Lable={"Daily KM's"}
                                    // y2Lable={'MTD'} 
                                    />) : ('')}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h4 className="card-title textStyle-charts-header">Journey Trend</h4>
                                    </div>
                                    <div className="col-md-9">
                                        <Select
                                            className='textStyles-small'
                                            showSearch
                                            style={{ width: "40%", float: "right" }}
                                            placeholder="Select a region"
                                            onChange={(value) => this.JourneyTrendDetails(value)}
                                            allowClear
                                        >
                                            {this.props?.regions?.map((item, index) => {
                                                return <Option value={item.id} key={item.id}>{item.name}</Option>;
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {this.state.requesteTrendChart ? (
                                    <LineCharts y1={this.state.requesteTrendChartSetY1}
                                        x1={this.stripYearFromDates(this.state.requesteTrendChartSetX1)}
                                        yLabale={"Total"} />) : ('')}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h4 className="card-title textStyle-charts-header">Running KM's</h4>
                                    </div>
                                    <div className="col-md-9">
                                        <Select
                                            className='textStyles-small'
                                            showSearch
                                            style={{ width: "40%", float: "right" }}
                                            placeholder="Select a region"
                                            onChange={(value) => this.getChartsRunningKms2RewampFetch(value)}
                                            allowClear
                                        >
                                            {this.props?.regions?.map((item, index) => {
                                                return <Option value={item.id} key={item.id}>{item.name}</Option>;
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {this.state.runningKMChart2 ? (
                                    <ColumnChartsDataLablesChart
                                        y1={this.state.runningKMChart2SetY1}
                                        x1={this.state.runningKMChart2SetX1}
                                        barColors={['#42B02A']}
                                        prefixData={"KM's"}
                                        labelData={"Monthly Running KM's"}
                                        yLabale={"Monthly KM's"} />) : ('')}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h4 className="card-title textStyle-charts-header">Journey Status</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {this.state.journeyStatus ? (
                                    <StackedBarChat
                                        y1={this.state.journeyStatusSetY1}
                                        x1={this.state.journeyStatusSetX1}
                                        yLabale={'Regions'}
                                        barColors={['#FF3A29', '#42B02A', '#FFB200']}
                                        horizontalStatus={true}
                                    />) : ('')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        organization_count: state.organization.length,
        regions: state.regions,
        depots: state.depots,
        sites_count: state.sites.length,
        vendor_count: state.vendor.length,
        roles_count: state.roles.length,
    };
}

export default connect(mapStateToProps, { getDonationCategories, getData })(
    Dashboard
);


