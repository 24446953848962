import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { getAllVendorTeam, updateVendorTeam, createNewVendorTeam, getAllTeamMembers } from '../../api/index';
import { connect } from "react-redux";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { Drawer, Spin, Select, Table, Space, Input, Modal } from "antd";
import {
	EditOutlined, EyeOutlined, ExclamationCircleOutlined, DeleteColumnOutlined
} from '@ant-design/icons';
import { FiTrash2 } from 'react-icons/fi';

const { Option } = Select;

class VendorTeam extends Component {

	constructor(props) {
		super(props)
		this.validator = new SimpleReactValidator();
		this.state = {
			filterTable: null,
			name: "",
			code: "",
			vendor: 1,
			region: 1,
			selectedRegionList: [],
			vendorTeams: [],
			teamMambers: [],
			open: false,
			isLoading: false,
			selectedUser: null,
			isEdit: false,
			selectedUserList: [],
			showMobile: false
		}
	}

	componentDidMount() {
		this.getAllVendorTeams()
	}

	getAllVendorTeams = async () => {
		this.setState({
			isLoading: true
		})
		var res = await getAllVendorTeam();
		var team_mambers = await getAllTeamMembers();
		console.log(team_mambers);
		if (res.success) {
			this.setState({
				isLoading: false,
				vendorTeams: res.data,
				teamMambers: team_mambers.data
			})
		}
	}

	// window soft reaload function for realod current frame
	reloadWindow = () => {
		window.location.reload(true);
	}

	createVendorTeam = async () => {
		if (this.validator.allValid()) {
			var res;
			var data = {
				"name": this.state.name,
				"code": this.state.code,
				"vendor": { id: this.state.vendor },
				"region": { id: this.state.region },
				"users": this.state.selectedUserList
			}

			if (this.state.isEdit) {
				data.id = this.state.id;
				console.log(data);
				res = await updateVendorTeam(data);
			}
			else {
				res = await createNewVendorTeam(data);
			}

			if (res.success) {
				toast.success(this.state.isEdit ? "Successfully Updated Vendor Team" : "Successfully Created Vendor Team");
				this.getAllVendorTeams();
				this.reloadWindow();
			}
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		}
	}

	formItemChange = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	}


	vendorChange = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	}

	search = (value) => {
		const { vendorTeams } = this.state;
		const filterTable = vendorTeams.filter((o) =>
			Object.keys(o).some((k) =>
				String(o[k]).toLowerCase().includes(value.toLowerCase())
			)
		);

		this.setState({ filterTable });
	};

	onDrawerClose = () => {
		this.setState({
			open: false
		})
	}

	openRegisterDrawer = (e) => {
		this.setState({
			open: true,
			selectedUser: e
		})
	}

	editVendorTeam = (e) => {
		this.setState({
			"id": e.id,
			"name": e.name,
			"code": e.code,
			"vendor": e.vendor.id,
			"region": e.region.id,
			"selectedUserList": e.users,
			"isEdit": true
		})


	}

	clearData() {
		this.setState({
			"id": "",
			"name": "",
			"code": "",
			"vendor": "",
			"region": "",
			"selectedUserList": "",
			"isEdit": true
		})
	}

	teamMemberChange = (team) => {
		var _team = [];
		team.map((item: any) => {
			_team.push({ "id": item })

		})
		this.setState({
			selectedUserList: _team
		})
	}

	regionChange = (team) => {
		var _rerion = [];
		team.map((item: any) => {
			_rerion.push({ "id": item })

		})
		this.setState({
			selectedRegionList: _rerion
		})
	}

	showPhoneNumberToast = (status) => {
		this.setState({
			showMobile: status
		})
	}

	showDeleteConfirm = (record) => {
		const { confirm } = Modal;
		confirm({
			title: 'Are you sure you want to delete this Vendor Team?',
			onOk: async () => {
				/*var res = await deleteVendor(record.id);
				if (res.success) {
				  toast.success('Vendor deleted successfully');
				  this.props.removeVendor(record.id);
				}*/
			},
			onCancel() { },
		});
	};

	showCreateConfirmationModal = (message) => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: message,
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.createVendorTeam(),
		});
	};

	render() {
		const columns = [
			{
				title: "Name",
				dataIndex: "name",
				key: "name",
				render: (text) => <span>{text == " " ? <b>Not Set</b> : text}</span>,
			},
			{
				title: "Code",
				dataIndex: "code",
				key: "code",
				render: (text) => <span>{text}</span>,
			},
			{
				title: "Vendor",
				dataIndex: "vendor",
				key: "vendor",
				render: (vendor) => <span>{vendor.name}</span>,
			},
			{
				title: "Region",
				dataIndex: "region",
				key: "region",
				render: (region) => <span>{region.name}</span>,
			}, {
				title: "Action",
				key: "action",
				render: (text, record) => (
					<Space size="middle">
						<EyeOutlined twoToneColor="#52c41a" onClick={() => this.openRegisterDrawer(record)} />
						<EditOutlined onClick={() => this.editVendorTeam(record)} />
						{/*<FiTrash2 onClick={() => this.showDeleteConfirm(record)} /> */}
					</Space>
				),
			}
		];
		return (
			<div><Spin spinning={this.state.isLoading} delay={200}> <div className="card-body">
				<div className="row">
					<div className="col-lg-4">
						<div className="card">
							<div className="card-header">
								<b>Create Vendor Team</b>
								{/* <p className="text-muted mb-0">Small description.</p>  */}
							</div>
							<div className="card-body">
								<form>
									<div className="mb-3">
										<label className="form-label" for="name">Name</label>
										<input autoComplete="off" type="text" className="form-control" name="name" value={this.state.name} aria-describedby="emailHelp" onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('name', this.state.name, 'required')}</p>
									</div>
									<div className="mb-3">
										<label className="form-label" for="description">Code</label>
										<input autoComplete="off" type="text" value={this.state.code} className="form-control" name="code" onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('code', this.state.code, 'required')}</p>
									</div>
									<div class="mb-3">
										<label class="form-label" for="exampleInputEmail1">Region</label>
										<Select
											mode="multiple"
											showSearch
											name="region"
											onChange={(e) => this.regionChange(e)}
											placeholder="Region"
											style={{ width: "100%" }}
											value={this.state.selectedRegionList.map((item) => item.id)}
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											filterSort={(optionA, optionB) =>
												optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
											}
										>
											{this.props.regions.map((item, index) => {
												return <Option key={index} value={item.id}>{item.name}</Option>;
											})}
										</Select>
									</div>
									<div className="mb-3">
										<label className="form-label" for="exampleInputPassword1">Region</label>
										<div className="col-sm-12">
											<select className="select2 form-control mb-3 custom-select" name="region" value={this.state.region} onChange={(e) => this.formItemChange(e)} style={{ width: '100%', height: '36px' }}>
												{this.props.regions.map((region, index) => {
													return <option value={region.id}>{region.name}</option>
												})}
											</select>
											{/* <p style={{color:"#f5325c"}}>{this.validator.message('vendor', this.state.vendor, 'required')}</p> */}
										</div>
									</div>
									<div className="mb-3">
										<label className="form-label" for="exampleInputPassword1">Vendor</label>
										<div className="col-sm-12">
											<select className="select2 form-control mb-3 custom-select" name="vendor" value={this.state.vendor} onChange={(e) => this.vendorChange(e)} style={{ width: '100%', height: '36px' }}>
												{this.props.vendors.map((vendor, index) => {
													return <option value={vendor.id}>{vendor.name}</option>
												})}
											</select>
											{/* <p style={{color:"#f5325c"}}>{this.validator.message('vendor', this.state.vendor, 'required')}</p> */}
										</div>
									</div>

									<div class="mb-3">
										<label class="form-label" for="exampleInputEmail1">User</label>
										<Select
											mode="multiple"
											showSearch
											name="regionId"
											onChange={(e) => this.teamMemberChange(e)}
											placeholder="Team Member"
											style={{ width: "100%" }}
											value={this.state.selectedUserList.map((item) => item.id)}
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											filterSort={(optionA, optionB) =>
												optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
											}
										>
											{this.state.teamMambers.map((item, index) => {
												return <Option key={index} value={item.id}>{item.name + "-" + item.email}</Option>;
											})}
										</Select>
									</div>
								</form>
								<button className="btn btn-primary"
									onClick={() => this.showCreateConfirmationModal(this.state.isEdit ?
										"Are you sure you want to update this Vendor Team?" : "Are you sure you want to create this Vendor Team?")} >
									{this.state.isEdit ? "Update Vendor Team" : "Register New Vendor Team"}
								</button>
							</div>
						</div>
					</div>
					<div className="col-lg-8">

						<div className="card">
							<div className="card-header">
								<h4 className="card-title">
									<b>
										Vendor Team List
									</b>
								</h4>
							</div>
							<Input
								enterButton
								style={{ width: "98%", margin: 10 }}
								placeholder="Search ..."
								onChange={(e) => {
									const currValue = e.target.value;
									this.search(currValue);
								}}
							/>
							<Table
								size="small"
								columns={columns}
								dataSource={this.state.filterTable == null ? 
									this.state.vendorTeams.sort((a, b) => b.id - a.id) 
									: this.state.filterTable.sort((a, b) => b.id - a.id)
								}
								rowKey="id"
							/>
						</div>
					</div>
				</div>
			</div></Spin>
				<Drawer title="Vendor Team Details" width={"500px"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open}>
					{this.state.selectedUser !== null && <div class="row">
						<div class="col-12">
							<div class="card">
								<div class="card-body p-0">
									<div id="user_map" class="pro-map"  ></div>
								</div>
								<div class="card-body">
									<div class="dastone-profile">
										<div class="row">

											<div class="col-lg-8">
												<ul class="list-unstyled personal-detail mb-0">
													<li class="mt-1"><b> Name </b> : {
														this.state.selectedUser && this.state.selectedUser.name
															? this.state.selectedUser.name
															: "Not set"
													}</li>
													<li class="mt-1"><b> Code </b> : {this.state.selectedUser.code}</li>
												</ul>
											</div>
										</div>
									</div>
								</div></div>
							<div class="card">
								<div class="card-body">
									<div class="dastone-profile">
										<div class="row">

											<div class="col-lg-8">
												<ul class="list-unstyled personal-detail mb-0">
													<li class="mt-1"><b> Vendor Name </b> : {this.state.selectedUser.vendor.name}</li>
													<li class="mt-1"><b> Vendor Contact Number </b> : {this.state.selectedUser.vendor.contactNumber}</li>
													<li class="mt-1"><b> Vendor Contact Person </b> : {this.state.selectedUser.vendor.contactPerson}</li>
												</ul>
											</div>
										</div>
									</div>
								</div></div>
							<div class="card">
								<div class="card-body">
									<div class="dastone-profile">
										<div class="row">

											<div class="col-lg-8">
												<ul class="list-unstyled personal-detail mb-0">
													<li class="mt-1"><b> Region </b> : {this.state.selectedUser.region.name}</li>
													<li class="mt-1"><b> Organization </b> : {this.state.selectedUser.region.organization.name}</li>
												</ul>
											</div>

										</div>
									</div>
								</div></div>
							<h5>Team Members</h5>
							<div class="row">

								{this.state.selectedUser !== null && this.state.selectedUser.users.map((user) => {
									return <div class="col-lg-11">
										<div class="card">
											<div class="card-body">
												<div class="row">
													<div class="col">
														<div class="media">
															<img src="assets/images/users/user-2.jpg" alt="user" class="rounded-circle thumb-lg align-self-center" />
															<div class="media-body ms-3 align-self-center">
																<h5 class="mt-0 mb-1">{user.name}</h5>
																<p class="mb-0 text-muted">{user.email}</p>
																{this.state.showMobile && <p class="mb-0 text-muted">{user.mobileNumber}</p>}
															</div>
														</div>
													</div>
													<div class="col-auto align-self-center">
														<ul class="list-inline mb-0">
															<li class="list-inline-item">
																<a href="#" onMouseEnter={() => this.showPhoneNumberToast(true)} onMouseLeave={() => this.showPhoneNumberToast(false)} class="me-1 contact-icon"><i class="fas fa-phone"></i></a>
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								})}
							</div>
						</div>
					</div>
					}
				</Drawer>
				<ToastContainer position="top-right" autoClose={5000} />
			</div >
		);
	}
}


function mapStateToProps(state) {
	console.log(state)
	return {
		vendors: state.vendor,
		regions: state.regions,

	};
}

function mapDispatchToProps(dispatch) {
	return {
		// addVendor: data => dispatch(addVendor(data))
	};
}

export default connect(
	mapStateToProps, mapDispatchToProps
)(VendorTeam);

