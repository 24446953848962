import React, { Component } from 'react'
import { Table, Tag, Button, Drawer, Form, Input, Select, InputNumber, message, Popconfirm, Collapse } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { createNewATs, getAtsDetails, UpdateAts, getRefData } from "../../api/index";
import AssetDelete from "../site_components/asset_delete";
import { checkServiceType } from "../../actions/service-type";



class Ats extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            showModal: false,
            open: false,
            openEdit: false,
            title: "",
            tabaleLoading: false,
            actionView: false,
            selectedAssetId: null,
            AssetType: [],
            selectedMakeId: null,
            makeType: [],
            selectedModelId: null,
            modelType: [],
            atsType: '',
            atsLowVoltageFunctionality: null,
            atsCapacity: '',
            AssetManagementPermission: false,
            openViewMoreDetails: false,
            selectedElement: "",
            atsMake: '',
            refData: [],

            dropdownATS : {
                "atsLowVoltageFunctionality": "",
            }
        };
        this.requestFromClose = this.requestFromClose.bind(this);
        this.handleAtsType = this.handleAtsType.bind(this);
        this.handleMake = this.handleMake.bind(this);
    }
    handleAtsType(e) {
        console.log(e)
        this.setState({ atsCapacity: e });
    }

    handleMake(e) {
        console.log(e)
        this.setState({ atsMake: e });
    }
    
    componentDidMount() {
        if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
            const { history } = this.props;
            history.push("/");
        } else {
            this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 38)
        }
        checkServiceType(38).then((data) => this.setState({
            AssetManagementPermission: data
        }, () => {
        }),
        )
    }


    onDrawerClose = () => {
        console.log('sddsds')
        this.formRef.current.resetFields();

        this.validator.hideMessages();
        this.setState({
            open: false,
            openEdit: false,
            selectedAssetId: null,
            AssetType: [],
            selectedMakeId: null,
            makeType: [],
            selectedModelId: null,
            modelType: [],
            atsType: '',
            atsLowVoltageFunctionality: null,
            atsCapacity: '',
            atsMake: ''

        })

    }
    requestFromClose() {
        console.log('dsdsd')
        this.setState({ showModal: true, open: true });
    }
    getSetPageData = (serviceTypeIs, Pageid) => {

        if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] != null) {
            console.log('fddf');
            this.setState({
                actionView: true,
            })
        }

    };
    onChangeAsset = (value) => {
        console.log("onChangeAsset");
        //console.log(value);
        this.setState({
            selectedAssetId: value,
            AssetType: {
                "id": value
            },

        })
    };
    onChangeModel = (value) => {
        console.log("onChangeModel");
        //console.log(value);
        this.setState({
            selectedModelId: value,
            modelType: {
                "id": value
            },

        })
    };
    onChangeislowVoltageFunctionality = (value) => {
        //console.log("onChangeislowVoltageFunctionality");
        console.log(value);
        if (value == 1) {
            this.setState({
                atsLowVoltageFunctionality: parseInt(value),


            })
        } else {
            this.setState({
                atsLowVoltageFunctionality: parseInt(value),


            })
        }

    };
    onChangeMake = (value) => {
        console.log("onChangeMake");
        //console.log(value);
        this.setState({
            selectedMakeId: value,
            makeType: {
                "id": value
            },

        })
    };
    atsUpdata(id) {
        console.log('dfdfd')
        console.log(id)
        this.getDataAtsDetails(id);
        this.setState({ openEdit: true });
    }
    getDataAtsDetailsForDelete = async (id) => {

        var res = await getAtsDetails(id);
        console.log(res);
        if (res.success) {
            var respons = AssetDelete.Action(res.data, this.props?.detailsSite?.site_details.id, UpdateAts)



            respons.then((value) => {
                if (value == 1) {
                    message.success('Successfully Delete!')
                    this.props.parentMethod();
                } else {
                    message.warning(' Something went wrong!')
                }


            });



        }
    }
    getDataAtsDetails = async (id) => {
        var res = await getAtsDetails(id);
        console.log('serfrtrtrtrtrtrtr');
        console.log(res);
        if (res.success) {
            this.setState({
                atsEdit: res.data,
                atsId: res.data.id,
                atsCapacity: res.data.capacity,
                //  atsLowVoltageFunctionality:res.data.atsLowVoltageFunctionality,
                // selectedMakeId: res.data.make.id,
                makeType: res.data.make,
                selectedModelId: res.data?.model?.id,
                modelType: res.data.model,
                atsMake: res.data.make
            })
            if (res.data?.lowVoltageFunctionality == false) {
                console.log('000')
                this.setState({
                    atsLowVoltageFunctionality: 0
                })

            } else {
                console.log('111')

                this.setState({
                    atsLowVoltageFunctionality: 1
                })
            }

        }

    }
    AllDataSubmitUpdate = async () => {
        if (this.validator.allValid()) {
            var dataSet;
            dataSet = {
                "id": this.state.atsId,
                "site": { "id": this.props?.detailsSite?.site_details.id },
                "asset": {
                    "id": 2
                },
                "make": this.state.atsMake,
                "model": this.state.modelType,
                "capacity": this.state.atsCapacity,
                "lowVoltageFunctionality": this.state.atsLowVoltageFunctionality,



            }
            console.log(dataSet)
            var res = await UpdateAts(dataSet);
            console.log(res)
            if (res.success) {
                message.success('Successfully ATS Edited!');
                this.formRef.current.resetFields();

                this.setState({
                    showModal: false,
                    open: false,
                    openEdit: false,

                    selectedAssetId: null,
                    AssetType: [],
                    selectedMakeId: null,
                    makeType: [],
                    selectedModelId: null,
                    modelType: [],
                    atsType: '',
                    atsLowVoltageFunctionality: null,
                    atsCapacity: '',
                    atsMake: ''

                })
                this.props.parentMethod();

            } else {
                message.warning(' Something went wrong!');
            }
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    AllDataSubmit = async () => {
        if (this.validator.allValid()) {
            var dataSet;
            dataSet = {

                "site": { "id": this.props?.detailsSite?.site_details.id },
                "asset": {
                    "id": 2
                },
                "make": this.state.atsMake,
                "model": this.state.modelType,
                "capacity": this.state.atsCapacity,
                "lowVoltageFunctionality": this.state.atsLowVoltageFunctionality,



            }
            console.log(dataSet)
            var res = await createNewATs(dataSet);
            if (res.success) {
                message.success('Successfully ATS Added!');
                this.formRef.current.resetFields();

                this.setState({
                    showModal: false,
                    open: false,
                    selectedAssetId: null,
                    AssetType: [],
                    selectedMakeId: null,
                    makeType: [],
                    selectedModelId: null,
                    modelType: [],
                    atsType: '',
                    atsLowVoltageFunctionality: null,
                    atsCapacity: '',
                    atsMake: ''
                })
                this.props.parentMethod();
                // const { history } = this.props;
                // history.push("/site");
            } else {
                message.warning(' Something went wrong!');
            }

        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    submitForm = async (item) => {
        this.getDataAtsDetailsForDelete(item);

    }

    viewMoreDetails(element) {
        this.setState({
            showModal2: true,
            openViewMoreDetails: true,
            selectedElement: element
        });
    }

    onViewMoreDetailsDrawerClose = () => {
        this.setState({
            showModal2: false,
            openViewMoreDetails: false,
        })
    }

    validatedTextLable = (field) => {
        return (field !== null && field !== '')
            ? <span class="badge badge-outline-secondary text-muted">{(field)}</span>
            : <span class="badge text-danger">Currently Not Set</span>;
    }

    render() {
        const { Option } = Select;
        const { Panel } = Collapse;
        const _ats = this.state.selectedElement
        const columns2 = [
            {
                title: "Make",
                details: [],
                content: _ats !== ""
                    ? <Tag color="gray" style={{ margin: "0px" }}><span>{_ats.make}</span></Tag> : <></>,
            },
            {
                title: "Model",
                content: _ats !== "" ? <span class="badge badge-outline-info badge-pill"><i class="fas fa-hdd" style={{ paddingRight: "5px" }} />{_ats.model?.name}</span> : <></>,
                details: [
                    {
                        title: "Capacity (A)",
                        content: this.validatedTextLable(_ats.model?.capacity),
                    },
                    {
                        title: "Low Voltage Functionality",
                        content: _ats.model?.lowVoltageFunctionality
                            ? <span class="badge bg-success">true</span>
                            : <span class="badge bg-danger">false</span>,
                    },
                ],
            },

        ];

        return (
            <>

                <div className='row'>
                    <div class="col-lg-12">
                        <div class="text-end">
                            <ul class="list-inline">
                                <li class="list-inline-item">
                                    {this.state.actionView
                                        ? <button type="button" class="btn btn-soft-primary btn-sm" onClick={this.requestFromClose}><i class="fas fa-plus me-2"></i> Add ATS</button>
                                        : ('')
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                    {this.props.details.access_technologies_data != null ? (<div className='col-lg-2' style={{ paddingBottom: 12 }}>
                        <table class="table table-dark2 mb-0" style={{ fontSize: 12 }} ><tbody><tr><td scope="row"><b>No. Of ATSs</b></td><td>: {this.props?.details.access_technologies_data?.noOfATSs}</td><td scope="row"></td></tr></tbody></table>

                    </div>) : ('')}

                    <div className='row'>
                        {this.props.details2.ats_data.map((element) => (
                            <div className='col-md-4'>
                                <div class="card">
                                    <div class="card-header">
                                        <div class="d-flex justify-content-start align-items-center">
                                            <img src={'https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG'} alt="" class="thumb-md rounded" />
                                            <div class="badge badge-soft-primary" style={{ padding: "5px", marginLeft: "15px", border: "1px solid rgba(0, 0, 0, 0.05)" }}>
                                                <i class="la la-home font-14 text-dark" style={{ paddingLeft: "10px" }} />
                                                <i class="la la-angle-right text-dark font-14" style={{ padding: "5px" }} />
                                                <span class="fw-semibold text-pink">{this.props?.detailsSite?.site_details.siteName}</span>
                                                <i class="la la-angle-right text-dark font-14" style={{ padding: "5px" }} />
                                                <span class="fw-semibold text-primary" style={{ paddingRight: "10px" }}>{element.make?.name}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="col">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h6 class="fw-semibold m-0">Make</h6>
                                                <span class="badge bg-secondary">{element.make}</span>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h6 class="fw-semibold m-0">Model</h6>
                                                <span class="badge badge-outline-info badge-pill"><i class="fas fa-hdd" style={{ paddingRight: "8px" }} />{element.model?.name}</span>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h6 class="fw-semibold m-0">Capacity (A)</h6>
                                                <span class="text-muted fw-normal">{element.model?.capacity != null ? element.model?.capacity : "Currently Not Set"}</span>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <h6 class="fw-semibold m-0">Low Voltage Functionality</h6>
                                                {element.model?.lowVoltageFunctionality
                                                    ? <span class="badge bg-success">true</span>
                                                    : <span class="badge bg-danger">false</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <p class="card-footer m-0">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <a href="#custom-modal" class="btn btn-soft-dark btn-sm waves-effect btn-round" onClick={() => this.viewMoreDetails(element)}>
                                                <i class="mdi mdi mdi-unfold-more-horizontal"></i> More Details </a>
                                            <div class="text-end">
                                                {this.state.AssetManagementPermission
                                                    ? (<button type="button" class="btn btn-soft-primary btn-icon-square-sm" onClick={() => this.atsUpdata(element.id)} style={{ marginRight: "8px" }}><i class="mdi mdi-pencil-outline"></i></button>)
                                                    : ("")}
                                                <Popconfirm
                                                    title="Are You Sure?"
                                                    description="Are you sure to delete this task?"
                                                    onConfirm={() => this.submitForm(element.id)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    {this.state.AssetManagementPermission
                                                        ? (<button type="button" class="btn btn-soft-danger btn-icon-square-sm"><i class="mdi mdi-trash-can-outline"></i></button>)
                                                        : ('')}
                                                </Popconfirm>
                                            </div>
                                        </div>
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>

                    <Drawer maskClosable={false} title={"ATS Details"} width={"50%"} placement="right" onClose={() => this.onViewMoreDetailsDrawerClose()} visible={this.state.openViewMoreDetails} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <Collapse style={{ borderColor: "lavender", alignContent: "center" }}>
                            {columns2.map((item) => (
                                <Panel
                                    style={{ borderColor: "lavender" }}
                                    header={
                                        <li class="d-flex justify-content-between align-items-center">
                                            <div>
                                                {item.details.length == 0 ? <i class="la la-hand-o-right text-primary" style={{ paddingLeft: "5px", paddingRight: "5px" }} /> : <></>}
                                                <span class="fw-normal m-2">{item.title}</span>
                                            </div>
                                            {item.content}
                                        </li>
                                    }
                                    key={item.title}
                                    showArrow={item.details.length > 0}
                                    collapsible={item.details.length > 0 ? "" : "icon"}
                                >
                                    {item.details.map((detail) => (
                                        <li class="d-flex justify-content-between align-items-center me-2" style={{ paddingBottom: "10px", paddingLeft: "10px" }}>
                                            <div>
                                                <i class="la la-circle text-primary me-2"></i> <span>{detail.title}</span>
                                            </div>
                                            {detail.content}
                                        </li>
                                    ))}
                                </Panel>
                            ))}
                        </Collapse>
                    </Drawer>

                    <Drawer maskClosable={false} title={" New ATS"} width={"50%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.open} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        Make <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="Make" >
                                                        {/* <Input id="name" onChange={this.handleMake} /> */}

                                                        <Select
                                                            showSearch
                                                            onChange={(e) => this.handleMake(e)}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                        >
                                                            {this.props?.allMakes?.allATSMakes?.map((item, index) => {
                                                                return <Option value={item?.name}>{item?.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Make', this.state.atsMake, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Model <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item required name="Is_active" tooltip="This is a required field">
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangeModel}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            allowClear

                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.atsMasterData?.atsMasterData?.atsModelList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}

                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Model', this.state.selectedModelId, 'required')}</p>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmit()} >Submit</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>

                    <Drawer maskClosable={false} title={"Edit ATS"} width={"50%"} placement="right" onClose={() => this.onDrawerClose()} visible={this.state.openEdit} closeIcon={<button type="button" class="btn btn-soft-danger btn-icon-square-sm" style={{ marginRight: "8px" }}><i class="mdi dripicons-cross"></i></button>}>
                        <div className="row" >
                            <div className="col-lg-12">
                                <div className="card">

                                    <div className="card-body">
                                        <Form
                                            ref={this.formRef}
                                        >
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="dcdu">
                                                        Make <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    <Form.Item  >
                                                        {/* <Input id="name" value={this.state.atsMake} onChange={this.handleMake} /> */}
                                                        <Select
                                                            showSearch
                                                            onChange={(e) => this.handleMake(e)}
                                                            style={{ width: "100%" }}
                                                            value={this.state?.atsMake}
                                                            placeholder="Search to Select"
                                                        >
                                                            {this.props?.allMakes?.allATSMakes?.map((item, index) => {
                                                                return <Option value={item?.name}>{item?.name}</Option>;
                                                            })}
                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Make', this.state.atsMake, 'required')}</p>

                                                         
                                                    </Form.Item>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label" for="depot">
                                                        Model <x style={{ color: "red" }}>*</x>
                                                    </label>
                                                    {this.state.selectedModelId != null ? (<Form.Item >
                                                        <Select
                                                            showSearch
                                                            onChange={this.onChangeModel}
                                                            defaultValue={this.state.selectedModelId}
                                                            style={{ width: "100%" }}
                                                            placeholder="Search to Select"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            filterSort={(optionA, optionB) =>
                                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                            }
                                                        >
                                                            {this.props.atsMasterData?.atsMasterData?.atsModelList?.map((item, index) => {
                                                                return <Option value={item?.id}>{item?.name}</Option>;
                                                            })}

                                                        </Select>
                                                        <p style={{ color: "#f5325c" }}>{this.validator.message('Model', this.state.selectedModelId, 'required')}</p>

                                                    </Form.Item>) : ('')}
                                                </div>
                                            </div>
                                        </Form>
                                        <div className='row' style={{ justifyContent: "end" }}>
                                            <div className='col-md-2' >   <button className="btn btn-primary" onClick={() => this.AllDataSubmitUpdate()} >Submit</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Drawer>
                </div>


            </>
        )
    }
}
export default Ats;
