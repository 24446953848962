
import React, { Component } from "react";
import { Table, Input, Tag, Space, DatePicker, Select } from "antd";
import ReactExport from "react-data-export";
import { getPisGenServiceFullExport, getUserSites, getCkecklist } from "../../api/index";
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

import ReactLoading from 'react-loading';
import { validateBoolValue, validateDateTimeFormat, validateIntValue, validateModle, validateStringDateTimeFormat, validateStringValue } from "../../actions/validators";
import { DATE, DATE_TIME, TIME } from "../../constants/action-types";
const moment = require('moment');


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { RangePicker } = DatePicker;
const { Option } = Select;

class PisGenServiceFullExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      filterTable: null,
      data_list: [],
      startDate: "",
      endDate: "",
      loading: false,
      allSite: [],
      depotList: [],
      regionsList: [],
      regionIds: '',
      depotIds: '',
      siteIds: '',
      allSite_loading: false,
      buttonLodading: false,
      fullArray: [],
      recodesPerPage1: 1000,


    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
      const { history } = this.props;

      history.push("/");

    } else {
      this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')), 16)
    }

    this.props.getData();
    this.getDataSitesByuser();

    var _depotList = [];
    var _regionsList = [];
    this.props.depots.map((depot) => {
      _depotList.push({ value: depot.id, label: depot.name })
    })
    this.props.regions.filter(allret => "0".includes(allret.isSubRegion)).map((regions) => {
      _regionsList.push({ value: regions.id, label: regions.name })
    })
    this.setState({
      depotList: _depotList,
      regionsList: _regionsList
    })


    //console.log(this.props.details);
  }
  getSetPageData = (serviceTypeIs, Pageid) => {
    //console.log("this.props");
    //console.log(serviceTypeIs.serviceType);
    if (serviceTypeIs.serviceType.filter(checkuser => checkuser?.id == Pageid)[0] == null) {
      const { history } = this.props;

      history.push("/not-allowed");
    }

  };
  getDataSitesByuser = async () => {
    var res = await getUserSites();
    var _allSite = [];
    //console.log("getUserSites");
    //console.log(res.data);
    if (res.success) {
      res.data.map((sites) => {
        _allSite.push({ value: sites.id, label: sites.siteName })
      })
      this.setState({
        allSite_loading: false,
        allSite: _allSite,
      });
    }
  };
  getDataexpot = async (data) => {
    console.log(data);
    var pageCount = 0;
    var res = await getPisGenServiceFullExport(data);
    console.log("getGenServiceFullExport");
    console.log(res);
    if (res.success) {
      pageCount = res?.data?.pageCount;

      if (pageCount == 0) {

        this.setState({
          loading: false,
          buttonLodading: false,
          data_list: [],
        });
      }
      if (pageCount == 1) {
        this.setState({
          loading: false,
          buttonLodading: false,
          data_list: res.data.data,
        });
      }
      if (pageCount > 1) {
        for (let i = 0; i < pageCount; i++) {
          this.getDataexpot2(i, pageCount)
          if (i == pageCount - 1) {
            this.setState({
              loading: false,

            })
          }
        }
      }



    }

  };

  getDataexpot2 = async (data, pagecout) => {


    
    var form = new FormData();
    form.append("fromDate", this.state.startDate);
    form.append("toDate", this.state.endDate);
    form.append("siteIds", this.state.siteIds);
    form.append("depotIds", this.state.depotIds);
    form.append("regionIds", this.state.regionIds);
    form.append("pageNo", data);
    form.append("recodesPerPage",this.state.recodesPerPage1);

    var res = await getPisGenServiceFullExport(form);
    if (res.success) {
      this.setState({
        fullArray: [...this.state.fullArray, ...res.data.data]
      }, () => {
        if (data + 1 == pagecout) {
          console.log('data+1')
          console.log(data + 1)
          this.setState({
            buttonLodading: false
          })


        }
        this.setState({

          data_list: this.state.fullArray,
        });
      })

    }

  }



  onChangeDate = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      startDate: dateString,
    });
  };
  onChangeDate2 = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      endDate: dateString,
    });
  };
  searchClick = () => {
    console.log(this.state.siteIds);

    this.setState({ data_list: [], fullArray: [], buttonLodading: true, loading: true, })

    var form = new FormData();
    form.append("fromDate", this.state.startDate);
    form.append("toDate", this.state.endDate);
    form.append("siteIds", this.state.siteIds);
    form.append("depotIds", this.state.depotIds);
    form.append("regionIds", this.state.regionIds);
    form.append("pageNo", 0);
    form.append("recodesPerPage",this.state.recodesPerPage1);

    this.getDataexpot(form)

  };

  searchRegionmChange = (event) => {
    var dd = event
    var subRegion = [];
    var _depotList1 = [];
    var subRegionIdArray = [];

    this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id)).map((subR) => {
      subRegionIdArray.push(subR.id)
    })

    this.setState({
      regionIds: subRegionIdArray
    });

    subRegion = this.props.regions.filter(allret => dd.map((n) => n).includes(allret.parentRegion?.id));
    this.props.depots.filter(alldate => subRegion.map((n) => n.id).includes(alldate.region?.id)).map((depot) => {
      _depotList1.push({ value: depot.id, label: depot.name })
    })

    this.setState({
      depotList: _depotList1

    })

  }
  searchDepottemChange = (event) => {

    this.setState({
      depotIds: event
    });
  }
  searchSiteemChange = (event) => {

    this.setState({
      siteIds: event
    });
  }

  render() {
    const fontValue = { sz: "12", bold: true, fill: { patternType: "solid", fgColor: { rgb: "FF0B0713" } } };
    const { filterTable } = this.state;
    const dataSet1 = this.state.data_list;


    const StatisticDataSet = [
      {
        columns: [
          { title: "Request Code", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 210 } },//char width 
          { title: "Site ID", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "Site Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "Vendor", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 
          { title: "Request Type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Request Created On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Job Requested Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Job Status", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Service Completed On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount
          { title: "Request Created By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width cManual_transport_amount

          { title: "Request Approved On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Request Approved By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount

          { title: "Vendor Team", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//pixels width 
          { title: "Vendor Team Assigned On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   
          { title: "Vendor Team Assigned By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   

          { title: "Pre Generator Worked On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   
          { title: "Pre Generator Worked By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   

          { title: "Post Generator Worked On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   
          { title: "Post Generator Worked By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 

          { title: "Vendor Cordinator Approved On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   
          { title: "Vendor Cordinator Approved By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   

          { title: "Vendor Coordinator Rejected On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Vendor Coordinator Rejected By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount

          { title: "Power Engineer Approved On", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   
          { title: "Power Engineer Approved By", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width 

          { title: "Generator Name", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Generator Capacity", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Generator Category", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Generator Type", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Generator Model", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount
          { title: "Generator Last Service Date", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 180 } },//char width cManual_transport_amount

          { title: "Site Key Permission", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   
          { title: "Under Warranty", style: { border: { right: { style: "thin", color: { rgb: "FF0B0713" } }, bottom: { style: "thin", color: { rgb: "FF0B0713" } }, top: { style: "thin", color: { rgb: "FF0B0713" } } }, fill: { patternType: "solid", fgColor: { rgb: "E7E9E6" } }, font: fontValue }, width: { wpx: 120 } },//char width   
        ],

        data: dataSet1?.map((data) => [
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.serviceRequest.requestCode) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.serviceRequest.site.siteId) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.serviceRequest.site.siteName) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.serviceRequest.vendor.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateStringValue(data.serviceRequest.requestType.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.initiateLog) ? validateDateTimeFormat(data.initiateLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.serviceRequest) ? validateDateTimeFormat(data.serviceRequest.requestDate, DATE) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.status) ? validateStringValue(data.status.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateStringValue(data.serviceDate), style: { font: { sz: "12" } } },
          { value: validateModle(data.initiateLog) ? validateStringValue(data.initiateLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.approvedInitiateRequestLog) ? validateDateTimeFormat(data.approvedInitiateRequestLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.approvedInitiateRequestLog) ? validateStringValue(data.approvedInitiateRequestLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.vendorTeam) ? validateStringValue(data.vendorTeam.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.vendorTeamAssignedLog) ? validateDateTimeFormat(data.vendorTeamAssignedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.vendorTeamAssignedLog) ? validateStringValue(data.vendorTeamAssignedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.preGenTestingLog) ? validateDateTimeFormat(data.preGenTestingLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.preGenTestingLog) ? validateStringValue(data.preGenTestingLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.postGenNotWorkedLog) ? validateDateTimeFormat(data.postGenNotWorkedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.postGenNotWorkedLog) ? validateStringValue(data.postGenNotWorkedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.vcApprovedLog) ? validateDateTimeFormat(data.vcApprovedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.vcApprovedLog) ? validateStringValue(data.vcApprovedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.vcRejectedLog) ? validateDateTimeFormat(data.vcRejectedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.vcRejectedLog) ? validateStringValue(data.vcRejectedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.peApprovedLog) ? validateDateTimeFormat(data.peApprovedLog.createdTime, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.peApprovedLog) ? validateStringValue(data.peApprovedLog?.user?.name) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateModle(data.generator) ? validateStringValue(data.generator.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.generator) ? validateIntValue(data.generator.capacity) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.generator) ? validateStringValue(data.generator.category?.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.generator) ? validateStringValue(data.generator.type?.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.generator) ? validateStringValue(data.generator.model?.name) : ("Null"), style: { font: { sz: "12" } } },
          { value: validateModle(data.generator) ? validateDateTimeFormat(data.generator.lastServicedDate, DATE_TIME) : ("Null"), style: { font: { sz: "12" } } },

          { value: validateBoolValue(data.serviceRequest.sitekeyPermission), style: { font: { sz: "12" } } },
          { value: validateBoolValue(data.serviceRequest.underWarranty), style: { font: { sz: "12" } } },
        ]),
      }
    ];

    const columns = [
      {
        title: "Request Code",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        render: (item) => <span>{item.requestCode}</span>,
      },
      {
        title: "Site ID",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        render: (item) => <span>{item.site.siteId}</span>,
      },
      {
        title: "Site Name",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        render: (item) => <span>{item.site.siteName}</span>,
      },
      {
        title: "Generator Name",
        dataIndex: "generator",
        key: "generator",
        render: (item) => <span>{item.name}</span>,
      },
      {
        title: "Request Type",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        render: (item) => <span>{item.requestType.name}</span>,

      },
      {
        title: "Request Date",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        render: (item) => <span>{item.requestDate}</span>,
      },
      {
        title: "Request Time",
        dataIndex: "serviceRequest",
        key: "serviceRequest",
        render: (item) => <span>{item.requestTime}</span>,
      },
    ];
    const timestamp = Date.now();
    return (
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="page-title-box">
              <div class="row">
                <div class="col">
                  <h4 class="page-title">PIS Gen Service Data Export</h4>
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Data Export </a></li>
                    <li class="breadcrumb-item"><a href="javascript:void(0);">Gen Service Data Export</a></li>

                  </ol>
                </div>
                <div class="col-auto align-self-center">
                  <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                    <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                    <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2 col-lg-2 col-xl-2">
            <DatePicker
              placeholder="Start Date"
              style={{ width: "100%" }}
              onChange={this.onChangeDate}
            />
          </div>
          <div className="col-md-2 col-lg-2 col-xl-2">
            <DatePicker
              placeholder="End Date"
              style={{ width: "100%" }}
              onChange={this.onChangeDate2}
            />
          </div>
          <div className="col-md-2 col-lg-2 col-xl-2">
            <Select
              mode="multiple"
              showSearch
              name="regionId"
              onChange={(e) => this.searchRegionmChange(e)}
              placeholder="Region"
              style={{ width: "100%" }}

              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            // options={this.state.regionsList}

            >
              {this.state.regionsList.map((item, index) => {
                return <Option value={item.value}>{item.label}</Option>;
              })}
            </Select>
          </div>
          <div className="col-md-3 col-lg-3 col-xl-3">
            <Select
              mode="multiple"
              showSearch
              name="depotId"
              onChange={(e) => this.searchDepottemChange(e)}
              placeholder="Depot"
              style={{ width: "100%" }}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            // options={this.state.depotList}

            >
              {this.state.depotList.map((item, index) => {
                return <Option value={item.value}>{item.label}</Option>;
              })}
            </Select>
          </div>
          <div className="col-md-3 col-lg-3 col-xl-3">
            <Select
              mode="multiple"
              placeholder="Site"
              style={{ width: "100%" }}
              // options={this.state.allSite} 
              loading={this.state.allSite_loading}
              name="siteId"
              onChange={(e) => this.searchSiteemChange(e)}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
              }
            >
              {this.state.allSite.map((item, index) => {
                return <Option value={item.value}>{item.label}</Option>;
              })}
            </Select>
          </div>
          <div className="col-lg-12 col-xl-12" style={{ padding: 10, textAlign: 'end' }}>
            <button
              onClick={this.searchClick}
              class="btn btn-primary"
              disabled={
                this.state.startDate == "" && this.state.endDate == "" && this.state.regionIds == "" && this.state.depotIds == "" && this.state.siteIds == ""
              }
            //style={{ float: "right" }}
            >
              <i class="fas fa-search"> </i> Search
            </button>

          </div>
          <br /><br />
          <div className="col-lg-12 col-xl-12">
            <div className="card">
              <div className="card-header">
                <div className="row ">
                  <div className="col">
                    <h4 className="card-title">
                      Report -PIS Gen Service Data export{" "}
                      <ExcelFile
                        filename={"PIS Gen Service Data export -" + timestamp}
                        element={
                          <button
                            class="btn btn-primary"
                            style={{ float: "right" }}
                            disabled={this.state.buttonLodading || dataSet1.length <= 0}
                          >
                            <i class="fas fa-file-export" style={{ paddingRight: 5 }}> </i> {this.state.buttonLodading ? (<img width={20} src={'https://media.tenor.com/wpSo-8CrXqUAAAAj/loading-loading-forever.gif'}></img>) : ('')} Gen Service Data Export
                          </button>
                        }
                      >

                        <ExcelSheet dataSet={StatisticDataSet} name="PIS Gen Service Data Export" />
                      </ExcelFile>
                    </h4>
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="card-body">

                <Table
                  size="small"
                  columns={columns}
                  // rowKey="id"
                  loading={this.state.loading}
                  dataSource={this.state.data_list}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  //console.log("state", state);
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}
export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })(
  PisGenServiceFullExport
)); 