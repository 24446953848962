import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';
import * as XLSX from 'xlsx';

const exportToCSV = (data) => {
  const fields = [
    'id', 'active', 'model', 'brand', 'distanceUnit', 'seatingCapacity', 'mileage', 'usedMileage', 'registrationNumber', 'driverId', 'driverName', 'driverContact', 'regionId', 'vendorPoolId', 'vehicleTypeId', 'vehicleSubTypeId', 'vehicleOwnerId', 'vehiclePaymentCategoryId', 'vehicleMasterId',
    'vehicleMaster.id', 'vehicleMaster.active', 'vehicleMaster.code', 'vehicleMaster.description', 'vehicleMaster.regionId', 'vehicleMaster.userId', 'vehicleMaster.mileage', 'vehicleMaster.travellingOfficerId', 'vehicleMaster.travellingOfficer', 'vehicleMaster.vehicleCategory',
    'vendorPool.id', 'vendorPool.name', 'vendorPool.description', 'vendorPool.vendorId', 'vendorPool.mileage', 'vendorPool.usedMileage',
    'vehicleType.id', 'vehicleType.name', 'vehicleType.description', 'vehicleType.deleted',
    'vehicleSubType.id', 'vehicleSubType.name', 'vehicleSubType.description', 'vehicleSubType.deleted',
    'vehicleOwner.id', 'vehicleOwner.name', 'vehicleOwner.deleted',
    'vehiclePaymentCategory.id', 'vehiclePaymentCategory.name', 'vehiclePaymentCategory.deleted'
  ];

  const opts = { fields };
  const parser = new Parser(opts);
  const csv = parser.parse(data);

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, 'data.csv');
};

const exportToExcel = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(data, { header: [
    'id', 'active', 'model', 'brand', 'distanceUnit', 'seatingCapacity', 'mileage', 'usedMileage', 'registrationNumber', 'driverId', 'driverName', 'driverContact', 'regionId', 'vendorPoolId', 'vehicleTypeId', 'vehicleSubTypeId', 'vehicleOwnerId', 'vehiclePaymentCategoryId', 'vehicleMasterId',
    'vehicleMaster.id', 'vehicleMaster.active', 'vehicleMaster.code', 'vehicleMaster.description', 'vehicleMaster.regionId', 'vehicleMaster.userId', 'vehicleMaster.mileage', 'vehicleMaster.travellingOfficerId', 'vehicleMaster.travellingOfficer', 'vehicleMaster.vehicleCategory',
    'vendorPool.id', 'vendorPool.name', 'vendorPool.description', 'vendorPool.vendorId', 'vendorPool.mileage', 'vendorPool.usedMileage',
    'vehicleType.id', 'vehicleType.name', 'vehicleType.description', 'vehicleType.deleted',
    'vehicleSubType.id', 'vehicleSubType.name', 'vehicleSubType.description', 'vehicleSubType.deleted',
    'vehicleOwner.id', 'vehicleOwner.name', 'vehicleOwner.deleted',
    'vehiclePaymentCategory.id', 'vehiclePaymentCategory.name', 'vehiclePaymentCategory.deleted'
  ]});

  const workbook = {
    Sheets: { data: worksheet },
    SheetNames: ['data']
  };

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(blob, 'data.xlsx');
};

export { exportToCSV, exportToExcel };
