import React, { useState, useEffect } from 'react';
import {
    CarOutlined,
    NodeIndexOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CheckOutlined,
    DashboardOutlined,
    SearchOutlined,
    PhoneOutlined,
    PaperClipOutlined,
    UploadOutlined,
    DownloadOutlined,
    CloudSyncOutlined
} from '@ant-design/icons';
import { Checkbox, Avatar, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Empty, Spin} from 'antd';
import {
    getAllActiveVehicleOwner,
    getAllMainRegions,
    getVehicleListFullExport,
    getSessionFullExport,
    getVehicleSummaryReport,
    getMonthlySummaryReport,
} from "../../api/index";
import './style.css';
import xlsx from 'xlsx';
import jsonfile from 'jsonfile';
import fs from 'fs';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ExportVehicle = () => {
    const [expoertCategory, setExportCategory] = useState('1');
    const [startAndEndDate, setStartAndEndDate] = useState([]);
    const [exportFormat, setExportFormat] = useState('1');
    const [regionList, setRegionList] = useState([]);
    const [vendorName, setVendorName] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [searchData, setSearchData] = useState(null);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [mappedData, setMappedData] = useState([]);
    const [buttonVisible, setButtonVisible] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState([]);
    const [isStillLoading, setIsStillLoading] = useState(false);

    useEffect(() => {
        fetchAllRegions();
        fetchVehicleOwner();
    }, []);

    const setMonthlyStartAndEnd = (date, dateString) => {
        const startMonth = dateString;
        const endMonth = dateString;
        const startYear = parseInt(startMonth.substring(0, 4));
        const startMonthNum = parseInt(startMonth.substring(5, 7));
        const endYear = parseInt(endMonth.substring(0, 4));
        const endMonthNum = parseInt(endMonth.substring(5, 7));
        const startDate = new Date(startYear, startMonthNum - 1, 1);
        startDate.setDate(startDate.getDate() + 1);
        const endDate = new Date(endYear, endMonthNum, 0);
        endDate.setDate(endDate.getDate() + 1);
        const formattedStartDate = startDate.toISOString().slice(0, 10);
        const formattedEndDate = endDate.toISOString().slice(0, 10);
        const monArry = [];
        monArry.push(formattedStartDate);
        monArry.push(formattedEndDate);
        setSelectedMonth(monArry);
    }

    // const setMonthlyStartAndEnd = (date, dateString) => {
    //     const startMonth = dateString;
    //     const endMonth = dateString;
    //     const startYear = parseInt(startMonth.substring(0, 4));
    //     const startMonthNum = parseInt(startMonth.substring(5, 7));
    //     const endYear = parseInt(endMonth.substring(0, 4));
    //     const endMonthNum = parseInt(endMonth.substring(5, 7));
    //     const startDate = new Date(startYear, startMonthNum - 1, 1); // Subtract 1 from startMonthNum to get the correct month index
    //     const endDate = new Date(endYear, endMonthNum - 1, 0); // Subtract 1 from endMonthNum to get the last day of the previous month
    //     const formattedStartDate = startDate.toISOString().slice(0, 10);
    //     const formattedEndDate = endDate.toISOString().slice(0, 10);
    //     const monArry = [];
    //     monArry.push(formattedStartDate);
    //     monArry.push(formattedEndDate);
    //     setSelectedMonth(monArry);
    // }
    

    const fetchExportVehicleFullExport = async () => {
        setIsStillLoading(true);
        const requestFormData = new FormData();
        requestFormData.append("startDate", startAndEndDate[0]);
        requestFormData.append("endDate", startAndEndDate[1]);
        requestFormData.append("regions", selectedRegions);
        // requestFormData.append("vendor_id", vendorName);
        const response = await getVehicleListFullExport(requestFormData);
        if (response.success) {
            setSearchData(response.data.results);
            const mapData = vehicleFullExportDataMap(response.data.results);
            setMappedData(mapData);
            setButtonVisible(true);
            setIsStillLoading(false);
        } else {
            message.error("Failed to fetch vehicle full export");
            setIsStillLoading(false);
        }
    };

    const fetchGetSessionFullExport = async () => {
        setIsStillLoading(true);
        const requestFormData = new FormData();
        requestFormData.append("startDate", startAndEndDate[0]);
        requestFormData.append("endDate", startAndEndDate[1]);
        requestFormData.append("regions", selectedRegions);
        // requestFormData.append("vendor_id", vendorName);
        const response = await getSessionFullExport(requestFormData);
        if (response.success) {
            setSearchData(response.data.results);
            const mapData = sessionDataMap(response.data.results);
            setMappedData(mapData);
            setButtonVisible(true);
            setIsStillLoading(false);
        } else {
            message.error("Failed to fetch session full export");
            setIsStillLoading(false);
        }
    }

    const fetchGetMonthlyPaymentSummary = async () => {
        setIsStillLoading(true);
        const requestFormData = new FormData();
        requestFormData.append("startDate", selectedMonth[0]);
        requestFormData.append("endDate", selectedMonth[1]);
        requestFormData.append("regions", selectedRegions);
        requestFormData.append("suppliers", selectedSupplier);

        const response = await getMonthlySummaryReport(requestFormData);
        if (response.success) {
            setSearchData(response.data.results);
            const mapData = monthlyPaymentSummeryDataMap(response.data.results);
            setMappedData(mapData);
            setButtonVisible(true);
            setIsStillLoading(false);
        } else {
            message.error("Failed to fetch monthly payment summary");
            setIsStillLoading(false);
        }
    }

    const fetchGetVehicleSummaryReport = async () => {
        setIsStillLoading(true);
        const requestFormData = new FormData();
        requestFormData.append("startDate", selectedMonth[0]);
        requestFormData.append("endDate", selectedMonth[1]);
        requestFormData.append("regions", selectedRegions);
        requestFormData.append("suppliers", selectedSupplier);

        const response = await getVehicleSummaryReport(requestFormData);
        if (response.success) {
            setSearchData(response.data.results);
            const mapData = monthlyVehicleSummeryDataMap(response.data.results);
            setMappedData(mapData);
            setButtonVisible(true);
            setIsStillLoading(false);
        } else {
            message.error("Failed to fetch vehicle summary report");
            setIsStillLoading(false);
        }
    }

    const prepareDownloadData = () => {
        const mapDataSet = mappedData;
        if (mapDataSet.length === 0) {
            message.error("No data to export");
            return;
        }
        if (expoertCategory === '1') {
            exportData(mapDataSet, "Session Full Export_");
        } else if (expoertCategory === '2') {
            exportData(mapDataSet, "Vehicle Full Export_");
        } else if (expoertCategory === '3') {
            exportData(mapDataSet, "Monthly Payment Summary_");
        } else {
            exportData(mapDataSet, "Monthly Vehicle Summary_");
        }
    }

    const  transformKey = (key) => {
        const words = key.split(/(?=[A-Z])/);
        const transformedKey = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return transformedKey;
      }

    const allKeys = searchData?.reduce((keys, obj) => {
        Object.keys(obj).forEach(key => {
            if (!keys.includes(key)) {
                keys.push(key);
            }
        });
        return keys;
    }, []);


    const maxColumns = Math.min(7, allKeys?.length);
    const columns = allKeys?.slice(0, maxColumns).map(key => ({
        title: transformKey(key),
        dataIndex: key,
        key: key,
    }));

    // function for 2024-04-01T07:50:06 make that time string as 2024-04-01 07:50:06 and want to check input date if null or empty
    // remove miliseconds
    const timeConversionToReadable = (inputTime) => {
        if (inputTime !== null || inputTime !== '') {
            return inputTime.replace("T", " ").slice(0, 19);
        } else {
            return "N/A";
        }
    }
    
    const vehicleFullExportDataMap = (apiData) => {
        return apiData.map(item => ({
            "Journey Code": item?.journyCode ? item.journyCode : "0",
            "Journey Created On": item?.journyCreatedOn ? timeConversionToReadable(item.journyCreatedOn) : "N/A",
            "Session ID": item?.sessionId ? item.sessionId : "0",
            "Starting Location (GPS)": item?.startingLocation ? item.startingLocation : "0",
            "End Location (GPS)": item?.endLocation ? item.endLocation : "0",
            "Initial Journey Start Time": item?.initialJourneyStartTime ? timeConversionToReadable(item.initialJourneyStartTime) : "N/A",
            "Journey Start Time": item?.journeyStartTime ? timeConversionToReadable(item.journeyStartTime) : "N/A",
            "Initial Journey End Time": item?.initialJourneyEndTime ? timeConversionToReadable(item.initialJourneyEndTime) : "N/A",
            "Journey End Time": item?.journeyEndTime ? timeConversionToReadable(item.journeyEndTime) : "N/A",
            "Journey Start Meter Reading": item?.journeyStartMeterReading ? item.journeyStartMeterReading : "0",
            "Journey End Meter Reading": item?.journeyEndMeterReading ? item.journeyEndMeterReading : "0",
            "Journey Start Meter Reading Approved": item?.journeyStartMeterReadingApproved ? item.journeyStartMeterReadingApproved : "0",
            "Journey End Meter Reading Approved": item?.journeyEndMeterReadingApproved ? item.journeyEndMeterReadingApproved : "0",
            "Running KM’s Approved": item?.runingKmsApproved ? item.runingKmsApproved : "0",
            "Approved By": item?.approvedBy ? item.approvedBy : "N/A",
            "Approved Time": item?.approvedTime ? timeConversionToReadable(item.approvedTime) : "N/A",
            "Approved Start Time": item?.approvedStartTime ? timeConversionToReadable(item.approvedStartTime) : "N/A",
            "Approved End Time": item?.approvedEndTime ? timeConversionToReadable(item.approvedEndTime) : "N/A",
            "Driver Name": item?.driverName ? item.driverName : "N/A",
            "Driver Contact No": item?.driverContact ? item.driverContact : "N/A",
            "Journey Status": item?.status ? item.status : "N/A",
            "Vehicle Code": item?.vehicleCode ? item.vehicleCode : "N/A",
            "Vendor Pool": item?.vendorPool ? item.vendorPool : "N/A",
            "Vehicle Registration Number": item?.vehicleNumber ? item.vehicleNumber : "N/A",
            "Vehicle Brand": item?.vehicleBrand ? item.vehicleBrand : "N/A",
            "Vehicle Model": item?.vehicleModel ? item.vehicleModel : "N/A",
            "Vehicle Supplier": item?.vehicleSupplier ? item.vehicleSupplier : "N/A",
            "Vehicle Region": item?.vehicleRegion ? item.vehicleRegion : "N/A",
            "Fixed Username": item?.fexedUserName ? item.fexedUserName : "N/A",
            "Passenger Name": item?.passengerName ? item.passengerName : "N/A",
            "Canceled (Yes/No)": item?.canceled ? item.canceled : "N/A",
            "Canceled Time": item?.canceledTime ? timeConversionToReadable(item.canceledTime) : "N/A",
            "Vehicle Category": item?.vehicleCategory ? item.vehicleCategory : "N/A",
            "Vehicle Sub Type": item?.vehicleSubType ? item.vehicleSubType : "N/A",
            "Vehicle Type": item?.vehicleType ? item.vehicleType : "N/A"
        }));
    }

    const sessionDataMap = (apiData) => {
        return apiData.map(item => ({
            "Session Code": item?.sessionCode ? item.sessionCode : "N/A",
            "Master Code" : item?.masterCode ? item.masterCode : "N/A",
            "Vendor Pool": item?.vendorPool ? item.vendorPool : "N/A",
            "Initial Session Start Time": item?.initialSessionStartTime ? timeConversionToReadable(item?.initialSessionStartTime) : "N/A",
            "Session Start Time": item?.sessionStartTime ? timeConversionToReadable(item.sessionStartTime) : "N/A",
            "Initial Session End Time": item?.initialSessionEndTime ? timeConversionToReadable(item.initialSessionEndTime) : "N/A",
            "Session End Time": item?.sessionEndTime ? timeConversionToReadable(item.sessionEndTime) : "N/A",
            "Approved Start Time": item?.approvedStartTime ? timeConversionToReadable(item.approvedStartTime) : "N/A",
            "Approved End Time": item?.approvedEndTime ? timeConversionToReadable(item.approvedEndTime) : "N/A",
            "Session Duration (Hours)": item?.sessionDuration ? item.sessionDuration : "0",
            "Approved By": item?.approvedBy ? item.approvedBy : "N/A",
            "Approved Time": item?.approvedTime ? timeConversionToReadable(item.approvedTime) : "N/A",
            "Driver Name": item?.driverName ? item.driverName : "N/A",
            "Driver Contact No": item?.driverContact ? item.driverContact : "N/A",
            "Session Status": item?.sessionStatus ? item.sessionStatus : "N/A",
            "NOT (Hours)": item?.not ? item.not : "0",
            "DOT (Hours)": item?.dot ? item.dot : "0",
            "Accommodation": item?.accomodation ? item.accomodation : "0"
        }));
    }

    const monthlyPaymentSummeryDataMap = (apiData) => {
        return apiData.map(item => ({
            "Month": item?.month ? item?.month.split("-")[0] + "-" + item?.month.split("-")[1] : "N/A",
            "Vendor Name": item?.vendorName ? item.vendorName : "N/A",
            "Vehicle Pool": item?.vehiclePool ? item.vehiclePool : "N/A",
            "No of Vehicles": item?.noofVehicles ? item.noofVehicles : "0",
            "Committed Base Rental": item?.commitedBaseRental ? item.commitedBaseRental : "0",
            "Committed Milage": item?.comitedMileage ? item.comitedMileage : "0",
            "Consumed Milage": item?.consumedMilage ? item.consumedMilage : "0",
            "Excess mileage": item?.excessMileage ? item.excessMileage : "0",
            "NOT (Hours)": item?.notH ? item.notH : "0",
            "DOT (Hours)": item?.dotH ? item.dotH : "0",
            "Accommodation": item?.accomodation ? item.accomodation : "N/A",
            "Monthly Rental": item?.monthlyRental ? item.monthlyRental : "0",
            "Cost for Excess": item?.costForExcess ? item.costForExcess : "0",
            "Cost for NOT": item?.costForNot ? item.costForNot : "0",
            "Cost for DOT": item?.costForDot ? item.costForDot : "0",
            "Cost for accommodation": item?.costForAccomodation ? item.costForAccomodation : "0",
            "Total Allowance cost": item?.totalAllowanceCost ? item.totalAllowanceCost : "0",
            "Total Invoicing amount": item?.totalInvoicingCost ? item.totalInvoicingCost : "0",
        }));
    }

    const monthlyVehicleSummeryDataMap = (apiData) => {
        return apiData.map(item => ({
            "Month": item?.month ? item?.month.split("-")[0] + "-" + item?.month.split("-")[1] : "N/A",
            "Supplier": item?.supplier ? item.supplier : "N/A",
            "Vehicle Category": item?.vehicleCategory ? item.vehicleCategory : "N/A",
            "Operating Model": item?.operatingModel ? item.operatingModel : "N/A",
            "Vehicle Pool": item?.vehiclePool ? item.vehiclePool : "N/A",
            "Pool/Non Pool": item?.poolOrFixed ? item.poolOrFixed : "N/A",
            "Seating Capacity": item?.seatCapacity ? item.seatCapacity : "0",
            "Vehicle Master Code": item?.masterCode ? item.masterCode : "N/A",
            "Vehicle Reg Number": item?.vregisterNumber ? item.vregisterNumber : "N/A",
            "Vehicle Running Purpose": item?.project ? item.project : "N/A",
            "Minimum Mileage committed": item?.minimumMilageComited ? item.minimumMilageComited : "0",
            "Base Rental": item?.baseRate ? item.baseRate : "0",
            "Excess Mileage rate (LKR)": item?.excessMilageRate ? item.excessMilageRate : "0",
            "NOT rate": item?.notRate ? item.notRate : "0",
            "DOT rate": item?.dotRate ? item.dotRate : "0",
            "Accommodation rate": item?.accomodationRate ? item.accomodationRate : "0",
            "No of days worked": item?.numOfDayWorked ? item.numOfDayWorked : "0",
            "Approved Base Rental": item?.approvedBaseRental ? item.approvedBaseRental : "0",
            "Region": item?.region ? item.region : "N/A",
            "Total Consumed Mileage": item?.totalConsumedMileage ? item.totalConsumedMileage : "0",
            "Excess mileage": item?.excessMileage ? item.excessMileage : "0",
            "NOT (Hours)": item?.notH ? item.notH : "0",
            "DOT (Hours)": item?.dotH ? item.dotH : "0",
            "Accommodation": item?.accomodation ? item.accomodation : "0",
            "Cost for Excess": item?.costForExcess ? item.costForExcess : "0",
            "Cost for NOT": item?.costForNot ? item.costForNot : "0",
            "Cost for DOT": item?.costForDot ? item.costForDot : "0",
            "Cost for accommodation": item?.costForAccomodation ? item.costForAccomodation : "0",
            "Total Allowance cost": item?.totalAllowanceCost ? item.totalAllowanceCost : "0",
        }));
    }

    const exportData = (data, exportName) => {
        if (exportFormat === '1') {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            xlsx.writeFile(workbook, exportName+ new Date().toISOString().slice(0, 10) + '.xlsx');

        } else {
            const csv = Papa.unparse(data);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            saveAs(blob, exportName + new Date().toISOString().slice(0, 10) + '.csv');
        }
    }


    const manageActionExport = () => {
        setButtonVisible(false);
        setSearchData([]);
        if (expoertCategory === '1' || expoertCategory === '2') {
            if (startAndEndDate[0] === undefined || startAndEndDate[1] === undefined || startAndEndDate[0] === "" || startAndEndDate[1] === "") {
                message.error("Please Select Start and End Date");
                return;
            } else {
                if (expoertCategory === '1') {
                    fetchGetSessionFullExport();
                } else if (expoertCategory === '2') {
                    fetchExportVehicleFullExport();
                }
            }
        } else if (expoertCategory === '3' || expoertCategory === '4') {
            if (selectedMonth[0] === undefined || selectedMonth[1] === undefined || selectedMonth[0] === "" || selectedMonth[1] === "") {
                message.error("Please Select Month");
            } else {
                if (expoertCategory === '3') {
                    fetchGetMonthlyPaymentSummary();
                } else {
                    fetchGetVehicleSummaryReport();
                }
            }
        }
    }

    const fetchAllRegions = async () => {
        const response = await getAllMainRegions();
        if (response.success) {
            setRegionList(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch regions");
        }
    };

    const fetchVehicleOwner = async () => {
        const response = await getAllActiveVehicleOwner();
        if (response.success) {
            console.log(response.data);
            setVendorName(response.data.results);
        } else {
            console.log(response.error);
            message.error("Failed to fetch vehicle owners");
        }
    };

    const setMultipleRegions = (value) => {
        console.log(value);
        // add array data comma separate way
        setSelectedRegions(value.join(","));
    }

    const setSuppliersToExport = (value) => {
        console.log(value);

        setVendorName(value);

        // add array data comma separate way
        // setVendorName(value.join(","));
    }

    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Reports And Exports
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Vehicle </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Reports And Exports</a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-outline-primary"
                                        id="Dash_Date"
                                    >
                                        <span className="day-name" id="Day_Name">
                                            Today:
                                        </span>
                                        &nbsp;
                                        <span className="" id="Select_date">
                                            {new Date().toUTCString().slice(5, 16)}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="feather feather-calendar align-self-center icon-xs ms-1"
                                        >
                                            <rect
                                                x="3"
                                                y="4"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{
                    backgroundColor: "#FFFFFF",
                    padding: "20px 20px 20px 20px",
                    borderRadius: "10px",
                    marginTop: "5px",
                }}>


                    <div style={{ padding: 10, borderRadius: 10 }}>
                        <Row>
                            <Col span={24}>
                                <Select 
                                placeholder="Export Category"
                                    className='borderedSelect'
                                    style={{ width: '220px' }}
                                    onChange={(value) => {
                                        setExportCategory(value);
                                        setButtonVisible(false);
                                        setSearchData([]);
                                    }}
                                    defaultValue={expoertCategory}
                                    bordered={false}
                                >
                                    <Option value="1" className="textStyle-small">Session Full Export </Option>
                                    <Option value="2" className="textStyle-small">Vehicle Full Export</Option>
                                    <Option value="3" className="textStyle-small">Monthly Payment Summary</Option>
                                    <Option value="4" className="textStyle-small">Monthly Vehicle Summary </Option>
                                </Select>

                                <Select placeholder="Select Export Format"
                                    className='borderedSelect'
                                    style={{ width: '220px', marginLeft: "10px" }}
                                    onChange={(value) => setExportFormat(value)}
                                    defaultValue={exportFormat}
                                    bordered={false}
                                >
                                    <Option value="1" className="textStyle-small">EXCEL</Option>
                                    <Option value="2" className="textStyle-small">CSV</Option>
                                </Select>

                                {
                                    isStillLoading !== true ? (
                                        <Button
                                        className='textStyles-small'
                                        type="primary"
                                        style={{ marginLeft: "10px", width: "150px", borderRadius: "10px" }}
                                        icon={<CloudSyncOutlined />}
                                        onClick={manageActionExport}
                                    >
                                        Fetch Data
                                    </Button>
                                    ) : null
                                }

                                {
                                    buttonVisible ? (
                                        <Button
                                            className='textStyles-small'
                                            type="primary"
                                            style={{ marginLeft: "10px", width: "150px", borderRadius: "10px", backgroundColor: "#52c41a" }}
                                            icon={<DownloadOutlined />}
                                            onClick={prepareDownloadData}
                                        >
                                            Export Report
                                        </Button>
                                    ) : <></>
                                }

                            </Col>
                            <Col span={12}>
                                <div style={{ float: "right" }}>
                                </div>
                            </Col>
                        </Row>

                        <Row style={{ marginTop: "10px" }}>
                            <Col span={24}>
                                <div style={{
                                    backgroundColor: "#fafafa",
                                    padding: "10px 10px 10px 10px",
                                    borderRadius: "10px",
                                    marginTop: "5px",
                                }}>
                                    <div>
                                        <h5 style={{ fontWeight: "bold" }}>Custom Filters</h5>
                                    </div>

                                    {
                                        expoertCategory === '1' || expoertCategory === '2' ? (
                                            <RangePicker
                                                className='customDropdown'
                                                onChange={(date, dateString) => setStartAndEndDate(dateString)}
                                            />
                                        ) :
                                        <DatePicker
                                            className='customDropdown'
                                            picker="month"
                                            onChange={setMonthlyStartAndEnd}
                                        />
                                    }

                                    <Select
                                        placeholder="Select Region"
                                        className='borderedSelect'
                                        style={{ width: '220px', marginLeft: "10px", height: "auto" }}
                                        bordered={false}
                                        onChange={setMultipleRegions}
                                        mode="multiple"
                                    >
                                        {regionList === null ? [] : regionList.map((region) => (
                                            <Option key={region.id} value={region.id} className="textStyle-small">{region.name}</Option>
                                        ))}
                                    </Select>
                                    {
                                        expoertCategory === '3' || expoertCategory === '4' ? (
                                            <>
                                                <Select
                                                    placeholder="Select Supplier"
                                                    className='borderedSelect'
                                                    style={{ width: '220px', marginLeft: "10px", height: "30px" }}
                                                    bordered={false}
                                                    onChange={(value) => setSelectedSupplier(value)}
                                                    allowClear
                                                >
                                                    {vendorName?.map((vendor) => (
                                                        <Option value={vendor.id} className="textStyle-small" key={vendor.id}>
                                                            {vendor.name}
                                                        </Option>
                                                    ))}
                                                </Select>

                                            </>
                                        ) : <></>
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div> <br />

                    <Spin tip="Fetching Data..." size="large" spinning={isStillLoading}>
                        <Table
                            className="table-striped-rows"
                            dataSource={searchData === null ? [] : searchData}
                            columns={columns}
                            loading={searchData === null ? fetching : false}
                            rowKey="key"
                            size="small"
                        />
                    </Spin>
                </div>
            </div>

        </body>

    )
}

export default ExportVehicle;