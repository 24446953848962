import React, { Component } from 'react';
import { Form, Input, Button, Table, Modal, message, Drawer } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
//import { createNewOrganization, deleteOrganization, updateOrganizationDetails } from '../../app/api/index';
//import { addOrganization, removeOrganization, updateOrganization } from '../../app/actions/index';
import { createNewOrganization, UpdateOrganizationByGivenID } from '../../app/api/index';
import { addOrganization, getDonationCategories, getData } from '../../app/actions/index';
import { connect } from 'react-redux';
import { ClearOutlined } from '@ant-design/icons';

class Organization extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: '',
      description: '',
      pageSize: 10,
      current: 1,
      searchText: '',
      visible: false,
      editDrawerVisible: false,
			filterTable: null,
      organizationList: [],
      editOrganizationDetails: {
        id: '',
        name: '',
        description: '',
      },
    };
  }

  sucessMessage = (messageInfo) => {
    message.success('System Message : ' + messageInfo);
  };

  errorMessage = (messageInfo) => {
    message.error('System Message : ' + messageInfo);
  };

  warningMessage = (messageInfo) => {
    message.warning('System Message : ' + messageInfo);
  };

  formItemChange = (event) => {
    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
  };

  showConfirmModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  createOrganization = () => {
    this.showConfirmModal();
  };

  createOrganizationLogic = async () => {
    console.log(this.state);
    if (this.validator.allValid()) {
      var data = {
        name: this.state.name,
        description: this.state.description,
      };
      var res = await createNewOrganization(data);
      if (res.success) {
        this.props.getData();
        //this.props.addOrganization(res.data.data);
        this.setState({
          name: '',
          description: '',
        },()=>{
         
        });
        this.sucessMessage('Organization created successfully');
      }
    } else {
      this.validator.showMessages();
      this.errorMessage("Please fill all the required fields");
      this.forceUpdate();
    }
  };

  handleOk = () => {
    this.createOrganizationLogic();
    this.handleCancel();
  };

  handleTableChange = (pagination) => {
    this.setState({
      current: pagination.current,
    });
  };


  search = (value) => {
		const filterTable = this.state.organizationList.filter((o) =>
			Object.keys(o).some((k) =>
				String(o[k]).toLowerCase().includes(value.toLowerCase())
			)
		);
		this.setState({ filterTable });
	};

  showUpdateConfirm = () => {
    const { confirm } = Modal;
    confirm({
      title: 'Are you sure you want to Update this Organization? ',
      onOk: () => {
        this.updateOrganization();
      },
      onCancel() { },
    });
  };

  showEditDrawer = (record) => {
    this.setState({
      editDrawerVisible: true,
      name: record.name,
      description: record.description,
      editOrganizationDetails: {
        id: record.id,
        name: record.name,
        description: record.description,
      },
    });
  };

  closeEditDrawer = () => {
    this.setState({
      editDrawerVisible: false,
      name: '',
      description: '',
      editOrganizationDetails: {
        id: '',
        name: '',
        description: '',
      },
    });
  };

  handleEditFormItemChange = (event,fieldName) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      editOrganizationDetails: {
        ...prevState.editOrganizationDetails,
        [fieldName]: value,
      },
    }));

    var obj = {};
    obj[event.target.name] = event.target.value;
    this.setState(obj);
    console.log(this.state.obj)
  };

  updateOrganization = async () => {
    if (this.validator.allValid()) {
      const res = await UpdateOrganizationByGivenID(this.state.editOrganizationDetails, this.state.editOrganizationDetails.id);
      if (res.success) {
        this.props.getData();
        this.setState({
          editDrawerVisible: false,
        });
        this.setState({
          name: '',
          description: '',
        },()=>{
         
        });
        this.sucessMessage('Organization updated successfully');
       
      } else {
        this.errorMessage('Failed to update organization');
      }
    } else {
      this.validator.showMessages();
      this.errorMessage(this.validator.getErrorMessages());
      this.forceUpdate();
    }
  }

  componentDidMount() {
  
    this.setState({
      organizationList: this.props.organization
    })
  }

  render() {
    const { organization } = this.props;
    const { pageSize, current, searchText, editDrawerVisible } = this.state;

    const escapeRegExp = (text) => {
      return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    };

    const wildcardSearch = (input, searchText) => {
      const escapedSearchText = escapeRegExp(searchText);
      const regex = new RegExp(escapedSearchText.replace(/%/g, '.*'), 'i');

      return regex.test(input);
    };

    const filteredData = organization.filter(
      (item) =>
        wildcardSearch(item.name, searchText) ||
        wildcardSearch(item.description, searchText)
    );

    const columns = [
      {
        title: 'Organization Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record, index) => (
          <span className="text-end">
            <a href="#" onClick={() => this.showEditDrawer(record)}>
              <i className="las la-pen text-secondary font-16"></i>
            </a>
            {/*<a href="#" onClick={() => this.showDeleteConfirm(record)}>
              <i className="las la-trash-alt text-secondary font-16"></i>
        </a>*/}
          </span>
        ),
      },
    ];

    return (
      <div className="container-fluid">
        <div className="row">

          <div className="row">
            <div className="col-sm-12">
              <div className="page-title-box">
                <div className="row">
                  <div className="col">
                    <h4 className="page-title">Organization</h4>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="javascript:void(0);">Admin</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="javascript:void(0);">General</a>
                      </li>
                    </ol>
                  </div>
                  <div className="col-auto align-self-center">
                    <a
                      href="#"
                      className="btn btn-sm btn-outline-primary"
                      id="Dash_Date"
                    >
                      <span className="day-name" id="Day_Name">
                        Today:
                      </span>
                      &nbsp;
                      <span
                        className=""
                        id="Select_date"
                      >
                        {new Date().toUTCString().slice(5, 16)}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-calendar align-self-center icon-xs ms-1"
                      >
                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                        <line x1="16" y1="2" x2="16" y2="6"></line>
                        <line x1="8" y1="2" x2="8" y2="6"></line>
                        <line x1="3" y1="10" x2="21" y2="10"></line>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Create New Organization</h4>
                </div>
                <div className="card-body">
                  <form>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="name">
                        Organization Name
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        autoComplete="true"
                        className="form-control"
                        name="name"
                        value={this.state.name}
                        aria-describedby="emailHelp"
                        placeholder="Enter organization name"
                        onChange={(e) => this.formItemChange(e)}
                      />
                      <p style={{ color: '#f5325c' }}>
                        {this.validator.message('name', this.state.name, 'required')}
                      </p>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="description">
                        Organization Description
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        autoComplete="true"
                        value={this.state.description}
                        className="form-control"
                        name="description"
                        placeholder="Enter Description"
                        onChange={(e) => this.formItemChange(e)}
                      />
                      <p style={{ color: '#f5325c' }}>
                        {this.validator.message(
                          'description',
                          this.state.description,
                          'required'
                        )}
                      </p>
                    </div>
                  </form>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.createOrganization()}
                  >
                    Create New Organization
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="card">
                <div className="card-header">
                  <Input
                    style={{ marginBottom: 16 }}
                    placeholder="Search organization"
                    onChange={(e) => {
                      const currValue = e.target.value;
                      this.search(currValue);
                    }}
                  />
                  <Table
                    columns={columns}
                    dataSource={this.state.filterTable!=null?[...this.state.filterTable].sort((a, b) => b.id - a.id):
                      [...this.props.organization].sort((a, b) => b.id - a.id)}
                    pagination={{
                      pageSize,
                      current,
                      total: filteredData.length,
                    }}
                    onChange={this.handleTableChange}
                  />
                </div>
              </div>
            </div>
          </div>

          <Modal
            title="Confirmation"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          > <p>Are you sure you want to create a new organization?</p> </Modal>

          {/* Edit Drawer */}
          <Drawer
            title="Edit Organization Details"
            width={400}
            onClose={this.closeEditDrawer}
            visible={editDrawerVisible}
          >
            <form>
              <div className="mb-3">
                <label className="form-label" htmlFor="name">
                  Organization Name :
                </label>
                <input
                   autoComplete="off"
                   type="text"
                   autoComplete="true"
                   className="form-control"
                   name="name"
                   value={this.state.name}
                   aria-describedby="emailHelp"
                   placeholder="Enter organization name"
                  onChange={(e) => this.handleEditFormItemChange(e, 'name')}
                />
                <p style={{ color: '#f5325c' }}>
                  {this.validator.message(
                    'name',
                    this.state.name,
                    'required'
                  )}
                </p>
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="description">
                  Organization Description :
                </label>
                <input
                  autoComplete="off"
                  type="text"
                  autoComplete="true"
                  value={this.state.description}
                  className="form-control"
                  name="description"
                  placeholder="Enter Description"
                  onChange={(e) => this.handleEditFormItemChange(e, 'description')}
                />
                <p style={{ color: '#f5325c' }}>
                  {this.validator.message(
                    'description',
                    this.state.description,
                    'required'
                  )}
                </p>
              </div>
            </form>
            <button className="btn btn-primary" onClick={() => this.showUpdateConfirm()}>
              Update Organization
            </button>
          </Drawer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    organization: state.organization,
  };
}

/*function mapDispatchToProps(dispatch) {
  return {
    addOrganization: (data) => dispatch(addOrganization(data)),
    //removeOrganization: (id) => dispatch(removeOrganization(id)),
    //updateOrganization: (data) => dispatch(updateOrganization(data)),
  };
}*/

export default connect(mapStateToProps, { getDonationCategories, getData })(
  Organization
);