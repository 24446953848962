import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from "react-redux";
import { createNewRegion, UpdateRegionByGivenID } from '../../app/api/index';
import { ToastContainer, toast } from 'material-react-toastify';
import { addRegion, getDonationCategories, getData } from '../../app/actions/index';
import { Modal, Drawer, Input, Table } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

class Region extends Component {

	constructor(props) {
		super(props)
		this.validator = new SimpleReactValidator();
		this.state = {
			name: "",
			description: "",
			isSubRegion: false,
			parentRegion: null,
			organization: null,
			regionState: [],
			filterTable: null,
			editDrawerVisible: false,
		}
	}

	componentDidMount() {
		this.props.getData();
		this.setState({
			regionState: this.props.regions
		}, () => {
			console.log(this.state.regionState)
		})
	}

	formItemChange = (event) => {
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	}

	formItemChecked = (event) => {
		const { name, checked } = event.target;
		this.setState((prevState) => ({
			editVendorDetails: {
				...prevState.editVendorDetails,
				[name]: checked?'1':'0',
			},
		}));
		var obj = {};
		obj[event.target.name] = event.target.checked;
		this.setState(obj);
	}

	// changes created here ................................................

	showDeleteConfirm = (record) => {
		const { confirm } = Modal;
		confirm({
			title: 'Are you sure you want to delete this Region?',
			onOk: async () => {
				/*var res = await deleteVendor(record.id);
				if (res.success) {
				  toast.success('Vendor deleted successfully');
				  this.props.removeVendor(record.id);
				}*/
			},
			onCancel() { },
		});
	};

	updateRegion = async () => {
		if (this.validator.allValid()) {
			var res = await UpdateRegionByGivenID(this.state.editVendorDetails, this.state.editVendorDetails.id);
			if (res.success) {
				this.props.getData();
				toast.success('Vendor updated successfully');
				this.closeEditDrawer();
				// window.location.reload(true);
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	};

	createRegion = async () => {
		if (this.validator.allValid()) {
			var data = {
				"name": this.state.name,
				"description": this.state.description,
				"isSubRegion": this.state.isSubRegion ? 1 : 0,
				"parentRegion": this.state.parentRegion,
				"organization": {
					"id": this.state.organization
				}
			}
			var res = await createNewRegion(data);
			if (res.success) {
				this.props.getData();
				toast.success("Successfully Created New Region")
				this.setState({
					name: "",
					description: ""
				}, () => {

				})
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	search = (value) => {
		const filterTable = this.state.regionState.filter((o) =>
			Object.keys(o).some((k) =>
				String(o[k]).toLowerCase().includes(value.toLowerCase())
			)
		);
		this.setState({ filterTable });
	};

	showCreateConfirmationModal = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Are you sure you want to register a new Region?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.createRegion(),
		});
	};

	showUpdateConfirmationModal = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Are you sure you want to update this Region?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.updateRegion(),
		});
	};


	showEditDrawer = (record) => {
		this.setState({
			editDrawerVisible: true,
			name: record.name,
			description: record.description,
			isSubRegion: record.isSubRegion == 0 ? 0 : 1,
			parentRegion: record.parentRegion == null ? null : record.parentRegion.id,
			organization: record.organization.id,
			editVendorDetails: {
				id: record.id,
				name: record.name,
				description: record.description,
				isSubRegion: record.isSubRegion == 0 ? 0 : 1,
				parentRegion: record.parentRegion == null ? null : record.parentRegion.id,
				organization: record.organization.id,
			},
		});
	};

	closeEditDrawer = () => {
		this.setState({
			editDrawerVisible: false,
			name: "",
			description: "",
			isSubRegion: false,
			parentRegion: null,
			organization: null,
			regionState: [],
		});
	};

	editFormItemChange = (event) => {
		const { name, value } = event.target;
		this.setState((prevState) => ({
			editVendorDetails: {
				...prevState.editVendorDetails,
				[name]: value,
			},
		}));

		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	};


	render() {
		const columns = [
			{
				title: 'Region Name',
				dataIndex: 'name',
				key: 'name',
				render: (text) => (
					<span>{text === ' ' ? <i>No name</i> : text}</span>
				),
			},
			{
				title: 'Description',
				dataIndex: 'description',
				key: 'description',
				render: (text) => <span>{text}</span>,
			},
			{
				title: 'Is Sub Region',
				dataIndex: 'isSubRegion',
				key: 'isSubRegion',
				render: (text) => <span>{text == 0 ? "No" : "Yes"}</span>,
			},
			{
				title: 'Parent Region',
				dataIndex: 'parentRegion',
				key: 'parentRegion',
				render: (text) => <span>{text == null ? "-" : text.name}</span>,
			},
			{
				title: 'Organization',
				dataIndex: 'organization',
				key: 'organization',
				render: (text) => <span>{text.name}</span>,
			},
			{
				title: 'Action',
				key: 'action',
				render: (text, record) => (
					<span className="text-end">
						<a href="#" onClick={() => this.showEditDrawer(record)}>
							<i className="las la-pen text-secondary font-16"></i>
						</a>
						{/*<a href="#" onClick={() => this.showDeleteConfirm(record)}>
							<i className="las la-trash-alt text-secondary font-16"></i>
				</a> */}
					</span>
				),
			},
		];
		return (
			<div class="container-fluid">
				<div class="row">
					<div class="col-sm-12">
						<div class="page-title-box">
							<div class="row">
								<div class="col">
									<b>Region List</b>
									<ol class="breadcrumb">
										<li class="breadcrumb-item"><a href="javascript:void(0);">Admin</a></li>
										<li class="breadcrumb-item"><a href="javascript:void(0);">Region</a></li>
									</ol>
								</div>
								<div class="col-auto align-self-center">
									<a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
										<span class="day-name" id="Day_Name">Today:</span>&nbsp;
										<span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
									</a>

								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-5">
						<div className="card">
							<div className="card-header">
								<b>Create New Region</b>
							</div>
							<div className="card-body">
								<form>
									<div className="mb-3">
										<label className="form-label" for="exampleInputEmail1">Name</label>
										<input autoComplete="off" type="text" className="form-control" id="exampleInputEmail1" onChange={(e) => this.formItemChange(e)} name="name" value={this.state.name} aria-describedby="emailHelp" placeholder="Name" />
										<p style={{ color: "#f5325c" }}>{this.validator.message('name', this.state.name, 'required')}</p>
									</div>
									<div className="mb-3">
										<label className="form-label" for="exampleInputPassword1">Description</label>
										<input autoComplete="off" type="text" value={this.state.description} className="form-control" name="description" onChange={(e) => this.formItemChange(e)} value={this.state.description} placeholder="Description" />
										<p style={{ color: "#f5325c" }}>{this.validator.message('description', this.state.description, 'required')}</p>
									</div>
									<div className="mb-3">
										<div className="checkbox checkbox-primary" style={{ marginLeft: 7 }}>
											<input id="checkbox2" type="checkbox" name="isSubRegion" checked={this.state.isSubRegion} onChange={(e) => this.formItemChecked(e)} />
											<label className="form-label" for="checkbox2">
												Sub Region
											</label>
										</div></div>
									{this.state.isSubRegion && <div className="mb-3">
										<label className="form-label" for="exampleInputPassword1">Parent Region</label>
										<div className="col-sm-12">
											<select className="select2 form-control mb-3 custom-select" name="parentRegion" onChange={(e) => this.formItemChange(e)} style={{ width: '100%', height: '36px' }}>
												<option value={''}></option>
												{this.props.regions.map((region, index) => {
													return <option value={region.id}>{region.name}</option>
												})}

											</select>

										</div>

									</div>}
									<div className="mb-3">
										<label className="form-label" for="exampleInputPassword1">Organization</label>
										<div className="col-sm-12">
											<select className="select2 form-control mb-3 custom-select" name="organization" onChange={(e) => this.formItemChange(e)} style={{ width: '100%', height: '36px' }}>
												<option value={''}></option>
												{this.props.organizations.map((organization, index) => {
													return <option value={organization.id}>{organization.name}</option>
												})}
											</select>
											<p style={{ color: "#f5325c" }}>{this.validator.message('organization', this.state.organization, 'required')}</p>
										</div>

									</div>
								</form>
								<button className="btn btn-primary" onClick={() => this.showCreateConfirmationModal()}>
									Create New Region
								</button>
							</div>
						</div>

						<Drawer
							title="Edit Region Details"
							width={400}
							onClose={this.closeEditDrawer}
							visible={this.state.editDrawerVisible}
						>
							<div className="mb-3">
								<label className="form-label" htmlFor="editName">
									Region Name
								</label>
								<input
									autoComplete="off" type="text" className="form-control" id="exampleInputEmail1" name="name" value={this.state.name} aria-describedby="emailHelp" placeholder="Name"
									onChange={this.editFormItemChange}
								/>
								<p style={{ color: '#f5325c' }}>
									{this.validator.message(
										'editName',
										this.state.name,
										'required'
									)}
								</p>
							</div>

							<div className="mb-3">
								<label className="form-label" htmlFor="editCode">
									Region Description
								</label>
								<input
									autoComplete="off" type="text" value={this.state.description} className="form-control" name="description" placeholder="Description"
									onChange={this.editFormItemChange}
								/>
								<p style={{ color: '#f5325c' }}>
									{this.validator.message(
										'editCode',
										this.state.description,
										'required'
									)}
								</p>
							</div>

							<div className="mb-3">
								<div className="checkbox checkbox-primary" style={{ marginLeft: 7 }}>
									<input id="checkbox2" type="checkbox" name="isSubRegion"
										checked={this.state.isSubRegion} onChange={(e) => this.editFormItemChange(e)} />
									<label className="form-label" for="checkbox2">
										{this.state.isSubRegion ? (
											"Make It As Region"
										) : (
											"Make It As Sub Region"
										)}
									</label>
								</div></div>
							{this.state.isSubRegion && <div className="mb-3">
								<label className="form-label" for="exampleInputPassword1">Parent Region</label>
								<div className="col-sm-12">
									<select className="select2 form-control mb-3 custom-select" name="parentRegion"
										onChange={(e) => this.editFormItemChange(e)}
										style={{ width: '100%', height: '36px' }}
										value={this.state.parentRegion}
									>
										{this.props.regions.map((region, index) => {
											return <option value={region.id}>{region.name}</option>
										})}
									</select>
									<p style={{ color: "#f5325c" }}>{this.validator.message('description', this.state.description, 'required')}</p>
								</div>
							</div>}
							<div className="mb-3">
								<label className="form-label" for="exampleInputPassword1">Select Organization</label>
								<div className="col-sm-12">
									<select className="select2 form-control mb-3 custom-select" name="organization"
										onChange={(e) => this.editFormItemChange(e)}
										style={{ width: '100%', height: '36px' }}
										value={this.state.organization}
									>
										{this.props.organizations.map((organization, index) => {
											return <option value={organization.id}>{organization.name}</option>
										})}
									</select>
									<p style={{ color: "#f5325c" }}>{this.validator.message('description', this.state.description, 'required')}</p>
								</div>
							</div>

							<button
								className="btn btn-success"
								onClick={this.showUpdateConfirmationModal}
							> Update Region </button>

						</Drawer>
					</div>
					<div className="col-lg-7">
						<div className="card">
							<div className="card-header">
								<b>Region List</b>
							</div>
							<div className="card-body">
								<div className="table-responsive">
									{/*<table className="table table-striped mb-0">
										<thead>
											<tr>
												<th>Region Name</th>
												<th>Description</th>
												<th>Is Sub Region</th>
												<th>Parent Region</th>
												<th>Organization</th>
												<th className="text-end">Action</th>
											</tr>
										</thead>
										<tbody>
											{
												this.props.regions.map((region, index) => {
													return <tr>
														<td>{region.name}</td>
														<td>{region.description}</td>
														<td>{region.isSubRegion == 0 ? "No" : "Yes"}</td>
														<td>{region.isSubRegion == 0 ? "-" : region.parentRegion == null ? "-" : region.parentRegion.name}</td>
														<td>Dialog</td>
														<td className="text-end">
															<a href="#"><i className="las la-pen text-secondary font-16"></i></a>
															<a href="#"><i className="las la-trash-alt text-secondary font-16"></i></a>
														</td>
													</tr>
												})
											}
										</tbody>
										</table>*/}
									<Input
										enterButton
										style={{ width: '98%', margin: 10 }}
										placeholder="Search Regions ..."
										onChange={(e) => {
											const currValue = e.target.value;
											this.search(currValue);
										}}
									/>
									<Table
										size="small"
										columns={columns}
										dataSource={
											this.state.filterTable == null
												? [...this.props.regions].sort((a, b) => b.id - a.id)// Reverse the order of the array
												: [...this.state.filterTable].sort((a, b) => b.id - a.id) // Reverse the order of the filtered array
										}
										rowKey="id"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ToastContainer position="top-right" autoClose={5000} />
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		regions: state.regions,
		organizations: state.organization
	};
}


/*function mapDispatchToProps(dispatch) {
	return {
		addRegion: data => dispatch(addRegion(data))
	};
}

export default connect(
	mapStateToProps, mapDispatchToProps
)(Region);
*/

export default connect(mapStateToProps, { getDonationCategories, getData })(
	Region
);

