import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { createNewDepot, getAllSubRegionsDepotCreate, UpdateDepotByGivenID } from '../../app/api/index'
import { connect } from "react-redux";
import { addDepot, getDonationCategories, getData } from '../../app/actions/index';
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { Input, Table, Modal, Drawer } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

var page_count = 0;
class Depot extends Component {

	constructor(props) {
		super(props)
		this.validator = new SimpleReactValidator();
		this.state = {
			name: "",
			description: "",
			unitTypeId: '',
			region: '',
			page_start: 0,
			page_end: 10,
			regionList: [],
			filterTable: null,
			depotList: [],
			editDepotDetails: {
				id: '',
				name: '',
				description: '',
				unitTypeId: 0,
				region: 0,
			}
		}
	}

	// Load All Sub Regions For Table 
	componentDidMount() {
		this.props.getData();
		this.setState({
			depotList: this.props.depots
		}, () => {
			console.log(this.state.depotList)
		})
		this.getAllSubRegionsDepotCreate()
	}

	getAllSubRegionsDepotCreate = async () => {
		var res = await getAllSubRegionsDepotCreate();
		console.log( this.props.region)
		if (res.success) {
			this.setState({
				regionList: this.props.region
			}, () => {
				console.log(this.state.regionList)
			})

		}
	}

	// Function For Create New Depot And Save In Database
	createDepot = async () => {
		if (this.validator.allValid()) {
			var data = {
				"name": this.state.name,
				"description": this.state.description,
				"unitTypeId": this.state.unitTypeId,
				"region":this.state.region
			}
			var res = await createNewDepot(data);
			if (res.success) {
				this.props.getData();

				toast.success("Successfully Created New Depot");
				this.setState({
					name: "",
					description: "",
					unitTypeId: '',
					region:[],
				}, () => {

				})
			}
		} else {
			this.validator.showMessages();
			toast.error("Please Fill All Fields");
			this.forceUpdate();
		}
	}

	// Function For Edit Depot and Save In Database
	updatedepot = async () => {
		if (this.validator.allValid()) {
			var res = await UpdateDepotByGivenID(this.state.editDepotDetails, this.state.editDepotDetails.id);
			if (res.success) {
				this.props.getData();
				toast.success('Depot updated successfully');
				this.closeEditDrawer();
				
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	};

	// Popup For create Depot and Save In Database and Update In Table
	showCreateConfirmationModal = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Are you sure you want to register a new Depot?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.createDepot(),
		});
	};

	showUpdateConfirmationModal = () => {
		Modal.confirm({
			title: 'Confirm',
			icon: <ExclamationCircleOutlined />,
			content: 'Are you sure you want to update this Depot?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: () => this.updatedepot(),
		});
	};

	showDeleteConfirm = (record) => {
		const { confirm } = Modal;
		confirm({
			title: 'Are you sure you want to delete this Depot?',
			onOk: async () => {
				/*var res = await deleteVendor(record.id);
				if (res.success) {
				  toast.success('Vendor deleted successfully');
				  this.props.removeVendor(record.id);
				}*/
			},
			onCancel() { },
		});
	};


	// Implement Search Functionality to the Table
	search = (value) => {
		const filterTable = this.state.depotList.filter((o) =>
			Object.keys(o).some((k) =>
				String(o[k]).toLowerCase().includes(value.toLowerCase())
			)
		);
		this.setState({ filterTable });
	};

	formItemChange = (event) => {
		console.log(event.target.value)
		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	}

	getList = () => {
		var pagination_list = [];
		page_count = this.props.depots.length / 10;
		for (var i = 0; i < page_count; i++) {
			pagination_list.push(<li style={{ cursor: "pointer" }} class="page-item" id={i} onClick={(e) => this.gotoPage(e)}><a class="page-link" >{i + 1}</a></li>)
		}
		return pagination_list;
	}

	gotoPage = (e) => {
		var _page_end = e.target.outerText * 10;
		var _page_start = _page_end - 10;
		this.setState({
			page_start: _page_start,
			page_end: _page_end
		})
	}


	showEditDrawer = (record) => {
		console.log(record)
		this.setState({
			editDrawerVisible: true,
			name: record.name,
			description: record.description,
			unitTypeId: record.unitTypeId,
			region: record.region,
			editDepotDetails: {
				id: record.id,
				name: record.name,
				description: record.description,
				unitTypeId: record.unitTypeId,
				region: record.region,
			}
		});
	};

	closeEditDrawer = () => {
		this.setState({
			editDrawerVisible: false,
			name: "",
			description: "",
			unitTypeId: 0,
			region: 0,
			editDepotDetails: {
				id: '',
				name: '',
				description: '',
				unitTypeId: 0,
				region: 0,
			}
		});
	};

	editFormItemChange = (event) => {
		const { name, value } = event.target;
		this.setState((prevState) => ({
			editDepotDetails: {
				...prevState.editDepotDetails,
				[name]: value,
			},
		}));

		var obj = {};
		obj[event.target.name] = event.target.value;
		this.setState(obj);
	};

	render() {
		const columns = [
			{
				title: 'Depot Name',
				dataIndex: 'name',
				key: 'name',
				render: (text) => (
					<span>{text === ' ' ? <i>No Depot Name</i> : text}</span>
				),
			},
			{
				title: 'Description',
				dataIndex: 'description',
				key: 'description',
				render: (text) => (
					<span>{text === ' ' ? <i>No Description For Depot</i> : text}</span>
				),
			},
			{
				title: 'Unit Type',
				dataIndex: 'unitTypeId',
				key: 'unitTypeId',
				render: (text) => (
					<span>{text === ' ' ? <i>No Previous Data Found</i> : text}</span>
				),
			},
			{
				title: 'Region',
				dataIndex: 'region',
				key: 'region',
				render: (text) => (
					<span>{text === ' ' ? <i>No Region Found</i> : text.name}</span>
				),
			},
			{
				title: 'Action',
				key: 'action',
				render: (text, record, index) => (
					<span className="text-end">
						<a href="#" onClick={() => this.showEditDrawer(record)}>
							<i className="las la-pen text-secondary font-16"></i>
						</a>
						{/*<a href="#" onClick={() => this.showDeleteConfirm(record)}>
							<i className="las la-trash-alt text-secondary font-16"></i>
				</a>*/}
					</span>
				),
			},
		];

		return (
			<div class="container-fluid" >
				<div class="row">
					<div class="col-sm-12">
						<div class="page-title-box">
							<div class="row">
								<div class="col">
									<h4 class="page-title">Create Depot</h4>
									<ol class="breadcrumb">
										<li class="breadcrumb-item"><a href="javascript:void(0);">Depot </a></li>
										<li class="breadcrumb-item"><a href="javascript:void(0);">Create</a></li>

									</ol>
								</div>
								<div class="col-auto align-self-center">


								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-5">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">
									<b>New Depot</b>
								</h4>
							</div>
							<div className="card-body">
								<form>
									<div className="mb-3">
										<label className="form-label" for="name">Name</label>
										<input autoComplete="off" type="text" className="form-control" name="name" id="name" value={this.state.name} aria-describedby="emailHelp" placeholder="Name" onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('name', this.state.name, 'required')}</p>
									</div>
									<div className="mb-3">
										<label className="form-label" for="description">Description</label>
										<input autoComplete="off" type="text" value={this.state.description} className="form-control" name="description" id="description" placeholder="Description" onChange={(e) => this.formItemChange(e)} />
										<p style={{ color: "#f5325c" }}>{this.validator.message('description', this.state.description, 'required')}</p>
									</div>

									<div className="mb-3">
										<label className="form-label" for="exampleInputPassword1">Unit Type</label>
										<div className="col-sm-12">
											<select className="select2 form-control mb-3 custom-select" name="unitTypeId" onChange={(e) => this.formItemChange(e)} style={{ width: '100%', height: '36px' }}>
												<option value=""> </option>
												<option value="1">1</option>
												<option value="2">2</option>
												<option value="3">3</option>

											</select>
											<p style={{ color: '#f5325c' }}>
												{this.validator.message(
													'editUnitType',
													this.state.unitTypeId,
													'required'
												)}
											</p>

										</div>

									</div>
									<div className="mb-3">
										<label className="form-label" for="exampleInputPassword1">Region</label>
										<div className="col-sm-12">
											<select className="select2 form-control mb-3 custom-select" name="region" onChange={(e) => this.formItemChange(e)} style={{ width: '100%', height: '36px' }}>

												<option value={''}>{ }</option>
												{
													this.state.regionList.map((region, i) => {
														return <option value={region.id}>{region.name}</option>
													})
												}
											</select>
											<p style={{ color: '#f5325c' }}>
												{this.validator.message(
													'editRegion',
													this.state.region,
													'required'
												)}
											</p>
										</div>
									</div>
								</form>
								<button type="submit" className="btn btn-primary" onClick={() => this.showCreateConfirmationModal()}>Create Depot</button>
							</div>
						</div>

						<Drawer
							title="Edit Depot Details"
							width={400}
							onClose={this.closeEditDrawer}
							visible={this.state.editDrawerVisible}
						>
							<form>
								<div className="mb-3">
									<label className="form-label" htmlFor="editName">
										Edit Name
									</label>
									<input
										autoComplete="off" type="text" className="form-control" name="name" id="name" value={this.state.name} aria-describedby="emailHelp" placeholder="Name"
										onChange={this.editFormItemChange}
									/>
									<p style={{ color: '#f5325c' }}>
										{this.validator.message(
											'editName',
											this.state.name,
											'required'
										)}
									</p>
								</div>

								<div className="mb-3">
									<label className="form-label" htmlFor="editDescription">
										Edit Description
									</label>
									<input
										autoComplete="off" type="text" value={this.state.description} className="form-control" name="description" id="description" placeholder="Description"
										onChange={this.editFormItemChange}
									/>
									<p style={{ color: '#f5325c' }}>
										{this.validator.message(
											'editDescription',
											this.state.description,
											'required'
										)}
									</p>
								</div>

								<div className="mb-3">
									<label className="form-label" htmlFor="editUnitType">
										Edit Unit Type
									</label>
									<div className="col-sm-12">
										<select className="select2 form-control mb-3 custom-select"
											name="unitTypeId" onChange={(e) => this.editFormItemChange(e)}
											style={{ width: '100%', height: '36px' }}
											value={this.state.unitTypeId}
										>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
										</select>
									</div>
									<p style={{ color: '#f5325c' }}>
										{this.validator.message(
											'editUnitType',
											this.state.unitTypeId,
											'required'
										)}
									</p>
								</div>

								<div className="mb-3">
									<label className="form-label" htmlFor="editRegion">
										Edit Region
									</label>
									{this.state.region != '' ? <select className="select2 form-control mb-3 custom-select" value={this.state.region.id} name="region" onChange={(e) => this.editFormItemChange(e)} style={{ width: '100%', height: '36px' }}>
									{
													this.state.regionList.map((region, i) => {
														return <option value={region.id}>{region.name}</option>
													})
												}
									</select> : ''}
									{this.state.region == '' ? <select className="select2 form-control mb-3 custom-select" value={this.state.region.id} name="region" onChange={(e) => this.editFormItemChange(e)} style={{ width: '100%', height: '36px' }}>
									{
													this.state.regionList.map((region, i) => {
														return <option value={region.id}>{region.name}</option>
													})
												}
									</select> : ''}

									<p style={{ color: '#f5325c' }}>
										{this.validator.message(
											'editRegion',
											this.state.region,
											'required'
										)}
									</p>
								</div>
							</form>
							<button
								className="btn btn-success"
								onClick={this.showUpdateConfirmationModal}> Update Depot Details </button>
						</Drawer>
					</div>
					<div className="col-lg-7">
						<div className="card">
							<div className="card-body">
								<div className="card-header">
									<h4 className="card-title">
										<b>Depote List </b>
									</h4>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										<Input
											enterButton
											style={{ width: '98%', margin: 10 }}
											placeholder="Search"
											onChange={(e) => {
												const currValue = e.target.value;
												this.search(currValue);
											}}
										/>
										<Table
											size="small"
											columns={columns}
											dataSource={
												this.state.filterTable == null
													? [...this.props.depots].sort((a, b) => b.id - a.id)
													: [...this.state.filterTable].sort((a, b) => b.id - a.id)
											}
											rowKey="id"
										/>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div >
				<ToastContainer position="top-right" autoClose={5000} />
			</div >
		);
	}
}

/*function mapDispatchToProps(dispatch) {
	return {
		addDepot: data => dispatch(addDepot(data))
	};
}*/

function mapStateToProps(state) {
	return {
		region: state.regions,
		depots: state.depots
	};
}

/*export default connect(
	mapStateToProps, mapDispatchToProps
)(Depot);

*/
export default connect(mapStateToProps, { getDonationCategories, getData })(
	Depot
);