import React, { useState, useEffect, useRef } from 'react';
import {
    CarOutlined,
    NodeIndexOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    CheckOutlined,
    DashboardOutlined,
    SearchOutlined,
    PhoneOutlined,
    PaperClipOutlined,
    UploadOutlined,
    PlusOutlined,
    CalendarOutlined,
    BankOutlined,
    UserOutlined,
    ToolOutlined,
    GlobalOutlined,
    KeyOutlined,
    InboxOutlined,
    CloudUploadOutlined,
} from '@ant-design/icons';
import {  Avatar, Notification, notification, Card, Skeleton, Switch, Table, Select, Input, Button, Row, Upload, Col, Dropdown, Tag, Modal, Steps, message, Form, DatePicker, TimePicker, Descriptions, Image, Spin, Checkbox } from 'antd';
import '../style.css';
import { useHistory } from 'react-router-dom';
import {
    getAllGroupHandlersREWAMP,
    getAllRequestCategoryREWAMP,
    getAllSubRequestTypeREWAMP,
    getSiteDetailsBySiteIDREWAMP,
    bulkSiteAccessRequestREWAMP,
    getAllVisitingPurposeREWAMP,
    getAllWorkTypeREWAMP,
    getAllWorkAreaREWAMP,
    getAllAccessNeedREWAMP,
    getAllSitesListByID,
} from "../../../api/index";

import {
    createBulkSiteAccessRequestREWAMP,
    uploadAccessRequestFiles
} from "../../../api/executor";

import Index from '../../vehicle';
import moment from 'moment';
import { get } from '../../../api/api.client';
import site from '../../site';
import axios from 'axios';
const { Dragger } = Upload;
// import moment from 'moment-timezone';

const AccessRequest = () => {

    const userUploadFileHeaders = [
        "name",
        "contactNumber",
        "nic",
        "company"
    ]
    const siteDetailsHeaders = [
        "siteId"
    ]

    const history = useHistory();
    const [bulkUpload, setBulkUpload] = useState(false);
    const [formID, setFormID] = useState(0);
    const [editModelOpen, setEditModelOpen] = useState(false);
    const [equpmentJson, setEqupmentJson] = useState([]);
    const [vehicleJson, setVehicleJson] = useState([]);
    const [instrumentJson, setInstrumentJson] = useState([]);
    const [teamMemberJson, setTeamMemberJson] = useState([]);
    const [operationModal, setOperationModal] = useState(2);

    const [sitejsonData, setSiteJsonData] = useState([]);
    const [handlerGroup, setHandlerGroup] = useState([]);
    const [requestCategory, setRequestCategory] = useState([]);
    const [subRequestType, setSubRequestType] = useState([]);
    const [siteDataArray, setSiteDataArray] = useState([]);

    const [fileDetailsUsers, setFileDetailsUsers] = useState(null);
    const [fileDetailsSites, setFileDetailsSites] = useState(null);

    const [isOOType, setIsOOType] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [siteType, setSiteType] = useState([]);

    const [visitingPurpose, setVisitingPurpose] = useState(null);
    const [workingType, setWorkingType] = useState(null);
    const [workArea, setWorkArea] = useState(null);
    const [accessNeed, setAccessNeed] = useState(null);

    const [loadFullSiteList, setLoadFullSiteList] = useState([]);
    const [siteIDValidationList, setSiteIDValidationList] = useState([]);
    const [isTypeSelect, setIsTypeSelect] = useState(false);
    const [openErrorLog, setOpenErrorLog] = useState(false);
    
    const [requestType, setRequestType] = useState(null);

    const [userValidation, setUserValidation] = useState([]);
    const [usersValidation, setUsersValidation] = useState(true);

    const [openTeamErrorLog, setOpenTeamErrorLog] = useState(false);

    const [accessFileList, setAccessFileList] = useState([]);
    const [fileUploading, setFileUploading] = useState(false);

    const formRefVH = useRef();
    const formRefEQ = useRef();
    const formRefIN = useRef();
    const formRefUS = useRef();
    const formRefMain = useRef();


    const handleResetModalForms = () => {
        formRefVH?.current?.resetFields();
        formRefEQ?.current?.resetFields();
        formRefIN?.current?.resetFields();
        formRefUS?.current?.resetFields();
    };

    const handleResetMainForm = () => {
        formRefMain?.current?.resetFields();
    };

    React.useEffect(() => {
        fetchAllGroupHandlers();
        fetchAllRequestCategory();
        fetchAllSubRequestType();
        fetchAllVisitingPurpose();
        fetchAllWorkType();
        fetchAllWorkArea();
        fetchAllAccessNeed();
        fetchAllSiteListSet();
    }, []);

    const fetchAllGroupHandlers = async () => {
        var responseAPI = await getAllGroupHandlersREWAMP();
        if (responseAPI.success) {
            setHandlerGroup(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const rapidRoute = (record) => {
        const route = `/access/dev/manage`
        history.push(route);
    };

    const showNotification = (type, message, description) => {
        notification[type]({
            message: message,
            description: description,
            //   placement: 'top'
        });
    }

    const fetchAllSiteListSet = async () => {
        var responseAPI = await getAllSitesListByID();
        if (responseAPI.success) {
            setLoadFullSiteList(responseAPI?.data);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI?.message);
        }
    }

    const fetchAllRequestCategory = async () => {
        var responseAPI = await getAllRequestCategoryREWAMP();
        if (responseAPI.success) {
            setRequestCategory(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI?.message);
        }
    }

    const fetchAllSubRequestType = async () => {
        var responseAPI = await getAllSubRequestTypeREWAMP();
        if (responseAPI.success) {
            setSubRequestType(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI?.message);
        }
    }

    const fetchAllVisitingPurpose = async () => {
        var responseAPI = await getAllVisitingPurposeREWAMP();
        if (responseAPI.success) {
            setVisitingPurpose(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const fetchAllWorkType = async () => {
        var responseAPI = await getAllWorkTypeREWAMP();
        if (responseAPI.success) {
            setWorkingType(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const fetchAllWorkArea = async () => {
        var responseAPI = await getAllWorkAreaREWAMP();
        if (responseAPI.success) {
            setWorkArea(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const fetchAllAccessNeed = async () => {
        var responseAPI = await getAllAccessNeedREWAMP();
        if (responseAPI.success) {
            setAccessNeed(responseAPI.data.results);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const fetchSiteINFObySiteID = async (siteID) => {
        var responseAPI = await getSiteDetailsBySiteIDREWAMP(siteID);
        const currentSiteDataArray = siteDataArray;
        const siteTypeArray = siteType;
        if (responseAPI.success) {
            const dataResponse = responseAPI.data;
            const startTimeFetchWeekdays = dataResponse?.workingDaysAccessibleHours?.split('-')[0];
            const endTimeFetchWeekDays = dataResponse?.workingDaysAccessibleHours?.split('-')[1];
            const startTimeFetchWeekends = dataResponse?.weekendsOrHolidaysAccessibleHours?.split('-')[0];
            const endTimeFetchWeekends = dataResponse?.weekendsOrHolidaysAccessibleHours?.split('-')[1];
            siteTypeArray.push(dataResponse?.siteTypeName);
            const dataArray = {
                siteId: siteID,
                siteName: dataResponse?.siteName,
                siteType: dataResponse?.siteTypeName,
                regionId: dataResponse?.regionId,
                regionName: dataResponse?.regionName,
                depotId: dataResponse?.depotId,
                depotName: dataResponse?.depotName,
                sitePrimaryKey: dataResponse?.id,
                latitude: dataResponse?.latitude,
                longitude: dataResponse?.longitude,
                siteOpeningTime: startTimeFetchWeekdays,
                siteClosingTime: endTimeFetchWeekDays,
                weekendSiteOpeningTime: startTimeFetchWeekends,
                weekendSiteClosingTime: endTimeFetchWeekends,
                keyLocation: dataResponse?.keyLocation,
                siteAddress: dataResponse?.siteAddress,
                siteOwner: dataResponse?.siteOwner,
                siteOwnerAddress: dataResponse?.siteOwnerAddress,
                siteOwnerFullName: dataResponse?.siteOwnerName,
            }
            currentSiteDataArray.push(dataArray);
            setSiteJsonData(currentSiteDataArray);
            setSiteType(siteTypeArray);
        } else {
            message.error('Error In Fetching API Request : ' + responseAPI.message);
        }
    }

    const fetchSiteInformationsByGivenDataArray = async (idArray) => {
        setIsLoading(true);
        setIsOOType(false);
        const informationArr = [];
        const siteTypeArr = [];
        const siteOwner = [];
        let isValid = await validationSiteDetails(idArray);
        console.log(isValid);
        if (isValid === false) {
            setOpenErrorLog(true);
            setIsLoading(false);
        } else {
            await Promise.all(idArray?.map(async (item) => {
                var responseAPI = await getSiteDetailsBySiteIDREWAMP(item);
                if (responseAPI.success) {
                    const dataResponse = responseAPI.data;
                    const startTimeFetchWeekdays = dataResponse?.workingDaysAccessibleHours?.split('-')[0];
                    const endTimeFetchWeekDays = dataResponse?.workingDaysAccessibleHours?.split('-')[1];
                    const startTimeFetchWeekends = dataResponse?.weekendsOrHolidaysAccessibleHours?.split('-')[0];
                    const endTimeFetchWeekends = dataResponse?.weekendsOrHolidaysAccessibleHours?.split('-')[1];
                    const dataArray = {
                        siteId: item,
                        siteName: dataResponse?.siteName,
                        siteType: dataResponse?.siteTypeName,
                        regionId: dataResponse?.regionId,
                        regionName: dataResponse?.regionName,
                        depotId: dataResponse?.depotId,
                        depotName: dataResponse?.depotName,
                        siteOpeningTime: startTimeFetchWeekdays,
                        siteClosingTime: endTimeFetchWeekDays,
                        weekendSiteOpeningTime: startTimeFetchWeekends,
                        weekendSiteClosingTime: endTimeFetchWeekends,
                        keyLocation: dataResponse?.keyLocation,
                        sitePrimaryKey: dataResponse?.id,
                        latitude: dataResponse?.latitude,
                        longitude: dataResponse?.longitude,
                        siteAddress: dataResponse?.siteAddress,
                        siteOwner: dataResponse?.siteOwner,
                        siteOwnerAddress: dataResponse?.siteOwnerAddress,
                        siteOwnerFullName: dataResponse?.siteOwnerName,
                    }
                    informationArr.push(dataArray);
                    siteTypeArr.push(dataResponse?.siteTypeName);
                    siteOwner.push(dataResponse?.siteOwner);
                } else {
                    message.error('Error In Fetching API Request : ' + responseAPI.message);
                }
            }));
            setIsLoading(false);
            const isSiteTypeSame = siteTypeArr.every((val, i, arr) => val === arr[0]);
            const isSiteOwnerSame = siteOwner.every((val, i, arr) => val === arr[0]);
            if (isSiteTypeSame) {
                setRequestType(siteTypeArr[0]);
                setSiteJsonData(informationArr);
                if (siteTypeArr[0] === 'OO') {
                    if (isSiteOwnerSame === true) {
                        console.log('Site Owner Same');
                        setIsOOType(true);
                    } else if (isSiteOwnerSame === false) {
                        message.error("The CSV file cannot be processed because it contains multiple site owners.");
                        setSiteJsonData([]);
                        setIsOOType(false);
                        setFileDetailsSites(null);
                    }
                }
            } else {
                message.error("The CSV file cannot be processed because it contains multiple site types.");
                setSiteJsonData([]);
                setIsOOType(false);
                setFileDetailsSites(null);
            }
        }
    }

    const nicValidation = (nic_number) => {
        var nic = nic_number;
        if (nic.length === 10) {
            if (nic.charAt(9) === 'V' || nic.charAt(9) === 'v') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const mobile_numberValidation = (mobile_number) => {
        var mobile = mobile_number;
        // Check if the number is exactly 9 or 10 digits long and consists only of digits
        if ((mobile.length === 9 || mobile.length === 10) && /^[0-9]+$/.test(mobile)) {
            // If it's 10 digits long, the first character must be '0'
            if (mobile.length === 10 && mobile.charAt(0) === '0') {
                return true;
            } else if (mobile.length === 9) { // If it's 9 digits long, no additional checks are needed
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
    const validationSiteDetails = async (siteIDList) => {
        setIsLoading(true);
        const validationMatrix = [];
        await Promise.all(siteIDList?.map(async (item) => {
            var responseAPI = await getSiteDetailsBySiteIDREWAMP(item);
            if (responseAPI.success) {
                const dataResponse = responseAPI.data;
                if (dataResponse === "Not record found for this site Id") {
                    validationMatrix.push({ siteId: item, status: false });
                } else {
                    validationMatrix.push({ siteId: item, status: true });
                }
            } else {
                message.error('Error In Fetching API Request : ' + responseAPI.message);
            }
        }));
        setSiteIDValidationList([]);
        setSiteIDValidationList(validationMatrix);
        const isAllValid = validationMatrix.every((val) => val.status === true);
        setIsLoading(false);
        return isAllValid;
    }

    const errorSummery = [
        {
            title: <span className='textStyle-small'>Site ID</span>,
            dataIndex: 'siteId',
            key: 'siteId',
            render: text => <span className='textStyle-small'>{text}</span>,
        },
        {
            title: <span className='textStyle-small'>Validation Status</span>,
            dataIndex: 'status',
            key: 'status',
            render: text => <Tag color={text === true ? 'green' : 'red'}>
                <span className='textStyle-small'>
                    {text === true ? 'Valid SiteID' : 'Invalid SiteID'}
                </span>
            </Tag>,
        }
    ]

    const directFetchSiteInformation = (siteID) => {
        console.log(siteID);
        const siteIDArray = [];
        siteIDArray.push(siteID);
        fetchSiteInformationsByGivenDataArray(siteIDArray);
    }

    const handleFileUpload = (info) => {
        setIsOOType(false);
        const fileObj = info?.file;
        setFileDetailsSites(fileObj);
        if (fileObj !== null) {
            setSiteJsonData(null);
            const reader = new FileReader();
            const siteIdentificationList = [];
            reader.onload = (e) => {
                const text = e.target.result;
                const headers = parseCsvHeaders(text);
                const isHeaderAvailable = siteDetailsHeaders.every((header) => headers.includes(header));
                if (!isHeaderAvailable) {
                    message.error("Uploaded CSV Files Not Meet All Required Hearders");
                    setSiteJsonData([]);
                    setFileDetailsSites(null);
                } else {
                    const data = parseCsvToJson(text);
                    const siteIDList = data?.map((item, index) => {
                        fetchSiteINFObySiteID(item?.siteId);
                        siteIdentificationList.push(item?.siteId);
                        return item?.siteId;
                    });
                    console.log(siteIdentificationList);
                    fetchSiteInformationsByGivenDataArray(siteIdentificationList);
                }
            }
            reader.readAsText(fileObj);
        } else {
            setSiteJsonData(null);
            message.error('No File Uploaded, Please Upload File');
        }
    };

    const handleFileUploadUsers = (info) => {
        const fileObj = info?.file;
        setFileDetailsUsers(fileObj);
        const validationMatrix = [];
    
        if (fileObj !== null) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target.result;
                const headers = parseCsvHeaders(text);
                const isHeaderAvailable = userUploadFileHeaders.every((header) => headers.includes(header));
                if (!isHeaderAvailable) {
                    message.error("Uploaded CSV Files Not Meet All Required Hearders");
                    setFileDetailsUsers(null);
                } else {
                    const data = parseCsvToJson(text);
                    const userDataArray = [];
                    const existingTeamArr = teamMemberJson.length > 0 ? teamMemberJson : [];
                    try {
                        const userRelatedData = data?.map((item, index) => {
                            let userJsonData = {};
                            if (nicValidation(item?.nic) === false || mobile_numberValidation(item?.contactNumber) === false) {
                                validationMatrix.push({ name : item?.name, 
                                    nic: item?.nic, 
                                    nicValidationStatus : nicValidation(item?.nic),
                                    contactNumber: item?.contactNumber, 
                                    contactNumberValidationStatus : mobile_numberValidation(item?.contactNumber) 
                                }); 
                            } else {
                                userJsonData = {
                                    name: item?.name ? item.name : "N/A",
                                    contactNumber: item?.contactNumber ? item.contactNumber : "N/A",
                                    nic: item?.nic ? item.nic : "N/A",
                                    company: item?.company ? item.company : "N/A",
                                    isKeyHolder: false,
                                    keyLocations: []
                                }
                                userDataArray.push(userJsonData);
                            }
                            return userJsonData;
                        });
                        const newConatArr = existingTeamArr.concat(userDataArray);
                        const isAnyInvalid = validationMatrix.some((val) => val.nicValidationStatus === false || val.contactNumberValidationStatus === false);
                        isAnyInvalid ? setUserValidation(validationMatrix) : setTeamMemberJson(newConatArr);
                        isAnyInvalid ? setOpenTeamErrorLog(true) : setOpenTeamErrorLog(false);
                        message.success('Team Members Bulk Data Extracted Successfully');
                    } catch (error) {
                        message.error('Error In Parsing CSV Data');
                    }
                }
            };
            reader.readAsText(fileObj);
        } else {
            message.error('No File Uploaded, Please Upload File');
        }
    };

    const teamMembersError = [
        {
            title: <span className='textStyle-small'>Name</span>,
            dataIndex: 'name',
            key: 'name',
            render: text => <span className='textStyle-small'>{text}</span>,
        },
        {
            title: <span className='textStyle-small'>NIC</span>,
            dataIndex: 'nic',
            key: 'nic',
            render: text => <span className='textStyle-small'>{text}</span>,
        },
        {
            title: <span className='textStyle-small'>NIC Validation</span>,
            dataIndex: 'nicValidationStatus',
            key: 'nicValidationStatus',
            render: text => <Tag color={text === true ? 'green' : 'red'}>
                <span className='textStyle-small'>
                    {text === true ? 'Valid NIC' : 'Invalid NIC'}
                </span>
            </Tag>,
        },
        {
            title: <span className='textStyle-small'>Contact Number</span>,
            dataIndex: 'contactNumber',
            key: 'contactNumber',
            render: text => <span className='textStyle-small'>{text}</span>,
        },
        {
            title: <span className='textStyle-small'>Contact Number Validation</span>,
            dataIndex: 'contactNumberValidationStatus',
            key: 'contactNumberValidationStatus',
            render: text => <Tag color={text === true ? 'green' : 'red'}>
                <span className='textStyle-small'>
                    {text === true ? 'Valid Contact Number' : 'Invalid Contact Number'}
                </span>
            </Tag>,
        }
    ]

    const parseCsvToJson = (text) => {
        const lines = text.split('\n');
        const headers = lines[0].split(',').map(header => header?.trim());
        const jsonData = [];
        for (let i = 1; i < lines.length; i++) {
            if (lines[i].trim() === '') continue;

            const values = lines[i].split(',');
            console.log('Line', i, 'values:', values);

            const obj = {};
            for (let j = 0; j < headers.length; j++) {
                obj[headers[j]] = values[j] ? values[j].trim() : '';
            }
            jsonData.push(obj);
        }
        return jsonData;
    };

    const parseCsvHeaders = (text) => {
        try {
            const lines = text.split('\n');
            const headers = lines[0].split(',').map(header => header?.trim());
            return headers;
        } catch (error) {
            message.error('Error In Parsing CSV Headers');
        }
    };

    const handleBulkUpload = () => {
        if (bulkUpload === false) {
            setBulkUpload(true);
        } else {
            setBulkUpload(false);
        }
    }

    const openModelForInsertDataCreateUsers = () => {
        setEditModelOpen(true);
        setFormID(1);
    }

    const openModelForInsertDataCreateVehicles = () => {
        setEditModelOpen(true);
        setFormID(2);
    }

    const openModelForInsertDataCreateEquipment = () => {
        setEditModelOpen(true);
        setFormID(3);
    }

    const openModelForInsertDataCreateInstruments = () => {
        setEditModelOpen(true);
        setFormID(4);
    }

    const openModelForInsertDataCreateKeyLocation = () => {
        setEditModelOpen(true);
        setFormID(5);
    }

    const closeModelForInsertData = () => {
        setFormID(0);
        setEditModelOpen(false);
    }

    const addTeamMembers = (values) => {
        const currentJsonArray = teamMemberJson;
        const memberJson = {
            name: values.name,
            contactNumber: values.contactNumber,
            nic: values.nic,
            company: values.company,
            isKeyHolder: false,
        }
        currentJsonArray.push(memberJson);
        setTeamMemberJson(currentJsonArray);
        message.success('Team Member Added Successfully');
        handleResetModalForms();
        console.log(currentJsonArray);
    }

    const addInstrumentsActions = (values) => {
        const currentJsonArray = instrumentJson;
        const instrumentJsonData = {
            itemName: values.itemName,
            serialNumber: values.serialNumber,
            actionType: values.actionType,
            description: values.description,
            quantity: values.quantity
        }
        currentJsonArray.push(instrumentJsonData);
        setInstrumentJson(currentJsonArray);
        message.success('Instrument Added Successfully');
        handleResetModalForms();
        console.log(currentJsonArray);
    }

    const addEquipmentActions = (values) => {
        const currentJsonArray = equpmentJson;
        const equipmentJsonData = {
            itemName: values.itemName,
            serialNumber: values.serialNumber,
            actionType: values.actionType,
            description: values.description,
            quantity: values.quantity
        }
        currentJsonArray.push(equipmentJsonData);
        setEqupmentJson(currentJsonArray);
        message.success('Equipment Added Successfully');
        handleResetModalForms();
        console.log(currentJsonArray);
    }

    const addVehicleActions = (values) => {
        const currentJsonArray = vehicleJson;
        const vehicleJsonData = {
            vehicleNumber: values.vehicleNumber,
            vehicleType: values.vehicleType
        }
        currentJsonArray.push(vehicleJsonData);
        setVehicleJson(currentJsonArray);
        message.success('Vehicle Added Successfully');
        handleResetModalForms();
        console.log(currentJsonArray);
    }

    const getTitle = (formID) => {
        if (formID === 1) {
            return <span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Team Members </span>;
        } else if (formID === 2) {
            return <span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Vehicles </span>;
        } else if (formID === 3) {
            return <span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Equipment </span>;
        } else if (formID === 4) {
            return <span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Instruments </span>
        } else if (formID === 5) {
            return <span style={{ fontWeight: "bold" }} className='textStyle-small'>Key Location</span>
        } else if (formID === 6) {
            return <span style={{ fontWeight: "bold" }} className='textStyle-small'>Site Details</span>
        }
    };

    const fetchBulkSiteAccessRequestREWAMP = async (data) => {
        setIsLoading(true);
        var responseAPI = await bulkSiteAccessRequestREWAMP(data);
        console.log(responseAPI);
        if (responseAPI.success) {
            handleResetMainForm();
            setIsLoading(false);
            sucessHandler();
        } else {
            message.error(responseAPI?.data?.errors[0]?.errorMessage);
            setIsLoading(false);
        }
    }

    const fetchBulkSiteAccessRequestREWAMPImgUpload = async (data) => {
        var responseAPI = await uploadAccessRequestFiles(data);
        console.log(responseAPI);
        if (responseAPI.success) {
            return responseAPI;
        } else {
            return false;
        }
    };

    const handleRemove = (file) => {
        setAccessFileList(prevList => prevList.filter(item => item.uid !== file.uid));
    };

    const handleFileUploadFunc = async (file) => {
        setFileUploading(true);
        const formData = new FormData();
        formData.append('file', file);
        const result = await fetchBulkSiteAccessRequestREWAMPImgUpload(formData);
        if (result.success) {
            setAccessFileList(prevList => [
                ...prevList,
                { 
                    uid: file.uid, 
                    name: file.name, 
                    status: 'done', 
                    url: result.data.url,
                    originalImageName: result?.data?.results?.originalImageName,
                    generatedImageName: result?.data?.results?.generatedImageName,
                 }
            ]);
            setFileUploading(false);
        } else {
            message.error(`${file.name} file upload failed. ${result.data}`);
            setFileUploading(false);
        }
    };
    
    const props = {
        name: 'file',
        multiple: false,
        customRequest: async ({ file, onSuccess, onError }) => {
            try {
                if (accessFileList.length > 4) {
                    message.error('You can only upload a maximum of 5 files');
                    return;
                } else {
                    await handleFileUploadFunc(file);
                    onSuccess("ok");
                }
            } catch (error) {
                onError(error);
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onRemove: handleRemove,
        fileList: accessFileList,
    };

    const requestInit = async (values) => {
        const formatDateTime = (datetime) => {
            return moment(datetime).format('YYYY-MM-DDTHH:mm');
        };
    
        const formatDate = (date) => {
            return moment(date).format('YYYY-MM-DD');
        };

        if (teamMemberJson.length === 0) {
            message.error('Please Add Team Members to Proceed');
            return;
        }
    
        let getPermissionStartTime = formatDateTime(values?.permissionStartDatetime);
        let permissionEndDate = formatDate(values?.permissionEndtDatetime);
        let downTimeRequiredDate = formatDate(values?.downTimeRequiredDate);
    
        const requestJsonFile = JSON.stringify({
            siteDetails: sitejsonData, 
            teamMembers: teamMemberJson,
            equipments: equpmentJson,
            instruments: instrumentJson,
            vehicles: vehicleJson,
    
            requestType: values?.type,
            requestCategoryId: values?.category || null,
            handlerGroupId: values?.handler || null,
            handlerGroupName: handlerGroup?.find(item => item.id === values.handler)?.name || null,
            permissionStartDateTime: values?.permissionStartDatetime ? getPermissionStartTime : null,
            permissionEndDate: values?.permissionEndtDatetime ? permissionEndDate : null,
            projectName: values?.projectName || null,
            scopeOfWork: values?.scopeOfWork || null,
            teamLeaderName: values?.nameOfTeamLeader || null,
            downTimeRequiredDate: values?.downTimeRequiredDate || null,
            teamLeaderContact: values?.contactNumberOfTeamLeader || null,
            subRequestTypeId: values?.subCategories || null,
            description: values?.workDescriptions || null,
            optionalRemarksOne: values?.reason || null,
            optionalRemarksTwo: values?.remark || null,
            optionalRemarksThree: null,
            visitingPurposeId: values?.visitingPurpose || null,
            workAreaId: values?.workArea || null,
            workTypeId: values?.workType || null,
            accessNeedId: values?.accessNeed || null,

            initiatorFileOne: accessFileList && accessFileList.length > 0 ? accessFileList[0]?.generatedImageName : null,
            initiatorFileOneOriginal: accessFileList && accessFileList.length > 0 ? accessFileList[0]?.originalImageName : null,

            initiatorFileTwo: accessFileList && accessFileList.length > 1 ? accessFileList[1]?.generatedImageName : null,
            initiatorFileTwoOriginal: accessFileList && accessFileList.length > 1 ? accessFileList[1]?.originalImageName : null,

            initiatorFileThree: accessFileList && accessFileList.length > 2 ? accessFileList[2]?.generatedImageName : null,
            initiatorFileThreeOriginal: accessFileList && accessFileList.length > 2 ? accessFileList[2]?.originalImageName : null,

            initiatorFileFour: accessFileList && accessFileList.length > 3 ? accessFileList[3]?.generatedImageName : null,
            initiatorFileFourOriginal: accessFileList && accessFileList.length > 3 ? accessFileList[3]?.originalImageName : null,

            initiatorFileFive: accessFileList && accessFileList.length > 4 ? accessFileList[4]?.generatedImageName : null,
            initiatorFileFiveOriginal: accessFileList && accessFileList.length > 4 ? accessFileList[4]?.originalImageName : null,

        });    
        const requestForm = new FormData();
        requestForm.append('requestDto', new Blob([requestJsonFile], { type: 'application/json' }));
        
        for (let i = 1; i <= 5; i++) {
            requestForm.append(`file${i}`, null);
        }
    
        if (moment(getPermissionStartTime).isBefore(moment(), 'day')) {
            message.error('Permission Start Date must be today or after the current date.');
            return;
        } 
    
        if (moment(permissionEndDate).isBefore(getPermissionStartTime, 'day')) {
            message.error('Permission End Date must be after the Permission Start Date.');
            return;
        }
    
        if (values?.downTimeRequiredDate) {
            if (moment(downTimeRequiredDate).isBetween(getPermissionStartTime, permissionEndDate, 'day', '[]')) {
                newRequestHandler(requestForm);
            } else {
                message.error('Downtime Required Date must be between Permission Start Date and Permission End Date.');
                return;
            }
        } else {
            newRequestHandler(requestForm);
        }
    };
    

    const newRequestHandler = (requestForm) => {
        Modal.confirm({
            title: "Confirm Create Request",
            content: "Are you sure you want to create new request?",
            onOk: async () => {
                await fetchBulkSiteAccessRequestREWAMP(requestForm);
            },
            onCancel: () => {
                setIsLoading(false);
            },
        });
    };

    const sucessHandler = () => {
        Modal.success({
            title: 'Success',
            content: <span style={{ fontWeight: "bold" }} className='textStyle-small'>Request Created Successfully</span>,
            onOk: () => {
                history.push('/access/dev/manage');
            },
            okCancel: () => { },
        });
    }

    const popFromUserArray = (arrIndex) => {
        const existingArray = [...teamMemberJson];
        if (arrIndex !== -1) {
            existingArray.splice(arrIndex, 1);
        }
        setTeamMemberJson(existingArray);
    }

    const popFromEquipmentArray = (arrIndex) => {
        const existingArray = [...equpmentJson];
        if (arrIndex !== -1) {
            existingArray.splice(arrIndex, 1);
        }
        setEqupmentJson(existingArray);
    }

    const popFromVehicleArray = (arrIndex) => {
        const existingArray = [...vehicleJson];
        if (arrIndex !== -1) {
            existingArray.splice(arrIndex, 1);
        }
        setVehicleJson(existingArray);
    }

    const popFromInstrumentArray = (arrIndex) => {
        const existingArray = [...instrumentJson];
        if (arrIndex !== -1) {
            existingArray.splice(arrIndex, 1);
        }
        setInstrumentJson(existingArray);
    }

    // make user as keyHolder 
    const makeAsKeyHolder = (index) => {
        const existingArray = [...teamMemberJson];
        if (existingArray[index]) {
            existingArray[index].isKeyHolder = !existingArray[index].isKeyHolder;
        } else {
            existingArray[index] = { isKeyHolder: false };
        }
        setTeamMemberJson(existingArray);
    }

    const keyLocationInformations = [
        {
            title: <span className="textStyle">Site Name</span>,
            dataIndex: 'siteName',
            key: 'siteName',
            render: text => <span className="textStyleChild">{text}</span>,
        },
        {
            title: <span className="textStyle">Key Location Address</span>,
            dataIndex: 'keyLocation',
            key: 'keyLocation',
            render: text => <span className="textStyleChild">{text ? text : 'Address Not Available'}</span>,
        },
    ]

    function downloadFile(url) {
        var link = document.createElement('a');
        link.href = url;
        link.download = url.split('/').pop(); // Set the filename as the last part of the URL
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const downloadTemplate = (templateID) => {
        switch (templateID) {
            case 1:
                downloadFile('https://storage.googleapis.com/dns_storage_v2/templates/Sites.csv');
                break;
            case 2:
                downloadFile('https://storage.googleapis.com/dns_storage_v2/templates/Team.csv');
                break;
        }
    }

    const beforeUpload = (file, fileList) => {
        return false;
      };

    return (
        <body>
            <div style={{ margin: '0', padding: '10px', height: '100%' }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="page-title-box">
                            <div className="row">
                                <div className="col">
                                    <h4 className="page-title" style={{ fontWeight: "bold" }}>
                                        Create Request
                                    </h4>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Site Access </a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="javascript:void(0);">Create Request </a>
                                        </li>
                                    </ol>
                                </div>
                                <div className="col-auto align-self-center">
                                    <a
                                        href="#"
                                        className="btn btn-sm btn-outline-primary"
                                        id="Dash_Date"
                                    >
                                        <span className="day-name" id="Day_Name">
                                            Today:
                                        </span>
                                        &nbsp;
                                        <span className="" id="Select_date">
                                            {new Date().toUTCString().slice(5, 16)}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            className="feather feather-calendar align-self-center icon-xs ms-1"
                                        >
                                            <rect
                                                x="3"
                                                y="4"
                                                width="18"
                                                height="18"
                                                rx="2"
                                                ry="2"
                                            ></rect>
                                            <line x1="16" y1="2" x2="16" y2="6"></line>
                                            <line x1="8" y1="2" x2="8" y2="6"></line>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{
                    backgroundColor: "#FFFFFF",
                    padding: "20px 20px 20px 20px",
                    borderRadius: "10px",
                    marginTop: "5px",
                    marginLeft: "20px"
                }}>
                    <Spin tip="Loading..." spinning={isLoading}>
                        <Form
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            layout="vertical"
                            style={{ marginLeft: "30px", marginTop: "20px" }}
                            onFinish={requestInit}
                            ref={formRefMain}
                        >
                            <div>
                                <Row>
                                    <Col span={12}>
                                        <span className='textStyle-small'>
                                            <Checkbox className='textStyle-small'
                                                onChange={() => {
                                                    try {
                                                        handleBulkUpload();
                                                        setIsOOType(false);
                                                        setSiteJsonData([]);
                                                        setFileDetailsSites(null);
                                                    } catch (error) {
                                                        console.error(error);
                                                    }
                                                    return null;
                                                }}
                                            >
                                                <span style={{ fontWeight: "bold" }} className='textStyle-small'>Bulk Request Initiation</span>
                                            </Checkbox>
                                        </span>
                                    </Col>
                                    <Col span={12}>
                                        {
                                            bulkUpload === true ? (
                                                <Select className='textStyle-small'
                                                    style={{ width: "50%", height: "35px", float: "right", marginRight: "20%" }}
                                                    placeholder="Select Template Type"
                                                    onChange={(value) => { downloadTemplate(value) }}
                                                    allowClear
                                                >
                                                    <Select.Option value={1} className='textStyle-small'>Sites Template</Select.Option>
                                                    <Select.Option value={2} className='textStyle-small'>Team Members Template</Select.Option>
                                                </Select>
                                            ) : null
                                        }
                                    </Col>
                                </Row>

                                <Row style={{ marginTop: '15px' }}>
                                    <Col span={24}>
                                        {
                                            bulkUpload !== true ? (
                                                <Form.Item
                                                    label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Site ID/Name</span>}
                                                    name="siteID"
                                                    rules={[{ required: true, message: 'Please select site ID!' }]}
                                                >
                                                    <Select
                                                        className='textStyle-small'
                                                        style={{ width: "90%", height: "35px" }}
                                                        placeholder="Select Site"
                                                        allowClear
                                                        showSearch
                                                        onChange={(value) => directFetchSiteInformation(value)}
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {
                                                            loadFullSiteList?.map((item, index) => (
                                                                <Select.Option key={index} value={item?.siteId}>
                                                                    {`${item?.siteName}-${item?.siteId}`}
                                                                </Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            ) : (
                                                <>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Upload Site Data File</span>}
                                                        name="siteDetails"
                                                        rules={[{ required: true, message: 'Please upload site data file!' }]}
                                                    >
                                                        <div style={{
                                                            border: '1px solid #d9d9d9', width: '90%', flexDirection: 'row', display: 'flex', height: 'auto'
                                                        }}>
                                                            <Upload
                                                                multiple={false}
                                                                beforeUpload={() => false}
                                                                accept=".csv"
                                                                onChange={handleFileUpload}
                                                                style={{ width: '500px', color: '#c1c2c3' }}
                                                                showUploadList={false}
                                                            >
                                                                <Button icon={<UploadOutlined />}
                                                                    style={{
                                                                        width: 'auto', backgroundColor: '#1890ff', borderColor: '#1890ff', color: 'white',
                                                                    }}
                                                                >
                                                                    <span className='textStyle-small' style={{ color: 'white' }}>
                                                                        Upload File
                                                                    </span>
                                                                </Button>
                                                                <span className='textStyle-small' style={{ marginLeft: '10px' }}>
                                                                    {fileDetailsSites ? fileDetailsSites.name : 'No File Uploaded'}
                                                                </span>
                                                            </Upload>
                                                            <DeleteOutlined
                                                                style={{ marginLeft: 'auto', fontSize: '15px', color: 'red', marginRight: '10px' }}
                                                                onClick={() => {
                                                                    try {
                                                                        setFileDetailsSites(null);
                                                                        setIsOOType(false);
                                                                        setSiteJsonData([]);
                                                                    } catch (error) {
                                                                        console.error(error);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                </>
                                            )
                                        }
                                    </Col>
                                </Row>

                                {isOOType ? (<>

                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Visiting Purpose</span>}
                                                name="visitingPurpose"
                                            >
                                                <Select
                                                    className='textStyle-small'
                                                    style={{ width: "80%", height: "35px" }}
                                                    placeholder="Select Visiting Purpose"
                                                >
                                                    {
                                                        visitingPurpose?.map((item, index) => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Access Need</span>}
                                                name="accessNeed"
                                                placeholder="Select Access Need"
                                            >
                                                <Select
                                                    className='textStyle-small'
                                                    style={{ width: "80%", height: "35px" }}
                                                    placeholder="Select Access Need"
                                                >
                                                    {
                                                        accessNeed?.map((item, index) => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Reason</span>}
                                                name="reason"
                                            >
                                                <Input
                                                    className='textStyle-small'
                                                    style={{ width: "80%", height: "35px" }}
                                                    placeholder="Enter Reason"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Remark</span>}
                                                name="remark"
                                            >
                                                <Input
                                                    className='textStyle-small'
                                                    style={{ width: "80%", height: "35px" }}
                                                    placeholder="Enter Remark"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Work Type</span>}
                                                name="workType"
                                            >
                                                <Select
                                                    className='textStyle-small'
                                                    placeholder="Select Work Type"
                                                    style={{ width: "80%", height: "35px" }}>
                                                    {
                                                        workingType?.map((item, index) => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>

                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Work Area</span>}
                                                name="workArea"
                                            >
                                                <Select
                                                    className='textStyle-small'
                                                    placeholder="Select Work Area"
                                                    style={{ width: "80%", height: "35px" }}>
                                                    {
                                                        workArea?.map((item, index) => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </>) : (<></>)}

                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Handler Group</span>}
                                            name="handler"
                                            rules={[{ required: true, message: 'Please select handler group!' }]}
                                        >
                                            <Select
                                                className='textStyle-small'
                                                style={{ width: "80%", height: "35px" }}
                                                placeholder="Select Handler"
                                            >
                                                {
                                                    handlerGroup?.map((item, index) => {
                                                        return (
                                                            <Select.Option value={item.id}>{item.name}</Select.Option>
                                                        )
                                                    }
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Type</span>}
                                            name="type"
                                            rules={[{ required: true, message: 'Please select type!' }]}
                                        >
                                            <Select
                                                className='textStyle-small'
                                                style={{ width: "80%", height: "35px" }}
                                                placeholder="Select Type"
                                                onChange={(value) => setOperationModal(value)}
                                            >
                                                <Select.Option value={0}>Operation</Select.Option>
                                                <Select.Option value={1}>Project</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                {
                                    operationModal === 1 ? (
                                        <>
                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Project Name</span>}
                                                        name="projectName"
                                                    >
                                                        <Input
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px" }}
                                                            placeholder="Enter Project Name"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Scope of Work</span>}
                                                        name="scopeOfWork"
                                                    >
                                                        <Input
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px" }}
                                                            placeholder="Enter Scope of Work"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Down Time Required Date</span>}
                                                        name="downTimeRequiredDate"
                                                    >
                                                        <DatePicker
                                                            placeholder="Select Date and Time"
                                                            className='ant-picker-input'
                                                            style={{ width: "80%", height: "35px" }}
                                                            format="YYYY-MM-DD"
                                                            disabledDate={(current) => current && current < moment().startOf('day')}
                                                            suffixIcon={<CalendarOutlined style={{ fontSize: '14px', color: '#1890ff' }} />}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Name of Team Leader</span>}
                                                        name="nameOfTeamLeader"
                                                    >
                                                        <Input
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px" }}
                                                            placeholder="Enter Name of Team Leader"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Contact Number of Team Leader</span>}
                                                        name="contactNumberOfTeamLeader"
                                                        rules={[{ message: 'Please input your phone number!' },
                                                        { pattern: new RegExp(/^[0-9\b]+$/), message: 'The phone number is not valid!' },
                                                        { min: 10, message: 'The phone number is not valid!' },
                                                        { max: 10, message: 'Please input valid contact number!' }
                                                        ]}>
                                                        <Input
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px" }}
                                                            placeholder="Enter Contact Number of Team Leader"
                                                            type='number'
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Sub Request Categories</span>}
                                                        name="subCategories"
                                                        rules={[{ required: true, message: 'Please select sub categories!' }]}
                                                    >
                                                        <Select
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px" }}
                                                            placeholder="Select Sub Categories"
                                                        >
                                                            {
                                                                subRequestType?.map((item, index) => {
                                                                    return (
                                                                        <Select.Option value={item.id}>{item.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                        </>
                                    ) : (
                                        <> </>
                                    )
                                }

                                {
                                    operationModal === 0 ? (
                                        <>
                                            <Row>

                                                <Col span={12}>
                                                    <Form.Item
                                                        label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Sub Request Categories</span>}
                                                        name="subCategories"
                                                        rules={[{ required: true, message: 'Please select sub categories!' }]}
                                                    >
                                                        <Select
                                                            className='textStyle-small'
                                                            style={{ width: "80%", height: "35px" }}
                                                            placeholder="Select Sub Categories"
                                                        >                                                        {
                                                                subRequestType?.map((item, index) => {
                                                                    return (
                                                                        <Select.Option value={item.id}>{item.name}</Select.Option>
                                                                    )
                                                                })
                                                            }
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </>
                                    ) : (
                                        <></>
                                    )
                                }

                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Request Category</span>}
                                            name="category"
                                            rules={[{ required: true, message: 'Please select request category!' }]}
                                        >
                                            <Select
                                                className='textStyle-small'
                                                style={{ width: "90%", height: "35px" }}
                                                placeholder="Select Category"
                                                defaultValue={requestType}
                                            >
                                                {
                                                    requestCategory?.map((item, index) => {
                                                        return (
                                                            <Select.Option value={item.id} disabled={item.name !== requestType}>
                                                                {item.name}
                                                            </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Permission Start Date and Time</span>}
                                            name="permissionStartDatetime"
                                            rules={[{ required: true, message: 'Please input permission start date and time!' }]}
                                        >
                                            <DatePicker
                                                showTime
                                                placeholder="Select Date and Time"
                                                className='ant-picker-input'
                                                style={{ width: "80%", height: "35px" }}
                                                format="YYYY-MM-DD HH:mm"
                                                disabledDate={(current) => current && current < moment().startOf('day')}
                                                suffixIcon={<CalendarOutlined style={{ fontSize: '14px', color: '#1890ff' }} />}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Permission End Date</span>}
                                            name="permissionEndtDatetime"
                                            rules={[{ required: true, message: 'Please input permission end date!' }]}
                                        >
                                            <DatePicker
                                                placeholder="Select Date"
                                                className='ant-picker-input'
                                                style={{ width: "80%", height: "35px", }}
                                                format="YYYY-MM-DD"
                                                disabledDate={(current) => current && current < moment().startOf('day')}
                                                suffixIcon={<CalendarOutlined style={{ fontSize: '14px', color: '#1890ff' }} />}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Team Members</span>}
                                            required={true}
                                            initialValue={teamMemberJson}
                                            name="addTeamMembers"
                                        >
                                            <Input
                                                placeholder="Add New Team Members"
                                                className='textStyle-small'
                                                style={{ width: "80%", height: "35px", backgroundColor: "white" }}
                                                disabled
                                                suffix={
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div
                                                            style={{
                                                                backgroundColor: '#1890ff',
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '3px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            onClick={openModelForInsertDataCreateUsers}
                                                        >
                                                            <PlusOutlined
                                                                size='small'
                                                                style={{ cursor: 'pointer', color: 'white' }} />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </Form.Item>
                                        {
                                            teamMemberJson?.map((item, index) => {
                                                return (
                                                    <div style={{ width: "80%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                                        <Row>
                                                            <Col span={18}>
                                                                <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px" }}>
                                                                    {item?.name ? item.name : "N/A"}
                                                                </span>
                                                                <Row>
                                                                    <Col span={24}>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                            <PhoneOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                                            {item?.contactNumber ? item.contactNumber : "N/A"}
                                                                        </span>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px", marginLeft: "5px" }}>
                                                                            <UserOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                                            {item?.nic ? item.nic : "N/A"}
                                                                        </span>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px", marginLeft: "5px" }}>
                                                                            <BankOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                                            {item?.company ? item.company : "N/A"}
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={6} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                                <div
                                                                    onClick={() => makeAsKeyHolder(index)}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px",
                                                                        borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center",
                                                                        background: item?.isKeyHolder === true ? "#2d5ff4" : "gray"
                                                                    }}>
                                                                    <KeyOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                                {
                                                                    item?.isKeyHolder === true ? (
                                                                        <div
                                                                            onClick={() => openModelForInsertDataCreateKeyLocation()}
                                                                            style={{
                                                                                cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", marginLeft: "5px",
                                                                                borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "#f68407"
                                                                            }}>
                                                                            <GlobalOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                        </div>
                                                                    ) : null
                                                                }
                                                                <div
                                                                    onClick={() => popFromUserArray(index)}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", marginLeft: "5px",
                                                                        borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                                    }}>
                                                                    <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Upload Team Members .csv Sheet</span>}
                                            name="uploadTeamMembers"
                                        >
                                            <div style={{
                                                border: '1px solid #d9d9d9', width: '80%', flexDirection: 'row', display: 'flex', height: 'auto'
                                            }}>
                                                <Upload
                                                    multiple={false}
                                                    beforeUpload={() => false}
                                                    accept=".csv"
                                                    onChange={handleFileUploadUsers}
                                                    style={{ width: '500px', color: '#c1c2c3' }}
                                                    showUploadList={false}
                                                >
                                                    <Button icon={<UploadOutlined />}
                                                        style={{
                                                            width: 'auto', backgroundColor: '#1890ff', borderColor: '#1890ff', color: 'white',
                                                        }}
                                                    >
                                                        <span className='textStyle-small' style={{ color: 'white' }}>
                                                            Upload File
                                                        </span>
                                                    </Button>
                                                    <span className='textStyle-small' style={{ marginLeft: '10px' }}>
                                                        {fileDetailsUsers ? fileDetailsUsers.name : 'No File Uploaded'}
                                                    </span>
                                                </Upload>
                                                <DeleteOutlined style={{ marginLeft: 'auto', fontSize: '15px', color: 'red', marginRight: '10px' }}
                                                    onClick={() => setFileDetailsUsers(null)} />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    {/* add equipment */}
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Equipment</span>}
                                            name="addEquipment"
                                        >
                                            <Input
                                                placeholder="Add New Equipment"
                                                style={{ width: "80%", height: "35px", backgroundColor: "white" }}
                                                className='textStyle-small'
                                                disabled
                                                suffix={
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div
                                                            style={{
                                                                backgroundColor: '#1890ff',
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '3px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            onClick={openModelForInsertDataCreateEquipment}
                                                        >
                                                            <PlusOutlined
                                                                size='small'
                                                                // onClick={handleIconClick} 
                                                                style={{ cursor: 'pointer', color: 'white' }} />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </Form.Item>

                                        {
                                            equpmentJson?.map((item, index) => {
                                                return (
                                                    <div style={{ width: "80%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                                        <Row>
                                                            <Col span={22}>
                                                                <Row>
                                                                    <Col span={8}>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                            {item?.itemName ? item.itemName : "N/A"}
                                                                        </span>
                                                                    </Col>
                                                                    <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px", padding: "5px" }}>
                                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                                <span style={{ color: "white" }}>{item?.actionType ? item.actionType : "N/A"}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex", alignItems: "center", justifyContent: "center", padding: "5px",
                                                                            backgroundColor: "#ab0eb9", width: "auto", height: "20px", borderRadius: "5px", marginLeft: "5px"
                                                                        }}>
                                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                                <span style={{ color: "white" }}>{item?.quantity ? item.quantity : "N/A"}</span>
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                    <span className='textStyle-small' style={{ fontSize: "12px", marginTop: "7px" }}>
                                                                        <b>{item?.serialNumber ? item.serialNumber : "N/A"}</b><br></br>
                                                                        {item?.description ? item.description : "N/A"}
                                                                    </span>
                                                                </Row>
                                                            </Col>
                                                            <Col span={2} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                                <div
                                                                    onClick={() => popFromEquipmentArray(index)}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                                    }}>
                                                                    <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                    {/* add instruments */}
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Instruments</span>}
                                            name="addInstruments"
                                        >
                                            <Input
                                                placeholder="Add New Instruments"
                                                style={{ width: "80%", height: "35px", backgroundColor: "white" }}
                                                className='textStyle-small'
                                                disabled
                                                suffix={
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div
                                                            style={{
                                                                backgroundColor: '#1890ff',
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '3px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            onClick={openModelForInsertDataCreateInstruments}
                                                        >
                                                            <PlusOutlined
                                                                size='small'
                                                                // onClick={handleIconClick} 
                                                                style={{ cursor: 'pointer', color: 'white' }} />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </Form.Item>
                                        {
                                            instrumentJson?.map((item, index) => {
                                                return (
                                                    <div style={{ width: "80%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                                        <Row>
                                                            <Col span={22}>
                                                                <Row>
                                                                    <Col span={8}>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                            {item?.itemName ? item.itemName : "N/A"}
                                                                        </span>
                                                                    </Col>
                                                                    <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px",padding: "5px" }}>
                                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                                <span style={{ color: "white" }}>{item?.actionType ? item.actionType : "N/A"}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex", alignItems: "center", justifyContent: "center",
                                                                            backgroundColor: "#ab0eb9", width: "auto", height: "20px", borderRadius: "5px", marginLeft: "5px", padding: "5px"
                                                                        }}>
                                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                                <span style={{ color: "white" }}>{item?.quantity ? item.quantity : "N/A"}</span>
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                    <span className='textStyle-small' style={{ fontSize: "12px", marginTop: "7px" }}>
                                                                        <b>{item?.serialNumber ? item.serialNumber : "N/A"}</b><br></br>
                                                                        {item?.description ? item.description : "N/A"}
                                                                    </span>
                                                                </Row>
                                                            </Col>
                                                            <Col span={2} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                                <div
                                                                    onClick={() => popFromInstrumentArray(index)}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px",
                                                                        borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                                    }}>
                                                                    <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Add Vehicles</span>}
                                            name="addVehicles"
                                        >
                                            <Input
                                                placeholder="Add New Vehicles"
                                                className='textStyle-small'
                                                style={{ width: "80%", height: "35px", backgroundColor: "white" }}
                                                disabled
                                                suffix={
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div
                                                            style={{
                                                                backgroundColor: '#1890ff',
                                                                width: '20px',
                                                                height: '20px',
                                                                borderRadius: '3px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                            onClick={openModelForInsertDataCreateVehicles}
                                                        >
                                                            <PlusOutlined
                                                                size='small'
                                                                // onClick={handleIconClick} 
                                                                style={{ cursor: 'pointer', color: 'white' }} />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </Form.Item>

                                        {
                                            vehicleJson?.map((item, index) => {
                                                return (
                                                    <div style={{ width: "80%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                                        <Row>
                                                            <Col span={22}>
                                                                <Row>
                                                                    <Col span={8}>
                                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                                            {item?.vehicleNumber ? item.vehicleNumber : "N/A"}
                                                                        </span>
                                                                    </Col>
                                                                    <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px", padding: "5px" }}>
                                                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                                                <span style={{ color: "white" }}>{item?.vehicleType ? item.vehicleType : "N/A"}</span>
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={2} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                                                <div
                                                                    onClick={() => popFromVehicleArray(index)}
                                                                    style={{
                                                                        cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px",
                                                                        borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                                    }}>
                                                                    <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: 'bold' }} className='textStyle-small'>Work Descriptions</span>}
                                            name="workDescriptions"
                                        >
                                            <Input showCount maxLength={80}
                                                style={{ width: "80%", height: "35px" }}
                                                className='textStyle-small'
                                                placeholder='Enter Work Descriptions'
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            label={<span style={{ fontWeight: "bold" }}>Attach Documents </span>}
                                            name="files"
                                        >
                                            <Spin spinning={fileUploading} size='medium'
                                            tip={<span className='textStyle-small'>
                                                File Processing For Upload
                                            </span>}
                                            style={{ width: "90%", height: "100%", backgroundColor: "white"}}>
                                            <Upload.Dragger
                                                name="file"
                                                multiple={true}
                                                width="20%"
                                                // onChange={({ fileList }) => {
                                                //     if (fileList.length > 5) {
                                                //         message.error('You can only upload maximum of 5 files');
                                                //         fileList.pop();
                                                //     }
                                                // }}
                                                maxCount={5}
                                                {...props}
                                                style={{ width: "90%", height: "20px", backgroundColor: "white" }}
                                                accept='.jpg,.png,.doc,.pdf,.xls,.xlsx,.csv'
                                            >
                                                <p className="ant-upload-drag-icon" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                    <div style={{ fontSize: "30px", fontWeight: "bold", borderRadius: "50%", backgroundColor: "#1890ff", padding: "10px", width: "30px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <UploadOutlined style={{ color: "white", fontWeight: "bold", fontSize: "15px" }} />
                                                    </div>
                                                </p>
                                                <p className="ant-upload-text" style={{ textAlign: "center", fontSize: "14px" }}>
                                                    <b>Drag & Drop your Files Here</b>
                                                </p>
                                                <p className="ant-upload-hint" style={{ textAlign: "center", fontSize: "12px" }}>
                                                    <span style={{ color: "red" }}>*</span>Supported : .JPG .PNG, Doc, PDF, Excel
                                                </p><br></br>
                                                <Button type='primary' style={{ borderRadius: "5px" }}>
                                                    <span className='textStyles-small'>
                                                        Browse Files
                                                    </span>
                                                </Button>
                                            </Upload.Dragger>
                                            </Spin>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row style={{marginTop: '20px'}}>
                                    <Col span={24}>
                                        <Button
                                            className='primary-button-design'
                                            style={{ width: '200px', float: 'right', marginRight: '10%' }}
                                            htmlType='submit'
                                        >
                                            <span className='textStyle-small'>
                                                Create Request
                                            </span>
                                        </Button>
                                        <Button
                                            danger
                                            className='danger-button'
                                            style={{ width: '200px', float: 'right', marginRight: '15px' }}
                                            onClick={() => { handleResetMainForm(); window.location.reload(); }}
                                        >
                                            <span className='textStyle-small'>
                                                Reset Fields
                                            </span>
                                        </Button>
                                        <Button
                                            className='secondary-button'
                                            style={{ width: '100px', float: 'right', marginRight: '15px' }}
                                            onClick={() => { history.push('/access/dev/manage') }}
                                        > <span className='textStyle-small'>Back</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Spin>
                </div>

                <Modal
                    title={getTitle(formID)}
                    visible={editModelOpen}
                    onOk={() => { closeModelForInsertData() }}
                    onCancel={() => { closeModelForInsertData() }}
                    footer={null}
                    style={{
                        borderRadius: "20px",
                    }}
                >
                    {/* Add Team Members */}
                    {
                        formID === 1 ? (

                            <Form
                                title={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Request Summary</span>}
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 24 }}
                                style={{ paddingLeft: '20px', paddingRight: '20px' }}
                                layout="horizontal"
                                onFinish={addTeamMembers}
                                ref={formRefUS}
                            >
                                <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: 'Please input your name!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Name"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="contactNumber"
                                    rules={[{ required: true, message: 'Please input your contact number!' },
                                    { pattern: /^[0-9]\d*$/, message: 'Please input valid contact number!' },
                                    { min: 10, message: 'Please input valid contact number!' },
                                    { max: 10, message: 'Please input valid contact number!' }
                                    ]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Contact Number"
                                        type='number'
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="nic"
                                    rules={[
                                        { required: true, message: 'Please input your NIC!' },
                                        {
                                            pattern: /^(?:\d{9}[vVxX]|\d{12})$/,
                                            message: 'Please input a valid NIC!'
                                        }
                                    ]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter NIC"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="company"
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Company"
                                    />
                                </Form.Item>

                                <Row style={{ float: 'right' }}>
                                    <Col span={24}>
                                        <Button
                                            className='secondary-button'
                                            style={{ width: '150px' }}
                                            onClick={() => { closeModelForInsertData() }}
                                        >
                                            <span className='textStyle-small'>Done</span>
                                        </Button>

                                        <Button
                                            className='primary-button'
                                            htmlType="submit"
                                            style={{ width: '150px' }}
                                        >
                                            <span className='textStyle-small'>Add Team Member</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        ) : (
                            <></>
                        )
                    }

                    {
                        formID === 4 ? (
                            <Form
                                title={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Equipment Details</span>}
                                layout="horizontal"
                                onFinish={addInstrumentsActions}
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 24 }}
                                style={{ paddingLeft: '20px', paddingRight: '20px' }}
                                ref={formRefIN}
                            >
                                <Form.Item
                                    name="itemName"
                                    rules={[{ required: true, message: 'Please input your item name!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Item Name"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="serialNumber"
                                    rules={[{ required: true, message: 'Please input your serial number!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Serial Number"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="actionType"
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Action Type"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="description"
                                >
                                    <Input.TextArea
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "50px" }}
                                        placeholder="Enter Description"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="quantity"
                                    rules={[{ required: true, message: 'Please input your quantity!' }, { pattern: /^[0-9]\d*$/, message: 'Please input valid quantity!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Quantity"
                                        type='number'
                                    />
                                </Form.Item>

                                <Row style={{ float: 'right' }}>
                                    <Col span={24}>
                                        <Button
                                            className='secondary-button'
                                            onClick={() => { closeModelForInsertData() }}
                                        >
                                            <span className='textStyle-small'>Done</span>
                                        </Button>

                                        <Button
                                            className='primary-button'
                                            htmlType='submit'
                                        >
                                            <span className='textStyle-small'>Add Instrument</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        ) : (
                            <></>
                        )
                    }

                    {/* Add Instrument */}
                    {
                        formID === 3 ? (
                            <Form
                                layout="horizontal"
                                onFinish={addEquipmentActions}
                                labelCol={{ span: 16 }}
                                wrapperCol={{ span: 24 }}
                                style={{ paddingLeft: '20px', paddingRight: '20px' }}
                                ref={formRefEQ}
                            >
                                <Form.Item
                                    name="itemName"
                                    rules={[{ required: true, message: 'Please input your item name!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Item Name"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="serialNumber"
                                    rules={[{ required: true, message: 'Please input your serial number!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Serial Number"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="actionType"
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Action Type"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="description"
                                >
                                    <Input.TextArea
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "50px" }}
                                        placeholder="Enter Description"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="quantity"
                                    rules={[{ required: true, message: 'Please input your quantity!' }, { pattern: /^[0-9]\d*$/, message: 'Please input valid quantity!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Quantity"
                                        type='number'
                                    />
                                </Form.Item>

                                <Row style={{ float: 'right' }}>
                                    <Col span={24}>
                                        <Button
                                            className='secondary-button'
                                            onClick={() => { closeModelForInsertData() }}
                                        >
                                            <span className='textStyle-small'>Done</span>
                                        </Button>
                                        <Button
                                            className='primary-button'
                                            htmlType='submit'
                                        >
                                            <span className='textStyle-small'>Add Equipment</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        ) : (
                            <> </>
                        )
                    }

                    {
                        formID === 2 ? (
                            <Form
                                title={<span style={{ fontWeight: "bold" }} className='textStyle-small'>Vehicle Details</span>}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 24 }}
                                layout="horizontal"
                                onFinish={addVehicleActions}
                                centered
                                style={{ paddingLeft: '20px', paddingRight: '20px' }}
                                ref={formRefVH}
                            >
                                <Form.Item
                                    name="vehicleNumber"
                                    rules={[{ required: true, message: 'Please input your vehicle number!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Vehicle Number"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="vehicleType"
                                    rules={[{ required: true, message: 'Please input your vehicle type!' }]}
                                >
                                    <Input
                                        className='textStyle-small customInput'
                                        style={{ width: "100%", height: "35px" }}
                                        placeholder="Enter Vehicle Type"
                                    />
                                </Form.Item>
                                <Row style={{ float: 'right' }}>
                                    <Col span={24}>
                                        <Button
                                            className='secondary-button'
                                            onClick={() => { closeModelForInsertData() }}
                                        >
                                            <span className='textStyle-small'>Done</span>
                                        </Button>
                                        <Button
                                            className='primary-button'
                                            htmlType='submit'
                                        >
                                            <span className='textStyle-small'>Add Vehicle</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        ) :
                            (<> </>)
                    }

                    {
                        formID === 5 ? (
                            <>
                                <div> {/* Add a parent element */}
                                    <Table dataSource={sitejsonData} columns={keyLocationInformations} />
                                </div>
                            </>
                        ) :
                            (<> </>)
                    }

                    <br></br>
                    <br></br>

                    {
                        formID === 1 && teamMemberJson?.map((item, index) => {
                            return (
                                <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                    <Row>
                                        <Col span={18}>
                                            <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "12px" }}>
                                                {item?.name ? item.name : "N/A"}
                                            </span>
                                            <Row>
                                                <Col span={24}>
                                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                        <PhoneOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                        {item?.contactNumber ? item.contactNumber : "N/A"}
                                                    </span>
                                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px", marginLeft: "5px" }}>
                                                        <UserOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                        {item?.nic ? item.nic : "N/A"}
                                                    </span>
                                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px", marginLeft: "5px" }}>
                                                        <BankOutlined style={{ fontSize: "12px", color: "#1890ff", marginRight: "5px" }} />
                                                        {item?.company ? item.company : "N/A"}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={6} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                            <div
                                                onClick={() => makeAsKeyHolder(index)}
                                                style={{
                                                    cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px",
                                                    borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center",
                                                    background: item?.isKeyHolder === true ? "#2d5ff4" : "gray"
                                                }}>
                                                <KeyOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                            </div>
                                            {
                                                item?.isKeyHolder === true ? (
                                                    <div
                                                        onClick={() => openModelForInsertDataCreateKeyLocation()}
                                                        style={{
                                                            cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", marginLeft: "5px",
                                                            borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "#f68407"
                                                        }}>
                                                        <GlobalOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                                    </div>
                                                ) : null
                                            }
                                            <div
                                                onClick={() => popFromUserArray(index)}
                                                style={{
                                                    cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", marginLeft: "5px",
                                                    borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                }}>
                                                <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })
                    }

                    {
                        formID === 4 && instrumentJson?.map((item, index) => {
                            return (
                                <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                    <Row>
                                        <Col span={22}>
                                            <Row>
                                                <Col span={8}>
                                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                        {item?.itemName ? item.itemName : "N/A"}
                                                    </span>
                                                </Col>
                                                <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px", padding: "5px" }}>
                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                            <span style={{ color: "white" }}>{item?.actionType ? item.actionType : "N/A"}</span>
                                                        </span>
                                                    </div>
                                                    <div style={{
                                                        display: "flex", alignItems: "center", justifyContent: "center",
                                                        backgroundColor: "#ab0eb9", width: "auto", height: "20px", borderRadius: "5px", marginLeft: "5px", padding: "5px"
                                                    }}>
                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                            <span style={{ color: "white" }}>{item?.quantity ? item.quantity : "N/A"}</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <span className='textStyle-small' style={{ fontSize: "12px", marginTop: "7px" }}>
                                                    <b>{item?.serialNumber ? item.serialNumber : "N/A"}</b><br></br>
                                                    {item?.description ? item.description : "N/A"}
                                                </span>
                                            </Row>
                                        </Col>
                                        <Col span={2} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                            <div
                                                onClick={() => popFromInstrumentArray(index)}
                                                style={{
                                                    cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px",
                                                    borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                }}>
                                                <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })
                    }

                    {
                        formID === 3 && equpmentJson?.map((item, index) => {
                            return (
                                <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                    <Row>
                                        <Col span={22}>
                                            <Row>
                                                <Col span={8}>
                                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                        {item?.itemName ? item.itemName : "N/A"}
                                                    </span>
                                                </Col>
                                                <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px", padding: "5px" }}>
                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                            <span style={{ color: "white" }}>{item?.actionType ? item.actionType : "N/A"}</span>
                                                        </span>
                                                    </div>
                                                    <div style={{
                                                        display: "flex", alignItems: "center", justifyContent: "center",
                                                        backgroundColor: "#ab0eb9", width: "auto", height: "20px", borderRadius: "5px", marginLeft: "5px", padding: "5px"
                                                    }}>
                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                            <span style={{ color: "white" }}>{item?.quantity ? item.quantity : "N/A"}</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                                <span className='textStyle-small' style={{ fontSize: "12px", marginTop: "7px" }}>
                                                    <b>{item?.serialNumber ? item.serialNumber : "N/A"}</b><br></br>
                                                    {item?.description ? item.description : "N/A"}
                                                </span>
                                            </Row>
                                        </Col>
                                        <Col span={2} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                            <div
                                                onClick={() => popFromEquipmentArray(index)}
                                                style={{
                                                    cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px", borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                }}>
                                                <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })
                    }

                    {
                        formID === 2 && vehicleJson?.map((item, index) => {
                            return (
                                <div style={{ width: "100%", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                                    <Row>
                                        <Col span={22}>
                                            <Row>
                                                <Col span={8}>
                                                    <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "11px" }}>
                                                        {item?.vehicleNumber ? item.vehicleNumber : "N/A"}
                                                    </span>
                                                </Col>
                                                <Col span={16} style={{ display: "flex", flexDirection: "row" }}>
                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#cbc300", width: "auto", height: "20px", borderRadius: "5px",  padding: "5px" }}>
                                                        <span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "10px" }}>
                                                            <span style={{ color: "white" }}>{item?.vehicleType ? item.vehicleType : "N/A"}</span>
                                                        </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={2} style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                                            <div
                                                onClick={() => popFromVehicleArray(index)}
                                                style={{
                                                    cursor: "pointer", color: "red", float: "right", width: "25px", height: "25px",
                                                    borderRadius: "5px", display: "flex", alignItems: "center", justifyContent: "center", background: "red"
                                                }}>
                                                <DeleteOutlined style={{ cursor: "pointer", color: "white", fontSize: "14px" }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })
                    }
                </Modal>
            </div >

            <Modal
                title={<span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "14px" }}>Import Summary - Sites</span>}
                visible={openErrorLog}
                footer={null}
                style={{
                    borderRadius: "20px",
                }}
                width={800}
            >
                <Row>
                    <Col span={24}>
                        <Table
                            size='small'
                            pagination={false}
                            dataSource={siteIDValidationList}
                            columns={errorSummery} />
                    </Col>
                </Row>
                <Row align={'end'}>
                    <Button
                        type='primary'
                        style={{ borderRadius: '5px', marginTop: '10px' }}
                        danger
                        onClick={() => {
                            setOpenErrorLog(false); setSiteJsonData([]);
                            setIsOOType(false);
                            setFileDetailsSites(null);
                        }}
                    >
                        <span className='textStyle-small'>Close And Import Again</span>
                    </Button>
                </Row>
            </Modal>

            <Modal
                title={<span className='textStyle-small' style={{ fontWeight: "bold", fontSize: "14px" }}>Import Summary - Team Members</span>}
                visible={openTeamErrorLog}
                footer={null}
                style={{
                    borderRadius: "20px",
                }}
                width={800}
            >
                <Row>
                    <Col span={24}>
                        <Table
                            size='small'
                            pagination={false}
                            dataSource={userValidation}
                            columns={teamMembersError} />
                    </Col>
                </Row>
                <Row align={'end'}>
                    <Button
                        type='primary'
                        style={{ borderRadius: '5px', marginTop: '10px' }}
                        danger
                        onClick={() => {
                            setOpenTeamErrorLog(false);
                        }}
                    >
                        <span className='textStyle-small'>Close And Import Again</span>
                    </Button>
                </Row>
            </Modal>
        </body >
    )
}

export default AccessRequest;
