import { get, globaAbortController, post, put, get2, getAll,postWithData,delete1, getFunctional} from "./api.client";
import { imageCapturingService, serviceCommon, serviceOne, serviceUser, serviceFinance, refuelserviceOne, serviceVehicle, siteAccessService, serviceRouting, pisService, baseUrl,hierarchy, vehicleRewamp, accessrw } from "./api.config";

import axios from "axios";
const CancelToken = axios.CancelToken;

let cancel;

export async function getUserRolesAndPermission2(data) {
  try {
    var response;
    const res = await post(
      serviceUser + "/user/getUserRolesAndPermission",
      { 'token': data }
    );
    ////console.log(res)      
    // console.log("first Set2")  

    if (res) {
      //  console.log('www');

      localStorage.setItem("userId", res.data.id);
      localStorage.setItem("setRole", JSON.stringify(res.data.roles));
    } else {
      //  console.log('eee');
      window.localStorage.clear();
      localStorage.setItem("userId", '');
      localStorage.setItem("setRole", JSON.stringify(''));
    }

    if (res.status == 200) {
      response = {

        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getUserRolesAndPermission() {
  try {
    var response;
    const res = await post(
      serviceUser + "/user/getUserRolesAndPermission",
      { 'token': localStorage.getItem('access_token') }
    );
    // console.log("first Set")        
    // console.log(res) 

    if (res) {
      //  console.log('www');
      localStorage.setItem("userId", res.data.id);
      localStorage.setItem("setRole", JSON.stringify(res.data.roles));
    } else {
      //  console.log('eee');
      window.localStorage.clear();
      localStorage.setItem("userId", '');
      localStorage.setItem("setRole", JSON.stringify(''));
    }



    if (res.status == 200) {

      // getUserServiceType();
      // this.getUserServiceType();
      // response = {
      //   success: true,
      //   data: res,
      // };

      return res.data;
    } else {

      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

// api endpoint : https://dnsuatapi.eimsky.com/common/dropdowns/getallv2 
// method : GET
export async function getAllDropdowns() {
  try {
    var response;
    const res = await get(serviceCommon + "/dropdowns/getallv2");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getUserServiceType() {
  try {
    var response;
    var headers = {
      // userId: "170",
      userId: localStorage.getItem("userId"),

      // logged_user_email: localStorage.getItem("logged_user_email")
    };
    const res = await get(serviceUser + "/user/getUserServiceType", headers);

    if (res.status == 200) {
      response = {

        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function createNewSpd(data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/spd/create",
      data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function createNewReWind(data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/ReWind/create",
      data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function createNewReSoler(data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/ReSoler/create",
      data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function createNewSharedOperator(data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/sharedOperator/create",
      data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function createNewFcb(data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/fcb/create",
      data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function createNewATs(data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/ats/create",
      data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function createRectifier(data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/rectifier/create",
      data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function createCabinet(data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/cabinet/create",
      data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function createBatteryBank(data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/batteryBank/create",
      data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function createAirConditioner(data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/airConditioner/create",
      data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function createNewBatteryRack(data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/batteryRack/create",
      data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function createNewdcdu(data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/dcdu/create",
      data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function createNewOrganization(organization_data) {
  try {
    var response;
    const res = await post(
      serviceCommon + "/organization/create",
      organization_data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function UpdateOrganizationByGivenID(data, orgID) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await put(serviceCommon + "/organization/edit/"+orgID, data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
 
  }
}

export async function createNewDepot(depot) {
  try {
    var response;
    const res = await post(serviceCommon + "/depot/create", depot);
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function UpdateDepotByGivenID(data, depotID) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await put(serviceCommon + "/depot/edit/" + depotID, data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
 
  }
}

export async function createNewContract(contract) {
  try {
    var response;
    const res = await post(serviceCommon + "/contract/create", contract);
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function createNewVendor(vendor_data) {
  try {
    var response;
    const res = await post(serviceCommon + "/vendor/create", vendor_data);
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function updateVendorByid(vendor_team, vendorID) {
  try {
    var response;
    const res = await put(serviceCommon + "/vendor/edit/"+vendorID, vendor_team);
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function createNewSiteCategory(data) {
  try {
    var response;
    const res = await post(serviceCommon + "/siteCategory/create", data);
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function updateSiteCategoryTeam(siteCategoryData, siteCategoryID) {
  try {
    var response;
    const res = await put(serviceCommon + "/siteCategory/edit/"+siteCategoryID, siteCategoryData);
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function updateShelterTypeByID(shelterTypeData, shelterTypeID) {
  try {
    var response;
    const res = await put(serviceCommon + "/shelterType/edit/"+shelterTypeID, shelterTypeData);
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function createNewVendorTeam(vendor_team) {
  try {
    var response;
    const res = await post(serviceUser + "/vendorTeam/create", vendor_team);
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function updateVendorTeam(vendor_team) {
  try {
    var response;
    const res = await post(serviceUser + "/vendorTeam/update", vendor_team);
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function createNewRegion(region_data) {
  try {
    var response;
    const res = await post(serviceCommon + "/region/create", region_data);
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function UpdateRegionByGivenID(data, regionID) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await put(serviceCommon + "/region/edit/"+regionID, data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
 
  }
}

export async function getAllVendorTeam() {
  try {
    var response;

    const res = await get(serviceCommon + "/vendorTeam/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function getAllVendors() {
  try {
    var response;

    const res = await get(serviceCommon + "/vendor/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function getAllTeamMembers() {
  try {
    var response;

    const res = await get(serviceUser + "/user/getTeamMemberUserList");
    console.log(res);
    if (res.status === 201) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getAllContracts() {
  try {
    var response;
    const res = await get(serviceCommon + "/contract/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function createNewSlaTerm(term) {
  try {
    var response;
    const res = await post(serviceCommon + "/vendorSlaTerm/create", term);
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getAllSlaTerms() {
  try {
    var response;
    const res = await get(serviceCommon + "/vendorSlaTerm/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getAllSiteCategories() {
  try {
    var response;
    const res = await get(serviceCommon + "/siteCategory/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function createNewShelterType(data) {
  try {
    var response;
    const res = await post(serviceCommon + "/shelterType/create", data);

    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}




export async function getAllShelterType() {
  try {
    var response;
    const res = await get(serviceCommon + "/shelterType/getAll");

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function createNewSite(data) {
  try {
    var response;
    const res = await post(serviceCommon + "/site/create", data);

    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

// get all siteList list by id's /common/site/GetAllSites
export async function getAllSitesListByID() {
  try {
    var response = await get(serviceCommon + "/site/GetAllSites");
    if (response.status == 200) {
      response = {
        success: true,
        data: response.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/siteAccessRequest/new-bulk-filtered/{size}/{page}
export async function getAllSiteAccessRequestBULKFiltered(size, page, data) {
  try {
    var response = await post(accessrw + "/api/v1/siteAccessRequest/new-bulk-filtered/"+size+"/"+page, data);
    if (response.status == 200) {
      response = {
        success: true,
        data: response.data,
      };
      return response;
    } else {
      response = {
        success: false,
      }
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getAllSiteAccessRequestBULKFilteredRequester(size, page, data) {
  try {
    var response = await post(accessrw + "/api/v1/siteAccessRequest/new-bulk-filtered-requester/"+size+"/"+page, data);
    if (response.status == 200) {
      response = {
        success: true,
        data: response.data,
      };
      return response;
    } else {
      response = {
        success: false,
      }
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/siteAccessRequest/bulk/{bulkId}/pending
export async function getAllSiteAccessRequestBULKPending(bulkId) {
  try {
    var response = await get(accessrw + "/api/v1/siteAccessRequest/bulk/"+bulkId+"/pending");
    if (response.status == 200) {
      response = {
        success: true,
        data: response.data,
      };
      return response;
    } else {
      response = {
        success: false,
      }
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/siteAccessRequest/bulk-filtered
export async function getAllSiteAccessRequestBULK(id) {
  try{
    var response = await get(accessrw + "/api/v1/siteAccessRequest/bulk-filtered/"+id);
    if (response.status == 200) {
      response = {
        success: true,
        data: response.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// that function is used to check the site id is already exist or not
export async function checkSiteIDDuplicateCheck(data) {
  try {
    var response = await post(serviceCommon + "/site/checkSiteBySiteId", data);
    if (response.status == 200) {
      response = {
        success: true,
        data: response.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// http://localhost:8801/api/v1/siteAccessRequest/approveBulk
export async function approveBulkSiteAccessRequest(data) {
  try {
    var response = await put(accessrw + "/api/v1/siteAccessRequest/approveBulk", data);
    if (response.status == 200) {
      response = {
        success: true,
        data: response.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// http://localhost:8801/api/v1/siteAccessRequest/rejectBulk
export async function rejectBulkSiteAccessRequest(data) {
  try {
    var response = await put(accessrw + "/api/v1/siteAccessRequest/rejectBulk", data);
    if (response.status == 200) {
      response = {
        success: true,
        data: response.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// http://localhost:8801/api/v1/siteAccessRequest/requireBulkSiteOwnerApproval
export async function requireBulkSiteOwnerApproval(data) {
  try {
    var response = await put(accessrw + "/api/v1/siteAccessRequest/requireBulkSiteOwnerApproval", data);
    if (response.status == 200) {
      response = {
        success: true,
        data: response.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getByCode(data) {
  try {
    var response;
    var headers = {
      // userId: "170",
      userId: localStorage.getItem("userId"),

      // logged_user_email: localStorage.getItem("logged_user_email")
    };
    const res = await get(serviceOne + "/pg/getByCode/" + data, headers);



    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updataRemoveTimeInPg(data) {
  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await post(serviceOne + "/pg/updateRemoveTimeInPg", data, headers);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getPgData() {
  try {
    var response;



    var headers = {
      // userId: "170",
      userId: localStorage.getItem("userId"),

      // logged_user_email: localStorage.getItem("logged_user_email")
    };
    const res = await get(serviceOne + "/pg/getAll/LAST_30_DAYS/0", headers);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getPgCountData(data) {
  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await post(serviceOne + "/approveRequest/getPgCountForServenDays", data, headers);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}
export async function getPgSLAData(data) {
  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await post(serviceOne + "/approveRequest/getPgSlaBreachCountForServenDays", data, headers);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}


export async function getPgCostData(data) {
  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await post(serviceFinance + "/pgPayment/getPgCostForServenDays", data, headers);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getPgStatusData(data) {
  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),
    };

    const res = await post(serviceOne + "/approveRequest/getPgCountStatusWiseForServenDays", data, headers);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getUsers() {
  try {
    var response;
    const res = await get(serviceUser + "/user/getAllUsers");
    // const res = await post(serviceCommon + "/depot/getDepotsByUser");
    //console.log(res);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
        // data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getAllDepotUsers() {
  try {
    var response;
    const res = await get(serviceUser + "/user/getAllDepotUsers");
    //console.log(res);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getMasterData() {
  try {
    var response;
    const res = await get(serviceCommon + "/base/getBaseData");
    //console.log(res);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getAllDepots() {
  try {
    var response;
    const res = await get(serviceCommon + "/depot/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getUserPageData() {

  const urls = [serviceUser + "/role/getAllRole", serviceCommon + "/organization/getAll", serviceCommon + "/region/getAll", serviceCommon + "/depot/getAll"];
  var response;
  const res = await getAll(urls);
  if (res.status == 200) {
    response = {
      success: true,
      data: res,
    };

    return response;
  } else {
    //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
    response = {
      success: false,
    };
    return response;
  }
}
export async function getAllUsersList(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),
    };

    const res = await post(serviceUser + "/user/getAllUsersNew",data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getRolesData() {
  try {
    var response;
    const res = await get(serviceUser + "/role/getAllRole");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getDepotDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/depot/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getAllDepotList() {
  try {
    var response;
    const res = await get(serviceCommon + "/depot/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getAllRegionList() {
  try {
    var response;
    const res = await get(serviceCommon + "/region/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getRegionDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/region/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getSitesByDepotId(id) {
  try {

    var headers = {
      'Content-Type': 'application/json',
    };
    var response;
    var data = { "id": id };
    //console.log(data);
    const res = await post(serviceCommon + "/site/GetSitesByDepotId", data, headers);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
// http://localhost:8010/depot/GetDepotByRegionId
export async function getDepotByRegionId(id) {
  try {

    var headers = {
      'Content-Type': 'application/json',
    };
    var response;
    var data = { "id": id };
    //console.log(data);
    const res = await post(serviceCommon + "/depot/GetDepotByRegionId", data, headers);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function assignToDepot(assign_data) {
  try {

    var response;
    const res = await post(
      serviceUser + "/user/createDepotUser",
      assign_data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getReginoWiseUsers() {
  try {

    var response;
    const res = await get(
      serviceCommon + "/region/getRegionWiseUserList",
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getRegionWiseVendorTeam() {
  try {

    var response;
    const res = await get(
      serviceCommon + "/region/getRegionWiseVendorTeam",
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function getRegionWiseDepots() {
  try {

    var response;
    const res = await get(
      serviceCommon + "/region/getRegionWiseDepots",
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function getRegionWiseSites() {
  try {

    var response;
    const res = await get(
      serviceCommon + "/region/getRegionWiseSites",
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getOrganizationList() {
  try {
    var response;
    const res = await get(
      serviceCommon + "/organization/getAll",
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }



  } catch (err) {
    return false;


  }
}

export async function getOrganizationWiseUsers() {
  try {
    var response;
    const res = await get(
      serviceOne + "/user/getOrganizationWiseUsers",
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }



  } catch (err) {
    return false;


  }
}

export async function getfullExportNew(data) {
  try {

    var response;
    const res = await post(
      serviceFinance + "/pgPayment/getPgPaymentSheetExport", data
    );
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getfullExport(data) {
  try {

    var response;
    const res = await post(
      serviceFinance + "/pgPayment/getPaymentSheet", data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getExportPaymentSheet(data) {
  try {

    var response;
    const res = await post(
      serviceFinance + "/refuelPayment/getExportPaymentSheet", data
    );
    //console.log(res)
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getRefuelProcumentExportSheet(data) {
  try {

    var response;
    const res = await post(
      serviceFinance + "/refuelPayment/getRefuelProcurementExport", data
    );
    //console.log(res)
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getRefuelPurchaseTransactionExport(data) {
  try {

    var response;
    const res = await post(
      serviceFinance + "/refuelPayment/getRefuelPurchaseTransactionExport", data
    );
    //console.log(res)
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getFuelBalanceExport() {
  try {

    var response;
    const res = await get(
      serviceFinance + "/refuelPayment/getFuelBalanceExport");
    //console.log(res)
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getPgCountSummaryDetails(data) {
  try {

    var response;
    const res = await post(
      serviceOne + "/approveRequest/getPgCountSummaryDetails", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getRequestReport(data) {
  try {
    var response;
    const res = await post(
      serviceOne + "/approveRequest/getPgrequestSummaryDetailsWithDates", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getPgByDepots(data) {
  try {
    //console.log(data);
    var response;
    const res = await post(
      serviceOne + "/dashboard/pgByDepots", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getTrackPg(data) {
  try {
    var response;
    const res = await post(serviceOne + "/dashboard/trackPg", data);
    //console.log(res);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function pgByDepotsList(data) {
  try {
    //console.log(data);
    var response;
    const res = await post(
      serviceOne + "/dashboard/pgByDepotsList", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


// REWAMP API's SET

export async function allJobsData(){
  try {
    var response;
    const res = await get(vehicleRewamp +"/api/v1/dashboard/stats");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }

}

// /api/v1/session/1
export async function getSessionDataBySessionID(id) {
  try {
    var response;
    const res = await get(vehicleRewamp +"/api/v1/session/session-with-edits/"+id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/journey/journeys/{id}
export async function getJourneyData(id) {
  try {
    var response;
    const res = await get(vehicleRewamp +"/api/v1/journey/journeys/"+id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function registerNewVehicle (data) {
  try {
    var response;
    const res = await post(vehicleRewamp +"/api/v1/vehicle", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getVehicleByID(id) {
  try {
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vehicle/"+id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function updateVehicleData (id, data) {
  try {
    var response;
    const res = await put(vehicleRewamp +"/api/v1/vehicle/"+id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getAllVehiclesList() {
  try {
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vehicle");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/vehicleMaster/getActiveAll

export async function getAllActiveVehicleMaster() {
  try {
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vehicleMaster/getActiveAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/vehicleType/getAll

export async function getAllVehicleType() {
  try {
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vehicleType/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/vehicleSubType/getActiveAll
export async function getAllActiveVehicleSubType() {
  try {
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vehicleSubType/getActiveAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

//  /api/v1/vehicleOwner/getActiveAll

export async function getAllActiveVehicleOwner() {
  try {
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vehicleOwner/getActiveAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// api/v1/vehiclePaymentCategory/getActiveAll

export async function getAllActiveVehiclePaymentCategory() {
  try {
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vehiclePaymentCategory/getActiveAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/vendorPool

export async function getAllVendorPool() {
  try{
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vendorPool");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// https://dnsuatapi.eimsky.com/vehiclerw/api/v1/session/getAllSessionsForAdminPendingAndApproved/1
export async function getAllSessionsForAdminPendingAndApproved(id, data) {
  try {
    var response;
    const res = await post(vehicleRewamp + "/api/v1/session/getAllSessionsForAdminPendingAndApproved/" + id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  }
  catch (err) {
    return false;
  }
}
// /api/v1/session/getAllApprovedSessionsForAdmin/1
export async function getAllApprovedSessionsForAdminAPI(id, data) {
  try {
    var response;
    const res = await post(vehicleRewamp + "/api/v1/session/getAllApprovedSessionsForAdmin/" + id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// https://dnsuatapi.eimsky.com/hierarchy/api/v1/auth/region

export async function getAllRegion() {
  try{
    var response;
    const res = await get(hierarchy +"/api/v1/auth/region/active-sub-regions");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getAllMainRegions() {
  try{
    var response;
    const res = await get(hierarchy +"/api/v1/auth/region");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// https://dnsuatapi.eimsky.com/hierarchy/api/v1/auth/drivers

export async function getAllDrivers() {
  try{
    var response;
    const res = await get(hierarchy +"/api/v1/auth/drivers");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/vehicle

export async function registerNewVehicles (data) {
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/vehicle", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return response;
  }
}

export async function updateVehicleByID (id, data) {
  try{
    var response;
    const res = await put(vehicleRewamp +"/api/v1/vehicle/"+id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function deleteVehicleByID (id) {
  try{
    var response;
    const res = await put(vehicleRewamp +"/api/v1/vehicle/markAsDeleted/"+id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// https://dnsuatapi.eimsky.com/vehiclerw/api/v1/vehicle/1

export async function getSingleVehicleByID(id) {
  try{
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vehicle/"+id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}


// https://dnsuatapi.eimsky.com/vehiclerw/api/v1/journey
export async function getAllJourneyDataFromDB() {
  try{
    var response;
    const res = await get(vehicleRewamp +"/api/v1/journey");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// https://dnsuatapi.eimsky.com/vehiclerw/api/v1/auth/currentTask
export async function getCurrentTaskData(data) {
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/auth/currentTask", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// https://dnsuatapi.eimsky.com/vehiclerw/api/v1/journey/adminApproval
export async function adminApprovalForJourney(id, data) {
  try{
    var response;
    const res = await put(vehicleRewamp +"/api/v1/journey/adminApproval/"+id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// get all journies belogs to session /api/v1/journey/session/18
export async function getAllJourneyBySessionID(id) {
  try{
    var response;
    const res = await get(vehicleRewamp +"/api/v1/journey/session/"+id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// https://dnsuatapi.eimsky.com/vehiclerw/api/v1/session


export async function getAllActiveSessions() {
  try{
    var response;
    // const res = await get(vehicleRewamp +"/api/v1/session/getAllSessionsWithMasterCode");
    const res = await get(vehicleRewamp +"/api/v1/session/getAllPendingApprovalSessionsForAdmin");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// api/v1/session/updateApprovalStatus/{id}

export async function updateApprovalStatusForSession(id){
  try{
    var response;
    const res = await put(vehicleRewamp +"/api/v1/session/updateApprovalStatus/"+id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// update session vehcilerw/api/v1/session/update
export async function updateSessionData(data){
  try{
    var response;
    const res = await put(vehicleRewamp +"/api/v1/session/update", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/vehicleMaster/getAll-filtered
export async function getAllFilteredVehicleMaster(){
  try{
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vehicleMaster/getAll-filtered");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/auth/drivers-filtered

export async function getAllFilteredDrivers(){
  try{
    var response;
    const res = await get(hierarchy +"/api/v1/auth/drivers-filtered");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}


// dashboard API List Revamp 

// /api/v1/view/charts
export async function getDashboardChartsDataRewamp(){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/view/charts");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } 
  } catch (err) {
    return false;
  }
}

// /api/v1/view/MonthlyRegionViceMillage
export async function getMonthlyRegionViceMillageRewamp(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/view/MonthlyRegionViceMillage" , data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/view/GeneMonthlyRegionViceMillage
export async function getGeneMonthlyRegionViceMillageRewamp(){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/view/GeneMonthlyRegionViceMillage");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/view/dashboardCounts
export async function getDashboardCountsRewamp(){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/view/dashboardCounts");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/view/dashboardCounts2
export async function getDashboardCounts2Rewamp(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/view/dashboardCounts2", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/session/updateApprovalStatus/{id}
export async function updateApprovalStatusForSessionRewamp(id, data){
  try{
    var response;
    const res = await put(vehicleRewamp +"/api/v1/session/updateApprovalStatus/"+id , data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/view/chartsJournyTrend -> POST
export async function getChartsJournyTrendRewamp(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/view/chartsJournyTrend", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/view/chartsRunningKms2 -> POST
export async function getChartsRunningKms2Rewamp(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/view/chartsRunningKms2", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/view/chartsRunningKms -> POST
export async function getChartsRunningKmsRewamp(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/view/chartsRunningKms", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/view/chartsJourneyStatus
export async function getChartsJourneyStatusRewamp(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/view/chartsJourneyStatus", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/session -> POST register new session
export async function registerNewSessionRewamp(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/session", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  } catch (err) {
    return false;
  }
}

// vehiclerw/api/v1/session/approveOrRejectAutomaticSession/{sessionId}/{isApproved}
export async function approveOrRejectAutomaticSessionRewamp(sessionId, isApproved, data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/session/approveOrRejectAutomaticSession/"+sessionId+"/"+isApproved, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  } catch (err) {
    return false;
  }
}

// /api/v1/session/endSession/{id} -> PUT close sessions
export async function endSessionRewamp(id, data){
  try{
    var response;
    const res = await put(vehicleRewamp +"/api/v1/session/endSession/"+id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/journey/startProcess -> POST new journey
export async function startNewJourneyRewamp(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/journey/startProcess", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/journey/endProcess/{taskId} -> PUT
export async function endJourneyRewamp(id, data){
  try{
    var response;
    const res = await put(vehicleRewamp +"/api/v1/journey/endProcess/"+id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/session/endSession/{id}
export async function endSessionRewampAPI(id, data){
  try{
    var response;
    const res = await put(vehicleRewamp +"/api/v1/session/endSession/"+id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/vehicleMaster -> GET
export async function getNewVehicleMaster(){
  try{
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vehicleMaster");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/vehicleMaster -> POST
export async function registerNewVehicleMaster(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/vehicleMaster", data);
    console.log(res);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }   
  catch (err) {
    console.log(err);
    return false;
  }
}

// /api/v1/vehicleMaster -> PUT
export async function updateVehicleMaster(vehicleId, newData) {
  try {
    var response;
    const res = await put(vehicleRewamp +"/api/v1/vehicleMaster/"+vehicleId, newData);
    if (res.status === 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  } catch (err) {
    return false;
  }
}
// }/api/v1/
export async function registerMasterRateCard(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/masterRateCard/create", data);
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
    return response;
    }
  }   
  catch (err) {
    console.log(err);
    return false;
  }
}

//  /api/v1/masterRateCard/getById/1
export async function registerMasterRateCardByID(id){
  try{
    var response;
    const res = await get(vehicleRewamp +"/api/v1/masterRateCard/getById/"+id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }   
  catch (err) {
    console.log(err);
    return false;
  }
}

// api/v1/masterRateCard/getAll
export async function getAllMasterRateCardRevamp(){
  try{
    var response;
    const res = await get(vehicleRewamp +"/api/v1/masterRateCard/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }   
  catch (err) {
    console.log(err);
    return false;
  }
}

// /api/v1/masterRateCard/update/1
export async function updateMasterRateCardRevamp(id, data){
  try{
    var response;
    const res = await put(vehicleRewamp +"/api/v1/masterRateCard/update/"+id, data);
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}


// /api/v1/request-category
export async function getAllRequestCategoryREWAMP(){
  try{
    var response;
    const res = await get(accessrw +"/api/v1/request-category");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}



// /api/v1/sub-request-type
export async function getAllSubRequestTypeREWAMP(){
  try{
    var response;
    const res = await get(accessrw +"/api/v1/sub-request-type");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// /api/v1/auth/group/handlers
export async function getAllGroupHandlersREWAMP(){
  try{
    var response;
    const res = await get(hierarchy +"/api/v1/auth/group/handlers");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// https://dnsuatapi.eimsky.com/common/site/GetSitesDetailsBySiteId/PU0215
export async function getSiteDetailsBySiteIDREWAMP(siteID){
  try{
    var response;
    const res = await post(serviceCommon +"/site/GetSitesDetailsBySiteId/"+siteID);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// http://34.28.76.89:8762/accessrw/api/v1/edit-history/site-access-request/154
export async function getEditHistorySiteAccessRequestREWAMP(id) {
  try {
    var response;
    const res = await get(accessrw + "/api/v1/edit-history/site-access-request/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// api/v1/auth/blacklist
export async function getAllBlackListDataREWAMP(){
  try{
    var response;
    const res = await get(accessrw +"/api/v1/auth/blacklist");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  } catch (err) {
    return false;
  }
}

// // api/v1/auth/blacklist (post)
export async function addBlackListDataREWAMP(data){
  try{
    var response;
    const res = await post(accessrw +"/api/v1/auth/blacklist", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  } catch (err) {
    return false;
  }
}

// api/v1/auth/blacklist/{id} (delete)
export async function deleteBlackListDataREWAMP(id){
  try{
    var response;
    const res = await put(accessrw +"/api/v1/auth/blacklist/"+id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  } catch (err) {
    return false;
  }
}


// https://dns.devapi.eimsky.com/pisService/civilRoutineService/getCrChecklistComponents
export async function getCrChecklistComponentsREWAMP(){
  try{
    var response;
    const res = await get(pisService +"/civilRoutineService/getCrChecklistComponents");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// https://dns.devapi.eimsky.com/pisService/civilRoutineService/getKeyValueDetailsByIdAndComponentId
export async function getKeyValueDetailsByIdAndComponentIdREWAMP(data){
  try{
    var response;
    const res = await post(pisService +"/civilRoutineService/getKeyValueDetailsByIdAndComponentId", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// http://34.28.76.89:8762/accessrw/api/v1/edit-history/11
export async function getEditHistorySiteAccessRequestByIDREWAMP(id) {
  try {
    var response;
    const res = await get(accessrw + "/api/v1/edit-history/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// http://34.28.76.89:8762/accessrw/api/v1/siteAccessRequest/bulk
export async function bulkSiteAccessRequestREWAMP(data){
  try{
    var response;
    console.log('EXECUTE THAT FUNCTION - 02');
    const res = await post(accessrw +"/api/v1/siteAccessRequest/bulk", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// /api/v1/siteAccessRequest/site-access-requests/all/1
export async function getAllSiteAccessRequestsDataREWAMP(pageNo){
  try{
    var response;
    const res = await get(accessrw +"/api/v1/siteAccessRequest/site-access-requests/all/"+pageNo);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// /api/v1/siteAccessRequest/filtered-depot-users/{size}/{page}
export async function getFilteredDepotUsersDataREWAMP(size, page, data){
  try{
    var response;
    const res = await post(accessrw +"/api/v1/siteAccessRequest/filtered-depot-users/"+size+"/"+page, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// api/v1/siteAccessRequest/requireSiteOwnerApproval/92
export async function requireSiteOwnerApprovalREWAMP(id, data){
  try{
    var response;
    const res = await put(accessrw +"/api/v1/siteAccessRequest/requireSiteOwnerApproval/"+id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  } catch (err) {
    return false;
  }
} 

// /api/v1/siteAccessRequest/filtered/1
export async function getFilteredSiteAccessRequestsDataREWAMP(pageNo, data){
  try{
    var response;
    const res = await post(accessrw +"/api/v1/siteAccessRequest/filtered/web/"+pageNo, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    } return response;
  } catch (err) {
    return false;
  }
}

// /api/v1/siteAccessRequest/approveWithOwner/92
export async function approvalWithOwner(id, data) {
  try{
    var response;
    const res = await put(accessrw +"/api/v1/siteAccessRequest/approveWithOwner/"+id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  } catch (err) {
    return false;
  }
}

// /api/v1/auth/siteAccessRequest/rejectWithOwner/1
export async function rejectWithOwner(id, data) {
  try{
    var response;
    const res = await put(accessrw +"/api/v1/siteAccessRequest/rejectWithOwner/"+id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  } catch (err) {
    return false;
  }
}

// /api/v1/siteAccessRequest/approve/1
export async function approveSiteAccessRequestREWAMP(id, data){
  try{
    var response;
    const res = await put(accessrw +"/api/v1/siteAccessRequest/approve/"+id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  } catch (err) {
    return false;
  }
}

// /api/v1/access-need
// api/v1/request-category
// api/v1/sub-request-type
// api/v1/visiting-purpose
// api/v1/work-area
// api/v1/work-type
export async function getAllAccessMasterData(id){
  try{
    let response;
    let res;
    switch(id) {
      case 'access-need':
        res = await get(accessrw + "/api/v1/access-need");
        break;
      case 'request-category':
        res = await get(accessrw + "/api/v1/request-category");
        break;
      case 'sub-request-type':
        res = await get(accessrw + "/api/v1/sub-request-type");
        break;
      case 'visiting-purpose':
        res = await get(accessrw + "/api/v1/visiting-purpose");
        break;
      case 'work-area':
        res = await get(accessrw + "/api/v1/work-area");
        break;
      case 'work-type':
        res = await get(accessrw + "/api/v1/work-type");
        break;
      default:
        throw new Error(`Invalid id: ${id}`);
    }
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  } catch (error) {
    return false;
  }
}

export async function insetMasterDataAccess(id, inputJson){
  try{
    let response;
    let res;
    switch(id) {
      case 'access-need':
        res = await post(accessrw + "/api/v1/access-need", inputJson);
        break;
      case 'request-category':
        res = await post(accessrw + "/api/v1/request-category", inputJson);
        break;
      case 'sub-request-type':
        res = await post(accessrw + "/api/v1/sub-request-type", inputJson);
        break;
      case 'visiting-purpose':
        res = await post(accessrw + "/api/v1/visiting-purpose", inputJson);
        break;
      case 'work-area':
        res = await post(accessrw + "/api/v1/work-area", inputJson);
        break;
      case 'work-type':
        res = await post(accessrw + "/api/v1/work-type", inputJson);
        break;
      default:
        throw new Error(`Invalid id: ${id}`);
    }
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  } catch (error) {
    return false;
  }
}

// /api/v1/siteAccessRequest/site-access-requests/status-counts
// /api/v1/siteAccessRequest/site-access-requests/status-counts-handler
// /api/v1/siteAccessRequest/site-access-requests/approval-counts
// /api/v1/siteAccessRequest/site-access-requests/approval-counts-handler

export async function getSiteAccessRequestStatusCountsREWAMPFunc(type){
  try{
    let response;
    let res;
    switch(type) {
      case 'status-counts':
        res = await get(accessrw + "/api/v1/siteAccessRequest/site-access-requests/status-counts");
        break;
      case 'status-counts-handler':
        res = await get(accessrw + "/api/v1/siteAccessRequest/site-access-requests/status-counts-handler");
        break;
      case 'approval-counts':
        res = await get(accessrw + "/api/v1/siteAccessRequest/site-access-requests/approval-counts");
        break;
      case 'approval-counts-handler':
        res = await get(accessrw + "/api/v1/siteAccessRequest/site-access-requests/approval-counts-handler");
        break;
      default:
        throw new Error(`Invalid id: ${type}`);
    }
    console.log(res);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  } catch (error) {
    return false;
  }
}

// /api/v1/auth/user/handler-write-permission
// /api/v1/auth/user/requester-write-permission
export async function getUserHandlerWritePermissionREWAMP(type){
  try{
    var response;
    let res;
    switch(type) {
      case 'handler':
        res = await get(hierarchy + "/api/v1/auth/user/handler-write-permission");
        break;
      case 'requester':
        res = await get(hierarchy + "/api/v1/auth/user/initiator-write-permission");
        break;
      default:
        throw new Error(`Invalid id: ${type}`);
    }
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  } catch (error) {
    return false;
  }
}

// /api/v1/auth/user/access-viewer-role
export async function getUserAccessViewerRoleREWAMP(){
  try{
    var response;
    const res = await get(hierarchy + "/api/v1/auth/user/access-viewer-role");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  } catch (error) {
    return false;
  }
}

// /api/v1/siteAccessRequest/bulk-dtos
export async function bulkSiteAccessRequestDTOsREWAMPForRewamp(){
  try{
    var response;
    const res = await get(accessrw + "/api/v1/siteAccessRequest/bulk-dtos");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  } catch (error) {
    return false;
  }
}

// /api/v1/siteAccessRequest/approveMultiple
export async function approveMultipleSiteAccessRequestREWAMP(data) {
  try {
    const res = await put(`${accessrw}/api/v1/siteAccessRequest/approveMultiple`, data);
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
      };
    } else {
      return {
        success: false,
        data: res.data,
      };
    }
  } catch (err) {
    return {
      success: false,
      message: err.message || 'An error occurred',
    };
  }
}

export async function rejectMultipleSiteAccessRequestREWAMP(data) {
  try {
    const res = await put(`${accessrw}/api/v1/siteAccessRequest/rejectMultiple`, data);
    if (res.status === 200) {
      return {
        success: true,
        data: res.data,
      };
    } else {
      return {
        success: false,
        data: res.data,
      };
    }
  } catch (err) {
    return {
      success: false,
      message: err.message || 'An error occurred',
    };
  }
}

export async function insertMasterDataAccess(id, inputJson, hierarchy) {
  if (!inputJson || typeof inputJson !== 'object') {
    return { success: false, data: 'Invalid input JSON' };
  }

  try {
    let endpoint;
    switch (id) {
      case 'role':
        endpoint = `${hierarchy}/api/v1/auth/role`;
        break;
      case 'group':
        endpoint = `${hierarchy}/api/v1/auth/group`;
        break;
      default:
        throw new Error(`Invalid id: ${id}`);
    }

    const res = await post(endpoint, inputJson);

    return {
      success: res.status === 200,
      data: res.data,
    };
  } catch (error) {
    console.error(error); // Log the error for debugging purposes
    return {
      success: false,
      data: error.message,
    };
  }
}
// GET (all):
// /api/v1/auth/role

// GET (all):
// api/v1/auth/group

export async function getAllRoleDataAccessFunction(id){
  try{
    let response;
    let res;
    switch(id) {
      case 'role':
        res = await get(hierarchy + "/api/v1/auth/role");
        break;
      case 'group':
        res = await get(hierarchy + "/api/v1/auth/group");
        break;
      default:
        throw new Error(`Invalid id: ${id}`);
    }
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  } catch (error) {
    return false;
  }
}


export async function updateMasterDataAccess(id, recordId, data){
  try{
    let response;
    let res;
    switch(id) {
      case 'access-need':
        res = await put(accessrw + "/api/v1/access-need/" + recordId, data);
        break;
      case 'request-category':
        res = await put(accessrw + "/api/v1/request-category/" + recordId, data);
        break;
      case 'sub-request-type':
        res = await put(accessrw + "/api/v1/sub-request-type/" + recordId, data);
        break;
      case 'visiting-purpose':
        res = await put(accessrw + "/api/v1/visiting-purpose/" + recordId, data);
        break;
      case 'work-area':
        res = await put(accessrw + "/api/v1/work-area/" + recordId, data);
        break;
      case 'work-type':
        res = await put(accessrw + "/api/v1/work-type/" + recordId, data);
        break;
      default:
        throw new Error(`Invalid id: ${id}`);
    }
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  } catch (error) {
    return false;
  }
}

export async function deleteMasterDataAccess(id, recordId){
  console.log(id, recordId);
  try{
    let response;
    let res;
    switch(id) {
      case 'access-need':
        res = await put(accessrw + "/api/v1/access-need/delete/" + recordId);
        break;
      case 'request-category':
        res = await put(accessrw + "/api/v1/request-category/delete/" + recordId);
        break;
      case 'sub-request-type':
        res = await put(accessrw + "/api/v1/sub-request-type/delete/" + recordId);
        break;
      case 'visiting-purpose':
        res = await put(accessrw + "/api/v1/visiting-purpose/delete/" + recordId);
        break;
      case 'work-area':
        res = await put(accessrw + "/api/v1/work-area/delete/" + recordId);
        break;
      case 'work-type':
        res = await put(accessrw + "/api/v1/work-type/delete/" + recordId);
        break;
      default:
        throw new Error(`Invalid id: ${id}`);
    }
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    }
    return response;
  } catch (error) {
    return false;
  }
}

//https://dns.devapi.eimsky.com/accessrw/api/v1/report/accessFullExport
export async function getAccessTeamExportDataREWAMP(data){
  try{
    var response;
    const res = await post(accessrw +"/api/v1/report/accessTeamExport", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    } return response;
  } catch (err) {
    return false;
  }
}

// https://dns.devapi.eimsky.com/accessrw/api/v1/report/accessFullExport
export async function getAccessFullExportDataREWAMP(data){
  try{
    var response;
    const res = await post(accessrw +"/api/v1/report/accessFullExport", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    } return response;
  } catch (err) {
    return false;
  }
}




// https://dns.devapi.eimsky.com/accessrw/api/v1/dashboard/charts
export async function getDashboardChartsDataREWAMP(data) {
  try {
    var response;
    const res = await post(accessrw + "/api/v1/dashboard/charts", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      }
    } return response;
  } catch (err) { 
    return false;
  }
}

// /api/v1/siteAccessRequest/reject/1
export async function rejectSiteAccessRequestREWAMP(id, data){
  try{
    var response;
    const res = await put(accessrw +"/api/v1/siteAccessRequest/reject/"+id, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  } catch (err) {
    return false;
  }
}
// /api/v1/siteAccessRequest/cancel/1
export async function cancelSiteAccessRequestREWAMP(id){
  try{
    var response;
    const res = await put(accessrw +"/api/v1/siteAccessRequest/cancel/"+id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  } catch (err) {
    return false;
  }
}

// api/v1/work-area
export async function getAllWorkAreaREWAMP(){
  try{
    var response;
    const res = await get(accessrw +"/api/v1/work-area");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// api/v1/access-need
export async function getAllAccessNeedREWAMP(){
  try{
    var response;
    const res = await get(accessrw +"/api/v1/access-need");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}
// api/v1/work-type
export async function getAllWorkTypeREWAMP(){
  try{
    var response;
    const res = await get(accessrw +"/api/v1/work-type");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// api/v1/visiting-purpose
export async function getAllVisitingPurposeREWAMP(){
  try{
    var response;
    const res = await get(accessrw +"/api/v1/visiting-purpose");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// /api/v1/siteAccessRequest/site-access-requests/status-counts
export async function getSiteAccessRequestStatusCountsREWAMP(){
  try{
    var response;
    const res = await get(accessrw +"/api/v1/siteAccessRequest/site-access-requests/status-counts");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

export async function getSiteAccessRequestByIDREWAMP(id, type){
  try{
    var response;
    let res = null;
    if (type !== 'BULK') {
      res = await get(accessrw +"/api/v1/siteAccessRequest/"+id);
    } else {
      res = await get(accessrw +"/api/v1/siteAccessRequest/bulk/"+id);
    }
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

export async function updateSiteAccessRequestStatusREWAMP(id, data, type){
  try{
    var response;
    let res = null;
    if (type !== 'BULK') {
      res = await put(accessrw +"/api/v1/siteAccessRequest/"+id, data);
    } else {
      res = await put(accessrw +"/api/v1/siteAccessRequest/bulk/"+id, data);
    }
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  }
  catch (err) {
    return false;
  }
}

// /api/v1/vehicleMaster -> DELETE
// /api/v1/vehicleMaster/delete/{id}
export async function deleteVehicleMaster(vehicleId) {
  try {
    var response;
    const res = await put(vehicleRewamp +"/api/v1/vehicleMaster/delete/"+vehicleId);
    if (res.status === 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getVehicleMasterByID(vehicleId) {
  try {
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vehicleMaster/"+vehicleId);
    if (res.status === 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/operatingModel/getAll
  export async function getAllOperatingModel(){
    try{
      var response;
      const res = await get(vehicleRewamp +"/api/v1/operatingModel/getAll");
      if (res.status == 200) {
        response = {
          success: true,
          data: res.data,
        };
        return response;
      }
    } catch (err) {
      return false;
    }
  }

// /api/v1/auth/users
export async function getAllUsersAuth(){
  try{
    var response;
    const res = await get(hierarchy +"/api/v1/auth/users");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/reports/vehicleFullExport
export async function getVehicleListFullExport(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/reports/vehicleFullExport", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/reports/sessionFullExport
export async function getSessionFullExport(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/reports/sessionFullExport", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/reports/vehicleSummaryReport
export async function getVehicleSummaryReport(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/reports/vehicleSummaryReport", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/reports/monthlySummaryReport
export async function getMonthlySummaryReport(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/reports/monthlySummaryReport", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/vehicle/null-master-id
export async function getVehicleWithOutMasterID(){
  try{
    var response;
    const res = await get(vehicleRewamp +"/api/v1/vehicle/null-master-id");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// }/api/v1/poolRateCard/create
export async function createPoolRateCard(data){
  try{
    var response;
    const res = await post(vehicleRewamp +"/api/v1/poolRateCard/create", data);
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
    console.log(response);
    return response;
    }
  }
  catch (err) {
    return false;
  }
}

// /api/v1/poolRateCard/getAll
export async function getAllPoolRateCard(){
  try{
    var response;
    const res = await get(vehicleRewamp +"/api/v1/poolRateCard/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    return response;
    }
  }
  catch (err) {
    return false;
  }
}

export async function getAllPoolRateCardByID(id){
  try{
    var response;
    const res = await get(vehicleRewamp +"/api/v1/poolRateCard/getById/"+id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    return response;
    }
  }
  catch (err) {
    return false;
  }
}

// /api/v1/poolRateCard/update/1
export async function updatePoolRateCard(id, data){
  try{
    var response;
    const res = await put(vehicleRewamp +"/api/v1/poolRateCard/update/"+id, data);
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
    return response;
    }
  }
  catch (err) {
    return false;
  }
}

// new dashboard API's list 
// /pis-service/commonServiceRequest/completionVsPending
//routing-service/dashboard/statusCount

export async function pisCompletionVsPending() {
  try {
    var response;
    const res = await post(pisService + "/commonServiceRequest/completionVsPending");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function routingServiceStatusCount(){
  try {
    var response;
    const res = await post(serviceRouting + "/dashboard/statusCount");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/journey/driver/{driverId}/ongoing -> get
export async function getOngoingJourneyByDriverID(id){
  try {
    var response;
    const res = await get(vehicleRewamp +"/api/v1/journey/driver/"+id+"/ongoing");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// /api/v1/siteAccessRequest/new-bulk-filtered/{size}/{page}
export async function getNewBulkFilteredSiteAccessRequest(size, page, data){
  try {
    var response;
    const res = await post(accessrw +"/api/v1/siteAccessRequest/new-bulk-filtered/"+size+"/"+page, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } 
  } catch (err) {
    return false;
  }
}

// /api/v1/siteAccessRequest/new-bulk-filtered-requester/{size}/{page}
export async function getNewBulkFilteredSiteAccessRequestByRequester(size, page, data){
  try {
    var response;
    const res = await post(accessrw +"/api/v1/siteAccessRequest/new-bulk-filtered-requester/"+size+"/"+page, data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

// try {
//   var response;
//   const res = await put(vehicleRewamp +"/api/v1/vehicleMaster/"+vehicleId, newData);
//   if (res.status === 200) {
//     response = {
//       success: true,
//       data: res.data,
//     };
//   } else {
//     response = {
//       success: false,
//       data: res.data,
//     };
//   }
//   return response;
// } catch (err) {
//   return false;
// }

// /api/v1/auth/driversWithVehicleMasters
export async function getDriversWithVehicleMastersAPI() {
  try {
    var response;
    const res = await get(hierarchy + "/api/v1/auth/driversWithVehicleMasters");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
    } else {
      response = {
        success: false,
        data: res.data,
      };
    }
    return response;
  } catch (err) {
    return false;
  }
}





export async function pgStatusCount(data) {
  try {
    //console.log(data);
    var response;
    const res = await post(
      serviceOne + "/dashboard/statusCount", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
        data:res.data
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function MapPgRequest() {
  try {
    var response;
    const res = await post(
      serviceOne + "/dashboard/mapPgRequest",
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function StatusTypesCount(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };

    const res = await post(
      serviceOne + "/dashboard/statusTypesCount", data, headers)

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function RefuelChartRequest(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };

    const res = await post(
      refuelserviceOne + "/Dashboard/chart", data, headers)

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function RefuelStatusTypesCount(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };

    const res = await post(
      refuelserviceOne + "/Dashboard/statusTypesCount", data, headers)

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

// http://localhost:8801/api/v1/siteAccessRequest/siteAccessRequests/34/download
export async function downloadSiteAccessRequest(id) { 
    const res = accessrw + "/api/v1/siteAccessRequest/pdf/" + id + "/download"; 
    return baseUrl+res;
  }

  export async function getFilteredSiteAccessRequestsDataByRequesterREWAMP(pageNo, data){
    try{
      var response;
      const res = await post(accessrw +"/api/v1/siteAccessRequest/filtered/requester/"+pageNo, data);
      if (res.status == 200) {
        response = {
          success: true,
          data: res.data,
        };
        return response;
      }
    } catch (err) {
      return false;
    }
  }

export async function getSitesWithFilter(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    }
    if (cancel !== undefined) {
      //console.log("cancelAbrot");
      cancel();
    }
    const res = await post(serviceCommon + "/site/getSitesWithFilter", data, headers, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });

    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function GetCostByDate(data) {

  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),

    }
    if (cancel !== undefined) {
      //console.log("cancelAbrot");
      cancel();
    }

    const res = await post(serviceFinance + "/pgPayment/getCostByDate", data, headers, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });

    if (res.status == 200) {
      //globaAbortController.abort();
      // globaAbortController=undefined;
      response = {

        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }

}

export async function DayWeekMonthFilter(data) {
  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await post(serviceOne + "/dashboard/dayWeekMonthFilter", data, headers);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function PgSLABreachPercentage(data) {
  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await post(serviceOne + "/dashboard/pgSLABreachPercentage", data, headers);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function GetCostByParentRegion(data) {
  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await post(serviceFinance + "/pgPayment/getCostByParentRegion", data, headers);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function GetCostByDepots(data) {
  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await post(serviceFinance + "/pgPayment/getCostByDepots", data, headers);


    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
        data: res.data,

      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function PgFullCountByTime(data) {
  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await post(serviceOne + "/dashboard/pgFullCountByTime", data, headers);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function PgCountByStatusAndRegion(data) {
  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await post(serviceOne + "/dashboard/pgCountByStatusAndRegion", data, headers);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function PgCountByStatusAndDepots(data) {
  try {
    var response;

    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await post(serviceOne + "/dashboard/pgCountByStatusAndDepots", data, headers);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function PgChartRequest(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };

    const res = await post(
      serviceOne + "/dashboard/chart", data, headers)

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function getRoutingDataExport(data) {
  try {

    var response;
    const res = await post(serviceRouting + "/dashboard/export", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getCkecklist() {
  try {

    var response;
    const res = await get(
      serviceRouting + "/dashboard/getCkecklist");
    //console.log(res)
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getVehicleData() {
  try {

    var response;
    const res = await get(
      serviceVehicle + "/vehicleData/allList");
    //console.log(res)
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
// https://dnsliveapi.eimsky.com/vehiclerw/api/v1/view/dashboardCounts
export async function VehicleDashboardCounts2(data) {
  try {
    var response;
    const res = await post(vehicleRewamp + "/api/v1/view/dashboardCounts", data
    );
    console.log(res)
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }


    if (res.status == 400) {
      response = {
        success: false,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    //console.log(err);
    return false;

  }
}
export async function VehicleDashboardCounts(data) {
  try {

    var response;
    const res = await post(serviceVehicle + "/Dashboard/dashboardCounts", data
    );
    //console.log(res)
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }


    if (res.status == 400) {
      response = {
        success: false,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    //console.log(err);
    return false;

  }
}
export async function SiteAccessDashboardCounts(data) {
  try {

    var response;
    const res = await post(siteAccessService + "/dashboard/dashboardCounts", data
    );
    //console.log(res)
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }


    if (res.status == 400) {
      response = {
        success: false,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    //console.log(err);
    return false;

  }
}

export async function GenDashboard(data) {
  try {

    var response;
    const res = await post(serviceCommon + "/dashboard/", data
    );
    //console.log(res)
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }


    if (res.status == 400) {
      response = {
        success: false,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    //console.log(err);
    return false;

  }
}
export async function createVehicleMaster(data) {
  try {

    var response;
    const res = await post(serviceVehicle + "/VehicleManagement/createVehicleMaster", data
    );
    //console.log(res)
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }


    if (res.status == 400) {
      response = {
        success: false,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    //console.log(err);
    return false;

  }
}
export async function createVehicleDetail(data) {
  try {

    var response;
    const res = await post(serviceVehicle + "/VehicleManagement/createVehicleDetail", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } if (res.status == 400) {
      response = {
        success: false,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getFilterUser(data) {
  try {

    var headers = {
      'Content-Type': 'application/json',
    };
    if (cancel !== undefined) {
      //console.log("cancelAbrot");
      cancel();
    }

    var response;

    //console.log(data);
    const res = await post(serviceVehicle + "/VehicleManagement/filterUser", data, headers, {
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getUnAsingeDrivers(data) {
  try {

    var headers = {
      'Content-Type': 'application/json',
    };
    var response;

    //console.log(data);
    const res = await post(serviceVehicle + "/VehicleManagement/getUnAsingeDrivers", data, headers);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getUnAsingeVehicleDetails(data) {
  try {

    var headers = {
      'Content-Type': 'application/json',
    };
    var response;

    //console.log(data);
    const res = await post(serviceVehicle + "/VehicleManagement/getUnAsingeVehicleDetails", data, headers);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getVehicleMasterById(data) {
  try {

    var headers = {
      'Content-Type': 'application/json',
    };
    var response;

    //console.log(data);
    const res = await post(serviceVehicle + "/VehicleManagement/getVehicleMasterById", data, headers);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getVehicleDetailById(data) {
  try {

    var headers = {
      'Content-Type': 'application/json',
    };
    var response;

    //console.log(data);
    const res = await post(serviceVehicle + "/VehicleManagement/getVehicleDetailById", data, headers);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function editVehicleMaster(data) {
  try {

    var response;
    const res = await post(serviceVehicle + "/VehicleManagement/editVehicleMaster", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function editVehicleDetail(data) {
  try {

    var response;
    const res = await post(serviceVehicle + "/VehicleManagement/editVehicleDetail", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getVehicleMaster(data) {
  try {

    var response;
    const res = await post(serviceVehicle + "/VehicleManagement/getVehicleMaster", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAllVehicleDetails(data) {
  try {

    var response;
    const res = await post(serviceVehicle + "/VehicleManagement/getVehicleDetails", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getVehicleFullExport(data) {
  try {

    var response;
    const res = await post(serviceVehicle + "/Dashboard/VehicleFullExport", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getVehiclePaymentSheet(data) {
  try {

    var response;
    const res = await post(serviceVehicle + "/Dashboard/PaymentSheet", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getSitesByuser() {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await get(refuelserviceOne + "/user/getSitesByuser");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getUserSites() {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await get(siteAccessService + "/data/getUserSites");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getHandlerSites() {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await get(siteAccessService + "/data/getHandlerSites");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getGroupBySite(data) {
  try {

    var response;
    const res = await post(siteAccessService + "/data/getGroupBySite", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getHandlersBySite(data) {
  try {

    var response;
    const res = await post(siteAccessService + "/data/getHandlersBySite", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getAllActiveUsers() {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await get(serviceUser + "/user/getAllActiveUsers");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getSiteAccessBaseData() {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await get(siteAccessService + "/data/getBaseData");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function siteAccessCreate(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/siteAccess/create", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getUserJobRequests(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/view/userJobRequests", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function viewerAllRequests(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/view/viewerAllRequests", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getUserAllRequests(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/view/userAllRequests", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getStatusSummary(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/flow/statusSummary", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getlogByStatusId(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/flow/logByStatusId", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAccessPermitionRequestById(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/view/getAccessPermitionRequestById", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function gethandlerJobRequests(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/view/handlerJobRequests", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function gethandlerAllRequests(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/view/handlerAllRequests", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function downloadAccessReqDocument(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/siteAccess/downloadAccessReqDocument", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function genareteAccessReqDocument11(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await get(siteAccessService + "/siteAccess/genareteAccessReqDocument/" + data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function genareteAccessReqDocument(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await get2(siteAccessService + "/siteAccess/genareteAccessReqDocument/" + data);
    var link = baseUrl + siteAccessService + "/siteAccess/genareteAccessReqDocument/" + data;

    //window.location.href = link;
    //  window.open(link, "_blank");

    if (res.status == 200) {

      const blob = new Blob(
        [res.data],
        { type: 'application/pdf' }
      )
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function requestSendToSite(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/flow/requestSendToSite", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAccessRestrictions(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/data/getAccessRestrictions", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function accessApprovedFromSite(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/flow/accessApprovedFromSite", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function accessRejectFromSite(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/flow/accessRejectFromSite", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function requestRejectByHandler(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/flow/requestRejectByHandler", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function siteAccessEdit(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/siteAccess/edit", data, headers
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAllSiteType() {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await get(serviceCommon + "/siteType/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAllEnergyCategory() {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await get(serviceCommon + "/energyCategory/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAllSiteCategory() {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await get(serviceCommon + "/siteCategory/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAllSiteOwner() {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await get(serviceCommon + "/siteOwner/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAllEntity() {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await get(serviceCommon + "/entity/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAllTowerCategory() {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await get(serviceCommon + "/towerCategory/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getAllGenHutType() {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await get(serviceCommon + "/genHutType/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getAllTowerType() {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await get(serviceCommon + "/towerType/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getAllCoolingMethods() {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await get(serviceCommon + "/coolingMethods/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updateSite(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/site/updateSite", data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function createSite(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/site/createSite", data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getAllDistrict() {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await get(serviceCommon + "/district/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getMasterDataSiteInfo(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/base/getMasterData");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getAllAtsMakes(){
  try {
    var response;
    const res = await get(serviceCommon + "/atsMake/getAll");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getGeneratorMasterData(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/Generator/GetMasterData");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getUpdatedAppVersion() {
  try {
    var response;
    const res = await get(serviceCommon + "/appVersion/getUpdatedAppVersion");

    console.log('resres')
    console.log(res)
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getSiteDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/site/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateCabinet(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/cabinet/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateAppVersions(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/appVersion/updateAppVersions", data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateSpd(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/spd/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateBatteryRack(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/batteryRack/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateDcdu(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/dcdu/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateRectifier(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/rectifier/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateBatteryBank(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/batteryBank/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateReWind(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/ReWind/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateReSoler(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/ReSoler/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateSharedOperator(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/sharedOperator/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateFcb(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/fcb/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateAts(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/ats/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function UpdateAirConditioner(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/airConditioner/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function Updategenerator(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/Generator/update", data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function Createcreatebulcktank(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/Generator/createBulckTank", data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function Creategenerator(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/Generator/createGenerator", data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getCabinetDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/cabinet/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getBatteryBankDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/batteryBank/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getSpdDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/spd/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getReWindDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/ReWind/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getReSolerDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/ReSoler/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getsharedOperatorDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/sharedOperator/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getFcbDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/fcb/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getBatteryRack(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/batteryRack/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getDcduDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/dcdu/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getRectifierDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/rectifier/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAtsDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/ats/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAirConditionerDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/airConditioner/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getGeneratorDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/Generator/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getGeneratorBySiteId(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/Generator/getGeneratorBySiteId", data, headers
    );
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updateBulckTank(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/Generator/updateBulckTank", data, headers
    );
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function getBulckTankById(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/Generator/getBulckTankById", data, headers
    );
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getBulckTankBySiteId(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/Generator/getBulckTankBySiteId", data, headers
    );
    console.log(res);
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getGeneratorModel() {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };


    const res = await get(serviceCommon + "/generatorModel/getAll", headers
    );
    //console.log(" sdsdsdsds1221")
    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getBatteryBankMake() {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };


    const res = await get(serviceCommon + "/batteryBankMake/getAll", headers
    );
    //console.log(" sdsdsdsds1221")
    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getRectifierMake() {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };


    const res = await get(serviceCommon + "/rectifierMake/getAll", headers
    );
    //console.log(" sdsdsdsds1221")
    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getPowerDetailsBySite(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };


    const res = await post(serviceCommon + "/powerSiteData/getPowerDetailsBySite", data, headers
    );
    //console.log(" sdsdsdsds1221")
    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getUploadedPowerDataSheetDetails(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };


    const res = await post(serviceCommon + "/powerSiteData/getUploadedPowerDataSheetDetails", data, headers
    );
    //console.log(" sdsdsdsds1221")
    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getPowerData(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };


    const res = await post(serviceCommon + "/powerSiteData/getDate", data, headers
    );
    //console.log(" sdsdsdsds1221")
    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getPowerBatteryBankDetails(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/powerSiteData/getPowerBatteryBankDetails", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getPowerRectifierDetails(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/powerSiteData/getPowerRectifierDetails", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAirconditionersData(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/powerSiteData/getAirconditionersData", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getPowerSpdsDetails(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/powerSiteData/getPowerSpdsDetails", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getPowerSharedOperatorDetails(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/powerSiteData/getPowerSharedOperatorDetails", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getPowerFcbDetails(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/powerSiteData/getPowerFcbDetails", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getPowerDcduDetails(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/powerSiteData/getPowerDcduDetails", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAtsData(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/powerSiteData/getAtsData", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getGeneratorsData(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/powerSiteData/getGeneratorsData", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getPowerCabinetDetails(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/powerSiteData/getPowerCabinetDetails", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getHistoryBySiteIdAndsubComId(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(imageCapturingService + "/dashboard/getHistoryBySiteIdAndsubComId", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }

}
export async function getLastestImgBySiteId(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(imageCapturingService + "/dashboard/getLastestImgBySiteId", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }

}

export async function getLastThreeRequestDetails(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(imageCapturingService + "/request/getLastThreeRequestDetails", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }

}
export async function getHistoryBySiteId(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(imageCapturingService + "/dashboard/getHistoryBySiteId", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
    throw err;
  }
}
export async function getImageCapturUserAll(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(imageCapturingService + "/dashboard/getUserAll", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
    throw err;
  }
}
export async function getLastBySiteId(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(imageCapturingService + "/dashboard/getLastBySiteId", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
    throw err;
  }
}
export async function getDetailsByRequestId(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(imageCapturingService + "/dashboard/getDetailsByRequestId", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
    throw err;
  }
}

export async function getsiteAccessExport(data) {
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(siteAccessService + "/dashboard/export", data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function createNewUser(user_data) {
  try {
    var response;
    const res = await post(
      serviceUser + "/user/userRegister",
      user_data
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function editUser(userId, user_data) {
  console.log("Edit");
  try {
    var response;
    const res = await post(
      serviceUser + "/user/userUpdate/" + userId,
      user_data
    );
    console.log("------", res);
    if (res.status === 201 || res.status === 200) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function userActive(data) {
  console.log("userActive");
  try {
    var response;
    const res = await post(
      serviceUser + "/user/userActive",
      data
    );
    console.log("------", res);
    if (res.status === 201 || res.status === 200) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function uploadFile(service, data, url) {
  try {

    var response;
    const res = await post(
      service + url,
      data
    );
    console.log(res)
    if (res.status === 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function uploadPowerdbuFile(data, url) {
  try {

    var response;
    const res = await post(
      serviceCommon + url,
      data
    );
    
    if (res.status === 201) {
      response = {
        success: true,
        data: res,
      };

      return response;
    } 
    
    if (res.status == 400) {
      response = {
        success: false,
        data: res.data,
      };
      return response;
    } else  {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
   
  } catch (err) {
    return false;

  }
}
export async function getPisHistoryData(url, data) {
  console.log(url);
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    // const res = await post(pisService + "/genServiceRequest/getAllPisGenServiceRequests", data, headers
    // );
    const res = await post(pisService + url, data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
    throw err;
  }
}
export async function getPisHistoryGenaralDetailsById(url, data) {
  console.log("url", url);
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(pisService + url, data, headers
    );

    console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
    throw err;
  }
}
export async function getPisHistoryDetailsById(url, data) {
  console.log("url", url);
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(pisService + url, data, headers
    );

    //console.log(res)
    if (res.status == 201 || res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
    throw err;
  }
}


export async function getUploadedScheduledList() {
  //console.log("----------------------");
  try {
    var response;
    var headers = {
      userId: localStorage.getItem("userId"),
    };
    const res = await get(pisService + "/commonServiceRequest/getAllUploadedRequestSheet", headers);
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getPisCivilServiceFullExport(data) {
  try {
    var response;
    const res = await post(serviceFinance + "/pisService/exportPisCivilService", data
    );
    
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getPisACServiceFullExport(data) {
  try {
    var response;
    const res = await post(serviceFinance + "/pisService/exportPisAcService", data
    );
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getPisGenServiceFullExport(data) {
  try {
    var response;
    const res = await post(serviceFinance + "/pisService/exportPisGenService", data
    );
  
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getPisScServiceFullExport(data) {
  try {
    var response;
    const res = await post(serviceFinance + "/pisService/exportPisScService", data
    );
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function getLocationList(data) {
  try {
    var response;
    const res = await post(serviceCommon + "/site/getSiteAddress", data
    );
    
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

//Dashboad
export async function getVehicleMonthlyMillage() {
  try {
    var response;
    const res = await post(serviceVehicle + "/Dashboard/MonthlyMillage");

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getRoutingMonthlyMillage() {
  try {
    var response;
    const res = await post(serviceRouting + "/dashboard/MonthlyCompletion");
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getRefData(){
    try {
        var response;
        const res = await get(serviceRouting + "/data/getReferanceTableValues");

        if (res.status == 200) {
        response = {
            success: true,
            data: res.data,
        };
        return response;
        } else {
        response = {
            success: false,
        };
        return response;
        }
    } catch (err) {
      return false;
    }
}

export async function getPgMonthlyMillage() {
  try {
    var response;
    const res = await post(serviceOne + "/dashboard/MonthlyCost");
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getRefuelMonthlyMillage() {
  try {
    var response;
    const res = await post(refuelserviceOne + "/Dashboard/MonthlyCompletion");
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getDashboardTarget() {

  try {
    var response;
    var headers = {
      // userId: "170",
      userId: localStorage.getItem("userId"),

      // logged_user_email: localStorage.getItem("logged_user_email")
    };
    const res = await get(serviceCommon + "/DashboardTarget/getAll", headers);

    if (res.status == 200) {
      response = {

        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getDashboardTargetUpdate(data) {
  try {
    var response;
    const res = await post(serviceCommon + "/DashboardTarget/update",data);
   
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

///routine RoutineView

export async function getRoutineView(data) {
  try {
    var response;
    const res = await post(serviceRouting + "/view/RoutineView",data);
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

///RoutingVisitById

export async function getRoutingVisitById(data) {
  try {
    var response;
    const res = await post(serviceRouting + "/view/getRoutingVisitById",data);
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

///PIS Genaral Dah
export async function getPisMonthlyTarget() {
  try {
    var response;
    const res = await post(pisService + "/commonServiceRequest/MonthlyTarget");
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

// PG Dashboard
export async function getMonthlyRegionViceCost(data) {
  try {
    var response;
    const res = await post(serviceOne + "/dashboard/MonthlyRegionViceCost",data);
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getDashboardChartDatas(data) {
  try {
    var response;
    const res = await post(serviceOne + "/dashboard/charts",data);
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
// image capture comp


export async function getImageCaptureAllComponents() {
  try {
    var response;
    const res = await get(imageCapturingService + "/request/getAllComponents");
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getMonthlyRegionViceMillage() {
  try {
    var response;
    const res = await post(serviceVehicle + "/Dashboard/MonthlyRegionViceMillage");

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getVehicleDashboardCharts() {
  try {
    var response;
    const res = await post(serviceVehicle + "/Dashboard/charts");

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function getRefuelDashboardCharts(data) {
  try {
    var response;
    const res = await post(refuelserviceOne + "/Dashboard/charts",data);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getRefuelRegionViseCount(data) {
  try {
    var response;
    const res = await post(refuelserviceOne + "/Dashboard/getFuelCount",data);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getRoutingDashboardCompletionCoun(data) {
  try {
    var response;
    const res = await post(serviceRouting + "/dashboard/getMonthCompletionCount", data);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getRoutingDashboardCharts(data) {
  try {
    var response;
    const res = await post(serviceRouting + "/dashboard/charts",data);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getRoutingDashboardCharts2(data) {
  try {
    var response;
    const res = await post(serviceRouting + "/dashboard/charts2",data);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


//PIS Dashboard
export async function getPISRegionViseStatusCount(type,data) {
  try {
    var response;
    var res;
    switch(type) {
      case "GEN":
        res = await post(pisService + "/pisServiceRequest/getRegionWiseGenStatusCount",data);
        break;
      case "AC":
        res = await post(pisService + "/pisServiceRequest/getRegionWiseAcStatusCount",data);
        break;
      case "SC":
        res = await post(pisService + "/pisServiceRequest/getRegionWiseScStatusCount",data);
        break;
      case "CR":
        res = await post(pisService + "/pisServiceRequest/getRegionWiseCrStatusCount",data);
        break;
      default:
        // code block
    }

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

// get PIS genaral-section details
export async function getPisGenaralDetailsById(type, data) {
  try{
    var response;
    var res;
    switch(type) {
      case "GEN":
        res = await post(pisService + "/pisServiceRequest/getPisGenStatusWiseRequestCounts", data);
        break;
      case "AC":
        res = await post(pisService + "/pisServiceRequest/getPisAcStatusWiseRequestCounts", data);
        break;
      case "SC":
        res = await post(pisService + "/pisServiceRequest/getPisScStatusWiseRequestCounts", data);
        break;
      case "CR":
        res = await post(pisService + "/pisServiceRequest/getPisCrStatusWiseRequestCounts", data);
        break;
    }

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    }

    if (res.status == 400) {
      response = {
        success: false,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}


export async function getPISRegionViseTargetCount(type,data) {
  try {
    var response;
    var res;
    switch(type) {
      case "GEN":
        res = await post(pisService + "/pisServiceRequest/getRegionWiseGenTargetCount",data);
        break;
      case "AC":
        res = await post(pisService + "/pisServiceRequest/getRegionWiseAcTargetCount",data);
        break;
      case "SC":
        res = await post(pisService + "/pisServiceRequest/getRegionWiseScTargetCount",data);
        break;
      case "CR":
        res = await post(pisService + "/pisServiceRequest/getRegionWiseCrTargetCount",data);
        break;
      default:
        // code block
    }

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getMonthlyCompletionTrendCount(type,data) {
  try {
    var response;
    var res;
    switch(type) {
      case "GEN":
        res = await post(pisService + "/pisServiceRequest/getMonthlyCompletionTrendGenCount",data);
        break;
      case "AC":
        res = await post(pisService + "/pisServiceRequest/getMonthlyCompletionTrendAcCount",data);
        break;
      case "SC":
        res = await post(pisService + "/pisServiceRequest/getMonthlyCompletionTrendScCount",data);
        break;
      case "CR":
        res = await post(pisService + "/pisServiceRequest/getMonthlyCompletionTrendCrCount",data);
        break;
      default:
        // code block
    }

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getMonthlyWiseStatusCount(type,data) {
  try {
    var response;
    var res;
    switch(type) {
      case "GEN":
        res = await post(pisService + "/pisServiceRequest/getMonthlyWiseGenStatusCount",data);
        break;
      case "AC":
        res = await post(pisService + "/pisServiceRequest/getMonthlyWiseAcStatusCount",data);
        break;
      case "SC":
        res = await post(pisService + "/pisServiceRequest/getMonthlyWiseScStatusCount",data);
        break;
      case "CR":
        res = await post(pisService + "/pisServiceRequest/getMonthlyWiseCrStatusCount",data);
        break;
      default:
        // code block
    }

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getMonthlyTargetVsCompletionTrendCount(type,data) {
  try {
    var response;
    var res;
    switch(type) {
      case "GEN":
        res = await post(pisService + "/pisServiceRequest/getMonthlyTargetVsCompletionTrendGenCount", data);
        break;
      case "AC":
        res = await post(pisService + "/pisServiceRequest/getMonthlyTargetVsCompletionTrendAcCount", data);
        break;
      case "SC":
        res = await post(pisService + "/pisServiceRequest/getMonthlyTargetVsCompletionTrendScCount", data);
        break;
      case "CR":
        res = await post(pisService + "/pisServiceRequest/getMonthlyTargetVsCompletionTrendCrCount", data);
        break;
      default:
        // code block
    }

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function completionVsPendingChart (data) {
  try {
    var response;
    var res = await post(pisService + "/commonServiceRequest/completionVsPending", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
// /routing-service/dashboard/statusCount
export async function routingDashCount (data) {
  try {
    var response;
    var res = await post(serviceRouting + "/dashboard/statusCount", data);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getAllSubRegionsDepotCreate() {
  try {
    var response;
    const res = await get(serviceCommon + "/region/getAllSubRegions");

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function getAllPisStatus() {
  try {
    var response;
    const res = await get(pisService + "/commonServiceRequest/getAllPisStatus");

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getPowerSummaryData(data) {
  try {
    var response;
    const res = await post(serviceCommon + "/powerSummary/getPowerSummaryData",data);
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getUserFullExport(data) {
  try {
    var response;
    const res = await post(serviceUser + "/user/UserFullExport", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}
export async function getsldDiagramById(data) {
  try {
    var response;
    const res = await get(serviceCommon + "/dashboard/sldDiagram/"+ data);
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAllModelData(Url) {
  try {
    var response;
    const res = await get(serviceCommon + Url);

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function powerDbModelCreate(Url,data) {
  try {
    var response;
    const res = await post(serviceCommon+Url,data);
   
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function powerDbModelGetById(Url) {
  try {
    var response;
    const res = await get(serviceCommon+Url);
   
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function powerDbModelUpdate(Url,data) {
  try {
    var response;
    const res = await post(serviceCommon+Url,data);
   
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function powerDbModelDelete(Url) {
  try {
    var response;
    const res = await post(serviceCommon+Url);
   
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function getNetworkDashboardData(data) {
  try {
    var response;
    const res = await post(serviceCommon + "/dashboard/networkDashboard", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}

export async function getReMasterData(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/base/getReMasterData");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function runEquations(data) {
  try {
    var response;
    const res = await post(serviceCommon + "/site/runEquations", data
    );
    if (res.status == 200) {
      response = {
        success: true,
      };
      return response;
    } else {
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;
  }
}
export async function networkDashboard(data) {
  try {
    var response;
    const res = await post(serviceCommon + "/dashboard/networkDashboard", data);
   
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


// QueryBuilder
export async function getPowerDbFilterData(data) {
  try {
    var response;
    const res = await post(serviceCommon + "/powerSiteData/getPowerDbFilterData",data);
   
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updateAcTechnologies(siteid,data) {
  try {
    var response;
    const res = await post(serviceCommon + "/update/acTechnologies/"+ siteid,data);
   
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function updateMpConnection(siteid,data) {
  try {
    var response;
    const res = await post(serviceCommon + "/update/mpConnection/"+ siteid,data);
   
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updateSbPower(siteid,data) {
  try {
    var response;
    const res = await post(serviceCommon + "/update/sbPower/"+ siteid,data);
   
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updatePowerCapacity(siteid,data) {
  try {
    var response;
    const res = await post(serviceCommon + "/update/powerCapacity/"+ siteid,data);
   
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updateDcLoad(siteid,data) {
  try {
    var response;
    const res = await post(serviceCommon + "/update/dcLoad/"+ siteid,data);
   
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updateEarthing(siteid,data) {
  try {
    var response;
    const res = await post(serviceCommon + "/update/earthing/"+ siteid,data);
   
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updateDcPowerSharing(siteid,data) {
  try {
    var response;
    const res = await post(serviceCommon + "/update/dcPowerSharing/"+ siteid,data);
   
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updateNoOfValues(data) {
  try {

    var response;
    const res = await post(serviceCommon + "/site/updateNoOfValues", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updateCabinDetails(data) {
  try {

    var response;
    const res = await post(serviceCommon + "/site/updateCabinDetails", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updateCaeDetails(data) {
  try {

    var response;
    const res = await post(serviceCommon + "/powerSiteData/updateCaeDetails", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function updateTrcslDetails(data) {
  try {

    var response;
    const res = await post(serviceCommon + "/powerSiteData/updateTrcslDetails", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function updateGenHutDetails(data) {
  try {

    var response;
    const res = await post(serviceCommon + "/site/updateGenHutDetails", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function getSitesLite(Url) {
  try {
    var response;
    const res = await get(serviceCommon +"/site/getSitesLite");

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function powerDataFileValidation(data) {
  try {

    var response;
    const res = await post(serviceCommon + "/fileValidation/upload", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function powerDataFileValidationSpecial(data) {
  try {

    var response;
    const res = await post(serviceCommon + "/fileValidation/special/upload", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getupdateGeneralDetailsById(data) {
  try {

    var response;
    const res = await post(serviceCommon + "/powerSiteData/getupdateGeneralDetailsById", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function dataUpdateGeneralDetails(data) {
  try {

    var response;
    const res = await post(serviceCommon + "/powerSiteData/updateGeneralDetails", data
    );
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
// routingObservation
export async function getRoutingObservationAll() {
  try {
    var response;
    const res = await get(serviceCommon +"/routingObservation/getAll");

    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function routingObservationCreate(data) {
  try {

    var response;
    const res = await post(serviceCommon + "/routingObservation/create", data
    );
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}

export async function routingObservationDetails(id) {
  try {
    var response;
    const res = await get(serviceCommon + "/routingObservation/getById/" + id);
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}


export async function UpdateroutingObservation(data) {
  try {

    var response;
    var headers = {
      userId: localStorage.getItem("userId"),

    };
    const res = await post(serviceCommon + "/routingObservation/update?userId" + localStorage.getItem("userId"), data, headers
    );
    if (res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getSitesLiteForSiteManage() {
  try {
    var response;
    
    const res = await get(serviceCommon + "/site/getSitesLiteForSiteManage");
    if (res.status == 200) {
      response = {
        success: true,
        data: res.data,
      };

      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getAllQueryTemplates(id) {
  try {

    var response;
    const res = await get(serviceCommon + "/templates/all/"+ localStorage.getItem("userId"), 
    );
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
export async function getQueryTemplatesCreate(data) {
  try {

    var response;
    const res = await post(serviceCommon + "/templates/create", data
    );
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
        data:res.data
      };
      return response;
    }
  } catch (err) {
    
    return false;

  }
}

export async function getQueryTemplatesDelete(data) {
  try {
   
    var response;
    const res = await post(serviceCommon + "/templates/delete/"+data
    );
    if (res.status == 200 || res.status == 201) {
      response = {
        success: true,
        data: res.data,
      };
      return response;
    } else {
      //throw new Error(texts.DEFAULT_ERROR_MESSAGE)
      response = {
        success: false,
        data: res.data,
      };
      return response;
    }
  } catch (err) {
    return false;

  }
}
