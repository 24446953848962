import React, { Component } from "react";
import {
  Table,
  Tag,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  InputNumber,
  message,
  Popconfirm,
  Collapse,
} from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import SimpleReactValidator from "simple-react-validator";
import { createNewdcdu, getDcduDetails, UpdateDcdu } from "../../api/index";
import AssetDelete from "../site_components/asset_delete";
import { checkServiceType } from "../../actions/service-type";

class Dcdus extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      title: "",
      tabaleLoading: false,
      actionView: false,
      showModal: false,
      open: false,
      openEdit: false,
      selectedAssetId: null,
      AssetType: [],
      dcduType: "",
      dcduCapacity: "",
      dcduFreeBreakerSlots: "",
      dcduPowerFromLLVD: "",
      dcduNoOfBreakerSlots: "",
      rbcapacityToPowerDCDU: "",
      AssetManagementPermission: false,
      openViewMoreDetails: false,
      selectedElement: "",
      rectifier: null,
      rectifierId: null,

      objDropdowndcdu: {
        dcduType: "",
        dcduPowerFromLLVD: "",
        dcduRbcapacityToPowerDCDU: "",
        dcduNoOfBreakerSlots: "",
        dcduFreeBreakerSlots: "",
      },
    };
    this.requestFromClose = this.requestFromClose.bind(this);
    this.handleDcduType = this.handleDcduType.bind(this);
    this.handleDcduCapacity = this.handleDcduCapacity.bind(this);
    this.handleFreeBreakerSlots = this.handleFreeBreakerSlots.bind(this);
    this.handlePowerFromLLVD = this.handlePowerFromLLVD.bind(this);
    this.handleNoOfBreakerSlots = this.handleNoOfBreakerSlots.bind(this);
    this.handleRbcapacityToPowerDCDU =
      this.handleRbcapacityToPowerDCDU.bind(this);
  }
  dcduUpdata(id) {
    // console.log('dfdfd')
    console.log(id);
    this.getDataDcduDetails(id);
    this.setState({ openEdit: true });
  }
  getDataDcduDetails = async (id) => {
    var res = await getDcduDetails(id);
    console.log(res);
    if (res.success) {
      this.setState({
        dcduEdit: res.data,
        dcduId: res.data.id,
        dcduType: res.data.type,
        dcduCapacity: res.data.totalCapacity,
        dcduFreeBreakerSlots: res.data.freeBreakerSlots,
        dcduPowerFromLLVD: res.data.powerFromLLVD,
        dcduNoOfBreakerSlots: res.data.noOfBreakerSlots,
        rbcapacityToPowerDCDU: res.data.rbcapacityToPowerDCDU,
      });
      if (res.data.rectifier != null && res.data.rectifier.delete == false) {
        console.log("ssssssssssss");
        console.log(res.data.rectifier);
        this.setState({
          rectifier: res.data.rectifier,
          rectifierId: res.data.rectifier.id,
        });
      } else {
        this.setState({
          rectifier: null,
          rectifierId: null,
        });
      }
    }
  };
  handleRbcapacityToPowerDCDU(e) {
    console.log(e);
    // console.log(e)
    this.setState({ rbcapacityToPowerDCDU: Math.round(e) });
  }
  handleNoOfBreakerSlots(e) {
    // console.log('ddddddd')
    // console.log(e)
    this.setState({ dcduNoOfBreakerSlots: Math.round(e) });
  }
  handlePowerFromLLVD(e) {
    this.setState({ dcduPowerFromLLVD: e });
  }
  handleFreeBreakerSlots(e) {
    //console.log(e.target.value)
    this.setState({ dcduFreeBreakerSlots: Math.round(e) });
  }
  handleDcduType(e) {
    //console.log(e.target.value)
    this.setState({ dcduType: e });
  }
  handleDcduCapacity(e) {
    console.log(e);
    this.setState({ dcduCapacity: e });
  }
  componentDidMount() {
    if (JSON.parse(localStorage.getItem("serviceTypeSet")) == null) {
      const { history } = this.props;

      history.push("/");
    } else {
      this.getSetPageData(
        JSON.parse(localStorage.getItem("serviceTypeSet")),
        38
      );
    }
    checkServiceType(38).then((data) =>
      this.setState(
        {
          AssetManagementPermission: data,
        },
        () => {}
      )
    );
  }
  getSetPageData = (serviceTypeIs, Pageid) => {
    if (
      serviceTypeIs.serviceType.filter(
        (checkuser) => checkuser?.id == Pageid
      )[0] != null
    ) {
      console.log("fddf");
      this.setState({
        actionView: true,
      });
    }
  };
  requestFromClose() {
    console.log("dsdsd");
    this.setState({ showModal: true, open: true });
  }
  onDrawerClose = () => {
    console.log("sddsds");
    this.formRef.current.resetFields();

    this.validator.hideMessages();
    this.setState({
      open: false,
      openEdit: false,
      selectedAssetId: null,
      AssetType: [],
      dcduType: "",
      dcduCapacity: "",
      dcduFreeBreakerSlots: "",
      dcduPowerFromLLVD: "",
      dcduNoOfBreakerSlots: "",
      rbcapacityToPowerDCDU: "",
      rectifier: null,
      rectifierId: null,
    });
  };
  onChangeAsset = (value) => {
    console.log("onChangeAsset");
    //console.log(value);
    this.setState({
      selectedAssetId: value,
      AssetType: {
        id: value,
      },
    });
  };
  AllDataSubmitUpdate = async () => {
    if (this.validator.allValid()) {
      var dataSet;
      console.log("ssss");
      dataSet = {
        id: this.state.dcduId,
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        type: this.state.dcduType,
        totalCapacity: this.state.dcduCapacity,
        freeBreakerSlots: this.state.dcduFreeBreakerSlots,
        powerFromLLVD: this.state.dcduPowerFromLLVD,
        noOfBreakerSlots: this.state.dcduNoOfBreakerSlots,
        rbcapacityToPowerDCDU: this.state.rbcapacityToPowerDCDU,
        rectifier: this.state.rectifier,
      };
      console.log(dataSet);
      var res = await UpdateDcdu(dataSet);
      console.log(res);
      if (res.success) {
        message.success("Successfully DCDU Edited!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,
          openEdit: false,

          selectedAssetId: null,
          AssetType: [],
          dcduType: "",
          dcduCapacity: "",
          dcduFreeBreakerSlots: "",
          dcduPowerFromLLVD: "",
          dcduNoOfBreakerSlots: "",
          rbcapacityToPowerDCDU: "",
        });
        this.props.parentMethod();
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };
  getDataDetailsForDelete = async (id) => {
    var res = await getDcduDetails(id);
    console.log(res);
    if (res.success) {
      var respons = AssetDelete.Action(
        res.data,
        this.props?.detailsSite?.site_details.id,
        UpdateDcdu
      );

      respons.then((value) => {
        if (value == 1) {
          message.success("Successfully Delete!");
          this.props.parentMethod();
        } else {
          message.warning(" Something went wrong!");
        }
      });
    }
  };
  submitForm = async (item) => {
    this.getDataDetailsForDelete(item);
  };
  AllDataSubmit = async () => {
    if (this.validator.allValid()) {
      var dataSet;

      dataSet = {
        site: { id: this.props?.detailsSite?.site_details.id },
        asset: {
          id: 2,
        },
        type: this.state.dcduType,
        totalCapacity: this.state.dcduCapacity,
        freeBreakerSlots: this.state.dcduFreeBreakerSlots,
        powerFromLLVD: this.state.dcduPowerFromLLVD,
        noOfBreakerSlots: this.state.dcduNoOfBreakerSlots,
        rbcapacityToPowerDCDU: this.state.rbcapacityToPowerDCDU,
        rectifier: this.state.rectifier,
      };
      console.log(dataSet);
      var res = await createNewdcdu(dataSet);
      if (res.success) {
        message.success("Successfully DCDU Added!");
        this.formRef.current.resetFields();

        this.setState({
          showModal: false,
          open: false,
          selectedAssetId: null,
          AssetType: [],
          dcduType: "",
          dcduCapacity: "",
          dcduFreeBreakerSlots: "",
          dcduPowerFromLLVD: "",
          dcduNoOfBreakerSlots: "",
          rbcapacityToPowerDCDU: "",
        });
        this.props.parentMethod();
        // const { history } = this.props;
        // history.push("/site");
      } else {
        message.warning(" Something went wrong!");
      }
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  };

  onChangeRectifier = (value) => {
    console.log(value);
    if (value !== undefined) {
      this.setState({
        rectifier: { id: value },
      });
    } else {
      this.setState({
        rectifier: null,
      });
    }
  };

  viewMoreDetails(element) {
    this.setState({
      showModal2: true,
      openViewMoreDetails: true,
      selectedElement: element,
    });
  }

  onViewMoreDetailsDrawerClose = () => {
    this.setState({
      showModal2: false,
      openViewMoreDetails: false,
    });
  };

  validatedTextLable = (field) => {
    return field !== null ? (
      <span class="badge badge-outline-secondary text-muted">{field}</span>
    ) : (
      <span class="badge text-danger">Currently Not Set</span>
    );
  };

  render() {
    const { Option } = Select;
    const { Panel } = Collapse;
    const _dcdu = this.state.selectedElement;

    // const columns2 = [
    //     {
    //         title: "Type",
    //         details: [],
    //         content: <Tag color="blue" style={{ margin: "0px" }}><span>{_dcdu.type}</span></Tag>,
    //     },
    //     {
    //         title: "Current Consuming Load (A)",
    //         details: [],
    //         content: this.validatedTextLable(_dcdu.totalCapacity),
    //     },
    //     {
    //         title: "Rectifier",
    //         details: [],
    //         content: _dcdu !== ""
    //             ? _dcdu.rectifier != null && _dcdu.rectifier.delete == false
    //                 ? <span class="badge badge-soft-pink fw-semibold" style={{ marginLeft: "5px" }}>{_dcdu.rectifier?.name}</span>
    //                 : <span class="badge badge-outline-secondary text-muted">No</span>
    //             : <></>,
    //     },
    //     {
    //         title: "No Of Breaker Slots",
    //         details: [],
    //         content: this.validatedTextLable(_dcdu.noOfBreakerSlots),
    //     },
    //     {
    //         title: "Free Breaker Slots",
    //         details: [],
    //         content: this.validatedTextLable(_dcdu.freeBreakerSlots),
    //     },
    //     {
    //         title: "Power from LLVD/BLVD",
    //         details: [],
    //         content: this.validatedTextLable(_dcdu.powerFromLLVD),
    //     },
    //     {
    //         title: "Rectifier Breaker capacity (A) to power DCDU",
    //         details: [],
    //         content: this.validatedTextLable(_dcdu.rbcapacityToPowerDCDU),
    //     },
    // ];

    const columns2 = [
      {
        title: "Type",
        details: [],
        content: (
          <Tag color="blue" style={{ margin: "0px" }}>
            <span>{_dcdu.type}</span>
          </Tag>
        ),
      },
      {
        title: "Power from LLVD/BLVD",
        details: [],
        content: this.validatedTextLable(_dcdu.powerFromLLVD),
      },
      {
        title: "Rectifier Breaker capacity (A) to power DCDU",
        details: [],
        content: this.validatedTextLable(_dcdu.rbcapacityToPowerDCDU),
      },
      {
        title: "Total Capacity",
        details: [],
        content: this.validatedTextLable(_dcdu.totalCapacity),
      },
      {
        title: "No Of Breaker Slots",
        details: [],
        content: this.validatedTextLable(_dcdu.noOfBreakerSlots),
      },
      {
        title: "Free Breaker Slots",
        details: [],
        content: this.validatedTextLable(_dcdu.freeBreakerSlots),
      },
      {
        title: "Rectifier",
        details: [],
        content:
          _dcdu !== "" ? (
            _dcdu.rectifier != null && _dcdu.rectifier.delete == false ? (
              <span
                class="badge badge-soft-pink fw-semibold"
                style={{ marginLeft: "5px" }}
              >
                {_dcdu.rectifier?.name}
              </span>
            ) : (
              <span class="badge badge-outline-secondary text-muted">No</span>
            )
          ) : (
            <></>
          ),
      },
    ];

    console.log(
      "-----------------------------------------------------==================================================================="
    );
    console.log(this.props.dcdudrop);

    return (
      <>
        <div className="row">
          <div class="col-lg-12">
            <div class="text-end">
              <ul class="list-inline">
                <li class="list-inline-item">
                  {this.state.actionView ? (
                    <button
                      type="button"
                      class="btn btn-soft-primary btn-sm"
                      onClick={this.requestFromClose}
                    >
                      <i class="fas fa-plus me-2"></i>Add DCDUs
                    </button>
                  ) : (
                    ""
                  )}
                </li>
              </ul>
            </div>
          </div>
          {this.props.details.access_technologies_data != null ? (
            <div className="col-lg-2" style={{ paddingBottom: 12 }}>
              <table class="table table-dark2 mb-0" style={{ fontSize: 12 }}>
                <tbody>
                  <tr>
                    <td scope="row">
                      <b>No of DCDUs:</b>
                    </td>
                    <td>
                      {this.props?.details.access_technologies_data?.noOfDCDUs}
                    </td>
                    <td scope="row"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}

          <div className="row">
            {this.props.details2.dcdus_data.map((element) => (
              <div className="col-md-4">
                <div class="card">
                  <div class="card-header">
                    <div class="d-flex justify-content-start align-items-center">
                      <img
                        src={
                          "https://static.vecteezy.com/system/resources/previews/001/902/502/large_2x/telecommunication-radio-antenna-and-satelite-tower-with-blue-sky-free-photo.JPG"
                        }
                        alt=""
                        class="thumb-md rounded"
                      />
                      <div
                        class="badge badge-soft-primary"
                        style={{
                          padding: "5px",
                          marginLeft: "15px",
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <i
                          class="la la-home font-14 text-dark"
                          style={{ paddingLeft: "10px" }}
                        />
                        <i
                          class="la la-angle-right text-dark font-14"
                          style={{ padding: "5px" }}
                        />
                        <span class="fw-semibold text-pink">
                          {element.rectifier != null
                            ? element.rectifier?.name?.substring(0, 11)
                            : "No Rectifier"}
                        </span>
                        <i
                          class="la la-angle-right text-dark font-14"
                          style={{ padding: "5px" }}
                        />
                        <span
                          class="fw-semibold text-primary"
                          style={{ paddingRight: "10px" }}
                        >
                          {element?.type?.substring(0, 18)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="col">
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fw-normal m-0">Type</h6>
                        <span class="badge bg-secondary">{element.type}</span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fw-normal m-0">
                          Total Capacity
                        </h6>
                        <span class="badge badge-outline-info badge-pill">
                          {element.totalCapacity}
                        </span>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fw-normal m-0">
                          Rectifier Breaker capacity (A) to power DCDU
                        </h6>
                        <div class="d-flex align-items-center">
                          <span class="badge badge-soft-warning fw-semibold">
                            {element.rbcapacityToPowerDCDU}
                          </span>
                        </div>
                      </div>
                      <div class="d-flex justify-content-between align-items-center mb-2">
                        <h6 class="fw-normal m-0">Breaker Slots</h6>
                        <div d-flex align-items-center>
                          <span
                            class="badge badge-soft-success fw-semibold"
                            style={{ marginRight: "10px" }}
                          >
                            Total Slots {element.noOfBreakerSlots}
                          </span>
                          <span class="badge badge-soft-pink fw-semibold">
                            Free Slots {element.freeBreakerSlots}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="card-footer m-0">
                    <div class="d-flex justify-content-between align-items-center">
                      <a
                        href="#custom-modal"
                        class="btn btn-soft-dark btn-sm waves-effect btn-round"
                        onClick={() => this.viewMoreDetails(element)}
                      >
                        <i class="mdi mdi mdi-unfold-more-horizontal"></i> More
                        Details{" "}
                      </a>
                      <div class="text-end">
                        {this.state.AssetManagementPermission ? (
                          <button
                            type="button"
                            class="btn btn-soft-primary btn-icon-square-sm"
                            onClick={() => this.dcduUpdata(element.id)}
                            style={{ marginRight: "8px" }}
                          >
                            <i class="mdi mdi-pencil-outline"></i>
                          </button>
                        ) : (
                          ""
                        )}
                        <Popconfirm
                          title="Are You Sure?"
                          description="Are you sure to delete this task?"
                          onConfirm={() => this.submitForm(element.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          {this.state.AssetManagementPermission ? (
                            <button
                              type="button"
                              class="btn btn-soft-danger btn-icon-square-sm"
                            >
                              <i class="mdi mdi-trash-can-outline"></i>
                            </button>
                          ) : (
                            ""
                          )}
                        </Popconfirm>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            ))}
          </div>
          <Drawer
            maskClosable={false}
            title={"DCDU Details"}
            width={"50%"}
            placement="right"
            onClose={() => this.onViewMoreDetailsDrawerClose()}
            visible={this.state.openViewMoreDetails}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <Collapse
              style={{ borderColor: "lavender", alignContent: "center" }}
            >
              {columns2.map((item) => (
                <Panel
                  style={{ borderColor: "lavender" }}
                  header={
                    <li class="d-flex justify-content-between align-items-center">
                      <div>
                        {item.details.length == 0 ? (
                          <i
                            class="la la-hand-o-right text-primary"
                            style={{ paddingLeft: "5px", paddingRight: "5px" }}
                          />
                        ) : (
                          <></>
                        )}
                        <span class="fw-normal m-2">{item.title}</span>
                      </div>
                      {item.content}
                    </li>
                  }
                  key={item.title}
                  showArrow={item.details.length > 0}
                  collapsible={item.details.length > 0 ? "" : "icon"}
                >
                  {item.details.map((detail) => (
                    <li
                      class="d-flex justify-content-between align-items-center me-2"
                      style={{ paddingBottom: "10px", paddingLeft: "10px" }}
                    >
                      <div>
                        <i class="la la-circle text-primary me-2"></i>{" "}
                        <span>{detail.title}</span>
                      </div>
                      {detail.content}
                    </li>
                  ))}
                </Panel>
              ))}
            </Collapse>
          </Drawer>

          <Drawer
            maskClosable={false}
            title={" New DCDU"}
            width={"70%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.open}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Type <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item
                            required
                            name="dcdu_Type"
                            tooltip="This is a required field"
                          >
                            {/* <Input id="dcdu_Type" onChange={this.handleDcduType} /> */}
                            <div>
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handleDcduType(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduType"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "dcdu_Type",
                                this.state.dcduType,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Power from LLVD/BLVD{" "}
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item
                            required
                            name="powerFromLLVD"
                            tooltip="This is a required field"
                          >
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handlePowerFromLLVD(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduPowerFromLLVD"
                                ].map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "powerFromLLVD",
                                this.state.dcduPowerFromLLVD,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Rectifier Breaker capacity (A) to power DCDU{" "}
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item required>
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handleRbcapacityToPowerDCDU(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduRbcapacityToPowerDCDU"
                                ].map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "RbcapacityToPowerDCDU",
                                this.state.rbcapacityToPowerDCDU,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            Total Capacity{" "}
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item required name="dcdu_Capacity">
                            <InputNumber
                              style={{ width: "100%" }}
                              id="dcdu_Capacity"
                              onChange={this.handleDcduCapacity}
                            />
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "dcdu_Capacity",
                                this.state.dcduCapacity,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            No Of Breaker Slots{" "}
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item required>
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handleNoOfBreakerSlots(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduNoOfBreakerSlots"
                                ].map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "NoOfBreakerSlots",
                                this.state.dcduNoOfBreakerSlots,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            Free Breaker Slots <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item required>
                            <div>
                              <Select
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handleFreeBreakerSlots(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduFreeBreakerSlots"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "free_Breaker_Slots",
                                this.state.dcduFreeBreakerSlots,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Rectifier <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item
                            required
                            name="Is_active"
                            tooltip="This is a required field"
                          >
                            <Select
                              showSearch
                              onChange={this.onChangeRectifier}
                              style={{ width: "100%" }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              allowClear
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                            >
                              {this.props.dcduMasterData?.dcduMasterData?.rectifierList?.map(
                                (item, index) => {
                                  return (
                                    <Option value={item?.id}>
                                      {item?.name}
                                    </Option>
                                  );
                                }
                              )}
                            </Select>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "Rectifier",
                                this.state.rectifier,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>
                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        {" "}
                        <button
                          className="btn btn-primary"
                          onClick={() => this.AllDataSubmit()}
                        >
                          Add New Asset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
          <Drawer
            maskClosable={false}
            title={" Edit DCDU"}
            width={"70%"}
            placement="right"
            onClose={() => this.onDrawerClose()}
            visible={this.state.openEdit}
            closeIcon={
              <button
                type="button"
                class="btn btn-soft-danger btn-icon-square-sm"
                style={{ marginRight: "8px" }}
              >
                <i class="mdi dripicons-cross"></i>
              </button>
            }
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <Form ref={this.formRef}>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Type <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item tooltip="This is a required field">
                            <div>
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                value={this.state.dcduType}
                                onChange={(e) => {
                                  this.handleDcduType(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduType"
                                ].map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "dcdu_Type",
                                this.state.dcduType,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Power from LLVD/BLVD{" "}
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item>
                            <div>
                              <Select
                                value={this.state.dcduPowerFromLLVD}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handlePowerFromLLVD(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduPowerFromLLVD"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "powerFromLLVD",
                                this.state.dcduPowerFromLLVD,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Rectifier Breaker capacity (A) to power DCDU{" "}
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item>
                            <div>
                              <Select
                                value={this.state.rbcapacityToPowerDCDU}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handleRbcapacityToPowerDCDU(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduRbcapacityToPowerDCDU"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "RbcapacityToPowerDCDU",
                                this.state.rbcapacityToPowerDCDU,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            Total Capacity{" "}
                            <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item>
                            <InputNumber
                              style={{ width: "100%" }}
                              value={this.state.dcduCapacity}
                              id="dcdu_Capacity"
                              onChange={this.handleDcduCapacity}
                            />
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "dcdu_Capacity",
                                this.state.dcduCapacity,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>

                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            No Of Breaker Slots <x style={{ color: "red" }}></x>
                          </label>
                          <Form.Item>
                            <div>
                              <Select
                                value={this.state.dcduNoOfBreakerSlots}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handleNoOfBreakerSlots(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduNoOfBreakerSlots"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "NoOfBreakerSlots",
                                this.state.dcduNoOfBreakerSlots,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                        
                        <div className="col-md-6">
                          <label className="form-label" for="dcdu">
                            Free Breaker Slots <x style={{ color: "red" }}>*</x>
                          </label>
                          <Form.Item>
                            <div>
                              <Select
                                value={this.state.dcduFreeBreakerSlots}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  this.handleFreeBreakerSlots(e);
                                }}
                              >
                                {this.props.dcdudrop["objDropdowndcdu"][
                                  "dcduFreeBreakerSlots"
                                ]?.map((x, y) => {
                                  return <Option value={x}>{x}</Option>;
                                })}
                              </Select>
                            </div>
                            <p style={{ color: "#f5325c" }}>
                              {this.validator.message(
                                "free_Breaker_Slots",
                                this.state.dcduFreeBreakerSlots,
                                "required"
                              )}
                            </p>
                          </Form.Item>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label className="form-label" for="depot">
                            Rectifier <x style={{ color: "red" }}>*</x>
                          </label>
                          {this.state.rectifierId != null ? (
                            <Form.Item>
                              <Select
                                showSearch
                                defaultValue={this.state.rectifierId}
                                onChange={this.onChangeRectifier}
                                style={{ width: "100%" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.dcduMasterData?.dcduMasterData?.rectifierList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item?.id}>
                                        {item?.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c" }}>
                                {this.validator.message(
                                  "Rectifier",
                                  this.state.rectifier,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                          {this.state.rectifierId == null ? (
                            <Form.Item required>
                              <Select
                                showSearch
                                onChange={this.onChangeRectifier}
                                style={{ width: "100%" }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                allowClear
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                              >
                                {this.props.dcduMasterData?.dcduMasterData?.rectifierList?.map(
                                  (item, index) => {
                                    return (
                                      <Option value={item?.id}>
                                        {item?.name}
                                      </Option>
                                    );
                                  }
                                )}
                              </Select>
                              <p style={{ color: "#f5325c" }}>
                                {this.validator.message(
                                  "Rectifier",
                                  this.state.rectifier,
                                  "required"
                                )}
                              </p>
                            </Form.Item>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Form>
                    <div className="row" style={{ justifyContent: "end" }}>
                      <div className="col-md-2">
                        {" "}
                        <button
                          className="btn btn-primary"
                          onClick={() => this.AllDataSubmitUpdate()}
                        >
                          Update That Asset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Drawer>
        </div>
      </>
    );
  }
}
export default Dcdus;
